export enum RolsEnum {
    FACILITY_MANAGER = 'FM',
    FACILITY_ADMINISTRATOR = 'FA',
    ORGANIZATION_MANAGER = 'OM',
    ORGANIZATION_ADMINISTRATOR = 'OA',
    CUSTOMER_SUPPORT = 'CS',
    SUPPORT_MANAGER = 'SM',
    SYSTEM_ADMINISTRATOR = 'SA',
    SALE = 'VT',
    All = 'ALL',
  }