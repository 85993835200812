<div class="wrapper section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-8">
            <p class="back" routerLink="/internal-users-list" (click)="changeMenu()">
              <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all Users">
              Back to all internal users
            </p>
            <h1>
              Internal Invites Management
            </h1>
            <p>Use the Advance search filters to optimize your search.</p>
          </div>
          <div class="col-md-4 text-right btn-margin">
            <button type="button" class="btn btn-primary submit btnInvitatin" routerLink="/invite-internal-users">Invite
              Internal User(s) </button>
          </div>
        </div>




        <ul class="nav filter-menu">
          <li class="nav-item">
            <a class="nav-link actived">
              All invites
            </a>
          </li>
          <!-- <li class="nav-item">
              <a class="nav-link">
                Accepted
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                Pending
              </a>
            </li> -->
          <div class="navItem">
            <!-- <a class="filters-link"  (click)="filters()" style="padding-right: 100px;">
                <span *ngIf="activeFilters">Close</span>
                  Advance search
              </a> -->
            <a class="filters-link" (click)="filters()">
              <span *ngIf="activeFilters">Close advance search</span>
              <span *ngIf="!activeFilters">Advance search</span>
            </a>
            <a class="filters-link"
              style="padding-left: 20px; padding-right: 20px; color: #A5A8C5; text-decoration:none;display: none">
              <img [src]="'assets/images/icons/filter.svg'" alt="Sort">
              Sort
              <img [src]="'assets/images/icons/botton.svg'" alt="Sort"></a>
          </div>
        </ul>
        <hr>
        <div class="row filters" *ngIf="activeFilters" [formGroup]="searchAdvancedFrom">
          <div class="col-6 col-sm-3 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control input" placeholder="example@mail.com" formControlName="email"
                (change)="search()">
            </div>
          </div>
          <div class="col-6 col-sm-3 col-md-3 col-lg-3">
            <div class="form-group">
              <div class="form-group">
                <label for="role">Role</label>
                <select class="form-control input select-old" formControlName="role" name="role" (change)="search()">
                  <option value="0" selected>Show All</option>
                  <option *ngFor="let userRol of listDataUser" [value]="userRol.id">
                    {{ userRol.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row labels">
          <div [ngClass]="{'col-5 col-md-5 col-lg-5': internalRol, 'col-4': !internalRol }"
            *ngIf="oraganizationListRol || internalRol">
            <label for="email">email</label>
          </div>
          <div class="col-4 col-md-5"
            *ngIf="oraganizationListRol || internalRol">
            <label for="role">role</label>
          </div>

          <div class="col-5 col-md-5 col-lg-5" *ngIf="!(oraganizationListRol || internalRol)">
            <label for="email">email</label>
          </div>
          <div class="col-4 col-md-5 col-lg-5" *ngIf="!(oraganizationListRol || internalRol)">
            <label for="role">role</label>
          </div>
          <div class="col-3 col-md-2 col-lg-2 text-center ">
            <label for="options">options</label>
          </div>
        </div>
        <div class="row data" *ngFor="let user of dataInvite.data; let i = index">
          <div [ngClass]="{'col-5': internalRol, 'col-4': !internalRol }" *ngIf="oraganizationListRol || internalRol">
            <p class="info">{{ user.email }}</p>
          </div>
          <div class="col-4 col-md-5"  *ngIf="oraganizationListRol || internalRol">
            <p class="info">{{ user.role }}</p>
          </div>

          <div class="col-5" *ngIf="!(oraganizationListRol || internalRol)">
            <p class="info">{{ user.email }}</p>
          </div>
          <div class="col-4" *ngIf="!(oraganizationListRol || internalRol)">
            <p class="info">{{ user.role }}</p>
          </div>
          <div class="col-3 col-md-2 col-lg-2 text-center actions">
            <button type="button" class="btn btn-primary actived" *ngIf="roleLevel <= user.roleLevel"
              (click)="openModalUser(template, user,i)">
              <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light actived" *ngIf="roleLevel < user.roleLevel"
              (click)="openDeleteModal(delete, user,i)">
              <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete User">
            </button>
            <!--Disable Buttons-->
            <button type="button" class="btn btn-light disabled" disabled *ngIf="roleLevel <= user.roleLevel">
              <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light disabled" disabled *ngIf="roleLevel < user.roleLevel">
              <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete User">
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="row justify-content-center top-button">

      <div class="col-6 col-md-auto pading-right" *ngIf="activeLess">

        <p class="text-center">
          <button type="button" class="btn btn-light btn-view" (click)="viewLess()">View less</button>
        </p>
      </div>
      <div class="col-6 col-md-auto pading-left" *ngIf="!isDisabled">
        <p class="text-center">
          <button [style]="{'margin-right': !activeLess ? '0px': ''}" type="button" class="btn btn-primary btn-more" (click)="viewMore()" [disabled]="isDisabled">View
            more</button>
        </p>
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body">
    <p class="back" (click)="closeFirstModal(template)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to All Invites">
      Back to all internal invites
    </p>
    <div style="color:#D20404!important;text-align: left;">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <form [formGroup]="inviteManagementForm">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control input" formControlName="email" placeholder="example@mail.com">
        <div
          *ngIf="inviteManagementForm.controls['email'].invalid && (inviteManagementForm.controls['email'].dirty || inviteManagementForm.controls['email'].touched)"
          class="invalid-feedback">
          <div *ngIf="inviteManagementForm.controls['email'].errors.required">* Email is required</div>
          <div *ngIf="inviteManagementForm.controls['email'].errors.email">* Email pattern is not valid</div>
        </div>
      </div>
      <div class="form-group">
        <label for="role">Role</label>
        <select class="form-control input select" formControlName="role">
          <option value="" selected>Select Role</option>
          <option *ngFor="let userRol of listDataUserEdit" [value]="userRol.id">
            {{ userRol.name }}
          </option>
        </select>
        <div
          *ngIf="inviteManagementForm.controls['role'].invalid && (inviteManagementForm.controls['role'].dirty || inviteManagementForm.controls['role'].touched)"
          class="invalid-feedback">
          <div *ngIf="inviteManagementForm.controls['role'].errors.required">* Role is required</div>
        </div>
      </div>
      <p class="text-center">
        <button type="button" [disabled]="inviteManagementForm.invalid"
          style="" (click)="closeFirstModalUser(succesfully)"
          class="btn btn-primary submit btn-modal">save changes</button>
      </p>
      <!-- <p class="bottom-info  text-center">Disclaimer here?</p> -->
    </form>

  </div>
</ng-template>
<ng-template #delete>

  <div class="modal-body text-center modal-fit">
    <h1>
      Are you sure you want to

      <span class="accent-text">delete</span> this invite?
    </h1>
    <p>
      <button type="button" (click)="closeFirstModal(delete)" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeleteModal(succesfully)">yes I want to delete it</button>
    </p>
  </div>

</ng-template>
<ng-template #succesfully>
  <div class="modal-body text-center modal-fit">
    <h1>
      Invited {{ message }}

      <span class="accent-text">successfully!</span>
    </h1>
  </div>
</ng-template>
