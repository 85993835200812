<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-3 col-md-3 col-lg-3 textalin ">
        <p class="back backto" routerLink="/units-dashboard">
          <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to units hub" *ngIf="dataUnit !== null">
          Back to units hub
        </p>
        <h1>{{ dataUnit?.name }}</h1>
        <span class="size-span">Serial Number: {{dataUnit?.suinNumber}}</span>
        <p class="network">
          <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network" *ngIf="dataUnit !== null">
          {{ dataUnit?.ipAddress}}
        </p>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
        <div class="row" *ngIf="dataUnit !== null">
          <div class="col-3 col-sm-4 col-md-3 col-lg-4">
            <label for="status">status</label>
            <p class="info">
              <span class="status online" *ngIf="dataUnit?.status === 'Online'">
                <span class="online"></span>
                {{ dataUnit?.status}}
              </span>
              <span class="status offline" *ngIf="dataUnit?.status === 'Offline'">
                <span class="offline"></span>
                {{ dataUnit?.status}}
              </span>
            </p>
            <label for="battery">battery</label>
            <p *ngIf="dataUnit !== null" class="info">
              {{ dataUnit?.lastFlush?.batteryLevel }}
              <span [ngClass]="{'ocultar-temperatura': !dataUnit.lastFlush }">
                %
              </span>
              <img [src]="'assets/images/icons/battery.svg'" width="30px" alt="Battery"
                [ngClass]="{'ocultar-temperatura': !dataUnit.lastFlush }" *ngIf="dataUnit !== null">
            </p>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4">
            <label for="flushes">Flushes</label>
            <p class="info">{{ dataUnit?.flushesNumber}}</p>
            <label for="last flushes">last flush date</label>
            <p class="info">{{ dataUnit?.lastFlush?.date | moment: 'timestamp'}}</p>
          </div>
          <div class="col-5 col-sm-4 col-md-5 col-lg-4">
            <label for="solenoid">Case temperature</label>
            <p class="solenoid" *ngIf="dataUnit !== null">
              {{ dataUnit?.lastFlush?.selenoidTemperature }}
              <span [ngClass]="{'ocultar-temperatura': !dataUnit.lastFlush }">°C</span>
            </p>
            <label for="presure on filters" >pressure on filters</label>
            <p class="presures good" *ngIf="dataUnit?.filtersPerformance === 'Good'">{{
              dataUnit?.filtersPerformance }}</p>
            <p class="presures critical" *ngIf="dataUnit?.filtersPerformance === 'Critical'">{{
              dataUnit?.filtersPerformance }}</p>
            <p class="presures requires-examination" *ngIf="dataUnit?.filtersPerformance === 'Requires Examination'">{{
              dataUnit?.filtersPerformance }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3 col-md-3 col-lg-3 btn-col ">
        <button class="btn btn-primary btn-edit" (click)="openEditModal(edit)" >edit
          unit</button>
        <button class="btn btn-light btn-detach" (click)="showDetachUnit(detach)">detach
          unit</button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4">
        <div class="card flushesHistoryCard">
          <label class="labelFlushes" for="Flushes history">
            <img [src]="'assets/images/icons/history-icon.svg'" alt="History" *ngIf="dataUnit !== null">
            Flushes history
          </label>
          <ul class="nav card-menu">
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'all':  allClass, 'active':  allClass}" (click)="getAll()">
                <span class="icon-all"></span>
                <br>
                All
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'steady': steadyClass, 'active': steadyClass}"
                (click)="getParamsFlusHealt('Steady')">
                <span class="icon-steady" *ngIf="!steadyClass"></span>
                <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Bad" class="span-imag"
                  *ngIf="steadyClass">
                <br>
                Steady
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link " [ngClass]="{'warning': warninClass, 'active': warninClass}"
                (click)="getParamsFlusHealt('Warning')">
                <span class="icon-warning" *ngIf="!warninClass"></span>
                <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Bad" class="span-imag"
                  *ngIf="warninClass">
                <br>
                Warning
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" [ngClass]="{'critical': badClass, 'active': badClass}"
                (click)="getParamsFlusHealt('Critical')">
                <span class="icon-critical" *ngIf="!badClass"></span>
                <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad" class="span-imag"
                  *ngIf="badClass">
                <br>
                Critical
              </a>
            </li>
          </ul>
          <hr>
          <div class="tarjeta">
            <div class="accordion" id="accordionGood1" *ngFor="let flush of dataFlushesHistory;index as i">
              <div class="card card-bottom" (click)="open(5)" [ngStyle]="applyClass1 ? {'background': 'initial'} : {'': ''}"
                 >
                <div class="card-header" id="headingOne">
                  <label class="text-left" type="button" data-toggle="collapse" [attr.data-target]="'#collapseGood'+i"
                    aria-expanded="false" [attr.aria-controls]="'collapseGood'+i" class="full-width">
                    <span>
                      <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Bad" class="span-imag"
                        *ngIf="flush.health === 'Steady'">
                      <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Bad" class="span-imag"
                        *ngIf="flush.health === 'Warning'">
                      <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad" class="span-imag"
                        *ngIf="flush.health === 'Critical'">
                    </span>
                    {{ flush.date | moment: 'timestamp' }}
                    <img [src]="'assets/images/icons/arrow_button_down.svg'" style="float: right;" alt="collapse">
                  </label>
                </div>

                <div id="collapseGood{{i}}" class="collapse" aria-labelledby="headingUnlink"
                  data-parent="#accordionGood{{i}}">
                  <div class="card-body" style="padding-top: 0px;">
                    <div class="row">
                      <div class="col-4 col">
                        <p class="info">{{ flush.batteryLevel }}% <img [src]="'assets/images/icons/battery.svg'"
                            width="30px" alt="Battery"></p>
                        <b class="solenoid">Case <span style="color: #303FD7;">{{
                            flush.selenoidTemperature }}°C</span></b>

                      </div>
                      <div class="col-4 col" *ngFor="let filterFlush of dataFlushesHistory[i]?.filterModel">
                        <mwl-gauge *ngIf="filterFlush?.value > 40" [max]="50" [dialStartAngle]="180" [dialEndAngle]="0"
                          [value]="filterFlush?.value" [animated]="true" [animationDuration]="1"
                          class="mwl-gauge bad-status margin-mobile">
                        </mwl-gauge>

                        <mwl-gauge *ngIf="filterFlush?.value <= 25" [max]="50" [dialStartAngle]="180" [dialEndAngle]="0"
                          [value]="filterFlush?.value" [animated]="true" [animationDuration]="1"
                          class="mwl-gauge ok-status margin-mobile">
                        </mwl-gauge>

                        <mwl-gauge *ngIf="filterFlush?.value > 25 && filterFlush?.value < 40" [max]="50"
                          [dialStartAngle]="180" [dialEndAngle]="0" [value]="filterFlush?.value" [animated]="true"
                          [animationDuration]="1" class="mwl-gauge warning-status margin-mobile">
                        </mwl-gauge>

                        <span class="c-grey2 psi-class"
                          >
                          PSI
                        </span>
                        <p class="status-text filter-class" >
                          <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad"
                            *ngIf="filterFlush.value > 40">
                          <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good"
                            *ngIf="filterFlush.value <= 25">
                          <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Warning"
                            *ngIf="filterFlush.value > 25 && filterFlush.value < 40">
                          Filter #0{{ filterFlush.filter }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul class="nav filter-menu advance-class" *ngIf="dataFlushesHistory?.length > 0">
            <a class="filters-link font-filter" [routerLink]="['/flushes-history/' + dataUnit?.id]" style="color: #00CFFF;">
              Advance search
            </a>
          </ul>
        </div>

      </div>
      <div class="col-12 col-sm-4 col-md-3 col-lg-4 top-filters">
        <label class="top-fil" for="Filters" *ngIf="filterModel.length > 0" >
          <img [src]="'assets/images/icons/filters.svg'" alt="Filters">
          Filters
        </label>
        <div class="row" *ngIf="filterModel.length > 0">
          <div class="col-4 col-sm-6 col-md-6 col-lg-4 margin-topFil padding-graf" *ngFor="let item of filterModel">
            <mwl-gauge *ngIf="item.value > 40" [max]="50" [dialStartAngle]="180" [dialEndAngle]="0" [value]="item.value"
              [animated]="true" [animationDuration]="1" class="bad-status width-gaugue">
            </mwl-gauge>
            <mwl-gauge *ngIf="item.value <= 25" [max]="50" [dialStartAngle]="180" [dialEndAngle]="0"
              [value]="item.value" [animated]="true" [animationDuration]="1" class="ok-status width-gaugue">
            </mwl-gauge>
            <mwl-gauge *ngIf="item.value > 25 && item.value < 40" [max]="50" [dialStartAngle]="180" [dialEndAngle]="0"
              [value]="item.value" [animated]="true" [animationDuration]="1" class="warning-status width-gaugue">
            </mwl-gauge>
            <span class="c-grey2 class-span" [ngClass]="{'ocultar-temperatura': !item.value }"
              >
              PSI
            </span>
            <p class="status-text margin-position"
              [ngClass]="{'ocultar-temperatura': !item.value }">
              <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad" *ngIf="item.value > 40">
              <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good" *ngIf="item.value <= 25">
              <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Warning"
                *ngIf="item.value > 25 && item.value < 40">
              Filter #0{{ item.filter }}
            </p>
          </div>
        </div>
       <!--  <hr style="margin-top: 75px;"> -->
        <!--  <p class="text-center filters-subtext">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Donec porta purus nisi, vitae laoreet justo aliquet sed.
          Integer venenatis magna imperdiet lacus eleifend.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
       <!--  <div class="row">
          <div class="col-12 col-sm-6 col-md-6">
            <div class="card">
               <label for="Health" *ngIf="dataUnit !== null">
                <img [src]="'assets/images/icons/health-label.svg'" alt="Health">
                Health
               </label> -
              <div class="row" style="margin-top: 1em;" *ngIf="dataUnit !== null">
                  <div class="col-3 text-right">
                  <img [src]="'assets/images/icons/status-bad.svg'" alt="Health"
                    *ngIf="dataUnit.lastFlush?.health === 'Critical'">
                  <img [src]="'assets/images/icons/status-check.svg'" alt="Health"
                    *ngIf="dataUnit.lastFlush?.health === 'Warning'">
                  <img [src]="'assets/images/icons/status-good.svg'" alt="Health"
                    *ngIf="dataUnit.lastFlush?.health === 'Steady'">
                </div>

                   <div class="col-9">
                  <p><span [innerHTML]="htmlDetail"></span></p>
                </div>
              </div>
               <p class="text-center link"><a>Having any inquiry or issue?<br>report a problem</a></p>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6">
               <div class="accordion" id="accordionUnlink" style="margin-bottom: 1em;">
              <div class="card" *ngIf="dataUnit !== null">
                <div class="card-header" id="headingOne">
                  <label class="text-left" type="button" data-toggle="collapse" data-target="#collapseUnlink"
                    aria-expanded="false" aria-controls="collapseUnlink" class="full-width">
                    <img src="assets/images/icons/savings_statistics.svg" alt="statistics">
                    saving statistics
                    <img [src]="'assets/images/icons/arrow-button.svg'" class="collapse-arrow" alt="collapse">
                  </label>
                </div>

                <div id="collapseUnlink" class="collapse" aria-labelledby="headingUnlink"
                  data-parent="#accordionUnlink">
                  <div class="card-body">
                    <a class="filters-link"  [routerLink]="['/saving-statistics/' + dataUnit?.id]" style="color: #00CFFF;display: flex;justify-content: center;">
                      Advance search
                    </a>
                  </div>
                </div>
              </div>

            </div>
             <div class="accordion" id="accordionExample" style="margin-bottom: 1em;">
              <div class="card" *ngIf="dataUnit !== null">
                <div class="card-header" id="headingOne">
                  <label class="text-left" type="button" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne" class="full-width">
                    <img [src]="'assets/images/icons/reset-icon.svg'" alt="Reboot">
                    Reboot
                    <img [src]="'assets/images/icons/arrow-button.svg'" class="collapse-arrow" alt="collapse">
                  </label>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                  data-parent="#accordionExample">
                  <div class="card-body">
                    You are about to reboot this device, which will take around 1 minute, are you
                    sure you want to continue?
                    <button class="btn btn-primary" (click)="showReset(reboot)" style="width: 100%; margin-top: 1em;
                                            border-radius: 2rem; color: #00CFFF; font-weight: 700;"
                      *ngIf="internalRol">
                      Reboot
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
         </div> -->
      </div>
      <div class="col-12 col-sm-4 col-md-5 col-lg-4">

        <div class="card">
          <label for="Health" *ngIf="dataUnit !== null">
            <img [src]="'assets/images/icons/health-label.svg'" alt="Health">
            Health
          </label>
          <div class="row margin-health" *ngIf="dataUnit !== null">
            <div class="col-3 text-right width-img">
              <img [src]="'assets/images/icons/status-bad.svg'" alt="Health"
                *ngIf="dataUnit.lastFlush?.health === 'Critical'" class="width-img">
              <img [src]="'assets/images/icons/status-check.svg'" alt="Health"
                *ngIf="dataUnit.lastFlush?.health === 'Warning'" class="width-img">
              <img [src]="'assets/images/icons/status-good.svg'" alt="Health"
                *ngIf="dataUnit.lastFlush?.health === 'Steady'" class="width-img">
            </div>

            <div class="col-9">
              <p class="marginHealthP"><span [innerHTML]="htmlDetail"></span></p>
            </div>
          </div>
          <!--  <p class="text-center link"><a>Having any inquiry or issue?<br>report a problem</a></p> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modals -->
<ng-template #edit>
  <div class="modal-body modal-fit">
    <p class="bottom-p"  (click)="closeModal(edit)">
      <img [src]="'assets/images/icons/arrow-left.svg'" alt="Back to Organization Profile">
      <a class="backto" (click)="closeModal(edit)">
        Back to unit profile
      </a>
    </p>
    <div class="error" *ngIf="msgError.length !==0">
      <ul *ngIf="msgError.length > 0">
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="error" *ngIf="mensajeEr !== ''">
      <span>
        {{ mensajeEr }}
      </span>
    </div>
    <form [formGroup]="FacilityEdit">
      <div class="form-group">
        <label for="unity">Name</label>
        <input type="text" class="form-control input" id="unity" formControlName="unity" placeholder="Name">
        <div
          *ngIf="FacilityEdit.controls['unity'].invalid && (FacilityEdit.controls['unity'].dirty || FacilityEdit.controls['unity'].touched)"
          class="invalid-feedback">
          <div *ngIf="FacilityEdit.controls['unity'].errors.required">* Name is required</div>
          <div *ngIf="FacilityEdit.controls['unity'].errors.maxlength">
            * Name must have at most 50 characters
          </div>
          <div *ngIf="FacilityEdit.controls['unity'].errors.minlength">
            * Name must be at least 5 characters long
          </div>
        </div>
      </div>
      <div class="form-group"
        *ngIf="(internalRol || oraganizationListRol || facilityListRol) &&  !(userRole === 'FM' || userRole === 'FA')">
        <label for="facility">Facility</label>
        <select class="form-control input select-old" id="facility" name="facility" formControlName="facility"
          (change)="valueFacility($event)">
          <option [value]="0" selected>Select Facility</option>
          <option *ngFor="let tipo of optionFacility" [value]="tipo.id">
            {{ tipo.name }}
          </option>
        </select>
        <div *ngIf="FacilityEdit.controls['facility'].invalid && (FacilityEdit.controls['facility'].dirty
                || FacilityEdit.controls['facility'].touched)" class="invalid-feedback">
          <div *ngIf="FacilityEdit.controls['facility'].errors.required">* Facility is required</div>
        </div>
      </div>
      <div class="form-group" style="margin: 2em 0"
        *ngIf="(internalRol || oraganizationListRol || facilityListRol) && (userRole === 'FM' || userRole === 'FA')">
        <label for="Facility">Facility</label>
        <input type="text" class="form-control input" disabled value="{{nameFacility}}">

      </div>
      <div class="text-center">
        <button type="button" [disabled]="FacilityEdit.invalid"
           (click)="closeFirstModal(edit)"
          class="btn btn-primary submit btn-save">save changes</button>
      </div>
      <!--  <p class="bottom-info text-center">Disclaimer here?</p> -->
    </form>

  </div>
</ng-template>

<ng-template #detach>
  <div class="modal-body" style="padding-left: 0px;padding-right: 0px;">



        <p (click)="closeModal(edit)" class="back2" style="">
          <img [src]="'assets/images/icons/arrow-left.svg'" alt="Back to Organization Profile">
          Back to unit profile
        </p>


  </div>
  <div style="color:#D20404;text-align: left;margin-bottom: 1em; font-size: 1rem; margin-top: -60px;">
    <ul *ngIf="msgDetach.length > 0">
      <li *ngFor="let error of msgDetach">
        {{ error }}
      </li>
    </ul>
  </div>
  <div class="error" *ngIf="mensajeEr !== ''">
    <span>
      {{ mensajeEr }}
    </span>
  </div>
  <div class="modal-body text-center">
    <h1>

      <span>Are you sure you want to <span class="accent-text">detach</span> this unit?</span>
    </h1>
    <p>
      <button  type="button" translate="cancel"
        class="btn btn-primary full-width btn-save" (click)="closeDatached()">no, I want to go back</button>
    </p>
    <p>
      <button
        type="button" translate="ok" class="btn btn-light full-width btn-yes" (click)="okDetach()">yes I want to detach
        it</button>
    </p>
  </div>
</ng-template>

<ng-template #reboot>
  <div class="modal-body" style="padding-left: 0px;padding-right: 0px;">
    <div class="row">
      <div class="col-md-6 text-left">
        <img [src]="'assets/images/icons/arrow-left.svg'" alt="Back to Organization Profile">
        <a (click)="closeModal(edit)" style="color: #303FD7; cursor: pointer; padding-left: 10px;">
          Back to unit profile
        </a>
      </div>
    </div>
  </div>
  <div style="color:#D20404;text-align: left;margin-bottom: 1em; font-size: 1rem">
    <ul *ngIf="msgErrorReboot.length > 0">
      <li *ngFor="let error of msgErrorReboot">
        {{ error }}
      </li>
    </ul>
  </div>
  <div style="color:#D20404;text-align: left;margin-bottom: 1em;" *ngIf="mensajeEr !== ''">
    <span>
      {{ mensajeEr }}
    </span>
  </div>
  <div class="modal-body text-center">
    <h1>
      <span>Are you sure you want to <span class="accent-text">reboot</span> this unit?</span>
    </h1>
    <p>
      <button style="border-radius: 26px; color: #00CFFF; font-weight: 700;" type="button" translate="cancel"
        class="btn btn-primary full-width" (click)="closeModal()">no, I want to go back</button>
    </p>
    <p>
      <button style="border-radius: 26px; color:#303FD7; background: #ffffff !important; font-weight: 700;"
        type="button" translate="ok" class="btn btn-light full-width" (click)="closeModalReebot()">yes I want to
        reboot it</button>
    </p>
  </div>
</ng-template>
