import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
})
export class FilterMomentPipe implements PipeTransform {
  constructor() {}
  transform(
    value: string | Date,
    type: 'timestamp' | 'shortDate'
  ): string {
    if (value) {
      switch (type) {
        case 'timestamp':
          return moment(value)
            .format('MM-DD-YYYY HH:mm:ss');
        case 'shortDate':
          return moment(value)
            .format('MM-DD-YYYY');
      }
    }
  }
}
