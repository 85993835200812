<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p class="back" [routerLink]="['/unit-details/' + unitId]">
                    <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to unit profile">
                    Back to unit profile
                </p>
                <h1>Flushes history</h1>

            </div>
        </div>
        <ul class="nav filter-menu" style="float: right; margin-top: -12px;">
            <a class="filters-link"  (click)="filters()">
                <span *ngIf="activeFilters">Close advance search</span>
                <span *ngIf="!activeFilters">Advance search</span>
            </a>
            <a class="filters-link" style="padding-left: 20px; padding-right: 20px; color: #A5A8C5; text-decoration:none; display: none" >
                <img style="padding-right: 6px;" [src]="'assets/images/icons/filter.svg'" alt="Sort">
                    Sort
                <img style="padding-right: 6px;" [src]="'assets/images/icons/botton.svg'" alt="Sort"></a>
        </ul>
        <hr>
        <div class="row filters" *ngIf="activeFilters" [formGroup]="searchForm">
            <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                <div class="form-group">
                    <label>Date</label>
                    <input type="text"
                    placeholder="mm-dd-yyyy"
                    class="form-control input"
                    formControlName="date"
                    name="date"
                    id="date"
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"
                    bsDatepicker
                    >
                </div>
                <div *ngIf="searchForm.controls['date'].invalid" class="invalid-feedback">

                  <span>Date is invalid</span>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                <div class="form-group">
                    <label for="temperature">Case Temperature</label>
                    <input type="number"
                        nma="temperature"
                        class="form-control input"
                        formControlName="temperature"
                        placeholder="Case Temperature"
                        min="0"
                        max="50"
                        maxlength="2"
                        (change)="search()">
                </div>
            </div>
            <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                <div class="form-group">
                    <label for="presure">Pressure on Filters</label>
                    <select
                        class="form-control input select-old"
                        id="presure"
                        formControlName="presure"
                        name="presure"
                        (change)="search()">
                        <option value="0" selected>Show All</option>
                        <option value="Good">Good</option>
                        <option value="Requires Examination">Requires Examination</option>
                        <option value="Critical">Critical</option>
                    </select>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                <div class="form-group">
                    <label for="health">Health Diagnosis</label>
                    <select
                        class="form-control input select-old"
                        id="health"
                        formControlName="health"
                        name="health"
                        (change)="search()">
                        <option value="0" selected>Show All</option>
                        <option value="Steady">Steady</option>
                        <option value="Warning">Warning</option>
                        <option value="Critical">Critical</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row labels">
            <div class="col-5 col-md-3 col-lg-3">
                <label for="date">date and time</label>
            </div>
            <div class="col-5 col-md-3 col-lg-3 ">
                <label class="paddin-fil" for="filters">filters</label>
            </div>
            <div class="col-2 col-md-3 col-lg-3 text-center caseTem">
                <label for="solenoid">case temperature</label>
            </div>
            <div class="col-2 col-md-1 col-lg-1 text-center">
                <label for="health">health</label>
            </div>
            <div class="col-2 col-md-2 col-lg-2 text-center battery">
                <label for="status">Battery Level</label>
            </div>
        </div>
        <div class="row data" *ngFor="let data of dataHistory.data; let i = index">
            <div class="col-5 col-md-3 col-lg-3">
                <h5>{{ data.date | moment: 'timestamp' }}</h5>
            </div>
            <div class="col-5 col-md-3 col-lg-3 ">
                <div class="row text-center paddin-col">
                    <div class="col-6 col " *ngFor="let item of dataHistory.filterHistory[i]">
                        <mwl-gauge
                            *ngIf="item.value > 40"
                            [max]="50"
                            [dialStartAngle]="180"
                            [dialEndAngle]="0"
                            [value]="item.value"
                            [animated]="true"
                            [animationDuration]="1"
                            class="bad-status"
                        >
                        </mwl-gauge>
                        <mwl-gauge
                            *ngIf="item.value <= 25"
                            [max]="50"
                            [dialStartAngle]="180"
                            [dialEndAngle]="0"
                            [value]="item.value"
                            [animated]="true"
                            [animationDuration]="1"
                            class="ok-status"
                        >
                        </mwl-gauge>
                        <mwl-gauge
                            *ngIf="item.value > 25 && item.value < 40"
                            [max]="50"
                            [dialStartAngle]="180"
                            [dialEndAngle]="0"
                            [value]="item.value"
                            [animated]="true"
                            [animationDuration]="1"
                            class="warning-status"
                        >
                        </mwl-gauge>
                        <span class="c-grey2 class-span">PSI</span>
                        <p class="status-text">
                            <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad" *ngIf="item.value > 40">
                            <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good" *ngIf="item.value <= 25">
                            <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Warning" *ngIf="item.value > 25 && item.value < 40">
                            Filter #0{{ item.filter }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-3 col-lg-3 text-center temperature">
                <p class="info solenoid" style="color: #000000;">{{ data.selenoidTemperature }} °C</p>
            </div>
            <div class="col-2 col-md-1 col-lg-1 text-center">
                <p class="info good" *ngIf="data.health === 'Steady'">{{ data.health }}</p>
                <p class="info critical" *ngIf="data.health === 'Critical'">{{ data.health }}</p>
                <p class="info warning" *ngIf="data.health === 'Warning'">{{ data.health }}</p>
            </div>
            <div class="col-2  col-md-2 col-lg-2 text-center batterylevel">
                <p class="status-box">
                    <span style="color:#707070">
                        {{ data.batteryLevel }}%
                    </span>
                    <!-- <span class="status on">
                        <span class="online"></span>
                        {{ data.batteryLevel}}
                    </span> -->
                </p>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-md-center" style="padding-top: 1em;">

          <div class="col-md-auto">

            <p class="text-center" *ngIf="activeLess">
              <button type="button"

                class="btn btn-light btn-view" (click)="viewLess()">View less</button>

            </p>


          </div>
          <div class="col-md-auto">
            <p class="text-center" *ngIf="!isDisabled">
              <button type="button" class="btn btn-primary btn-more"
                (click)="viewMore()" [disabled]="isDisabled">View more</button>
            </p>
          </div>
        </div>
    </div>
</div>
