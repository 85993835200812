import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { RolsEnum } from '@Models/enum/rols.enum';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export interface ResponseDataOtganizations {
  data: any;
  meta: any;
}

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.sass'],
})
export class OrganizationListComponent implements OnInit {
  activeFilters: boolean = false;
  allUnitsClassActive: boolean = true;
  onlineClassActive: boolean = false;
  OfflineClassActive: boolean = false;
  isDisabled: boolean = false;
  messageModal: string = '';
  facilityForm: FormGroup;
  searchAdvancedFrom: FormGroup;
  userRole: RolsEnum;
  modalRef: BsModalRef | null;
  dataOrganization: ResponseDataOtganizations = {
    data: [],
    meta: [],
  };
  listOrganization: any = [];
  listState: any = [];
  internalRol: boolean = false;
  activeLess: boolean = false;
  rowActual;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private appServices: AppServices,
    private authService: AuthService,
    private toastrService: ToastrService,
    private sideNavbarService: SideNavbarService,
    private router: Router,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('OL');
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let indexOrganizationList = menu.findIndex(
        (item) => item.link === '/organization-list'
      );
      const indexUserInvite = menu.findIndex((item) =>
        item.link.includes('/organization-profile')
      );
      const orgProfile = menu.findIndex(
        (item) => item.link === `/set-up-organization`
      );
      let indexMneChange = -1;
      if (indexOrganizationList !== -1) {
        indexMneChange = indexOrganizationList;
      } else if (indexUserInvite !== -1) {
        indexMneChange = indexUserInvite;
      } else if (orgProfile !== -1) {
        indexMneChange = orgProfile;
      }
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = this.router.url;
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
  }

  ngOnInit(): void {
    this.userRole = this.authService.authStatus.getValue().role;

  /*   this.activeFilters =
      localStorage.getItem('urlSearch') === null ? false : true; */
    let listSearch =
      JSON.parse(localStorage.getItem('paramSearch')) === null
        ? { name: '', state: 0, city: '' }
        : JSON.parse(localStorage.getItem('paramSearch'));

    this.searchAdvancedFrom = this.fb.group({
      name: [listSearch.name === '' ? '' : listSearch.name],
      state: [listSearch.state === 0 ? 0 : listSearch.state],
      city: [listSearch.city === '' ? '' : listSearch.city],
    });
    this.buildFormFacility();
    this.getAllOrganization();
    this.getListOrganization();
    this.getStates();
    if (
      listSearch.name === '' &&
      listSearch.state === 0 &&
      listSearch.city === ''
    ) {
      this.activeFilters = false;
    } else {
      this.activeFilters = true;
    }

    if (typeof(this.dataOrganization.meta.currentPage) === "number") {
      if (this.dataOrganization.meta.currentPage === 1) {
        this.isDisabled = false;
        this.activeLess = false;
      }else if (this.dataOrganization.meta.totalPages === this.dataOrganization.meta.currentPage) {
        this.isDisabled = true;
        this.activeLess = true;
      }else{
        this.isDisabled = false;
        this.activeLess = true;
      }
    }else {
      this.isDisabled = false;
      this.activeLess = false;
    }


    let classOnlineOffline = localStorage.getItem('classActiveOnlineOffline');
    this.OfflineClassActive = classOnlineOffline === 'O' ? true : false;
    this.onlineClassActive = classOnlineOffline === 'A' ? true : false;
    this.allUnitsClassActive = classOnlineOffline === null ? true : false;
  }

  ngOnDestroy(): void {
    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let index = menu.findIndex((item) =>
        item.link.includes('/organization-list')
      );
      if (index !== -1) {
        menu[index].link = '/organization-list';
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
  }

  buildFormFacility() {
    this.facilityForm = this.fb.group({
      facilityName: ['', [Validators.required]],
      units: ['', [Validators.required]],
      users: ['', [Validators.required]],
      city: ['', [Validators.required]],
    });
  }

  onSubmit() {}

  close() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  openActivateModal(activate: TemplateRef<any>, organization: any, index) {
    this.rowActual = index;
    this.modalRef = this.modalService.show(activate, {
      id: organization.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  openModal(template: TemplateRef<any>, organization: any, index) {
    this.rowActual = index;
    this.modalRef = this.modalService.show(template, {
      id: organization.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  openEditModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-lg modal-dialog-centered modal-fit',
    });
  }

  async closeFirstModal(succesfully) {
    const idOrganziation = {
      id: this.modalRef.id,
    };
    const deactivaOrganization = await this.appServices
      .deActivateOrganization(idOrganziation)
      .toPromise();
    if (deactivaOrganization['data']) {
      //this.toastrService.success('Succes', 'Was successfully desactivated');
      this.getAllOrganization();
      if (!this.modalRef) {
        return;
      }

      this.dataOrganization.data[this.rowActual].status = 'Inactive';
      this.dataOrganization = this.dataOrganization;
      localStorage.setItem('listRecord', JSON.stringify(this.dataOrganization));

      this.modalRef.hide();
      this.modalRef = null;
      this.messageModal = 'deactivated';
      this.modalRef = this.modalService.show(succesfully, {
        id: 1,
        class: 'modal-sm modal-dialog-centered succesfullyCustom',
      });
      //setTimeout(this.modalRef.hide,5000)
      let timerId = setInterval(() => this.modalRef.hide(), 3000);
      setTimeout(() => {
        clearInterval(timerId);
        this.modalRef.hide();
      }, 3000);
    } else {
      //this.toastrService.error('error', 'error')
    }
  }

  async closeActivateModal(succesfully) {
    const idOrganziation = {
      id: this.modalRef.id,
    };
    const deactivaOrganization = await this.appServices
      .activateOrganization(idOrganziation)
      .toPromise();
    if (deactivaOrganization['data']) {
      //this.toastrService.success('Succes', 'Was successfully activated');
      this.getAllOrganization();
      if (!this.modalRef) {
        return;
      }

      this.dataOrganization.data[this.rowActual].status = 'Active';
      this.dataOrganization = this.dataOrganization;
      localStorage.setItem('listRecord', JSON.stringify(this.dataOrganization));
      this.modalRef.hide();
      this.modalRef = null;
      this.messageModal = 'activated';
      this.modalRef = this.modalService.show(succesfully, {
        id: 1,
        class: 'modal-sm modal-dialog-centered succesfullyCustom',
      });
      //setTimeout(this.modalRef.hide,5000)
      let timerId = setInterval(() => this.modalRef.hide(), 3000);
      setTimeout(() => {
        clearInterval(timerId);
        this.modalRef.hide();
      }, 3000);
    } else {
      this.toastrService.error('error', 'error');
    }
  }

  filters() {
    /**use */
    this.activeFilters = !this.activeFilters;
  }

  getAllOrganization() {
    let urlEndPoint = this.urlService();
    let classOnlineOffline = localStorage.getItem('classActiveOnlineOffline');
    if (classOnlineOffline !== null) {
      let Status = classOnlineOffline === 'A' ? true : false;
      urlEndPoint = this.urlService() + '&Status=' + Status;
    }

    let listpage =
      JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;

    if (listpage) {
      this.appServices.getAllOrganization(urlEndPoint).subscribe(
        (data) => {
          this.dataOrganization.data = data['data'];
          this.dataOrganization.meta = data['meta'];
          this.isDisabled =
            this.dataOrganization.meta.currentPage ===
            this.dataOrganization.meta.totalPages
              ? true
              : false;
        },
        (error) => {

          this.isDisabled = true;
        }
      );
    } else {
      this.dataOrganization = JSON.parse(localStorage.getItem('listRecord'));
    }
  }

  urlService(): string {
    /**use */
    let url =
      localStorage.getItem('urlSearch') === null
        ? '/Organizations/GetAll?PageSize=5'
        : localStorage.getItem('urlSearch');
    let paramName = this.searchAdvancedFrom.get('name').value;
    let paramState = Number(this.searchAdvancedFrom.get('state').value);
    let paramsCity = this.searchAdvancedFrom.get('city').value;
    url = url;

    /*if(!this.allUnitsClassActive){
      const status = this.onlineClassActive ? true : false;
      url = url + '&Status='+ status;
    } */

    if (paramState !== 0) {
      url = url + '&StateId=' + paramState;
    }

    if (paramsCity !== '') {
      url = url + '&City=' + paramsCity;
    }

    if (paramName !== '') {
      url = url + '&Name=' + paramName;
    }

    //if (paramState !== 0 || paramsCity !== '' || paramName !== '') {
    localStorage.setItem('urlSearch', url);
    localStorage.setItem(
      'paramSearch',
      JSON.stringify({ name: paramName, state: paramState, city: paramsCity })
    );
    //}

    return url;
  }

  allOrganizations() {
    localStorage.removeItem('classActiveOnlineOffline');
    this.allUnitsClassActive = true;
    this.onlineClassActive = false;
    this.OfflineClassActive = false;
    this.isDisabled = false;
    let urlService = this.urlService();
    //let url = '/Organizations/GetAll?PageSize=5';
    this.appServices.getAllOrganization(urlService).subscribe((data) => {
      this.dataOrganization.data = data['data'];
      this.dataOrganization.meta = data['meta'];
      this.isDisabled =
        this.dataOrganization.meta.currentPage ===
        this.dataOrganization.meta.totalPages
          ? true
          : false;
    });
  }

  onlineOrganizations(option: string) {
    this.allUnitsClassActive = false;
    this.isDisabled = false;
    this.onlineClassActive = option === 'Active' ? true : false;
    this.OfflineClassActive = option === 'Inactive' ? true : false;
    let Status = option === 'Active' ? true : false;

    localStorage.setItem('classActiveOnlineOffline', Status ? 'A' : 'O');
    let urlService = this.urlService() + '&Status=' + Status;

    //let url = '/Organizations/GetAll?PageSize=5&Status=' + Status;
    this.appServices.getAllOrganization(urlService).subscribe((data) => {
      this.dataOrganization.data = data['data'];
      this.dataOrganization.meta = data['meta'];
      this.isDisabled =
        this.dataOrganization.meta.currentPage ===
          this.dataOrganization.meta.totalPages ||
        this.dataOrganization.meta.totalPages === 0
          ? true
          : false;
    });
  }

  search() {
    this.isDisabled = false;
    localStorage.removeItem('urlSearch');
    let urlService = this.urlService();

    urlService = this.onlineClassActive
      ? urlService + '&Status=' + true
      : urlService;
    urlService = this.OfflineClassActive
      ? urlService + '&Status=' + false
      : urlService;

    if (urlService) {
      this.appServices.getAllOrganization(urlService).subscribe((data) => {
        this.dataOrganization.data = data['data'];
        this.dataOrganization.meta = data['meta'];
        /* this.isDisabled = (this.dataOrganization.meta.currentPage === this.dataOrganization.meta.totalPages) ? true : false;
        if(this.dataOrganization.meta.totalPages===1){
          this.isDisabled = true;
        } */
        this.isDisabled =
          this.dataOrganization.meta.totalPages <= 1 ? true : false;
      });
    }
  }

  async viewMore() {

    /*  if (this.activeFilters) {
       this.search();
     } else {
       let pageSize = 5 */

    let pageNumber = this.dataOrganization.meta.currentPage + 1;

    this.activeLess = true;
    let urlService = this.urlService();
    if (urlService) {
      let dataViewMore = await this.appServices
        .getAllOrganization(urlService, pageNumber)
        .toPromise();
      this.dataOrganization.meta = dataViewMore['meta'];
      this.dataOrganization.data = [
        ...this.dataOrganization.data,
        ...dataViewMore['data'],
      ];
    }

    this.isDisabled =
      this.dataOrganization.meta.currentPage ===
      this.dataOrganization.meta.totalPages
        ? true
        : false;
    // }

    localStorage.setItem('listRecord', JSON.stringify(this.dataOrganization));
  }

  async viewLess() {
    let pageSize = 5;

    let pageNumber = this.dataOrganization.meta.currentPage - 1;

    if (pageNumber === 1) {
      this.activeLess = false;
    }

    let urlService = this.urlService();

    if (pageNumber === 1) {
      this.dataOrganization.data = [];
      if (urlService) {
        let dataViewMore = await this.appServices
          .getAllOrganization(urlService, pageNumber)
          .toPromise();
        this.dataOrganization.meta = dataViewMore['meta'];
        this.dataOrganization.data = [
          ...this.dataOrganization.data,
          ...dataViewMore['data'],
        ];
      }
    } else {
      if (urlService) {
        let dataViewMore = await this.appServices
          .getAllOrganization(urlService, pageNumber)
          .toPromise();
        this.dataOrganization.meta = dataViewMore['meta'];
        this.dataOrganization.data = [
          ...this.dataOrganization.data,
          ...dataViewMore['data'],
        ];
      }
      this.dataOrganization.data.splice(
        this.dataOrganization.data.length - 10,
        10
      );
    }
    this.isDisabled =
      this.dataOrganization.meta.currentPage ===
      this.dataOrganization.meta.totalPages
        ? true
        : false;

    localStorage.setItem('listRecord', JSON.stringify(this.dataOrganization));
  }

  navigateLink(organization: any) {
    this.router.navigate(['/organization-profile/' + organization.id]);
  }

  getListOrganization() {
    this.appServices.getForSelectOrganization().subscribe((data) => {
      this.listOrganization = data['data'];
    });
  }

  getStates() {
    this.appServices.getStates().subscribe((data) => {
      this.listState = data['data'];
    });
  }

  classFilters(): string {
    if (this.internalRol) {
      return 'col-6 col-sm-3 col-md-4 col-lg-3';
    }
    return 'col-6 col-sm-4 col-md-4 col-lg-4';
  }
}
