<div class="wrapper">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12">
              <div class="row">

                      <div class="col-md-6">
                          <h1>Units Hub</h1>
                          <p>Use the Advance search filters to optimize your search.</p>
                      </div>
                      <div class="col-md-6 text-right">

                          <div>
                              <button routerLink="/assign-new-unit" type="button" class="btn btn-primary assing-units">
                                  + ASSIGN UNIT(S)</button>
                          </div>
                      </div>

              </div>
              <ul class="nav filter-menu">
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{'active': allUnitsClassActive }"  (click)="allUnits()">
                          All units
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{'active': onlineClassActive }" (click)="onlineUnits('online')">
                          <span class="online"></span>
                          Online
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{'active': OfflineClassActive }" (click)="onlineUnits('ofline')">
                          <span class="offline"></span>
                          Offline
                      </a>
                  </li>
                  <a class="filters-link" (click)="filters()">
                      <span *ngIf="activeFilters">Close advance search</span>
                      <span *ngIf="!activeFilters">Advance search</span>
                  </a>
              </ul>
              <hr>
              <div class="row filters" *ngIf="activeFilters" [formGroup]="searchAdvancedFrom">
                  <div [ngClass]=" !internalRol ?
                              'col-6 col-sm-6 col-md-4 col-lg-3' : 'col-6 col-sm-6 col-md-4 col-lg-2'">
                      <div class="form-group">
                          <label class="label-search" for="name">Name</label>
                          <input
                              type="text"
                              class="form-control input"
                              name="name"
                              formControlName="name"
                              placeholder="Name"
                              (change)="search()">
                      </div>
                  </div>
                  <div [ngClass]=" !internalRol ?
                          'col-6 col-sm-6 col-md-4 col-lg-3' : 'col-6 col-sm-6 col-md-4 col-lg-2'">
                      <div class="form-group">
                          <label class="label-search" for="name">Serial Number</label>
                          <input
                              type="number"
                              class="form-control input"
                              name="siunNumber"
                              formControlName="siunNumber"
                              placeholder="0000"
                              min="99"
                              (change)="search()">
                      </div>
                  </div>
                  <div [ngClass]=" !internalRol ?
                          'col-6 col-sm-6 col-md-4 col-lg-3' : 'col-6 col-sm-6 col-md-4 col-lg-2'">
                      <div class="form-group">
                          <label class="label-search" for="health">Health</label>
                          <select
                              class="form-control input select-old"
                              id="health"
                              formControlName="health"
                              name="health"
                              (change)="search()">
                            <option value="0" selected>Show All</option>
                            <option value="Steady">Steady</option>
                            <option value="Warning">Warning</option>
                            <option value="Critical">Critical</option>
                          </select>
                      </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="internalRol">
                      <div class="form-group">
                          <label class="label-search" for="Organization">Organization</label>
                          <select
                              class="form-control input select-old"
                              id="OrganizationId"
                              formControlName="organizationId"
                              (change) ="inputChange($event);search()">
                              <option [value]="0" selected>Show All</option>
                              <option *ngFor="let org of listOrganization" [value]="org.id">
                                  {{ org.name }}
                                </option>
                          </select>
                      </div>
                  </div>
                  <div [ngClass]=" !internalRol ?
                          'col-6 col-sm-6 col-md-4 col-lg-3' : 'col-6 col-sm-6 col-md-4 col-lg-3'"
                          *ngIf="internalRol || oraganizationListRol">
                      <div class="form-group">
                          <label class="label-search" for="facility">Facility</label>
                          <select
                              class="form-control input select-old"
                              id="facility"
                              formControlName="facility"
                              (change)="search()">
                              <option [value]="0" selected>Show All</option>
                              <option *ngFor="let tipo of optionFacility" [value]="tipo.id">
                                  {{ tipo.name }}
                              </option>
                          </select>
                      </div>
                  </div>
              </div>
              <div class="row mobile-first-row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-3" *ngFor= "let unit of dataUnitHub, ; let i=index">
                      <div class="card">
                          <h4>{{ unit.name }}</h4>
                          <div class="health-unit" [ngClass]="haltUnit(unit.health)"></div>
                          <span>Serial Number: {{ unit.suinNumber }} </span>
                          <p class="network">
                              <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">
                              {{unit.ipAddress}}
                          </p>
                          <p class="status" *ngIf="unit.status !== undefined">
                              <span [ngClass]="unit?.status === 'Offline' ? 'offline' : 'online'"></span>
                              {{ unit.status }}
                              <span class="details" [routerLink]="['/unit-details/' + unit.id]">
                                  Details
                                  <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
                              </span>
                          </p>
                          <p class="status" *ngIf="unit.status === undefined">
                              <span class="offline"></span>
                              Offline
                              <span class="details" [routerLink]="['/unit-details/' + unit.id]">
                                  Details
                                  <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
                              </span>
                          </p>
                      </div>
                  </div>
              </div>




          </div>
      </div>
      <div class="row justify-content-center button top-button">

          <div class="col-6 col-md-auto pading-right" *ngIf="activeLess">

            <p class="text-center">
              <button type="button" class="btn btn-light btn-view" (click)="viewLess()">View less</button>
            </p>
          </div>
          <div class="col-6 col-md-auto pading-left" *ngIf="!isDisabled">
            <p class="text-center">
              <button type="button" class="btn btn-primary btn-more" (click)="viewMore()" [style]="{'margin-right': !activeLess ? '0px': ''}" [disabled]="isDisabled">View
                more</button>
            </p>
          </div>
        </div>


  </div>
</div>
