<div class="wrapper">
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <p class="back" routerLink="/unassigned-units">
                  <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all Facilities">
                  Back to unassigned units
              </p>
              <h1>Re-Assign Unit</h1>
              <p>Enter the information of your MS-100 specs, to re-assign the unit.</p>
              <hr>
              <div class="error" *ngIf="errorsArray.length !==0">
                  <ul>
                      <li *ngFor="let error of errorsArray">
                          {{ error }}
                      </li>
                  </ul>
              </div>
              <form [formGroup]="unitsForm" (ngSubmit)="onSubmit()" style="margin-top: 0px;">
                  <div formArrayName="units">
                      <div class="row" *ngFor="let quantity of units.controls; let i=index" [formGroupName]='i'>
                        <div class="col-md-12 col-lg-10">
                          <div class="row">
                            <div class="col-xs-12 col-sm-3 col-md-3" >
                              <div class="form-group">
                                  <label for="name">Name</label>
                                  <input
                                      type="text"
                                      class="form-control input"
                                      formControlName="name"
                                      placeholder="Name"
                                      (keyup)="inputChange($event, 'name')">
                                 <div
                                      *ngIf="quantity.get('name').invalid &&
                                          (quantity.get('name').dirty ||
                                          quantity.get('name').touched)"
                                          class="invalid-feedback">
                                      <div *ngIf="quantity.get('name').errors.required">
                                          * Name is required
                                      </div>
                                      <div *ngIf="quantity.controls['name'].errors.maxlength">
                                          * Name must have at most 50 characters
                                      </div>
                                      <div *ngIf="quantity.controls['name'].errors.minlength">
                                          * Name must be at least 5 characters long
                                      </div>
                                  </div>
                              </div>
                          </div>
                         <!--  <div class="col-xs-12 col-sm-3 col-md-3">
                              <div class="form-group">
                                  <label for="siun">MAC ADDRESS</label>
                                  <input type="number"
                                      class="form-control input"
                                      formControlName="suinNumber"
                                      placeholder="0000"
                                      (keyup)="inputChange($event, 'suinNumber')">

                                  <div
                                      *ngIf="quantity.get('suinNumber').invalid &&
                                          (quantity.get('suinNumber').dirty ||
                                          quantity.get('suinNumber').touched)"
                                          class="invalid-feedback">
                                      <div *ngIf="quantity.get('suinNumber').errors.required">
                                          * MAC ADDRESS is required
                                      </div>
                                      <div *ngIf="quantity.get('suinNumber').errors.minlength">
                                          the number must be greater than 99
                                      </div>
                                  </div>
                              </div>
                          </div> -->
                          <div class="col-xs-12 col-6 col-sm-3 col-md-3">
                              <div class="form-group">
                                  <label for="Organization">Organization</label>
                                  <select
                                      class="form-control input select"
                                      id="OrganizationId"
                                      formControlName="organizationId"
                                      (change) ="inputChange($event,'OrganizationId', i)">
                                      <option [value]="0" selected>Select Organization</option>
                                      <option *ngFor="let org of listOrganization" [value]="org.id">
                                          {{ org.name }}
                                        </option>
                                  </select>
                              </div>
                          </div>
                          <div  class="col-xs-12 col-6 col-sm-3 col-md-3">
                              <div class="form-group">
                                  <label for="facility">Facility</label>
                                  <select
                                      class="form-control input select"
                                      formControlName="facilityId"
                                      id="facilityId"
                                      (change) ="inputChange($event,'facilityId')">
                                      <option [value]="0" selected>Select Facility</option>
                                      <option *ngFor="let tipo of optionFacility.listFacility[i]" [value]="tipo.id">
                                          {{ tipo.name }}
                                      </option>
                                  </select>
                                  <div
                                      *ngIf="quantity.get('facilityId').invalid &&
                                          (quantity.get('facilityId').dirty ||
                                          quantity.get('facilityId').touched)"
                                          class="invalid-feedback">
                                      <div *ngIf="quantity.get('facilityId').errors.requiredFacility">
                                          * Facility is required
                                      </div>
                                  </div>
                              </div>
                          </div>
                          </div>
                        </div>


                          <!-- <div class="col-md-auto text-centers">
                              <button (click)="removeQuantity(i)" *ngIf="position > 0" class="btn btn-light sm-btn">
                                  <span>-</span>
                              </button>
                              <button
                                  type="button"
                                  [disabled]="unitsForm.invalid"
                                  (click)="addQuantity(i)"
                                  *ngIf="position == i"
                                  class="btn btn-light sm-btn">
                                      <span>+</span>
                              </button>
                          </div> -->
                  </div>
              <p class="text-center">
                  <button
                      type="button"

                      [disabled]="unitsForm.invalid"
                      (click)="openModal(template)"
                      class="btn btn-primary submit btn-add">RE-ASSIGN UNIT</button>
              </p>
                  </div>

              </form>
                <!-- {{this.unitsForm.value | json}} -->
          </div>
      </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center modal-fit">
      <h1>
          Unit re-assigned
          <br>
          <span class="accent-text">successfully!</span>
      </h1>
      <button
          type="button"
          (click)="closeFirstModal(template)"
          class="btn btn-primary submit">
              Accept
      </button>
  </div>
</ng-template>
