import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseDataUsers } from '../users-list/users-list.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppServices } from 'src/app/services/api.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';
import { AuthService } from 'src/app/services/auth.service';
import { ResponseDataInvite } from '../invite-management/invite-management.component';

@Component({
  selector: 'app-internal-users-list',
  templateUrl: './internal-users-list.component.html',
  styleUrls: ['./internal-users-list.component.sass'],
})
export class InternalUsersListComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private appServices: AppServices,
    private modalService: BsModalService,
    private actionMenu: ActionMenuService,
    private authService: AuthService
  ) {
    this.actionMenu.setSelectMenu('IU');
  }

  internalRol: boolean = false;
  allUserClassActive: boolean = true;
  onlineClassActive: boolean = false;
  OfflineClassActive: boolean = false;
  activeFilters: boolean = false;
  dataUserList: ResponseDataUsers = {
    data: [],
    meta: [],
  };
  isDisabled: boolean = false;
  userFacilityForm: FormGroup;
  modalRef: BsModalRef | null;
  msgError: any[] = [];
  userSearchForm: FormGroup;
  listDataUser: any = [];
  activeLess: boolean = false;
  rowActual;
  inviteForm: FormGroup;
  listDataUserEdit: any = [];
  roleLevel = +this.authService.getRoleLevel();
  message;
  ngOnInit(): void {
    this.allUserClassActive = true;
    this.onlineClassActive = false;
    this.OfflineClassActive = false;

    this.userSearchForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      roleId: ['0'],
      email: [''],
      organizationId: [0],
    });

    this.allUserInternal();
    this.getRolesForUpdateSelect();
    this.buildInviteManagementForm();
  }

  changeMenu(url) {
    localStorage.setItem('listRecord', null);
  }

  onlineUsers(option: string) {}

  allUserInternal() {
    const urlEndPoint = this.urlService();
    let listpage =
      JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;

    if (listpage) {
      this.appServices.getUserInternal(urlEndPoint).subscribe(
        (data) => {
          this.dataUserList.data = data['data'];
          const dataUser = this.dataUserList.data;

          this.dataUserList.data = dataUser.map(function (user) {
            return user;
          });
          this.dataUserList.meta = data['meta'];
          if (this.dataUserList.meta.totalPages === 1) {
            this.isDisabled = true;
          }
          if (this.dataUserList.data.length === 0) {
            this.isDisabled = true;
          }
        },
        (error) => {

          this.isDisabled = true;
        }
      );
    } else {
      this.dataUserList = JSON.parse(localStorage.getItem('listRecord'));
      this.isDisabled =
        this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages
          ? true
          : false;
      if (this.dataUserList.data.length > 5) {
        this.activeLess = true;
      }
    }
  }

  filters() {
    this.activeFilters = !this.activeFilters;
  }

  async viewMore() {
    if (!this.isDisabled) {
      if (this.activeFilters) {
        this.search();
      } else {
        let pageNumber = this.dataUserList.meta.currentPage + 1;
        this.activeLess = true;
        let urlService = this.urlService();
        if (urlService) {
          let dataViewMore = await this.appServices
            .getUserInternal(urlService, pageNumber)
            .toPromise();
          //this.dataUserList.meta = dataViewMore['meta'];
          const dataUser = dataViewMore['data'];

          dataViewMore['data'] = dataUser.map(function (user) {
            return user;
          });

          this.dataUserList.meta = dataViewMore['meta'];
          this.dataUserList.data = [
            ...this.dataUserList.data,
            ...dataViewMore['data'],
          ];
        }
      }
    }
    this.isDisabled =
      this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages
        ? true
        : false;

    localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));
  }

  async viewLess() {
    if (this.activeFilters) {
      this.search();
    } else {
      let pageNumber = this.dataUserList.meta.currentPage - 1;
      if (pageNumber === 1 || pageNumber === 0) {
        this.activeLess = false;
      }
      let urlService = this.urlService();
      if (urlService) {
        let dataViewMore = await this.appServices
          .getUserInternal(urlService, pageNumber)
          .toPromise();
        //this.dataUserList.meta = dataViewMore['meta'];
        const dataUser = dataViewMore['data'];

        dataViewMore['data'] = dataUser.map(function (user) {
          return user;
        });

        this.dataUserList.meta = dataViewMore['meta'];
        this.dataUserList.data = [
          ...this.dataUserList.data,
          ...dataViewMore['data'],
        ];
        if (pageNumber === 1 || pageNumber === 0) {
          this.dataUserList.data = [];
          this.dataUserList.meta = dataViewMore['meta'];
          this.dataUserList.data = [
            ...this.dataUserList.data,
            ...dataViewMore['data'],
          ];
        } else {
          this.dataUserList.data.splice(this.dataUserList.data.length - 10, 10);
          this.isDisabled =
            this.dataUserList.meta.currentPage ===
            this.dataUserList.meta.totalPages
              ? true
              : false;
        }
      }
    }
    //this.isDisabled = (this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages) ? true : false;
    this.isDisabled = this.dataUserList.meta.totalPages <= 1 ? true : false;

    localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));
  }

  async closeFirstModal() {}

  closeModalUpdateFacility() {
    this.resetForm(this.userFacilityForm);
    this.closeModal();
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.msgError = [];
  }

  urlService() {
    let url = '/Users/GetAllInternal?PageSize=5';
    let paramFirstName = this.userSearchForm.get('firstName').value;
    let paramLastName = this.userSearchForm.get('lastName').value;
    let paramRoleId = this.userSearchForm.get('roleId').value;
    let paramEmail = this.userSearchForm.get('email').value;

    if (paramFirstName !== '') {
      url = url + '&FirstName=' + paramFirstName;
    }

    if (paramLastName !== '') {
      url = url + '&LastName=' + paramLastName;
    }

    if (paramRoleId !== '0') {
      url = url + '&RoleId=' + paramRoleId;
    }

    if (paramEmail !== '') {
      url = url + '&Email=' + paramEmail;
    }
    return url;
  }
  search() {
    this.isDisabled = false;
    let urlService = this.urlService();
    if (urlService) {
      this.appServices.getAllFacility(urlService).subscribe((data) => {
        this.dataUserList.data = data['data'];
        const dataUser = this.dataUserList.data;
        this.dataUserList.data = dataUser.map(function (user) {
          return user;
        });
        this.dataUserList.meta = data['meta'];
        this.isDisabled = this.dataUserList.meta.totalPages <= 1 ? true : false;
      });
    }
  }

  getRolesForUpdateSelect() {
    this.appServices.getRolesInternalForUpdateSelect().subscribe((data) => {
      this.listDataUser = data['data'];
    });
  }

  openDeleteModal(template: TemplateRef<any>, user) {
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: user.id,
      class: 'modal-md modal-dialog-centered modal-fit',
    });
  }

  closeDeletetModal(template: TemplateRef<any>) {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const dataRole = {
      id: this.modalRef.id,
    };
    this.appServices.deleteUserInternal(dataRole).subscribe(
      (data) => {
        //this.toastrService.success('Succes', 'Was successfully');
        //this.allUserInternal();
        let pageNumber = this.dataUserList.meta.currentPage;
        this.dataUserList.meta = [];
        this.dataUserList.data = [];

        for (let i = 0; i < pageNumber; i++) {
          this.dataPageReturn(i + 1);
        }
        this.modalRef.hide();
        this.modalRef = null;
        this.message = 'deleted';
        this.modalRef = this.modalService.show(template, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });

        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
  }
  buildInviteManagementForm(): void {
    this.inviteForm = this.fb.group({
      email: ['', [Validators.email]],
      role: ['', [Validators.required]],
    });
    this.inviteForm.get('email').disable();
  }

  openModalUser(edit: TemplateRef<any>, user, index) {
    this.rowActual = index;
    this.msgError = [];
    this.appServices
      .GetInternalRolesForSelectEdit(user.roleId)
      .subscribe((data) => {

        this.listDataUserEdit = data['data'];
        this.inviteForm.patchValue({
          email: user.email,
          role: user.roleId,
        });
        this.modalRef = this.modalService.show(edit, {
          id: user,
          class: 'modal-md modal-dialog-centered customListModal',
        });
      });
  }

  async dataPageReturn(pageNumber) {
    let urlService = this.urlService();

    let dataViewMore = await this.appServices
      .getUserInternal(urlService, pageNumber)
      .toPromise();
    //this.dataUserList.meta = dataViewMore['meta'];
    const dataUser = dataViewMore['data'];

    dataViewMore['data'] = dataUser.map(function (user) {
      return user;
    });

    this.dataUserList.meta = dataViewMore['meta'];
    this.dataUserList.data = [
      ...this.dataUserList.data,
      ...dataViewMore['data'],
    ];
    localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));
    if (this.dataUserList.data.length === 0 && pageNumber === 2) {
      this.activeLess = false;
    }
  }
  closeFirstModalUser(template) {
    if (!this.modalRef) {
      return;
    }
    const invitesData = {
      id: this.modalRef.id['id'],
      //email: this.inviteForm.get('email').value,
      roleId: this.inviteForm.get('role').value,
    };
    this.appServices.UserEdit(invitesData).subscribe(
      (data) => {
        //this.toastrService.success('Succes', 'Was successfully');

        let dataCom = this.inviteForm.get('role').value;
        this.dataUserList.data[this.rowActual].roleId = dataCom;
        this.dataUserList.data[this.rowActual].role = this.listDataUserEdit.find(
          (d) => d.id === dataCom
        ).name;
        localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));

        this.modalRef.hide();
        this.modalRef = null;
        this.allUserInternal();
        this.message = 'updated';
        this.modalRef = this.modalService.show(template, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        //setTimeout(this.modalRef.hide,5000)
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);

        this.inviteForm.reset();
        this.inviteForm.patchValue({
          email: '',
          role: '',
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                this.msgError.push(mensajeError);
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
        this.inviteForm.patchValue({
          email: this.modalRef.id['email'],
        });
      }
    );
  }
}
