<div class="container-fluid">
    <div class="row">
        <div class="co-xs-12 col-sm-4 col-md-4 left-col full-height padre">
         <div class="desktop">
          <img class="logo" [src]="'assets/images/logo-janway.svg'" alt="Janway Logo" width="200px">
          <h1>Welcome to <span class="accent-text">Janway.</span> </h1>
         </div>



          <!--   <p>Aptent iaculis lobortis nisl pretium et sed himenaeos neque litora, accu msan eros nibh etiam hac nisi
                velit.</p> -->
        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 right-col full-height full-height-movil">
            <div class="row form">
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-6 offset-md-2 offset-lg-4">
                    <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
                        <div>
                          <h3>Sign in to <span class="accent-text">JanWay</span></h3>
                        </div>

                        <!-- <div *ngIf="msgErrorList.length !==0" class="error" > -->
                          <div class="error">
                            <ul>
                              <li *ngFor="let error of msgErrorList">
                                {{ error }}
                              </li>
                            </ul>
                            <span *ngIf="msgError !== ''">
                                {{ msgError }}
                            </span>
                        </div>
                        <div class="form-group">

                            <label for="email">Email Address</label>
                            <input type="email" class="form-control input" id="email" formControlName="email"
                                placeholder="example@mail.com">
                            <div class="invalid-feedback"
                                *ngIf="loginForm.get('email').hasError('required') && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                                Email is <strong>required</strong>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="loginForm.get('email').hasError('email') && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                                You must enter a <strong>valid email</strong>
                            </div>
                        </div>
                        <div class="form-group top-password">
                            <label for="password">Password</label>
                            <span class="r-label" (click)="forgotPassword()" placement="top"
                                    ngbTooltip="Forgot password">Forgot password?</span>
                            <div class="input-group mb-3">
                                <input #password type="password" class="form-control input" id="password"
                                    formControlName="password" placeholder="*******">
                                <div class="input-group-append"
                                    (click)="password.type=password.type=='password'?'text':'password'">
                                    <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                                </div>
                                <div *ngIf="loginForm.controls['password'].invalid
                                            && (loginForm.controls['password'].dirty
                                            || loginForm.controls['password'].touched)" class="invalid-feedback">
                                    <div *ngIf="loginForm.controls['password'].errors.required">* Password is required</div>
                                    <div *ngIf="loginForm.controls['password'].errors.minlength">* Password must have at least 7 characters</div>
                                    <div *ngIf="loginForm.controls['password'].errors.maxlength">* Password must have  at most 100 characters</div>
                                    <div *ngIf="loginForm.controls['password'].errors.pattern">
                                        * Password must contain at least: one capital letter, one lowercase letter, one numeric digit and one special character.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input check-top" formControlName="remember" id="remember">
                            <label class="checkbox" for="remember" placement="top" ngbTooltip="Remember me">Remember
                                me</label>
                        </div>
                        <p class="text-center" placement="top" ngbTooltip="Sign in">
                            <button type="submit"
                                [disabled]="loginForm.invalid" class="btn btn-primary btn-sign">sign in</button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
