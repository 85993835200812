<div class="wrapper">
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <p class="back" routerLink="/organization-list" *ngIf="internalRol">
                  <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all organizations">
                  Back to all organizations
              </p>
              <h1>Set up your organization</h1>
              <p>Add the required information to set up your organization.</p>
              <hr>
          </div>
      </div>
      <div class="container padding-container">
          <div class="row basic-info text-center class-steps">
              <div class="col-3 col-md-3 col-lg-3">
                  <h4 class="step1"
                      [ngClass]="{'inactive': formStep != 1, 'active': formStep == 1, 'error': errorsOrganization.length > 0 }">
                      <span class="step"
                          [ngClass]="{'inactive': formStep != 1, 'active': formStep == 1, 'error': errorsOrganization.length > 0 }">1</span>

                  </h4>
                  <div class="progress-bar">
                      <span class="progress"
                          [ngClass]="{'progress': formStep == 1, 'progress-error': errorsOrganization.length > 0 }"></span>
                  </div>
              </div>
              <div class="col-3 col-md-3 col-lg-3">
                  <h4 class="step2"
                      [ngClass]="{'inactive': formStep != 2, 'active': formStep == 2, 'error': errorsFacility.length > 0 }">
                      <span class="step"
                          [ngClass]="{'inactive': formStep != 2, 'active': formStep == 2, 'error': errorsFacility.length > 0 }">2</span>

                  </h4>
                  <div class="progress-bar">
                      <span class="progress"
                          [ngClass]="{'progress': formStep == 2, 'progress-error': errorsFacility.length > 0 }"></span>
                  </div>
              </div>
              <div class="col-3 col-md-3 col-lg-3">
                  <h4 class="step3"
                      [ngClass]="{'inactive': formStep != 3, 'active': formStep == 3, 'error': errorsUsers.length > 0 }">
                      <span class="step"
                          [ngClass]="{'inactive': formStep != 3, 'active': formStep == 3, 'error': errorsUsers.length > 0 }">3</span>

                  </h4>
                  <div class="progress-bar">
                      <span class="progress"
                          [ngClass]="{'progress': formStep == 3, 'progress-error': errorsUsers.length > 0 }"></span>
                  </div>
              </div>
              <div class="col-3 col-md-3 col-lg-3">
                  <h4 class="step4"
                      [ngClass]="{'inactive': formStep != 4, 'active': formStep == 4, 'error': errorsUnits.length > 0 }">
                      <span class="step"
                          [ngClass]="{'inactive': formStep != 4, 'active': formStep == 4, 'error': errorsUnits.length > 0 }">4</span>

                  </h4>
                  <div class="progress-bar">
                      <span class="progress"
                          [ngClass]="{'progress': formStep == 4, 'progress-error': errorsUnits.length > 0 }"></span>
                  </div>
              </div>
              <div *ngIf="errorsArray.length > 0" class="error">
                  <ul>
                      <li *ngFor="let error of errorsArray">
                          {{ error }}
                      </li>
                  </ul>
                  <span *ngIf="msjSinPasos !== ''">
                      {{ msjSinPasos }}
                  </span>
              </div>
          </div>
      </div>
      <!-- Begin Step 1 Organization -->
      <form [formGroup]="OrganizationForm" *ngIf="formStep == 1">
          <div class="row">
              <div class="col-xs-12 col-12 col-sm-8 col-md-8 offset-sm-2 offset-md-2">
                  <div class="row">
                      <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                              <label for="organization">Name</label>
                              <input type="text" class="form-control input" id="organization"
                                  formControlName="organization" placeholder="Name"
                                  (keyup)="inputChange($event, 'organization')">
                              <div *ngIf="OrganizationForm.controls['organization'].invalid && (OrganizationForm.controls['organization'].dirty || OrganizationForm.controls['organization'].touched)"
                                  class="invalid-feedback">
                                  <div *ngIf="OrganizationForm.controls['organization'].errors.required">*
                                      Name is required</div>
                                  <div *ngIf="OrganizationForm.controls['organization'].errors.maxlength">
                                      * Name must have at most 50 characters
                                  </div>
                                  <div *ngIf="OrganizationForm.controls['organization'].errors.minlength">
                                      * Name must be at least 5 characters long
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                              <label for="phone">Phone Number</label>
                              <input type="text" class="form-control input" id="phone" formControlName="phone"
                                  placeholder="123 123 1234">
                              <div *ngIf="OrganizationForm.controls['phone'].invalid && (OrganizationForm.controls['phone'].dirty || OrganizationForm.controls['phone'].touched)"
                                  class="invalid-feedback">
                                  <div *ngIf="OrganizationForm.controls['phone'].errors.required">* Phone Number is
                                      required</div>
                                  <div *ngIf="OrganizationForm.controls['phone'].errors.pattern">* Phone pattern is
                                      not valid</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-xs-12 col-12 col-sm-12 col-md-12 col-lg-12">
                          <div class="form-group">
                              <label for="address">Address</label>
                              <input type="text" class="form-control input" id="address" formControlName="address"
                                  placeholder="4348 Rubaiyat Road Grand Rapids, MI 49503">
                              <div *ngIf="OrganizationForm.controls['address'].invalid && (OrganizationForm.controls['address'].dirty || OrganizationForm.controls['address'].touched)"
                                  class="invalid-feedback">
                                  <div *ngIf="OrganizationForm.controls['address'].errors.required">* Address is
                                      required</div>
                                  <div *ngIf="OrganizationForm.controls['address'].errors.maxlength">
                                      * Address must have at most 100 characters
                                  </div>
                                  <div *ngIf="OrganizationForm.controls['address'].errors.minlength">
                                      * Address must be at least 5 characters long
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                              <label for="state">State</label>
                              <select class="form-control input select-old" id="state" formControlName="state"
                                  name="state" requireda>
                                  <option value="" selected>Select State</option>
                                  <option *ngFor="let state of listState" [value]="state.id">
                                      {{ state.name }}
                                  </option>
                              </select>
                              <div *ngIf="OrganizationForm.controls['state'].invalid &&
                                      (OrganizationForm.controls['state'].dirty ||
                                      OrganizationForm.controls['state'].touched)" class="invalid-feedback">
                                  <div *ngIf="OrganizationForm.controls['state'].errors.required">* State is required
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                              <label for="city">City</label>
                              <input type="text" class="form-control input" id="city" name="city" required
                                  formControlName="city" placeholder="City">
                              <div *ngIf="OrganizationForm.controls['city'].invalid &&
                                    (OrganizationForm.controls['city'].dirty ||
                                    OrganizationForm.controls['city'].touched)" class="invalid-feedback">
                                  <div *ngIf="OrganizationForm.controls['city'].errors.required">
                                      * City is required
                                  </div>
                                  <div *ngIf="OrganizationForm.controls['city'].errors.maxlength">
                                      * City must have at most 50 characters
                                  </div>
                                  <div *ngIf="OrganizationForm.controls['city'].errors.minlength">
                                      * City must be at least 5 characters long
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="text-center form-buttons">
                      <button type="submit" [disabled]="OrganizationForm.invalid"
                          class="btn btn-primary submit btn-next" (click)="nextStep(formStep + 1)">next</button>
                  </div>
              </div>
          </div>
      </form>

      <!-- Begin Step 2 Facilities -->
      <form [formGroup]="FacilityStepTwoForm" *ngIf="formStep == 2">
          <div formArrayName="facilities">
              <div class="col-xs-12 col-sm-8 col-md-8 offset-sm-2 offset-md-2" style="padding-left: 5px;">
                  <div class="row" *ngFor="let facility of facilities.controls; index as i" [formGroupName]="i">
                      <div class="col-xs-12 col-8 col-sm-6 col-md-6 col-lg-6">
                          <div class="form-group">
                              <label for="facilityName">Name</label>
                              <input type="text" class="form-control input" id="facilityName" required
                                  formControlName="facilityName" placeholder="Name">
                              <div *ngIf="facility.controls['facilityName'].invalid
                                          && (facility.controls['facilityName'].dirty ||
                                          facility.controls['facilityName'].touched)" class="invalid-feedback">
                                  <div *ngIf="facility.controls['facilityName'].errors.required">
                                      * Name is required
                                  </div>
                                  <div *ngIf="facility.controls['facilityName'].errors.maxlength">
                                      * Name must have at most 50 characters
                                  </div>
                                  <div *ngIf="facility.controls['facilityName'].errors.minlength">
                                      * Name must be at least 5 characters long
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xs-6  col-4 col-sm-6 col-md-6 col-lg-6 text-centers">
                          <button (click)="removeFacility(i)" *ngIf="facilityPosition > 0"
                              class="btn btn-light sm-btn">
                              <span>-</span>
                          </button>
                          <button type="button" [disabled]="FacilityStepTwoForm.invalid" (click)="addFacility(i)"
                              *ngIf="facilityPosition == i" class="btn btn-light sm-btn">
                              <span>+</span>
                          </button>
                      </div>
                  </div>

                  <div class="text-center form-buttons">
                      <button type="button" (click)="backStep(formStep - 1)" class="btn btn-light btn-prv">previous
                          step</button>
                      <button type="submit" [disabled]="FacilityStepTwoForm.invalid"
                          class="btn btn-primary submit btn-next" (click)="nextStep(formStep + 1)">next</button>
                  </div>
              </div>
          </div>

      </form>

      <!-- Begin Step 3 Facilities -->
      <form [formGroup]="UsersStepTwoForm" *ngIf="formStep == 3">
          <div formArrayName="users">
              <div class="row" *ngFor="let user of users.controls; index as i" [formGroupName]="i">
                  <!--   <div class="col-xs-12 col-sm-8 col-md-8 offset-sm-2 offset-md-2" style="margin-bottom: 40px;">
                      <h1 style="color: #A5A8C5; font-size: 32px">Organization Administrator</h1>
                      <div class="row">

                          <div class="col-xs-12 col-sm-6 col-md-6">
                              <div class="form-group">
                                  <label for="nameAdmin">Name</label>
                                  <input type="text" class="form-control input" required id="nameAdmin"
                                      formControlName="nameAdmin" placeholder="Name">
                                  <div *ngIf="user.controls['nameAdmin'].invalid &&
                                          (user.controls['nameAdmin'].dirty ||
                                          user.controls['nameAdmin'].touched)" class="invalid-feedback">
                                      <div *ngIf="user.controls['nameAdmin'].errors.required">* Name is required</div>
                                      <div *ngIf="user.controls['nameAdmin'].errors.maxlength">
                                          * Name must have at most 50 characters
                                      </div>
                                      <div *ngIf="user.controls['nameAdmin'].errors.minlength">
                                          * Name must be at least 5 characters long
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md-6">
                              <div class="form-group">
                                  <label for="emailAdmin">Email</label>
                                  <input type="email" class="form-control input" required formControlName="emailAdmin"
                                      placeholder="example@mail.com">
                                  <div *ngIf="user.controls['emailAdmin'].invalid
                                          && (user.controls['emailAdmin'].dirty ||
                                          user.controls['emailAdmin'].touched)" class="invalid-feedback">
                                      <div *ngIf="user.controls['emailAdmin'].errors.required">* Email is required
                                      </div>
                                      <div *ngIf="user.controls['emailAdmin'].errors.email">* Email pattern is not
                                          valid
                                      </div>
                                      <div *ngIf="user.controls['emailAdmin'].errors.maxlength">
                                          * Email must have at most 100 characters
                                      </div>
                                      <div *ngIf="user.controls['emailAdmin'].errors.minlength">
                                          * Email must be at least 5 characters long
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> -->
                  <div class="col-xs-12 col-sm-8 col-md-8 offset-sm-2 offset-md-2">
                      <h1 class="class-organization-h1">Organization Manager</h1>
                      <div class="row">

                          <div class="col-xs-12 col-sm-6 col-md-6">
                              <div class="form-group">
                                  <label for="nameManager">Name</label>
                                  <input type="text" class="form-control input" required id="nameManager"
                                      formControlName="nameManager" placeholder="Name">
                                  <div *ngIf="user.controls['nameManager'].invalid &&
                                  (user.controls['nameManager'].dirty || user.controls['nameManager'].touched)"
                                      class="invalid-feedback">
                                      <div *ngIf="user.controls['nameManager'].errors.required">* Name is required
                                      </div>
                                      <div *ngIf="user.controls['nameManager'].errors.maxlength">
                                          * Name must have at most 50 characters
                                      </div>
                                      <div *ngIf="user.controls['nameManager'].errors.minlength">
                                          * Name must be at least 5 characters long
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xs-12 col-sm-6 col-md-6">
                              <div class="form-group">
                                  <label for="emailManager">Email</label>
                                  <input type="text" class="form-control input" required
                                      formControlName="emailManager" placeholder="example@mail.com">
                                  <div *ngIf="user.controls['emailManager'].invalid &&
                                  (user.controls['emailManager'].dirty ||
                                  user.controls['emailManager'].touched)" class="invalid-feedback">
                                      <div *ngIf="user.controls['emailManager'].errors.required">* Email is required
                                      </div>
                                      <div *ngIf="user.controls['emailManager'].errors.email">* Email pattern is not
                                          valid
                                      </div>
                                      <div *ngIf="user.controls['emailManager'].errors.maxlength">
                                          * Email must have at most 100 characters
                                      </div>
                                      <div *ngIf="user.controls['emailManager'].errors.minlength">
                                          * Email must be at least 5 characters long
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text-center form-buttons">
                          <button type="button" (click)="backStep(formStep - 1)"
                              class="btn btn-light btn-prv">previous
                              step</button>
                          <button type="button"
                              [disabled]="users.controls[0].get('nameManager').errors?.required || users.controls[0].get('emailManager').errors?.required"
                              (click)="nextStep(formStep + 1)" class="btn btn-primary btn-next">next</button>
                          <!--  <button type="button"
                              [disabled]="users.controls[0].get('nameAdmin').errors?.required || users.controls[0].get('emailAdmin').errors?.required || users.controls[0].get('nameManager').errors?.required || users.controls[0].get('emailManager').errors?.required"
                              style="border-radius: 26px; color: #00CFFF; font-weight: 700;" (click)="nextStep(formStep + 1)"
                              class="btn btn-primary">next</button> -->
                      </div>
                  </div>
              </div>

          </div>
      </form>
      <form [formGroup]="UnitsStepThreeForm" *ngIf="formStep == 4">
        <div style="text-align: center;" *ngIf="unitsPosition === -1">
            <button type="button" class="btn btn-light sm-btn" (click)="addUnitAfterDelete(0)">
                <span>+</span>
            </button>
        </div>

      <!-- Step 4-->
        <div formArrayName="units">
            <div class="row justify-content-center" *ngFor="let unity of units.controls; index as i" [formGroupName]="i">
                <div class="col-xs-12 col-sm-10 col-md-10 offset-sm-1 offset-md-1">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="siun">Mac Address</label>
                                <input type="text" class="form-control input" formControlName="macAddress"
                                    placeholder="Mac Address" (change)="keyupUnitMacAddress($event,i,unity)">

                                 <div *ngIf="unity.get('macAddress').invalid &&
                                                    (unity.get('macAddress').dirty ||
                                                    unity.get('macAddress').touched)" class="invalid-feedback">
                                <div *ngIf="unity.get('macAddress').errors.required">
                                  * Mac Address is required
                                </div>
                                <div *ngIf="unity.controls['macAddress'].errors.macAddressRegistered">
                                    Mac Address is not registered
                                  </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3"  *ngIf="!(unity.get('macAddress').invalid) && activeVal[i] && !(unity.controls['macAddress'].pending) ">
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" class="form-control input" id="name" formControlName="name"
                                    placeholder="Name">
                                <div *ngIf="unity.controls['name'].invalid
                                        && (unity.controls['name'].dirty ||
                                        unity.controls['name'].touched)" class="invalid-feedback">
                                    <div *ngIf="unity.controls['name'].errors.required">
                                        * Name is required
                                    </div>
                                    <div *ngIf="unity.controls['name'].errors.maxlength">
                                        * Name unit must have at most 50 characters
                                    </div>
                                    <div *ngIf="unity.controls['name'].errors.minlength">
                                        * Name unit must be at least 5 characters long
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  <div class="col-10 col-sm-6 col-md-3">
                            <div class="form-group">
                                <label for="siun">SUIN Number</label>
                                <input type="number" class="form-control input" id="siun" formControlName="siun"
                                    placeholder="0000">
                                <div *ngIf="unity.controls['siun'].invalid
                                    && (unity.controls['siun'].dirty ||
                                    unity.controls['siun'].touched)" class="invalid-feedback">
                                    <div *ngIf="unity.controls['siun'].errors.required">
                                        * SUIN Number is required
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-8 col-sm-6 col-md-3 col-lg-3"  *ngIf="(oraganizationListRol || internalRol) && !(unity.get('macAddress').invalid) && activeVal[i] && !(unity.controls['macAddress'].pending) ">
                            <div class="form-group">
                                <label for="facility">Facility</label>
                                <select class="form-control input select-old" id="facility"
                                    formControlName="facility">
                                    <option value="">Select Facility</option>
                                    <option *ngFor="let facility of dataSaveOrganization.facilities"
                                        [value]="facility.id">
                                        {{ facility.name }}
                                    </option>
                                </select>
                                <!-- <div *ngIf="unity.controls['facility'].invalid &&
                                    (unity.controls['facility'].dirty ||
                                    unity.controls['facility'].touched)" class="invalid-feedback">
                                    <div *ngIf="unity.controls['facility'].errors.required">* Facility is required
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-4 col-sm-4 col-md-3 col-lg-3 text-centers" *ngIf="!(unity.controls['macAddress'].pending) && !(unity.get('macAddress').invalid) && activeVal[i]">
                            <button (click)="removeUnit(i)" *ngIf="unitsPosition > 0" class="btn btn-light sm-btn">
                                <span>-</span>
                            </button>
                            <button type="button" (click)="addUnit(i)" [disabled]="true"
                                *ngIf="!activeVal[i] &&unitsPosition >= i" class="btn btn-light sm-btn">
                                <span>+</span>
                            </button>
                            <button type="button" (click)="addUnit(i)" [disabled]="UnitsStepThreeForm.invalid"
                                *ngIf="activeVal[i] && unitsPosition == i" class="btn btn-light sm-btn">
                                <span>+</span>
                            </button>
                            <!-- <button type="button" (click)="addUnit(i)" [disabled]="UnitsStepThreeForm.invalid"
                                *ngIf="unitsPosition == i" class="btn btn-light sm-btn">
                                <span>+</span>
                            </button> -->
                        </div>
                    </div>
                </div>


            </div>
            <div class="row justify-content-center">
                <div class="col-xs-12 col-sm-8 col-md-8 align-self-center">
                    <div class="text-center form-buttons">
                        <button type="button" (click)="backStep(formStep - 1)" class="btn btn-light btn-prv">
                            previous step
                        </button>
                        <button type="button" (click)="save(saveModal)" [disabled]="UnitsStepThreeForm.invalid"
                            class="btn btn-primary btn-next">save
                        </button>
                    </div>
                </div>

            </div>

            <!-- <p class="text-center">
                <a class="back text-center">Skip this step</a>
            </p> -->
        </div>
    </form>

  </div>
</div>
<ng-template #exit>
  <div class="modal-body text-center modal-fit">
      <div class="back" (click)="closeModal()">
          <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to organization Profile">
          Back to organization profile
      </div>
      <h1 class="modal-title">
          Are you sure you want to <span style="color: #00CFFF;"> leave </span>the
          initial setup?

      </h1>
      <div>
          <button type="button" (click)="closeModal()" class="btn btn-primary submit full-width bottom-btn">no, I want to go
              back</button>
      </div>
      <div>
          <button class="btn btn-light full-width" (click)="closeModalYes()">Yes, I want to leave</button>
      </div>
  </div>
</ng-template>
<ng-template #saveModal>
  <div class="modal-body text-center modal-fit">
      <h1>
          Organization setup
          <br>
          <span class="accent-text">successfully!</span>
      </h1>
      <!-- <button type="button" (click)="closeSaveModal(template)" class="btn btn-primary submit">Accept</button> -->
  </div>
</ng-template>
