<div>
  <div *ngIf="urlBack" class="modal-body" style="padding-left: 0px;padding-right: 0px;">
    <div class="row">
          <!-- <div class="col-md-6"><img class="logo" style="max-width: 130px;" [src]="'assets/images/logo_janway_popup.svg'" alt="Janway Logo" width="200px" /></div> -->
            <div class="col-md-6 text-left">
                <img *ngIf="back != ''" [src]="'assets/images/icons/arrow-left.svg'" alt="Back to">
                <a  *ngIf="back != ''"(click)="bsModalRef.hide()" 
                  style="padding-left: 10px; color: #303FD7; text-decoration: none; cursor: pointer;">
                    {{back}}
                </a>
                <!-- <img class="text-right" [src]="'assets/images/icons/left.svg'" alt="Janway Logo"/> -->
            </div>
        </div>
</div>
    <div class="modal-body text-center">
      <h1 *ngIf="urlBack" style="margin-top: 0.3em;">
        {{title}}
        <br>
        <span class="accent-text">{{message}}</span>
      </h1>
      <h1 *ngIf="!urlBack" style="margin-top: -0.7em;">
        {{title}}
        <br>
        <span class="accent-text">{{message}}</span>
      </h1>
      <p *ngIf="btn1 !== ''" style="margin-bottom: 0rem !important;">
        <button style="border-radius: 26px; color: #00CFFF; font-weight: 700;" type="button"
          (click)="clickOk()" translate="cancel" class="btn btn-primary full-width">
          {{btn1}}
        </button>
      </p>
      <p *ngIf="btn2 !== ''" style="margin-bottom: 0rem !important;">
        <button 
          style="border-radius: 26px; color:#303FD7; background: #ffffff !important; font-weight: 700;" 
          type="button" (click)="clickOk()" 
          translate="ok" class="btn btn-light full-width">{{btn2}}</button>
      </p>
    </div>
</div>