import { Component, HostListener, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { ActionMenuService } from 'src/app/services/action-menu.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {
  public tableChart: boolean = false;
  public barChartOptions: ChartOptions = {
    responsive: !this.tableChart,
    maintainAspectRatio: this.tableChart,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: 100,
            min: 0,
            stepSize: 15,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = ['2021'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [
    { data: [90], label: 'Series A', backgroundColor: '#00CFFF' },
    { data: [70], label: 'Series B', backgroundColor: '#303FD7' },
  ];
  //LineChart
  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            stepSize: 15,
            max: 100,
            min: 0,
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#00EA00',
      backgroundColor: 'rgb(0 234 0 / 0.2)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  //Units Health
  public unitHealthBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            max: 100,
            min: 0,
            stepSize: 20,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public unitHealthBarChartLabels: Label[] = ['Unit Status'];
  public unitHealthBarChartType: ChartType = 'bar';
  public unitHealthBarChartLegend = false;

  public unitHealthBarChartData: ChartDataSets[] = [
    { data: [100], label: 'Good', backgroundColor: '#00EA00' },
    { data: [70], label: 'Warning', backgroundColor: '#FF8A00' },
    { data: [40], label: 'Critical', backgroundColor: '#FF0000' },
  ];
  //Units Usage Chart
  public unitUsageBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            max: 120,
            min: 0,
            stepSize: 30,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public unitUsageBarChartLabels: Label[] = ['1', '2', '3', '4', '5'];
  public unitUsageBarChartType: ChartType = 'bar';
  public unitUsageBarChartLegend = false;

  public unitUsageBarChartData: ChartDataSets[] = [
    {
      data: [120, 95, 100, 65, 40],
      label: 'Usage',
      backgroundColor: '#303FD7',
    },
  ];

  public getScreenWidth: any;

  constructor(private actionMenu: ActionMenuService) {
    this.actionMenu.setSelectMenu('H');
  }

  ngOnInit(): void {
    this.onWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.tableChart = +window.innerWidth <= 768 ? true : false;

    this.barChartOptions = {
      responsive: !this.tableChart,
      maintainAspectRatio: this.tableChart,
      // We use these empty structures as placeholders for dynamic theming.
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
              max: 100,
              min: 0,
              stepSize: 15,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        },
      },
    };
    this.unitHealthBarChartOptions = {
      responsive: !this.tableChart,
      maintainAspectRatio: this.tableChart,
      // We use these empty structures as placeholders for dynamic theming.
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              max: 100,
              min: 0,
              stepSize: 20,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        },
      },
    };
    this.unitUsageBarChartOptions = {
      responsive: !this.tableChart,
      maintainAspectRatio: this.tableChart,
      // We use these empty structures as placeholders for dynamic theming.
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              max: 120,
              min: 0,
              stepSize: 30,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        },
      },
    };
    this.lineChartOptions = {
      responsive: !this.tableChart,
      maintainAspectRatio: this.tableChart,
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
              stepSize: 15,
              max: 100,
              min: 0,
            },
          },
        ],
      },
    };
  }
}
