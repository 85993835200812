import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/LoaderService';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent implements OnInit {


  constructor(private loaderService: LoaderService,private spinner: NgxSpinnerService) { }

  isLoading: Subject<boolean> = this.loaderService.isLoading;
  ngOnInit(): void {

    this.spinner.show();


  }

}
