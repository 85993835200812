import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanLoad, Route, Router, CanDeactivate, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, IAuthStatus } from './auth.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  protected currentAuthStatus: IAuthStatus;
  constructor(private authService: AuthService, private router: Router, private popupService: PopupService) {
    this.authService.authStatus.subscribe(
      authStatus => (this.currentAuthStatus = (this.authService.getAuthStatus()))
    )
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    return this.checkPermissions(childRoute);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(next);
  }

  checkLogin(route?: ActivatedRouteSnapshot) {
    const userRole = this.authService.getAuthStatus();

    if ((this.authService.getToken() == null || this.authService.getToken() === '')) {
      if (userRole.role !== 'ALL') {
        this.popupService.showConfirm("", "You don't have permission to access this resource", "Ok", "", "Back to app");
        this.router.navigate(['login']);
        return false;
      }
    } else {
      // Comentado a peticion del cliente
      /* if (route.data.role && route.data.role.indexOf(userRole.role) === -1) {
        this.popupService.showConfirm("", "Username and password don't match", "Ok", "", "Back to app",true);
        return false;
      } else {
        return true;
      } */
      return true;
    }
    this.router.navigate(['login']);
  }

  protected checkPermissions(route?: ActivatedRouteSnapshot) {
    let roleMatch = true;
    let params: any;

    if (route) {
      const expectedRole = route.data.expectedRole;
      if (expectedRole) {
        roleMatch = this.currentAuthStatus.role === expectedRole;
      }
    }
    if (!roleMatch) {
      //alert('No tienes los permisos para ver este recurso');
      this.popupService.showConfirm("", "You don't have permission to access this resource", "Ok", "", "Back to app");
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
