import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EncrDecrService } from 'src/app/services/encrypt.service';
import { PopupService } from 'src/app/services/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public msgError: string = '';
  public msgErrorList: string[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private EncrDecr: EncrDecrService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('remember')) {
      this.router.navigate(['/dashboard']);

    } else {
      localStorage.removeItem('remember');
      localStorage.clear();
      this.authService.logout();
    }
    this.buildLoginForm();
  }

  buildLoginForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/
          ),
          Validators.minLength(7),
          Validators.maxLength(100),
        ],
      ],
      remember: [false],
    });
  }

  login(submittedForm: FormGroup) {
    let data = {
      password: this.EncrDecr.set(
        environment.key_login,
        submittedForm.value.password
      ),
      email: submittedForm.value.email,
      rememberMe: submittedForm.value.remember,
    };
    this.authService.login(data).subscribe(
      (res) => {
        let response: any = res;

        if (response.data) {
          if (submittedForm.value.remember) {
            localStorage.setItem('remember', 'Y');
          } else {
            localStorage.removeItem('remember');
          }
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/new-password']);
        }
      },
      (error) => {

        let errors = JSON.parse(error);
        this.msgError = '';
        this.msgErrorList = [];
        //this.popupService.showConfirm("", "Username and password don't match", "Ok","","",false);
        if (typeof errors === 'string') {
          this.msgError = errors;
        } else if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            //@ts-ignore
            const count = value.length;
            for (let i = 0; i < count; i++) {
              this.msgErrorList.push(value[i]);

            }
          });
        }
      }
    );
  }

  forgotPassword(){
    let usuario = this.loginForm.get('email').value;
    localStorage.setItem('emailForgot',usuario);
    this.router.navigate(['/forgot-password']);
  }
}
