import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppServices } from 'src/app/services/api.service';
import { EncrDecrService } from 'src/app/services/encrypt.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.sass']
})
export class ConfirmAccountComponent implements OnInit {
  ConfirmAccountForm: FormGroup;
  public confirm: boolean = false;
  public terminos: boolean = false;
  public privacidad: boolean = false;
  public token: string = '';
  public idInvite: number = 0;
  public dataInvite: any = null;
  public emailInvite: string = '';
  public msgError: string = '';
  public msgErrorList: string[] = [];
  public isInternalRole: boolean = false;
  public paramsUrl: any = null;
  public isFacility: boolean = false;
  public isOrganization: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppServices,
    private EncrDecr: EncrDecrService) {
    // this.route
    //   .paramMap
    //   .subscribe(
    //     (param: Params) => {
    //       const { ...other } = param;

    //       if(other.params?.token) {
    //         this.token = other.params.token;
    //         this.idInvite = other.params.id;
    //       } /*else {
    //         this.router.navigate['/login'];
    //       }*/
    //
    //     }
    //   )

    if (localStorage.getItem('jwt')) {
      this.router.navigate(['/dashboard']);
    }

    this.paramsUrl = this.route.snapshot.params;

    if (!(_.isEmpty(this.paramsUrl))) {
      this.token = this.paramsUrl.token;
      this.token = this.token.replace(/-/gi, "/");
      this.token = this.token.replace(/_/gi, "=");
      this.token = this.token.replace(/,/gi, "+");

      this.idInvite = this.paramsUrl.id;
    }


  }

  MatchPassword(abstractControl: AbstractControl) {
    let password = abstractControl.get('password').value;
    let passwordc = abstractControl.get('passwordc').value;

    if (password != passwordc) {
      abstractControl.get('passwordc').setErrors({
        MatchPassword: true
      })
    } else {
      return null
    }
  }

  ngOnInit() {
    this.ConfirmAccountForm = this.fb.group({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordc: '',
      organization: '',
      //facility: '',
      confirm: false,
    });
    this.appService.GetInviteForConfirmAccount(this.idInvite).subscribe(
      (data) => {
        this.dataInvite = data['data'];
        this.isInternalRole = this.dataInvite.isInternalRole;
        let organization = this.dataInvite !== null ? this.dataInvite.organization : '';
        this.ConfirmAccountForm.get('organization').setValue(organization);
        this.ConfirmAccountForm.get('email').setValue(this.dataInvite.email);
        this.ConfirmAccountForm.get('facility').setValue(this.dataInvite.facility);

        this.isFacility = this.dataInvite !== null ? this.dataInvite.facility : '';
        this.isOrganization = this.dataInvite !== null ? this.dataInvite.organization : '';

      }
    )
    this.buildConfirmAccountForm();
  }

  buildConfirmAccountForm(): void {
    this.ConfirmAccountForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
      passwordc: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
      organization: ['', [Validators.required]],
      facility: ['', /*[Validators.required]*/],
      confirm: [false, [Validators.required, Validators.pattern('true')]],
    }, {
      validator: this.MatchPassword
    })
    this.ConfirmAccountForm.get('email').disable();
    this.ConfirmAccountForm.get('facility').disable();
    this.ConfirmAccountForm.get('organization').disable();
  }

  checkConfirm() {
    this.confirm = this.confirm ? false : true;
  }

  onSubmit(submittedForm: FormGroup) {
    if (this.dataInvite !== null) {
      const data = {
        firstName: submittedForm.value.firstName,
        lastName: submittedForm.value.lastName,
        email: this.dataInvite.email,
        organizationId: this.dataInvite?.organizationId || null,
        facilityId: this.dataInvite?.facilityId || null,
        password: this.EncrDecr.set(
          environment.key_login,
          submittedForm.value.password),
        confirmPassword: this.EncrDecr.set(
          environment.key_login,
          submittedForm.value.passwordc),
        token: this.token,
        userId: +this.idInvite
      }
      this.msgError = '';
      this.msgErrorList = [];
      this.appService.confirmAccount(data).subscribe(
        (data) => {
          if (data) {
            this.router.navigate(['/login']);
          }
        }, error => {
          let errors = JSON.parse(error);
          if (typeof errors === 'string') {
            this.msgError = errors;
          } else if (typeof errors === 'object') {
            Object.entries(errors).forEach(([key, value]) => {
              //@ts-ignore
              const count = value.length;
              for (let i = 0; i < count; i++) {
                this.msgErrorList.push(value[i])
              }
            })
          }
        }
      );
    }
  }

  change(cual: Number) {
    if (cual === 1) { this.terminos = this.terminos === false ? true : false; }
    if (cual === 2) { this.privacidad = this.privacidad === false ? true : false; }
  }
}
