<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p class="back" routerLink="/facilities-list">
                    <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all Facilities">
                    Back to all facilities
                </p>
                <h1>Add New Facility</h1>
                <p>Add the required information to create a new facility.</p>
                <hr>
            </div>
        </div>
        <div class="container padding-container">
            <div class="row basic-info text-center class-steps">
                <div class="col-4 col-md-4">
                    <h4 class="step1" [ngClass]="{'inactive': formStep != 1, 'active': formStep == 1, 'error': errorsFacility.length > 0 }">
                        <span class="step step-mobile" [ngClass]="{'inactive': formStep != 1, 'active': formStep == 1, 'error': errorsFacility.length > 0 }">1</span>

                    </h4>
                    <div class="progress-bar">
                        <span class="progress"
                            [ngClass]="{'progress': formStep == 1, 'progress-error': errorsFacility.length > 0 }"></span>
                    </div>
                </div>
                <div class="col-4 col-md-4">
                    <h4 class="step2" [ngClass]="{'inactive': formStep != 2, 'active': formStep == 2, 'error': errorsUsers.length > 0 }">
                        <span class="step step-mobile" [ngClass]="{'inactive': formStep != 2, 'active': formStep == 2, 'error': errorsUsers.length > 0 }">2</span>

                    </h4>
                    <div class="progress-bar">
                        <span class="progress"
                            [ngClass]="{'progress': formStep == 2, 'progress-error': errorsUsers.length > 0 }"></span>
                    </div>
                </div>
                <div class="col-4 col-md-4">
                    <h4 class="step3 " [ngClass]="{'inactive': formStep != 3, 'active': formStep == 3, 'error': errorsUnits.length > 0 }">
                        <span class="step step-mobile" [ngClass]="{'inactive': formStep != 3, 'active': formStep == 3, 'error': errorsUnits.length > 0 }">3</span>

                    </h4>
                    <div class="progress-bar">
                        <span class="progress"
                            [ngClass]="{'progress': formStep == 3, 'progress-error': errorsUnits.length > 0 }"></span>
                    </div>
                </div>
                <div  *ngIf="errorsArray.length !==0" class="error" >
                    <ul>
                        <li *ngFor="let error of errorsArray">
                            {{ error }}
                        </li>
                    </ul>
                    <span *ngIf="msjSinPasos !== ''">
                        {{ msjSinPasos }}
                    </span>
                </div>
            </div>
        </div>
        <!-- Begin Step 1 Facility -->
        <form [formGroup]="FacilityStepOneForm" *ngIf="formStep == 1">
            <div class="row">
                <div class="col-xs-12 col-sm-8 col-md-8 offset-sm-2 offset-md-2">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6" *ngIf="internalRol">
                            <div class="form-group">
                                <label for="Organization">Organization</label>
                                <select
                                    class="form-control input select"
                                    id="OrganizationId"
                                    formControlName="organizationId">
                                    <option [value]="0" selected>Select Organization</option>
                                    <option *ngFor="let org of listOrganization" [value]="org.id">
                                        {{ org.name }}
                                      </option>
                                </select>
                                <div *ngIf="FacilityStepOneForm.controls['organizationId'].invalid &&
                                        (FacilityStepOneForm.controls['organizationId'].dirty ||
                                        FacilityStepOneForm.controls['organizationId'].touched)" class="invalid-feedback">
                                    <div *ngIf="FacilityStepOneForm.controls['organizationId'].errors.required">* Organization is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6" >
                            <div class="form-group">
                                <label for="facility">Name</label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="facility"
                                    name="facility"
                                    required
                                    formControlName="facility"
                                    placeholder="Name">
                                    <div *ngIf="FacilityStepOneForm.controls['facility'].invalid &&
                                        (FacilityStepOneForm.controls['facility'].dirty ||
                                        FacilityStepOneForm.controls['facility'].touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="FacilityStepOneForm.controls['facility'].errors.required">
                                            * Name is required
                                        </div>
                                        <div *ngIf="FacilityStepOneForm.controls['facility'].errors.maxlength">
                                            * Name must have  at most 50 characters
                                        </div>
                                        <div *ngIf="FacilityStepOneForm.controls['facility'].errors.minlength">
                                            * Name must be at least 5 characters long
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="phone">Phone Number</label>
                                <input type="text"
                                    class="form-control input"
                                    id="phone"
                                    required
                                    name="phone"
                                    formControlName="phone"
                                    placeholder="123 123 1234">
                                    <div *ngIf="FacilityStepOneForm.controls['phone'].invalid
                                        && (FacilityStepOneForm.controls['phone'].dirty ||
                                        FacilityStepOneForm.controls['phone'].touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="FacilityStepOneForm.controls['phone'].errors.required">
                                            * Phone Number is required
                                        </div>
                                        <div *ngIf="FacilityStepOneForm.controls['phone'].errors.pattern">
                                            * Phone pattern is not valid
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="address"
                                    name="address"
                                    required
                                    min="5"
                                    max="50"
                                    formControlName="address"
                                    placeholder="4348 Rubaiyat Road Grand Rapids, MI 49503">
                                    <div *ngIf="FacilityStepOneForm.controls['address'].invalid &&
                                        (FacilityStepOneForm.controls['address'].dirty ||
                                        FacilityStepOneForm.controls['address'].touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="FacilityStepOneForm.controls['address'].errors.required">
                                            * Address is required
                                        </div>
                                        <div *ngIf="FacilityStepOneForm.controls['address'].errors.maxlength">
                                            * Address must have  at most 100 characters
                                        </div>
                                        <div *ngIf="FacilityStepOneForm.controls['address'].errors.minlength">
                                            * Address must be at least 5 characters long
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="state">State</label>
                                <select
                                    class="form-control input select-old"
                                    id="state"
                                    formControlName="state"
                                    name="state"
                                    required
                                    (change)="selectedState($event)">
                                    <option value="" selected>Select State</option>
                                    <option *ngFor="let state of listState" [value]="state.id">
                                        {{ state.name }}
                                    </option>
                                </select>
                                <div *ngIf="FacilityStepOneForm.controls['state'].invalid &&
                                        (FacilityStepOneForm.controls['state'].dirty ||
                                        FacilityStepOneForm.controls['state'].touched)"
                                        class="invalid-feedback">
                                    <div *ngIf="FacilityStepOneForm.controls['state'].errors.required">
                                        * State is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="city">City</label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="city"
                                    name="city"
                                    required
                                    formControlName="city"
                                    placeholder="City">
                                <div *ngIf="FacilityStepOneForm.controls['city'].invalid &&
                                      (FacilityStepOneForm.controls['city'].dirty ||
                                      FacilityStepOneForm.controls['city'].touched)"
                                      class="invalid-feedback">
                                    <div *ngIf="FacilityStepOneForm.controls['city'].errors.required">
                                        * City is required
                                    </div>
                                    <div *ngIf="FacilityStepOneForm.controls['city'].errors.maxlength">
                                        * City must have  at most 50 characters
                                    </div>
                                    <div *ngIf="FacilityStepOneForm.controls['city'].errors.minlength">
                                        * City must be at least 5 characters long
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center form-buttons">
                        <button
                            type="submit"

                            [disabled]="FacilityStepOneForm.invalid" class="btn btn-primary submit btn-next"
                            (click)="nextStep(formStep + 1)">
                            next
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <!-- End Step 1 Facility -->

        <!-- Begin Step 2 Users -->
        <form [formGroup]="UsersStepTwoForm" *ngIf="formStep == 2">
            <div class="row" formArrayName="users">
                <div class="col-xs-12 col-sm-10 col-md-10 offset-sm-2 offset-md-2">
                        <div class="row" *ngFor="let user of users.controls; index as i" [formGroupName]="i">
                            <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        type="email"
                                        class="form-control input"
                                        id="email" required
                                        formControlName="email"
                                        placeholder="example@mail.com">
                                        <div
                                            *ngIf="user.controls['email'].invalid &&
                                                    (user.controls['email'].dirty ||
                                                    user.controls['email'].touched)" class="invalid-feedback">
                                            <div *ngIf="user.controls['email'].errors.required">
                                                * Email is required
                                            </div>
                                            <div *ngIf="user.controls['email'].errors.email">
                                                * Email pattern is not valid
                                            </div>
                                            <div *ngIf="user.controls['email'].errors.maxlength">
                                                * Email must have  at most 100 characters
                                            </div>
                                            <div *ngIf="user.controls['email'].errors.minlength">
                                                * Email must be at least 5 characters long
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-8 col-sm-4 col-md-4">
                                <div class="form-group">
                                    <label for="role">Role</label>
                                    <select
                                        class="form-control input select-old"
                                        id="role"
                                        formControlName="role"
                                        name="role"
                                        required>
                                        <option value="" selected>Select Role</option>
                                        <option *ngFor="let role of listRoles" [value]="role.id">
                                            {{ role.name }}
                                        </option>
                                    </select>
                                      <div *ngIf="user.controls['role'].invalid &&
                                            (user.controls['role'].dirty ||
                                            user.controls['role'].touched)"
                                            class="invalid-feedback">
                                        <div *ngIf="user.controls['role'].errors.required">
                                            * Role is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-4 col-sm-4 col-md-4 text-centers">
                                <button (click)="removeUser(i)" *ngIf="position > 0" class="btn btn-light sm-btn">
                                    <span>-</span>
                                </button>
                                <button type="button"
                                    [disabled]="UsersStepTwoForm.invalid"
                                    (click)="addUser(i)" *ngIf="position == i"
                                    class="btn btn-light sm-btn">
                                    <span>+</span>
                                </button>
                            </div>
                        </div>

                </div>
            </div>

            <div class="text-center form-buttons">
                <button type="button"

                    (click)="backStep(formStep - 1)" class="btn btn-light btn-prv btn-step2 ">
                    previous step
                </button>
                <button type="button" [disabled]="UsersStepTwoForm.invalid"

                    (click)="nextStep(formStep + 1)" class="btn btn-primary btn-next btn-step2">next</button>
            </div>
        </form>
        <!-- End Step 2 -->

        <!-- Begin Step 3 Units -->
        <form [formGroup]="UnitsStepThreeForm" *ngIf="formStep == 3">
            <div class="row" formArrayName="units">
                <div class="col-xs-12 col-sm-10 col-md-10 offset-sm-2 offset-md-2">
                        <div class="row" *ngFor="let unit of units.controls; index as i" [formGroupName]="i">
                            <div class="col-xs-12 col-12 col-sm-4 col-md-4">
                                <div class="form-group">
                                    <label for="name">Mac Address</label>
                                    <input
                                    type="text" class="form-control input" formControlName="macAddress" placeholder="Mac Address">
                                     <div *ngIf="unit.controls['macAddress'].invalid && (unit.controls['macAddress'].dirty
                                        || unit.controls['macAddress'].touched)" class="invalid-feedback">
                                        <div *ngIf="unit.controls['macAddress'].errors.required">
                                            * Mac Address is required
                                        </div>
                                        <div *ngIf="unit.controls['macAddress'].errors.macAddressRegistered">
                                            Mac Address is not registered
                                          </div>
                                        <div *ngIf="unit.controls['macAddress'].errors.minlength">
                                            * Mac Address must be at least 29 characters long
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="!(unit.get('macAddress').invalid) && !(unit.controls['macAddress'].pending) " >

                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input
                                        type="text"
                                        class="form-control input"
                                        required id="name"
                                        formControlName="name"
                                        placeholder="Name">
                                    <div *ngIf="unit.controls['name'].invalid && (unit.controls['name'].dirty
                                        || unit.controls['name'].touched)" class="invalid-feedback">
                                        <div *ngIf="unit.controls['name'].errors.required">
                                            * Name is required
                                        </div>
                                        <div *ngIf="unit.controls['name'].errors.maxlength">
                                            * Name must have  at most 50 characters
                                        </div>
                                        <div *ngIf="unit.controls['name'].errors.minlength">
                                            * Name must be at least 5 characters long
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <!--  <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="(unit.get('macAddress').invalid)"></div> -->
                          <!--   <div class="col-xs-12 col-sm-4 col-md-4">
                                <div class="form-group">
                                    <label for="siun">SUIN Number</label>
                                    <input type="number"
                                        class="form-control input"
                                        required id="siun"
                                        formControlName="siun"
                                        placeholder="0000">
                                    <div *ngIf="unit.controls['siun'].invalid &&
                                                (unit.controls['siun'].dirty ||
                                                unit.controls['siun'].touched)" class="invalid-feedback">
                                        <div *ngIf="unit.controls['siun'].errors.required">* SUIN Number is required</div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-xs-12 col-4 col-sm-4 col-md-4 text-centers" *ngIf="!(unit.controls['macAddress'].pending) && !(unit.get('macAddress').invalid)">
                                <button (click)="removeUnit(i)" *ngIf="unitsPosition > 0" class="btn btn-light sm-btn">
                                    <span>-</span>
                                </button>
                                <button type="button"
                                    [disabled]="UnitsStepThreeForm.invalid"
                                        (click)="addUnit(i)" *ngIf="unitsPosition == i"
                                    class="btn btn-light sm-btn">
                                    <span>+</span>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>

            <div class="text-center form-buttons">
                <button type="button"

                    (click)="backStep(formStep - 1)"
                    class="btn btn-light btn-prv btn-step2">
                    previous step
                </button>


                <button type="button"

                    [disabled]="UnitsStepThreeForm.invalid"
                    (click)="save(template)" class="btn btn-primary btn-next btn-step2">
                        save
                 </button>

                <!-- <button type="button"
                    style="border-radius: 26px; color: #00CFFF; font-weight: 700;"
                    [disabled]="units.controls[0].get('name').errors?.required ||
                    units.controls[0].get('siun').errors?.required"
                    (click)="save()" class="btn btn-primary">
                    save
                </button> -->
            </div>
        </form>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center modal-fit">
        <h1>
            Facility created
            <br>
            <span class="accent-text">successfully!</span>
        </h1>
        <!-- <button type="button" (click)="closeSaveModal(template)" class="btn btn-primary submit">Accept</button> -->
    </div>
</ng-template>
