import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ValidatorFn,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
import { AppServices } from 'src/app/services/api.service';
import { UnitModel } from 'src/app/models/unit/unit.model';
import { RolsEnum } from 'src/app/models/enum/rols.enum';
import { ToastrService } from 'ngx-toastr';
import { facilityRol, internalsRol, organizationRol } from '@Models/role.model';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { Router } from '@angular/router';
import { ActionMenuService } from 'src/app/services/action-menu.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

class Users {
  name!: string;
  email!: string;
  facility!: any;
  roleId!: string;
  facilityId!: number;
  organizationId!: number;
}

class Unit {
  name!: string;
  //suinNumber!: number;
  facility!: any;
  facilityId!: number;
  organizationId!: number;
}

@Component({
  selector: 'app-assign-new-unit',
  templateUrl: './assign-new-unit.component.html',
  styleUrls: ['./assign-new-unit.component.sass'],
})
export class AssignNewUnitComponent implements OnInit {
  unitsForm: FormGroup;
  position: number = 1;
  modalRef: BsModalRef | null;

  public dataUnit = new UnitModel();
  optionFacility = {
    listFacility: [],
  };
  errorsArray: string[] = [];
  public userRole: RolsEnum;
  listOrganization: any[] = [];
  internalRol: boolean = false;
  oraganizationListRol: boolean = false;
  facilityListRol: boolean = false;
  organizationLocalStorageId = this.authService.getOrganizationId();
  facilityLocalStorageId = this.authService.getFacilityId();
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private appServices: AppServices,
    private modalService: BsModalService,
    private router: Router,
    private toastrService: ToastrService,
    private sideNavbarService: SideNavbarService,
    private actionMenu: ActionMenuService
  ) {

    this.actionMenu.setSelectMenu('UD');

    this.userRole = this.authService.authStatus.getValue().role;
    //const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
    //this.internalRol = isInternalRol.length > 0 ? true : false;
    //const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;


    let menu = this.sideNavbarService.rolsMenu$;
    let indexOrganizationList = menu.findIndex(
      (item) => item.link === '/units-dashboard'
    );
    const indexUserInvite = menu.findIndex((item) =>
      item.link.includes('/flushes-history')
    );
    const indexUnitDetails = menu.findIndex((item) =>
      item.link.includes('/unit-details')
    );
    const orgProfile = menu.findIndex(
      (item) => item.link === `/assign-new-unit`
    );
    let indexMneChange = -1;
    if (indexOrganizationList !== -1) {
      indexMneChange = indexOrganizationList;
    } else if (indexUserInvite !== -1) {
      indexMneChange = indexUserInvite;
    } else if (orgProfile !== -1) {
      indexMneChange = orgProfile;
    } else if (indexUnitDetails !== -1) {
      indexMneChange = indexUnitDetails;
    }
    if (indexMneChange !== -1) {
      menu[indexMneChange].link = this.router.url;
      this.sideNavbarService.rolsMenu$ = menu;
    }

    if (this.oraganizationListRol || this.internalRol) {
      this.getListFacility(+this.organizationLocalStorageId, 0);
    }
    if (this.internalRol) {
      this.getListOrganization();
    }
  }

  ngOnInit() {
    this.unitsForm = this.fb.group({
      units: this.fb.array([]),
    });
    this.addQuantity(0, true);

  }

  ngOnDestroy(): void {
    let menu = this.sideNavbarService.rolsMenu$;
    let index = menu.findIndex((item) =>
      item.link.includes('/assign-new-unit')
    );
    if (index !== -1) {
      menu[index].link = '/units-dashboard';
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  getListOrganization() {
    if (this.internalRol) {

      this.appServices.getForSelectOrganization().subscribe((data) => {
        this.listOrganization = data['data'];
      });
    }
  }

  get units(): FormArray {
    return this.unitsForm.get('units') as FormArray;
  }

  newQuantity(i: number, loadFirst: boolean = false): FormGroup {
    const organizationIdProfile = localStorage.getItem('organizationIdProfile');
    let organizationId =
      this.organizationLocalStorageId === null
        ? '0'
        : this.organizationLocalStorageId;
    const facilityLocalStorage = localStorage.getItem('facilityId');
    let facilityId = this.facilityListRol ? +facilityLocalStorage : 0;

    if (this.oraganizationListRol || this.internalRol) {
      this.getListFacility(+organizationId, i + 1);
    }
    if (organizationIdProfile !== 'null') {
      this.getListFacility(+organizationIdProfile, loadFirst ? 0 : i + 1);
    }

    let ProfileOrganizationId = localStorage.getItem('ProfileOrganizationId');
    let ProfileFacilityId = localStorage.getItem('ProfileFacilityId');

    this.getListFacility(+ProfileOrganizationId, loadFirst ? 0 : i + 1);

    return this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      facilityId: [+ProfileFacilityId],
      macAddress: new FormControl(
        '',
        [Validators.required],
        [this.validatonMacAddress()]
      ),
      facility: ['Facility'],
      organizationId: [
        ProfileOrganizationId === null ? '0' : ProfileOrganizationId,
      ],
    });
  }

  addQuantity(i, loadFirst: boolean = false) {
    i == 0 && this.position == 1 ? (this.position = i) : this.position++;
    this.units.push(this.newQuantity(i, loadFirst));
  }

  removeQuantity(i: number) {
    this.position--;
    this.units.removeAt(i);
    //this.optionFacility.listFacility = this.optionFacility.listFacility.splice(i, 1);
  }

  onSubmit() {}

  inputChange(evt: KeyboardEvent, key: string,unit?: FormGroup, i?: number) {
    //@ts-ignore
    let value = evt.target.value;
    if (this.unitsForm[key] !== value) {
      this.dataUnit[key] = value;
    }
    if (key === 'OrganizationId') {
      if (this.internalRol) {
        let taskListArrays = this.units;
        taskListArrays.controls[i].patchValue({ facilityId: 0 });
        this.getListFacility(+value, i);
      }
    }

    if (this.internalRol) {
      unit.get('facilityId').setValidators(this.validatonFacility());
      unit.get('facilityId').updateValueAndValidity();
      unit.get('organizationId').setValidators(this.validatonOrganization());
      unit.get('organizationId').updateValueAndValidity();
    }

  }

  openModal(template: TemplateRef<any>) {
    let dataUnitSave = this.unitsForm.value.units;
    let organizationId =
      this.organizationLocalStorageId !== null
        ? +this.organizationLocalStorageId
        : 0;
    let facilityId =
      this.facilityLocalStorageId !== null ? +this.facilityLocalStorageId : 0;
    this.errorsArray = [];
    const dataSave: UnitModel[] = dataUnitSave.map((item) => {
      item.facilityId = this.internalRol ? Number(item.facilityId) : facilityId;
      item.organizationId = this.internalRol
        ? +item.organizationId
        : organizationId;
        item.facility = null;
      return item;
    });
    this.appServices.assignUnit(dataSave).subscribe(
      (data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, {
            id: 1,
            class: 'modal-md modal-dialog-centered succesfullyCustom',
          });

          //this.modalRef = null;
          this.unitsForm.reset();
          //setTimeout(this.modalRef.hide,5000)
          this.resetForm(this.unitsForm);
          let timerId = setInterval(() => this.closeSaveModal(), 3000);
          setTimeout(() => {
            clearInterval(timerId);
            this.modalRef.hide();
          }, 3000);
        }
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          let errorsList = [];
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.errorsArray = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                errorsList.push(valueError);
              }
            });
          }
          if (this.errorsArray.length === 0 && errors !== '') {
            this.errorsArray.push(errors);
          } else {
            this.errorsArray = errorsList;
          }
        } else {
          this.errorsArray.push(errors);
        }
      }
    );
  }

  validatonFacility(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { requiredFacility: true };
    };
  }

  validatonOrganization(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { requiredrganization: true };
    };
  }

  getListFacility(organizationId?: number, i?: number) {
    this.optionFacility.listFacility[i] = [];
    if (organizationId) {

      this.appServices.getListFacility(organizationId).subscribe(
        (data: any) => {
          this.optionFacility.listFacility[i] = data['data'];

        },
        (error) => {
          let errors = JSON.parse(error);
          if (typeof errors !== 'object') {
            //this.toastrService.error('error', errors)
          }
        }
      );
    }
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.hide();
    this.modalRef = null;
    this.resetForm(this.unitsForm);
    this.unitsForm.setControl('units', this.fb.array([]));
    this.position = 1;
    this.addQuantity(0);
    this.errorsArray = [];
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });

    this.position = 1;

    this.unitsForm = this.fb.group({
      units: this.fb.array([]),
    });
    this.addQuantity(0, true);
  }

  closeSaveModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  validatonMacAddress(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.appServices
        .GetIsUnitRegistered(control.value)
        .pipe(
          map((result) =>
            Boolean(result['data']) === true
              ? null
              : { macAddressRegistered: true }
          )
        );
    };
  }
}
