<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <h1>
              List of Organizations
            </h1>
            <p>Use the Advance search filters to optimize your search.</p>
          </div>
          <div class="col-md-6 text-right">
         <div>
          <button class="btn btn-primary submit btn-add " routerLink="/add-organization">+ Add Organization</button>
         </div>



          </div>

        </div>
        <ul class="nav filter-menu">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': allUnitsClassActive }" (click)="allOrganizations()">
              All organizations
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': onlineClassActive }" (click)="onlineOrganizations('Active')">
              <span class="online"></span>
              Active
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': OfflineClassActive }" (click)="onlineOrganizations('Inactive')">
              <span class="offline "></span>
              Inactive
            </a>
          </li>
          <a class="filters-link" (click)="filters()">
            <span *ngIf="activeFilters">Close advance search</span>
            <span *ngIf="!activeFilters">Advance search</span>
          </a>
          <!-- <a class="filters-link" style="padding-right: 80px;" (click)="filters()">Advance search</a> -->
          <a class="filters-link style-link" >
            <img [src]="'assets/images/icons/filter.svg'" alt="Sort">
            Sort
            <img [src]="'assets/images/icons/botton.svg'" alt="Sort">
          </a>
        </ul>
        <hr>
        <div class="row filters" *ngIf="activeFilters" [formGroup]="searchAdvancedFrom">
          <div [ngClass]="classFilters()">

              <div class="form-group padding">
                <label>Name</label>
                <input type="text" class="form-control input" placeholder="Name" formControlName="name"
                  (change)="search()">
              </div>


          </div>
          <div [ngClass]="classFilters()">

              <div class="form-group">
                <label for="state">State</label>
                <select class="form-control input select" formControlName="state" name="state" (change)="search()">
                  <option value="0" selected>Show All</option>
                  <option *ngFor="let state of listState" [value]="state.id">
                    {{ state.name }}
                  </option>
                </select>
              </div>


          </div>
          <div [ngClass]="classFilters()">

              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control input" placeholder="City" formControlName="city"
                  (change)="search()">
              </div>


          </div>
          <!-- <div class="col-12 col-sm-2 col-md-2">
                <div class="form-group">
                  <label>Numbers of Facilities </label>
                  <input
                    type="text"
                    class="form-control input"
                    placeholder="Name"
                    formControlName="name"
                    (change)="search()">
                </div>
            </div>
            <div class="col-12 col-sm-2 col-md-2">
              <div class="form-group">
                <label for="facility">Status</label>
                <select
                  class="form-control input"
                  formControlName="status"
                  name="status"
                  (change)="search()">
                  <option value="2" selected>Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div> -->
        </div>
        <div class="row labels">
          <div class="col-6 col-sm-3 col-md-2 col-lg-3 margin-name">
            <label for="name">name</label>
          </div>
          <div class="col-2 col-sm-1 col-md-1 col-lg-1 text-center state">
            <label for="state">state</label>
          </div>
          <div class="col-2 col-sm-2 col-md-2 col-lg-2 text-center city">
            <label for="city">city</label>
          </div>
          <div class="col-1 col-sm-1 col-md-2 col-lg-1 text-center facilities margin-facilities">
            <label for="city">Facilities</label>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 text-center user margin-users">
            <label for="city">Users</label>
          </div>
          <div class="col-2 col-sm-1 col-md-1 col-lg-1 text-center status4">
            <label for="city">Status</label>
          </div>
          <div class="col-4 col-sm-3 col-md-3 col-lg-3 text-center options">
            <label for="units">Options</label>
          </div>
        </div>
        <!-- <div class="row data" [routerLink]="['/facility-profile']"> -->
        <div class="row data" *ngFor="let organization of dataOrganization.data; let i = index">
          <div class="col-6 col-sm-3 col-md-2 col-lg-3">
            <h5>{{ organization.name }}</h5>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 text-center state2">
            <p class="info">{{ organization?.state }}</p>
          </div>
          <div class="col-2 col-sm-2 col-md-2 col-lg-2 text-center city2">
            <p class="info">{{ organization?.city }}</p>
          </div>
          <div class="col-1 col-sm-1 col-md-2 col-lg-1 text-center facilities">
            <p class="info">{{ organization.facilitiesNumber }}</p>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 text-center user">
            <p class="info">{{ organization.usersNumber }}</p>
          </div>
          <div class="col-2 col-sm-1 col-md-1 col-lg-1 text-center status2">
            <p class="status-box">
              <span class="status on mrg-sta" *ngIf="organization.status === 'Active'">
                {{ organization.status }}
              </span>

              <span class="inactive margin-span" *ngIf="organization.status !== 'Active'">
                {{ organization.status }}
              </span>

            </p>
          </div>
          <div class="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center actions">
            <button type="button" class="btn btn-primary actived btn-margin" (click)="navigateLink(organization)"
              *ngIf="organization.setup == true">
              <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role">
            </button>

            <a><button type="button" [routerLink]="'/set-up-organization/' + organization.id"
                class="btn btn-light actived" *ngIf="organization.setup == false">
                <!-- <span class="icon icon-play-circle"></span> -->
                <img [src]="'assets/images/icons/icon-play-circle.svg'" class="icon-play-circle-active" alt="Deactivate Organization">
              </button></a>

            <a><button type="button" (click)="openModal(deactivate, organization,i)" class="btn btn-light actived"
                *ngIf="organization.status === 'Active'">
                <img [src]="'assets/images/icons/deactivate-icon-2.svg'" alt="Deactivate Organization">
              </button></a>
            <a><button type="button" (click)="openActivateModal(activate, organization,i)" class="btn btn-light actived"
                *ngIf="organization.status !== 'Active'">
                <img [src]="'assets/images/icons/check-circle-activate.svg'" alt="Activate Organization">
              </button></a>
            <!-- <button type="button" (click)="openModal(delete)" class="btn btn-light actived">
                  <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete Facility">
              </button> -->
            <!--Disable Buttons-->
            <button type="button" class="btn btn-light disabled btn-margin" disabled *ngIf="organization.setup == true">
              <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light disabled btn-margin" disabled
              *ngIf="organization.setup == false">
              <!-- <span class="icon icon-play-circle"></span> -->
              <img class="icon-play-circle-disable" [src]="'assets/images/icons/icon-play-circle.svg'" alt="Deactivate Organization">
            </button>
            <button type="button" class="btn btn-light disabled" disabled *ngIf="organization.status === 'Active'">
              <img [src]="'assets/images/icons/deactivate-icon.svg'" alt="Deactivate Organization">
            </button>

            <button type="button" class="btn btn-light disabled" disabled *ngIf="organization.status !== 'Active'">
              <img [src]="'assets/images/icons/check-circle.svg'" alt="Deactivate Organization">
            </button>

            <!-- <button type="button" class="btn btn-light disabled" disabled *ngIf="organization.status !== 'Active'">
                <img [src]="'assets/images/icons/check-circle-activate.svg'" alt="Deactivate Organization"
                style="width: 20px; filter: invert(69%) sepia(3%) saturate(261%) hue-rotate(314deg) brightness(88%) contrast(94%);">
              </button> -->
            <!-- <button type="button" class="btn btn-light disabled" disabled>
                  <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete Facility">
              </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center top-button">

      <div class="col-6 col-md-auto pading-right" *ngIf="activeLess">

        <p class="text-center">
          <button type="button" class="btn btn-light btn-view" (click)="viewLess()">View less</button>
        </p>
      </div>
      <div class="col-6 col-md-auto pading-left" *ngIf="!isDisabled">
        <p class="text-center">
          <button [style]="{'margin-right': !activeLess ? '0px': ''}" type="button" class="btn btn-primary btn-more" (click)="viewMore()" [disabled]="isDisabled">View
            more</button>
        </p>
      </div>
    </div>


  </div>
</div>
<!-- Modals -->
<!-- <ng-template #edit>
  <div class="modal-body">
    <form [formGroup]="facilityForm" (ngSubmit)="onSubmit()">
      <p class="back" (click)="closeFirstModal(edit)">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to organization List">
        Back to facility list
      </p>
      <div class="form-group">
        <label for="facilityName">Facility Name</label>
        <input type="text" class="form-control input" formControlName="facilityName" id="facilityName" placeholder="Facility Name">
      </div>

      <div class="form-group">
        <label for="state">State</label>
        <select class="form-control input" formControlName="state" id="state">
          <option>Select State</option>
        </select>
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <select class="form-control input" formControlName="city" id="city">
          <option>Select City</option>
        </select>
      </div>
      <div class="form-group">
        <label for="address">Units</label>
        <input type="text" class="form-control input" formControlName="units" id="units" placeholder="Units">
      </div>
      <div class="form-group">
        <label for="city">Users</label>
        <select class="form-control input" formControlName="users" id="users">
          <option>Select Users</option>
        </select>
      </div>
      <p class="text-center">
        <button type="button" [disabled]="facilityForm.invalid" style="border-radius: 26px; color: #00CFFF; font-weight: 700;"
        (click)="closeFirstModal(edit)" class="btn btn-primary submit">save changes</button>
      </p>
      <p class="bottom-info text-center">Disclaimer here?</p>
    </form>
  </div>
</ng-template> -->
<ng-template #template>
  <!-- <div class="modal-body text-center modal-fit">
      <p class="back" (click)="closeFirstModal(template)">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to organization list">
        Back to organization list
      </p>
      <h1 class="modal-title">
          Are you sure you want to
          <br>
          <span class="accent-text">delete</span> this facility?
      </h1>
      <p>
        <button type="button" (click)="closeFirstModal(template)" class="btn btn-primary submit full-width">no, I want to go back</button>
      </p>
      <p>
        <button class="btn btn-light full-width" (click)="closeFirstModal(template)">yes I want to delete it</button>
      </p>
  </div> -->
  <div class="modal-body text-center modal-fit">
    <h1>
      Are you sure you want to
      <br>
      <span class="accent-text">delete</span> this facility?
    </h1>
    <p>
      <button type="button" (click)="closeFirstModal(template)" class="btn btn-primary submit full-width">no, I want to
        go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeFirstModal(template)">yes I want to delete it</button>
    </p>
  </div>
</ng-template>
<ng-template #deactivate>
  <!-- <div class="modal-body text-center modal-fit">
      <p class="back" (click)="close()">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to organization List">
        Back to organization list
      </p>
      <h1 class="modal-title">
          Are you sure you want to
          <br>
          <span class="accent-text">deactivate</span> this organization?
      </h1>
      <p>
        <button type="button" (click)="close()" class="btn btn-primary submit full-width">no, I want to go back</button>
      </p>
      <p>
        <button class="btn btn-light full-width" (click)="closeFirstModal(deactivate)">yes I want to deactivate it</button>
      </p>
  </div> -->
  <div class="modal-body text-center modal-fit">
    <h1>
      Are you sure you want to

      <span class="accent-text">deactivate</span> this organization?
    </h1>
    <p>
      <button type="button" (click)="close()" class="btn btn-primary submit full-width bottom-btn">no, I want to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeFirstModal(succesfully)">yes I want to deactivate
        it</button>
    </p>
  </div>
</ng-template>

<ng-template #activate>
  <!--  <div class="modal-body text-center modal-fit">
      <p class="back" (click)="close()">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to organization List">
        Back to organization list
      </p>
      <h1 class="modal-title">
          Are you sure you want to
          <br>
          <span class="accent-text">activate</span> this organization?
      </h1>
      <p>
        <button type="button" (click)="close()" class="btn btn-primary submit full-width">no, I want to go back</button>
      </p>
      <p>
        <button class="btn btn-light full-width" (click)="closeActivateModal(activate)">yes I want to activate it</button>
      </p>
  </div> -->
  <div class="modal-body text-center modal-fit">
    <h1>
      Are you sure you want to
    <br>
      <span class="accent-text">activate</span> this organization?
    </h1>
    <p>
      <button type="button" (click)="close()" class="btn btn-primary submit full-width bottom-btn">no, I want to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeActivateModal(succesfully)">yes I want to activate
        it</button>
    </p>
  </div>
</ng-template>

<!-- <ng-template #delete>
  <div class="modal-body text-center modal-fit">
      <p class="back" (click)="closeFirstModal(deactivate)">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility List">
        Back to facility list
      </p>
      <h1 class="modal-title">
          Are you sure you want to
          <br>
          <span class="accent-text">delete</span> this facility?
      </h1>
      <p>
        <button type="button" (click)="closeFirstModal(deactivate)" class="btn btn-primary submit full-width">no, I want to go back</button>
      </p>
      <p>
        <button class="btn btn-light full-width" (click)="closeFirstModal(delete)">yes I want to delete it</button>
      </p>
  </div>
</ng-template> -->
<ng-template #succesfully>
  <div class="modal-body text-center modal-fit">
    <h1>
      Organization {{messageModal}}
      <span class="accent-text">successfully!</span>
    </h1>
  </div>
</ng-template>
