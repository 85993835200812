import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-savings-statistics',
  templateUrl: './savings-statistics.component.html',
  styleUrls: ['./savings-statistics.component.sass']
})
export class SavingsStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
