import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppServices } from 'src/app/services/api.service';
import { throwError } from 'rxjs';
import { CacheService } from './cache.service';
import { RolsEnum } from '../models/enum/rols.enum';
import { RoleModel, ROLS } from '@Models/role.model';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CacheService {
  rolsUsers: RoleModel | null;

  private readonly authProvider: (email: string, password: string) => Observable<IServerAuthResponse>;
  authStatus = new BehaviorSubject<IAuthStatus>(this.getItem('authStatus') || defaultAuthStatus);

  private myStatus = new BehaviorSubject<string>('');
  myStatus$ = this.myStatus.asObservable();

  constructor(private httpClient: HttpClient, private _appServices: AppServices,private router: Router) {
    super();

    this.authStatus.subscribe(authStatus => {
      this.setItem('authStatus', authStatus);
    });
  }

  /*login(user, pass) {

    this.logout();
  }*/

  login(data: any) {
    this.logout();
    return this.httpClient.post(`${environment.server}/api/Account/SignIn`, data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json')/*,
        withCredentials: false*/
      })
      .pipe(
        map(res => {

          let response: any = res;
          let decode: any = jwt_decode(response.data);

          this.setToken(response.data);
          const organization = decode?.OrganizationId || null;
          this.setOrganizationId(organization);
          this.setExp(decode.exp);
          const orgName = decode?.OrganizationName || '';
          this.setOrganizationName(orgName);
          const orgSetup = decode?.OrganizationSetup || '';
          this.setOrganizationSetup(orgSetup)
          this.setRoleLevel(decode.RoleLevel);
          const supervisor = decode?.SupervisorId || null;
          this.setSupervisorId(supervisor);
          //this.setRole(decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])
          this.setEmail(decode.Email);
          this.setNameUser(decode.Name)
          this.setUserId(decode.Id);
          const facility = decode?.FacilityId || null;
          this.setFacilityId(facility);
          const facName = decode?.FacilityName || null;
          this.setFacilityName(facName);
          const roleUser = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
          let roleSplit = roleUser.split(' ');
          const rolI = roleSplit[0];
          const rolII = roleSplit[1]??'';
          let rolData = `${rolI.charAt(0)}${rolII.charAt(0)}`;
          let rolEnum = ROLS.filter((item) => item.role === (rolData ==='S' ? 'VT': rolData));
          this.setInternalRole(decode.IsInternalRole);
          this.authStatus.next({
            role: rolEnum[0].role
          });
          return res;
        }),
        catchError(transformError)
      );
  }

  logout() {
    //this.removeItem('jwt');
    localStorage.clear();
    this.authStatus.next(defaultAuthStatus);
  }

  logoutUser(){
    this._appServices.getSignOut().subscribe((data) => {
      if (data) {
        localStorage.clear();
        this.authStatus.next(defaultAuthStatus);
      }
    })
  }

  private setToken(jwt: string) {
    this.setItem('jwt', jwt);
  }

  getToken(): string {
    return this.getItem('jwt') || '';
  }

  private setNameUser(nameUser: string) {
    this.setItem('nameUser', nameUser);
  }

  getNameUser(): string {
    return this.getItem('nameUser') || '';
  }

  private setOrganizationId(organizationId: string) {
    this.setItem('organizationId', organizationId);
  }

  getOrganizationId(): string {
    return this.getItem('organizationId') || '0';
  }

  private setFacilityId(facilityId: string) {
    this.setItem('facilityId', facilityId);
  }

  getFacilityId(): string {
    return this.getItem('facilityId') || '0';
  }

  private setFacilityName(facilityName: string) {
    this.setItem('facilityName', facilityName);
  }

  getFacilityName(): string {
    return this.getItem('facilityName') || '';
  }

  private setExp(expiration: string) {
    this.setItem('exp', expiration);
  }

  getExp(): string {
    return this.getItem('expiration') || '';
  }

  getAuthStatus(): IAuthStatus {
    return this.getItem('authStatus');
  }

  private setOrganizationName(organizationName: string) {
    this.setItem('organizationName', organizationName);
  }

  public setOrganizationSetup(organizationSetup: string) {
    this.setItem('organizationSetup', organizationSetup);
    this.myStatus.next(organizationSetup);
  }

  getOrganizationSetup(): string {
    return this.getItem('organizationSetup') || '';
  }

  getOrganizationName(): string {
    return this.getItem('organizationName') || '';
  }

  private setRoleLevel(roleLevel: string) {
    this.setItem('roleLevel', roleLevel);
  }

  getRoleLevel(): string {
    return this.getItem('roleLevel') || '0';
  }

  private setSupervisorId(supervisorId: string) {
    this.setItem('supervisorId', supervisorId);
  }

  getSupervisorId(): string {
    return this.getItem('supervisorId') || '0';
  }

  private setRole(role: string) {
    this.setItem('role', role)
  }

  getRole(): string {
    return this.getItem('role') || RolsEnum.All;
  }

  private setEmail(email: string) {
    this.setItem('email', email)
  }

  getEmail(): string {
    return this.getItem('email') || '';
  }

  private setUserId(userId: string) {
    this.setItem('userId', userId)
  }

  getUserId(): string {
    return this.getItem('userId') || '';
  }

  private setInternalRole(inernalRole: string) {
    this.setItem('inernalRole', inernalRole);
  }

  getInternalRole(): string {
    return this.getItem('inernalRole') || '';
  }
}

export interface IAuthStatus {
  role: RolsEnum  //Role
}

interface IServerAuthResponse {
  token: string;
}

const defaultAuthStatus: IAuthStatus = {
  role: RolsEnum.All
};

export function transformError(error: HttpErrorResponse | string) {

  let errorMessage = 'An unknown error has occurred';
  if (typeof error === 'string') {
    errorMessage = JSON.stringify(error);
  } else if (error.error instanceof ErrorEvent) {
    if (error.error.message)
      errorMessage = JSON.stringify(`Error! ${error.error.message}`);
  } else if (error.status) {
    if (error.status === 400) {
      if (error.error?.errors) {
        errorMessage = JSON.stringify(error.error.errors);
      } else if (error.error?.data) {
        errorMessage = JSON.stringify(error.error.data);
      } else {
        errorMessage = JSON.stringify(error.error.message);
      }

    }else if (error.status === 401) {
      errorMessage = JSON.stringify(`Request failed with ${error.status}`);
    }else{
      errorMessage = JSON.stringify(`Request failed with ${error.status}`);
    }
  }
  return throwError(errorMessage);
}
