<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-3 col-lg-3">
        <h1>{{ organizationData.organization?.name || '' }}</h1>
      </div>
      <div class="col-12 col-sm-12 col-md-5 col-lg-6">
        <div class="row">
          <div class="col-4 col-sm-4 col-md-5 col-lg-4">
            <label for="address">address</label>
            <p class="info">{{ organizationData.address?.address || 'No address' }}</p>
            <label for="phone" class="top-label" >phone number</label>
            <p class="info">{{ organizationData.organization?.phoneNumber || 'No phone number' }}</p>

          </div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-4">
            <label for="city">City</label>
            <p class="info">{{ organizationData.address?.city || 'No city' }}</p>
            <label for="state" class="top-label">state</label>
            <p class="info">{{ organizationData.address?.stateName || 'No stateName' }}</p>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4">
            <label for="units">Facilities</label>
            <p class="info">{{ organizationData.facilitiesNumber }}</p>
            <label for="members" class="top-label">Members</label>
            <p class="info">{{ organizationData.usersNumber }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3 col-md-4 col-lg-3 btn-col">
        <button  class="btn btn-primary btn-editOrganization"
          (click)="openEditModal(edit)">edit organization</button>

        <button
          class="btn btn-light btn-deactivate" (click)="openModal(deactivate)" *ngIf="organizationData.organization?.status">Deactivate
          organization</button>

        <button
          class="btn btn-light btn-activate" (click)="openModal(activate)" *ngIf="!organizationData.organization?.status">Activate
          organization</button>
        <!-- <button class="btn btn-light reboot-unit" (click)="openModal(template)">delete organization</button> -->
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card-main-title margin-card">
          <label class="margin-units" for="units">units</label>
          <a class="right-action add top-links" routerLink="/assign-new-unit">Assign new unit</a>
          <a class="right-action dhub top-links" routerLink="/units-dashboard">See all units</a>
        </div>
      </div>
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
  <owl-carousel-o [options]="customOptions" *ngIf="unitsFacility !== null">
    <ng-template carouselSlide *ngFor="let item of listFacilityCard">
      <div class="card">
        <h4>{{ item.name }} </h4>
        <div class="status good" *ngIf="item.health === 'Steady'"></div>
        <div class="status check" *ngIf="item.health === 'Warning'"></div>
        <div class="status bad" *ngIf="item.health === 'Critical'"></div>
        <p class="network">
          <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">
          {{ item.ipAddress }}
        </p>
        <p class="status">
          <span class="offline" *ngIf="item.status === 'Offline'"></span>
          <span class="online" *ngIf="item.status === 'Online'"></span>
          {{ item.status}}
          <span class="details" [routerLink]="['/unit-details/' + item.id]" style="z-index: 999!important;">
            Details
            <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
          </span>
        </p>
      </div>
    </ng-template>
    <ng-template carouselSlide *ngIf="listFacilityCard.length < 2">
      <div class="col-3 hide-section">
        <div class="card">
          <h4></h4>
          <div class="status good"></div>
          <div class="status check"></div>
          <div class="status bad"></div>
          <p class="network">
            <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">

          </p>
          <p class="status">
            <span class="offline"></span>
            <span class="online"></span>

            <span class="details">
              Details
              <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
            </span>
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide *ngIf="listFacilityCard.length < 3">
      <div class="col-3 hide-section">
        <div class="card">
          <h4></h4>
          <div class="status good"></div>
          <div class="status check"></div>
          <div class="status bad"></div>
          <p class="network">
            <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">

          </p>
          <p class="status">
            <span class="offline"></span>
            <span class="online"></span>

            <span class="details">
              Details
              <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
            </span>
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide *ngIf="listFacilityCard.length < 4">
      <div class="col-3 hide-section">
        <div class="card">
          <h4></h4>
          <div class="status good"></div>
          <div class="status check"></div>
          <div class="status bad"></div>
          <p class="network">
            <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">

          </p>
          <p class="status">
            <span class="offline"></span>
            <span class="online"></span>

            <span class="details">
              Details
              <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
            </span>
          </p>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

    </div>



    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="card-main-title">
          <label for="facilities">facilities</label>
          <!-- <div class="right-action add">Add new facility</div>
                    <div class="right-action facilities">See all facilities</div> -->
          <a class="right-action add top-facility" routerLink="/add-facility">Add new facility</a>
          <a class="right-action facilities top-facility" routerLink="/facilities-list">See all facilities</a>
        </div>
        <div *ngIf="dataFacility !== null">
          <div class="list-content">
            <div class="card-content">
              <div class="accent-card">
                <div class="row data" *ngFor="let item of listDataFacility">
                  <div class="col-6 col-md-4 col-lg-3 col-xl-4">
                    <h5 class="margin" >{{ item.name }}</h5>
                  </div>
                  <div class="col-3 col-md-3 col-lg-3 text-center city">
                    <p class="info">{{ item.city }}, {{ item.state }}</p>
                  </div>
                  <div class="col-3 col-md-2 col-lg-2  text-center status">
                    <p class="status-box">
                      <span class="info" *ngIf="item.status === 'Inactive'">Inactive</span>
                      <span class="status on" *ngIf="item.status !== 'Inactive'">
                        Active
                      </span>
                    </p>
                  </div>
                  <div class="col-3 col-md-3 col-lg-4 col-xl-3  text-center actions">
                    <button type="button" class="btn btn-primary actived" (click)="navigateLink(item)">
                      <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role">
                    </button>
                    <a>
                      <button type="button" (click)="openModalDeactivate(deactivateFacility, item)"
                        class="btn btn-light actived" *ngIf="item.status !== 'Inactive'">
                        <img [src]="'assets/images/icons/deactivate-icon-2.svg'" alt="Deactivate Facility"
                          class="img-width" >
                      </button>
                    </a>
                    <a>
                      <button type="button" (click)="openActivateModal(activateFacility, item)"
                        class="btn btn-light actived" *ngIf="item.status === 'Inactive'">
                        <img [src]="'assets/images/icons/check-circle-activate.svg'" alt="Activate Facility"
                        class="img-width">
                      </button>
                    </a>
                    <!--Disable Buttons-->
                    <button type="button" class="btn btn-light disabled" disabled>
                      <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
                    </button>
                    <button type="button" class="btn btn-light disabled" disabled *ngIf="item.status !== 'Inactive'">
                      <img [src]="'assets/images/icons/deactivate-icon.svg'" alt="Deactivate Facility"
                      class="img-width">
                    </button>
                    <button type="button" class="btn btn-light disabled" disabled *ngIf="item.status === 'Inactive'">
                      <img [src]="'assets/images/icons/check-circle.svg'" alt="Activate Facility" class="img-width">
                    </button>
                  </div>
                  <!-- <div class="col-2 text-center" *ngIf="dataFacility !== null" style="display: none;">
                    <p class="status-box">
                      <img [src]="'assets/images/icons/more-icon.svg'" alt="More">
                    </p>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="btn-view-more" *ngIf="organizationData.facilitiesNumber > 5">
              <div class="row justify-content-md-center" style="padding-top: 1em;">

                <div class="col-md-auto">

                  <p class="text-center" *ngIf="activeLess">
                    <button type="button"

                      class="btn btn-light btn-view" (click)="viewLessFacilities()">View less</button>

                  </p>

                </div>
                <div class="col-md-auto" *ngIf="!isDisabled">
                  <p class="text-center" style="margin: 0 auto;" *ngIf="supervisorUser?.data.length > 0">
                    <button type="button"
                      class="btn btn-primary btn-more" (click)="viewMoreFacilities()">View more</button>
                  </p>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 top-rowUsers">
        <div class="card-main-title">
          <label for="users">users</label>
          <!-- <div class="right-action add">Invite new user</div>
                    <div class="right-action users">See all users</div> -->
          <a class="right-action add top-users" routerLink="/users-invite">Invite new user</a>
          <a class="right-action users top-users" routerLink="/users-list">See all users</a>
        </div>

        <div *ngIf="supervisorUser !== null">
          <div class="list-content">
            <div class="card-content">
              <div class="accent-card" *ngIf="supervisorUser !== null">
                <div class="row data" *ngFor="let item of listDataUsers">
                  <div class="col-6 col-md-4 col-lg-4 margin-users">
                    <h5>{{ item.name }}</h5>
                    <p class="info bottom-p" style="overflow-wrap: break-word;">{{ item.email.substring(0, item.email.indexOf('@')) }} <br>{{ item.email.substring(item.email.indexOf('@'),item.email.length ) }} </p>
                  </div>
                  <div class="col-4 col-md-3 col-lg-4 role text-center">
                    <p class="top-facili" style="overflow-wrap: break-word;">{{ item.role }}</p>
                    <p class="info margin-facil" *ngIf="item.facility !== null || item?.facility !== ''">{{ item.facility }}</p>
                    <p class="info margin-item" *ngIf="item.facility === null && item.organization !== null">{{ item.organization }}
                    </p>
                  </div>
                  <div class="col-2 col-md-2 col-lg-1 text-center class-status" >
                    <p class="status-box bottom-status">
                      <span class="info span-inactive" *ngIf="item.status !== 'Active'" style="padding: 0px;">Inactive</span>
                      <span class="status on" *ngIf="item.status === 'Active'" style="padding: 0px;">
                        Active
                      </span>
                    </p>
                  </div>
                  <!-- <div class="col-3 text-center">
                    <p class="status-box">
                      <img [src]="'assets/images/icons/more-icon.svg'" alt="More">
                    </p>
                  </div> -->
                  <div class="col-3 col-md-3 col-lg-3 text-center actions marginOption style-facility" >
                    <button type="button" class="btn btn-primary actived" *ngIf="item.showBtnDeleteUpdateUser">
                      <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role"
                        (click)="openModalUsers(templateUser, item)">
                    </button>
                    <button type="button" class="btn btn-light actived" *ngIf="item.showBtnUpdateFacility">
                      <img [src]="'assets/images/icons/change-icon-2.svg'" alt="Change Facility" style="width: 20px"
                        (click)="openModalFacility(facility, item)">
                    </button>
                    <button type="button" class="btn btn-light actived" (click)="openDeleteModal(deleteUser, item)"
                      *ngIf="item.showBtnDeleteUpdateUser">
                      <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete User">
                    </button>
                    <!--Disable Buttons-->
                    <button type="button" class="btn btn-light disabled" disabled *ngIf="item.showBtnDeleteUpdateUser">
                      <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
                    </button>
                    <button type="button" class="btn btn-light disabled" disabled *ngIf="item.showBtnUpdateFacility">
                      <img [src]="'assets/images/icons/change-icon-disable-2.svg'" alt="Change Facility"
                        style="width: 20px">
                    </button>
                    <button type="button" class="btn btn-light disabled" disabled *ngIf="item.showBtnDeleteUpdateUser">
                      <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete User">
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-view-more" *ngIf="organizationData.usersNumber > 5">
              <div class="row justify-content-md-center">
                <!--                 <p class="text-center" style="margin: 0 auto;" *ngIf="supervisorUser?.data.length > 0">
                  <button type="button" style="border-radius: 26px; color: #00CFFF; font-weight: 700;"
                    class="btn btn-primary" (click)="viewMoreUsers()">View more</button>
                </p> -->
                <div class="col-md-auto">

                  <p class="text-center" *ngIf="activeLessUser">
                    <button type="button"

                      class="btn btn-light btn-view" (click)="viewLessUsers()">View less</button>

                  </p>

                </div>
                <div class="col-md-auto" *ngIf="!isDisabledUser">
                  <p class="text-center" style="margin: 0 auto;" *ngIf="supervisorUser?.data.length > 0">
                    <button type="button"
                      class="btn btn-primary btn-more" (click)="viewMoreUsers()">View more</button>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" *ngIf=" userRole !== 'OA'">
      <button type="button" routerLink="/organization-list"

        class="btn btn-light submit btn-back">Back to all organizations</button>
    </div>
  </div>
</div>
<!-- Modals -->
<ng-template #edit>
  <div class="modal-body modal-list">
    <p class="back" (click)="closeFirstModal(edit)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Organization Profile">
      Back to organization profile
    </p>
    <div class="error" >
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <form [formGroup]="OrganizationProfileForm">
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control input" formControlName="name" placeholder="Name">
        <div *ngIf="OrganizationProfileForm.controls['name'].invalid &&
            (OrganizationProfileForm.controls['name'].dirty || OrganizationProfileForm.controls['name'].touched)"
          class="invalid-feedback">
          <div *ngIf="OrganizationProfileForm.controls['name'].errors.required">* Name is required</div>
          <div *ngIf="OrganizationProfileForm.controls['name'].errors.maxlength">
            * Name must have at most 50 characters
          </div>
          <div *ngIf="OrganizationProfileForm.controls['name'].errors.minlength">
            * Name must be at least 5 characters long
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="Address">Address</label>
        <input type="text" class="form-control input" formControlName="address"
          placeholder="4348 Rubaiyat Road Grand Rapids, MI 49503">
        <div
          *ngIf="OrganizationProfileForm.controls['address'].invalid && (OrganizationProfileForm.controls['address'].dirty || OrganizationProfileForm.controls['address'].touched)"
          class="invalid-feedback">
          <div *ngIf="OrganizationProfileForm.controls['address'].errors.required">* Address is required</div>
          <div *ngIf="OrganizationProfileForm.controls['address'].errors.maxlength">
            * Address must have at most 50 characters
          </div>
          <div *ngIf="OrganizationProfileForm.controls['address'].errors.minlength">
            * Address must be at least 5 characters long
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="state">State</label>
        <select class="form-control input select-old" formControlName="state" id="state" required>
          <option value="0" selected>Select State</option>
          <option *ngFor="let state of listState" [value]="state.id">
            {{ state.name }}
          </option>
        </select>
        <div
          *ngIf="OrganizationProfileForm.controls['state'].invalid && (OrganizationProfileForm.controls['state'].dirty || OrganizationProfileForm.controls['state'].touched)"
          class="invalid-feedback">
          <div *ngIf="OrganizationProfileForm.controls['state'].errors.required">* State is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input type="text" class="form-control input" formControlName="city" id="city" placeholder="City">
        <div
          *ngIf="OrganizationProfileForm.controls['city'].invalid && (OrganizationProfileForm.controls['city'].dirty || OrganizationProfileForm.controls['city'].touched)"
          class="invalid-feedback">
          <div *ngIf="OrganizationProfileForm.controls['city'].errors.required">* City is required</div>
          <div *ngIf="OrganizationProfileForm.controls['city'].errors.maxlength">
            * City must have at most 50 characters
          </div>
          <div *ngIf="OrganizationProfileForm.controls['city'].errors.minlength">
            * City must be at least 5 characters long
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="name">Phone Number</label>
        <input type="text" class="form-control input" formControlName="phoneNumber" placeholder="123 123 1234">
        <div
          *ngIf="OrganizationProfileForm.controls['phoneNumber'].invalid &&
            (OrganizationProfileForm.controls['phoneNumber'].dirty || OrganizationProfileForm.controls['phoneNumber'].touched)"
          class="invalid-feedback">
          <div *ngIf="OrganizationProfileForm.controls['phoneNumber'].errors.required">* Phone Number is required</div>
          <div *ngIf="OrganizationProfileForm.controls['phoneNumber'].errors.pattern">* Phone Number pattern is not
            valid</div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" [disabled]="OrganizationProfileForm.invalid"
           (click)="closeFirstModal(edit)"
          class="btn btn-primary submit btn-modal">save changes</button>
      </div>
    <!--   <p class="bottom-info text-center">Disclaimer here?</p> -->
    </form>

  </div>
</ng-template>
<ng-template #template>
  <div class="modal-body text-center modal-fit">
    <p class="back back2" (click)="closeFirstModal(template)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Organization Profile">
      Back to organization profile
    </p>
    <h1 class="modal-title" style="margin-top: 0;">
      Are you sure you want to

      <span class="accent-text">delete</span> this organization?
    </h1>
    <p>
      <button type="button" (click)="closeFirstModal(template)" class="btn btn-primary submit full-width">no, I want to
        go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeFirstModal(template)">yes I want to delete it</button>
    </p>
  </div>
</ng-template>
<ng-template #deactivate>
  <div class="modal-body text-center modal-fit">
    <p class="back2" (click)="closeModal(deactivate)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Organization Profile">
      Back to organization profile
    </p>
    <div class="error" >
      <ul style="margin-top: 0;">
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
      <span *ngIf="mensajeError !== ''"> {{ mensajeError }} </span>
    </div>
    <h1 class="modal-title margin-modal">
      Are you sure you want to

      <span class="accent-text">deactivate</span> this organization?
    </h1>
    <p>
      <button type="button" (click)="closeModal(deactivate)" class="btn btn-primary submit full-width">no, I
        want
        to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeModalDesactive(deactivate)">yes I want to deactivate
        it</button>
    </p>
  </div>
</ng-template>

<ng-template #activate>
  <div class="modal-body text-center modal-fit">
    <p class="back2" (click)="closeModal(activate)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
      Back to organization profile
    </p>
    <div class="error">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
      <span *ngIf="mensajeError !== ''"> {{ mensajeError }} </span>
    </div>
    <h1 class="modal-title margin-modal">
      Are you sure you want to

      <span class="accent-text">activate</span> this organization?
    </h1>
    <p>
      <button type="button" (click)="closeModal(activate)" class="btn btn-primary submit full-width">no, I want
        to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeModalActivate(activate)">yes I want to activate it</button>
    </p>
  </div>
</ng-template>

<ng-template #templateUser>
  <div class="modal-body" [formGroup]="userRolForm">
    <p class="back" (click)="closeModal(templateUser)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to User Details">
      Back to organization profile
    </p>
    <div class="error">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="form-group">
      <label for="role">Role</label>
      <select class="form-control input select-old" formControlName="roleId" name="roleId" required>
        <option value="0" selected>Select Role</option>
        <option *ngFor="let userRol of listDataUserEdit" [value]="userRol.id">
          {{ userRol.name }}
        </option>
      </select>
      <div *ngIf="userRolForm.get('roleId').invalid &&
                  (userRolForm.get('roleId').dirty ||
                  userRolForm.get('roleId').touched)" class="invalid-feedback">
        <div *ngIf="userRolForm.get('roleId').errors.required">
          * Role is required
        </div>
      </div>
    </div>
    <p class="text-center">
      <button type="button"
        [disabled]="userRolForm.invalid" (click)="closeFirstModalUsers(templateUser)" class="btn btn-primary submit btn-save">
        save changes
      </button>
    </p>
    <p class="bottom-info  text-center">An email will be sent to the user to notify this change</p>
  </div>
</ng-template>

<ng-template #facility>
  <div class="modal-body" [formGroup]="userFacilityForm">
    <p class="back" (click)="closeModal(facility)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to User Details">
      Back to organization profile
    </p>

    <div class="error" >
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="form-group">
      <label for="role">Facility</label>
      <select class="form-control input select-old" formControlName="facilityId" name="facility" required>
        <option value="0" selected>Select Facility</option>
        <option *ngFor="let facility of listFacilities" [value]="facility.id">
          {{ facility.name }}
        </option>
      </select>
      <div *ngIf="userFacilityForm.get('facilityId').invalid &&
                  (userFacilityForm.get('facilityId').dirty ||
                  userFacilityForm.get('facilityId').touched)" class="invalid-feedback">
        <div *ngIf="userFacilityForm.get('facilityId').errors.required">
          * Facility is required
        </div>
      </div>
    </div>
    <p class="text-center">
      <button type="button"
        [disabled]="userFacilityForm.invalid" (click)="closeModalFacility(facility)" class="btn btn-primary submit btn-save">save
        changes</button>
    </p>
    <p class="bottom-info  text-center">An email will be sent to the user to notify this change</p>
  </div>
</ng-template>
<ng-template #deleteUser>
  <div class="modal-body text-center modal-fit">
    <p class="back back2" (click)="closeModal(delete)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to User Details">
      Back to organization profile
    </p>
    <div class="error">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <h1 class="modal-title">
      Are you sure you want to

      <span class="accent-text">delete</span> this user?
    </h1>
    <p>
      <button type="button" (click)="closeModal(delete)" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeletetModal(delete)">yes I want to delete it</button>
    </p>
  </div>
</ng-template>

<ng-template #deactivateFacility>
  <div class="modal-body text-center modal-fit">
    <p class="back back2" (click)="closeModal()">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to organization profile">
      Back to organization profile
    </p>
    <div class="error">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
      <span *ngIf="mensajeError !== ''"> {{ mensajeError }} </span>
    </div>
    <h1 class="modal-title margin-modal">
      Are you sure you want to

      <span class="accent-text">deactivate</span> this facility?
    </h1>
    <p>
      <button type="button" (click)="closeModal()" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeactivatel(deactivate)">yes I want to deactivate
        it</button>
    </p>
  </div>
</ng-template>
<ng-template #activateFacility>
  <div class="modal-body text-center modal-fit">
    <p class="back back2" (click)="closeModal()">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility List">
      Back to organization profile
    </p>
    <div class="error">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
      <span *ngIf="mensajeError !== ''"> {{ mensajeError }} </span>
    </div>
    <h1 class="modal-title margin-modal">
      Are you sure you want to

      <span class="accent-text">activate</span> this facility?
    </h1>
    <p>
      <button type="button" (click)="closeModal()" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeActivateModal(activate)">yes I want to activate it</button>
    </p>
  </div>
</ng-template>
