<div class="wrapper">
  <div class="fondoDisable"></div>
  <div class="marca-de-agua"><img src="../../../assets/images/example.png" alt=""> </div>
  <div class="container-fluid" style="opacity: 0.5;">
      <div class="row">
          <div class="col-xs-12 col-sm-8 col-md-8 col-lg-9">
              <div class="card">
                  <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-8">
                          <label>Water saved this week</label>
                          <div class="row text-center">
                              <div class="col-6 chart-info">
                                  <h3>77 people</h3>
                                  <p>per unit</p>
                                  <h3>360 people</h3>
                                  <p>for the Organizations /<br>Facilities selected</p>
                              </div>
                              <div class="col-6">

                                  <div *ngIf="!tableChart" style="height: 250px">
                                      <canvas baseChart
                                      [datasets]="barChartData"
                                      [labels]="barChartLabels"
                                      [options]="barChartOptions"
                                      [legend]="barChartLegend"
                                      [chartType]="barChartType">
                                    </canvas>
                                  </div>
                                  <div *ngIf="tableChart" style="height: 250px">
                                      <canvas baseChart
                                      height="189vw"
                                      width="100vw"
                                      [datasets]="barChartData"
                                      [labels]="barChartLabels"
                                      [options]="barChartOptions"
                                      [legend]="barChartLegend"
                                      [chartType]="barChartType">
                                    </canvas>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                          <div class="alternative-card">
                              <label>Money saved this week</label>
                              <div *ngIf="!tableChart"  style="display: block;">
                                  <canvas baseChart width="400" height="200"
                                    [datasets]="lineChartData"
                                    [labels]="lineChartLabels"
                                    [options]="lineChartOptions"
                                    [colors]="lineChartColors"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType"
                                    [plugins]="lineChartPlugins">
                                  </canvas>
                              </div>
                              <div *ngIf="tableChart"  style="display: block;">
                                  <canvas baseChart width="158vw" height="135vw"
                                    [datasets]="lineChartData"
                                    [labels]="lineChartLabels"
                                    [options]="lineChartOptions"
                                    [colors]="lineChartColors"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType"
                                    [plugins]="lineChartPlugins">
                                  </canvas>
                              </div>
                              <h3 class="text-center">$2,100.00</h3>
                              <p class="text-center">per unit</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 pading-right-card">
                      <h1 class="label">Feed</h1>
                      <div class="secondary-card feed">
                          <ul>
                              <li>
                                  <img [src]="'assets/images/icons/sign-out.svg'">
                                  Ginger Signorelli signed out.<span class="date">May 30th at 10:50 AM</span>
                              </li>
                              <li>
                                  <img [src]="'assets/images/icons/sign-in.svg'">
                                  Ginger Signorelli signed in.<span class="date">May 30th at 05:10 AM</span>
                              </li>
                              <li>
                                  <img [src]="'assets/images/icons/sign-out.svg'">
                                  Ginger Signorelli signed out.<span class="date">May 29th at 10:50 AM</span>
                              </li>
                              <li>
                                  <img [src]="'assets/images/icons/assign-icon.svg'">
                                  Ginger Signorelli assigned<br>a new unit to Facility Name.<span class="date">May 29th at 10:50 AM</span>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                      <h1 class="label">Last Flushes</h1>
                      <div class="secondary-card">
                          <div class="accordion" id="accordionGood1">
                              <div class="card accordion-card">
                                <div class="card-header" id="headingOne">
                                  <label class="text-left" type="button" data-toggle="collapse" data-target="#collapseGood1" aria-expanded="false" aria-controls="collapseGood1" class="full-width">
                                      <span class="icon-steady icon"></span>
                                      2020-02-15 12:30:58
                                      <img [src]="'assets/images/icons/arrow-button.svg'" class="collapse-arrow" alt="collapse">
                                  </label>
                                </div>

                                <div id="collapseGood1" class="collapse" aria-labelledby="headingUnlink" data-parent="#accordionGood1">
                                  <div class="card-body">
                                      <div class="row">
                                          <div class="col-4 col">
                                              <p class="info">100% <img [src]="'assets/images/icons/battery.svg'" width="30px" alt="Battery"></p>
                                              <b class="solenoid">Solenoid</b>
                                              <p class="solenoid">45 °C</p>
                                          </div>
                                          <div class="col-4 col">
                                              <mwl-gauge
                                              [max]="50"
                                              [dialStartAngle]="180"
                                              [dialEndAngle]="0"
                                              [value]="45"
                                              [animated]="true"
                                              [animationDuration]="1"
                                              class="bad-status"
                                              >
                                              </mwl-gauge>
                                              <p class="status-text">
                                                  <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad">
                                                  Filter #01
                                              </p>
                                              <mwl-gauge
                                              [max]="50"
                                              [dialStartAngle]="180"
                                              [dialEndAngle]="0"
                                              [value]="15"
                                              [animated]="true"
                                              [animationDuration]="1"
                                              class="ok-status"
                                              >
                                              </mwl-gauge>
                                              <p class="status-text">
                                                  <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good">
                                                  Filter #03
                                              </p>
                                          </div>
                                          <div class="col-4 col">
                                              <mwl-gauge
                                              [max]="50"
                                              [dialStartAngle]="180"
                                              [dialEndAngle]="0"
                                              [value]="22"
                                              [animated]="true"
                                              [animationDuration]="1"
                                              class="ok-status"
                                              >
                                              </mwl-gauge>
                                              <p class="status-text">
                                                  <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good">
                                                  Filter #02
                                              </p>
                                              <mwl-gauge
                                              [max]="50"
                                              [dialStartAngle]="180"
                                              [dialEndAngle]="0"
                                              [value]="35"
                                              [animated]="true"
                                              [animationDuration]="1"
                                              class="warning-status"
                                              >
                                              </mwl-gauge>
                                              <p class="status-text">
                                                  <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Warning">
                                                  Filter #04
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card accordion-card">
                                  <div class="card-header" id="accordionGood2">
                                    <label class="text-left" type="button" data-toggle="collapse" data-target="#collapseGood2" aria-expanded="false" aria-controls="collapseGood2" class="full-width">
                                        <span class="icon-steady icon"></span>
                                        2020-02-15 12:30:58
                                        <img [src]="'assets/images/icons/arrow-button.svg'" class="collapse-arrow" alt="collapse">
                                    </label>
                                  </div>

                                  <div id="collapseGood2" class="collapse" aria-labelledby="collapseGood2" data-parent="#accordionGood2">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-4 col">
                                                <p class="info">100% <img [src]="'assets/images/icons/battery.svg'" width="30px" alt="Battery"></p>
                                                <b class="solenoid">Solenoid</b>
                                                <p class="solenoid">45 °C</p>
                                            </div>
                                            <div class="col-4 col">
                                                <mwl-gauge
                                                [max]="50"
                                                [dialStartAngle]="180"
                                                [dialEndAngle]="0"
                                                [value]="45"
                                                [animated]="true"
                                                [animationDuration]="1"
                                                class="bad-status"
                                                >
                                                </mwl-gauge>
                                                <p class="status-text">
                                                    <img [src]="'assets/images/icons/filter-status-bad.svg'" alt="Filter Bad">
                                                    Filter #01
                                                </p>
                                                <mwl-gauge
                                                [max]="50"
                                                [dialStartAngle]="180"
                                                [dialEndAngle]="0"
                                                [value]="15"
                                                [animated]="true"
                                                [animationDuration]="1"
                                                class="ok-status"
                                                >
                                                </mwl-gauge>
                                                <p class="status-text">
                                                    <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good">
                                                    Filter #03
                                                </p>
                                            </div>
                                            <div class="col-4 col">
                                                <mwl-gauge
                                                [max]="50"
                                                [dialStartAngle]="180"
                                                [dialEndAngle]="0"
                                                [value]="22"
                                                [animated]="true"
                                                [animationDuration]="1"
                                                class="ok-status"
                                                >
                                                </mwl-gauge>
                                                <p class="status-text">
                                                    <img [src]="'assets/images/icons/filter-status-good.svg'" alt="Filter Good">
                                                    Filter #02
                                                </p>
                                                <mwl-gauge
                                                [max]="50"
                                                [dialStartAngle]="180"
                                                [dialEndAngle]="0"
                                                [value]="35"
                                                [animated]="true"
                                                [animationDuration]="1"
                                                class="warning-status"
                                                >
                                                </mwl-gauge>
                                                <p class="status-text">
                                                    <img [src]="'assets/images/icons/filter-status-check.svg'" alt="Filter Warning">
                                                    Filter #04
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-3">
              <h1 class="label">Units</h1>
              <div class="card">
                  <div class="row text-center status" style="width: 100% !important; height: inherit !important;">
                      <div class="col-4 first">
                          <div class="good">
                              <img [src]="'assets/images/icons/status-good.svg'" alt="Unit Good Status" width="50px">
                              <h2>35</h2>
                          </div>
                      </div>
                      <div class="col-4 center">
                          <div class="warning">
                              <img [src]="'assets/images/icons/status-check.svg'" alt="Unit Warning Status" width="50px">
                              <h2>20</h2>
                          </div>
                      </div>
                      <div class="col-4 last">
                          <div class="critical">
                              <img [src]="'assets/images/icons/status-bad.svg'" alt="Unit Critical Status" width="50px">
                              <h2>5</h2>
                          </div>
                      </div>
                  </div>
              </div>
              <h1 class="label">Units’ Health</h1>
              <div class="secondary-card">
                  <div *ngIf="!tableChart" style="height: 200px">
                      <canvas baseChart
                      [datasets]="unitHealthBarChartData"
                      [labels]="unitHealthBarChartLabels"
                      [options]="unitHealthBarChartOptions"
                      [legend]="unitHealthBarChartLegend"
                      [chartType]="unitHealthBarChartType">
                    </canvas>
                  </div>
                  <div *ngIf="tableChart">
                      <canvas baseChart
                      height="132vw"
                      width="179vw"
                      [datasets]="unitHealthBarChartData"
                      [labels]="unitHealthBarChartLabels"
                      [options]="unitHealthBarChartOptions"
                      [legend]="unitHealthBarChartLegend"
                      [chartType]="unitHealthBarChartType">
                    </canvas>
                  </div>

              </div>
              <h1 class="label">Units’ Usage</h1>
              <div class="card">
                  <h3>2h 35m</h3>
                  <div *ngIf="!tableChart" tyle="height: 150px">
                      <canvas baseChart
                      [datasets]="unitUsageBarChartData"
                      [labels]="unitUsageBarChartLabels"
                      [options]="unitUsageBarChartOptions"
                      [legend]="unitUsageBarChartLegend"
                      [chartType]="unitUsageBarChartType">
                    </canvas>
                  </div>
                  <div *ngIf="tableChart" style="height: 150px">
                      <canvas baseChart
                      height="132vw"
                      width="179vw"
                      [datasets]="unitUsageBarChartData"
                      [labels]="unitUsageBarChartLabels"
                      [options]="unitUsageBarChartOptions"
                      [legend]="unitUsageBarChartLegend"
                      [chartType]="unitUsageBarChartType">
                    </canvas>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
