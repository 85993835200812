import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { RolsEnum } from '@Models/enum/rols.enum';
import { facilityRol } from '@Models/role.model';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export class DataEditFacility {
  facility: {
    id: number;
    name: string;
    phoneNumber: string;
    organizationId: number;
  };
  address: {
    address: string;
    city: string;
    stateId: number;
    state: string;
  };
}

@Component({
  selector: 'app-facility-profile',
  templateUrl: './facility-profile.component.html',
  styleUrls: ['./facility-profile.component.sass'],
})
export class FacilityProfileComponent implements OnInit {
  facilityForm: FormGroup;
  facilityOrganizationManager: FormGroup;
  newFacilityForm: FormGroup;
  userRole: RolsEnum;
  public facilityId: number;
  modalRef: BsModalRef | null;
  dataFacility: any = null;
  unitsFacility: any = null;
  supervisorUser: any = null;
  listState: any = [];
  listOrganization: any[] = [];
  facilityListRol: boolean = false;
  isDisabledUser: boolean = false;
  activeLess: boolean = false;
  metaDataUsers: any;
  usersNumber: number = 0;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  dataSave: DataEditFacility;
  listDataUsers: any[] = [];
  listFacilityCard = [];
  listDataUser = [];
  listFacility = [];
  selectedUser: any;
  selectedUserFacility: any;
  hidenCarousel: boolean = false;
  msgError: any[] = [];
  msgErrorDeleteUsers: string[] = [];
  listFacilities: any = [];
  mensajeEr: string = '';
  isInternalRol: any[] = [];
  internalRol: boolean = false;
  showBtnUserFacility: boolean = true;
  organizationStorageId = this.authService.getOrganizationId();
  roleLevel = this.authService.getRoleLevel();

  /*@HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    // working in Firefox, Chrome and Edge
}*/

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private modalService: BsModalService,
    private appServices: AppServices,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private sideNavbarService: SideNavbarService,
    readonly router: Router,
    private authService: AuthService,
    private navigation: NavigationService,
    private actionMenu:ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('FL');
    const userRole = this.authService.getAuthStatus();
    if (
      userRole.role === RolsEnum.FACILITY_ADMINISTRATOR ||
      userRole.role === RolsEnum.FACILITY_MANAGER
    ) {
      this.showBtnUserFacility = false;
    }
    const facility = route.params['_value'];

    this.facilityId = Number(facility.id);
    this.userRole = this.authService.authStatus.getValue().role;
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;
    if (!this.facilityListRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let indexOrganizationList = menu.findIndex(
        (item) => item.link === '/facilities-list'
      );
      const indexUserInvite = menu.findIndex((item) =>
        item.link.includes('/facility-profile')
      );
      const orgProfile = menu.findIndex(
        (item) => item.link === `/add-facility`
      );
      let indexMneChange = -1;
      if (indexOrganizationList !== -1) {
        indexMneChange = indexOrganizationList;
      } else if (indexUserInvite !== -1) {
        indexMneChange = indexUserInvite;
      } else if (orgProfile !== -1) {
        indexMneChange = orgProfile;
      }
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = this.router.url;
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
  }

  ngOnInit(): void {
    /*const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
    this.internalRol = isInternalRol.length > 0 ? true : false;*/
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    this.facilityOrganizationManager = this.fb.group({
      newRole: ['0', [this.stateValidator()]],
    });

    this.newFacilityForm = this.fb.group({
      name: ['0', [this.stateValidator()]],
    });
    this.buildFormFacility();
    this.getData();
    this.getStates();
    if (this.internalRol) {
      this.getListOrganization();
      this.facilityForm.controls['idOrganizacion'].setErrors({
        required: true,
      });
    } else {
      this.facilityForm.controls['idOrganizacion'].setErrors(null);
    }
  }

  ngOnDestroy(): void {
    if (!this.facilityListRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let index = menu.findIndex((item) =>
        item.link.includes('/facility-profile')
      );
      if (index !== -1) {
        menu[index].link = '';
        menu[index].link = '/facilities-list';
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
  }

  getListOrganization() {
    this.appServices.getForSelectOrganization().subscribe((data) => {
      this.listOrganization = data['data'];
    });
  }

  GetExternalRolesForSelectEdit(roleId: string) {
    this.appServices.GetExternalRolesForSelectEdit(roleId).subscribe((data) => {
      this.listDataUser = data['data'];
    });
  }

  nextNavigate() {
    alert('itemmm');
  }

  buildFormFacility() {
    this.facilityForm = this.fb.group({
      idOrganizacion: [0, [this.validatonOrganization()]],
      facilityName: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      address: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      state: ['0', [this.stateValidator()]],
      city: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
          ),
        ],
      ],
    });
    this.facilityForm.patchValue({
      state: '0',
    });
  }

  onSubmit() {}

  openModal(template: TemplateRef<any>) {
    this.msgError = [];
    this.mensajeEr = '';
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-md modal-dialog-centered modal-fit',
    });
  }

  openEditModal(template: TemplateRef<any>) {
    this.msgError = [];
    this.mensajeEr = '';
    this.facilityForm.patchValue({
      idOrganizacion: this.dataFacility.data.facility?.organizationId || 0,
      facilityName: this.dataFacility.data.facility?.name || '',
      address: this.dataFacility.data.address.address || '',
      state: this.dataFacility.data.address.stateId || '',
      city: this.dataFacility.data.address.city || '',
      phoneNumber: this.dataFacility.data.facility.phoneNumber || '',
    });

    setTimeout(() => {
      this.modalRef = this.modalService.show(template, {
        id: 1,
        class: 'modal-md modal-dialog-centered customListModal',
      });
    }, 500);
  }

  async viewMoreUsers() {
    let pageSize = 5;

    if (!this.isDisabledUser) {
      let pageNumber = this.metaDataUsers.currentPage + 1;
      let organizationId = this.internalRol
        ? '0'
        : this.authService.getOrganizationId();
      this.activeLess = true;

      let dataViewMore = await this.appServices
        .getSupervisor(this.facilityId, +organizationId, pageSize, pageNumber)
        .toPromise();
      const dataUser = dataViewMore['data'];
      const rolIdUsuarioLogueado = +this.roleLevel;
      dataViewMore['data'] = dataUser.map(function (user) {
        const rolLebelUserSelected = user.roleLevel;
        const rolLebelUserLogueado = rolIdUsuarioLogueado;
        user['showBtnDeleteUpdateUser'] =
          rolLebelUserLogueado < rolLebelUserSelected ? true : false;
        user['showBtnUpdateFacility'] =
          user.facilityId === 0 ||
          user.role === 'Facility Administrator' ||
          user.role === 'Facility Manager'
            ? false
            : true;

        return user;
      });

      this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']];
    }
    this.isDisabledUser =
      this.metaDataUsers.currentPage === this.metaDataUsers.totalPages
        ? true
        : false;
  }

  closeFirstModal() {
    const { idOrganizacion } = this.facilityForm.value;
    //let organizationId = this.authService.getOrganizationId();
    const data = {
      facility: {
        id: this.facilityId,
        name: this.facilityForm.get('facilityName').value,
        phoneNumber: this.facilityForm.get('phoneNumber').value,
        organizationId: +idOrganizacion,
      },
      address: {
        address: this.facilityForm.get('address').value,
        city: this.facilityForm.get('city').value,
        stateId: Number(this.facilityForm.get('state').value),
        state: '',
      },
    };
    this.appServices.facilityEdit(data).subscribe(
      (item) => {
        if (!this.modalRef) {
          return;
        }
        this.modalRef.hide();
        this.modalRef = null;
        this.getData();
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        this.mensajeEr = '';
        const arrayErrors = responseError.split('\n');
        arrayErrors.forEach((element) => {
          if (element !== '') {
            const errorMensaje = element.split('-');
            if (errorMensaje.length > 1) {
              if (errorMensaje[1] !== '') {
                this.msgError.push(errorMensaje[1]);
              }
            }
          }
        });
        if (this.msgError.length === 0) {
          const valueError = responseError.replace(/['"]+/g, '');
          this.mensajeEr = valueError;
        }
      }
      /*error => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if( typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-','')
                this.msgError.push(mensajeError);
              }
            }
          }
        } else {
          let errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-','')
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }*/
    );
  }

  async closeModalDesactive(template) {
    const idFcility = {
      id: this.facilityId,
    };

    this.appServices.deactivateFacility(idFcility).subscribe(
      (data) => {
        this.updateFacilityActivate().then(() => {
          if (!this.modalRef) {
            return;
          }
          this.modalRef.hide();
          this.modalRef = null;
          this.msgError = [];
          this.mensajeEr = '';
          this.activeLess = false;
          this.getData();
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        this.mensajeEr = '';
        const arrayErrors = responseError.split('\n');
        arrayErrors.forEach((element) => {
          if (element !== '') {
            const errorMensaje = element.split('-');
            if (errorMensaje.length > 1) {
              if (errorMensaje[1] !== '') {
                this.msgError.push(errorMensaje[1]);
              }
            }
          }
        });
        if (this.msgError.length === 0) {
          const valueError = responseError.replace(/['"]+/g, '');
          this.mensajeEr = valueError;
        }
      }
    );
  }

  async closeModalActivate(activate) {
    let organizationId = this.authService.getOrganizationId();
    const idFcility = {
      id: this.facilityId,
      organizationId: +organizationId,
    };

    this.appServices.activateFacility(idFcility).subscribe(
      (data) => {
        this.updateFacilityActivate().then(() => {
          if (!this.modalRef) {
            return;
          }
          this.modalRef.hide();
          this.modalRef = null;
          this.msgError = [];
          this.mensajeEr = '';
          this.getData();
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        this.mensajeEr = '';
        const arrayErrors = responseError.split('\n');
        arrayErrors.forEach((element) => {
          if (element !== '') {
            const errorMensaje = element.split('-');
            if (errorMensaje.length > 1) {
              if (errorMensaje[1] !== '') {
                this.msgError.push(errorMensaje[1]);
              }
            }
          }
        });
        if (this.msgError.length === 0) {
          const valueError = responseError.replace(/['"]+/g, '');
          this.mensajeEr = valueError;
        }
      }
    );
  }

  async updateFacilityActivate() {
    this.dataFacility = await this.appServices
      .getFacilityId(this.facilityId)
      .toPromise();
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.msgErrorDeleteUsers = [];
    this.msgError = [];
    this.mensajeEr = '';
  }

  async getData() {
    this.listFacilityCard = [];
    let organizationId = this.internalRol
      ? '0'
      : this.authService.getOrganizationId();
    this.dataFacility = await this.appServices
      .getFacilityId(this.facilityId)
      .toPromise();
    this.unitsFacility = await this.appServices
      .getUnitFacilityAll(this.facilityId, +organizationId)
      .toPromise();
    this.supervisorUser = await this.appServices
      .getSupervisor(this.facilityId, +organizationId)
      .toPromise();

    this.listDataUsers = this.supervisorUser.data;

    const dataUser = this.supervisorUser['data'];
    const rolIdUsuarioLogueado = +this.roleLevel;
    this.listDataUsers = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        user.facilityId === 0 ||
        user.role === 'Facility Administrator' ||
        user.role === 'Facility Manager'
          ? false
          : true;

      return user;
    });

    this.metaDataUsers = this.supervisorUser.meta;

    const dataListFacility = this.unitsFacility.data;
    const countData = dataListFacility.length;
    for (let i = 0; i < countData; i++) {
      this.listFacilityCard.push(this.unitsFacility.data[i]);
    }
    this.hidenCarousel = this.listFacilityCard.length > 1 ? false : true;

    localStorage.setItem(
      'ProfileOrganizationId',
      this.dataFacility.data.facility?.organizationId
    );
    localStorage.setItem(
      'ProfileFacilityId',
      this.dataFacility.data.facility?.id
    );

    this.facilityForm.patchValue({
      idOrganizacion: this.dataFacility.data.facility?.organizationId || 0,
      facilityName: this.dataFacility.data.facility?.name || '',
      address: this.dataFacility.data.address.address || '',
      state: this.dataFacility.data.address.stateId || '',
      city: this.dataFacility.data.address.city || '',
      phoneNumber: this.dataFacility.data.facility.phoneNumber || '',
    });

    this.usersNumber = this.dataFacility.data.usersNumber;

    this.isDisabledUser =
      this.metaDataUsers.currentPage === this.metaDataUsers.totalPages
        ? true
        : false;
    this.activeLess = false;
  }

  getStates() {
    this.appServices.getStates().subscribe((data) => {
      this.listState = data['data'];
    });
  }

  openModalOrganizationManager(
    organizationManager: TemplateRef<any>,
    selectedUser: any
  ) {
    this.selectedUser = selectedUser;
    this.msgError = [];
    this.mensajeEr = '';
    this.appServices
      .GetExternalRolesForSelectEdit(selectedUser.roleId)
      .subscribe((data) => {
        this.listDataUser = data['data'];
        this.facilityOrganizationManager.patchValue({
          newRole: selectedUser.roleId,
        });
        this.modalRef = this.modalService.show(organizationManager, {
          id: 1,
          class: 'modal-md modal-dialog-centered',
        });
      });
  }

  async closeOrganizationManager() {
    if (!this.modalRef) {
      return;
    }
    const id = this.selectedUser.id;
    const dataSave = {
      id: id,
      roleId: this.facilityOrganizationManager.controls['newRole'].value,
    };
    this.appServices.updateRole(dataSave).subscribe(
      (data) => {
        let organizationId = this.authService.getOrganizationId();
        this.facilityOrganizationManager.patchValue({
          newRole: ['0'],
        });
        this.modalRef.hide();
        this.modalRef = null;
        this.msgErrorDeleteUsers = [];
        this.getData();
        //this.updateUsersList();
        //this.getData();
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgErrorDeleteUsers = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace('\n', '');
                const errorName = errorValue.replace('-', '');
                if (errorName) {
                  this.msgErrorDeleteUsers.push(errorName);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const errorName = errorValue.replace('-', '');
          this.msgErrorDeleteUsers.push(errorName.replace('\n', ''));
        }
      }
    );
  }

  openModalNewFacility(newFacility: TemplateRef<any>, user: any) {
    this.selectedUserFacility = user;
    this.msgErrorDeleteUsers = [];
    this.mensajeEr = '';
    let organizationLocaleStorage =
      this.organizationStorageId !== null ? +this.organizationStorageId : 0;
    let organizationId = this.internalRol
      ? user.organizationId
      : organizationLocaleStorage;
    this.appServices.getListFacility(organizationId).subscribe((data) => {
      this.listFacility = data['data'];
      this.newFacilityForm.patchValue({
        name: user.facilityId,
      });

      this.modalRef = this.modalService.show(newFacility, {
        id: 1,
        class: 'modal-md modal-dialog-centered',
      });
    });
  }

  async closeNewFacility() {
    if (!this.modalRef) {
      return;
    }
    const userId = this.selectedUserFacility.id;
    const newFacilityId = this.newFacilityForm.controls['name'].value;
    const dataSave = {
      userId: userId,
      facilityId: +newFacilityId,
    };

    this.appServices.relocate(dataSave).subscribe(
      (dataResponse) => {
        let organizationId = this.authService.getOrganizationId();
        this.newFacilityForm.patchValue({
          name: ['0'],
        });
        this.modalRef.hide();
        this.modalRef = null;
        this.msgErrorDeleteUsers = [];
        this.mensajeEr = '';
        this.updateUsersList();
        //this.getData();
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgErrorDeleteUsers = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgErrorDeleteUsers.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgErrorDeleteUsers.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
  }
  openModalDeleteUser(deleteUser: TemplateRef<any>, selectedUser: any) {
    this.selectedUser = selectedUser;
    this.msgErrorDeleteUsers = [];
    this.mensajeEr = '';
    this.modalRef = this.modalService.show(deleteUser, {
      id: 1,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }
  async closeModalDeleteUser() {
    if (!this.modalRef) {
      return;
    }
    const id = this.selectedUser.id;
    const dataDelete = {
      id: id,
    };
    this.appServices.deleteUser(dataDelete).subscribe(
      (dataResponse) => {
        let organizationId = this.authService.getOrganizationId();
        this.modalRef.hide();
        this.modalRef = null;
        this.msgErrorDeleteUsers = [];
        this.updateUsersList();
        //this.getData();
        /*this.appServices.getSupervisor(this.facilityId, +organizationId).subscribe(
          (data) =>{
            if (!this.modalRef) {
              return;
            }
            this.modalRef.hide();
            this.modalRef = null;
            this.msgErrorDeleteUsers =[];
          }, error =>{

          }
        )*/
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgErrorDeleteUsers = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgErrorDeleteUsers.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgErrorDeleteUsers.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
  }

  closeModalGeneral() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.msgErrorDeleteUsers = [];
    this.mensajeEr = '';
  }

  async updateUsersList() {
    let organizationId = this.authService.getOrganizationId();
    this.supervisorUser = await this.appServices
      .getSupervisor(this.facilityId, +organizationId)
      .toPromise();
    const dataUser = this.supervisorUser['data'];
    const rolIdUsuarioLogueado = +this.roleLevel;
    this.supervisorUser['data'] = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        user.facilityId === 0 ||
        user.role === 'Facility Administrator' ||
        user.role === 'Facility Manager'
          ? false
          : true;

      return user;
    });
  }

  stateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = Number(control.value);
      if (value === 0) {
        return { stateSelected: true };
      }
    };
  }

  validatonOrganization(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (!this.internalRol) {
        return null;
      } else {
        if (value !== '0') {
          return null;
        }
        return { required: true };
      }
    };
  }

  backNavigation() {
    this.navigation.back();
  }

  DetallUnit() {
    localStorage.setItem('listRecord', null);
  }

  async viewLess() {
    let pageSize = 5;

    let pageNumber = this.metaDataUsers.currentPage - 1;
    let organizationId = this.internalRol
      ? '0'
      : this.authService.getOrganizationId();

    if (pageNumber === 1) {
      this.activeLess = false;
    }

    let dataViewMore = await this.appServices
      .getSupervisor(this.facilityId, +organizationId, pageSize, pageNumber)
      .toPromise();
    const dataUser = dataViewMore['data'];
    const rolIdUsuarioLogueado = +this.roleLevel;
    dataViewMore['data'] = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        user.facilityId === 0 ||
        user.role === 'Facility Administrator' ||
        user.role === 'Facility Manager'
          ? false
          : true;

      return user;
    });
    if (pageNumber !== 1) {
      this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']];
      this.listDataUsers.splice(this.listDataUsers.length - 10, 10);
    } else {
      this.listDataUsers = [];
      this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']];
    }

    this.isDisabledUser =
      this.metaDataUsers.currentPage === this.metaDataUsers.totalPages
        ? true
        : false;
  }
}
