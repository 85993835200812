<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <h1>
              List of Internal Users
            </h1>
            <p>Use the Advance search filters to optimize your search.</p>
          </div>
          <div class="col-md-6 text-right botom-mobile">
            <div class="mobile">
              <button type="button"
              class="btn btn-primary submit btn-invite" routerLink="/invite-internal-users">Invite Internal User(s)</button>
            <button type="button"
              class="btn btn-light submit btn-all"  (click)="changeMenu('/internal-users-list')" routerLink="/internal-invite-management">All internal invites</button>
            </div>

          </div>
        </div>
        <h1>


        </h1>

        <ul class="nav filter-menu">

          <div class="float">
            <a class="filters-link" (click)="filters()">
              <span *ngIf="activeFilters">Close advance search</span>
              <span *ngIf="!activeFilters">Advance search</span>
            </a>
            <!-- <a class="filters-link" (click)="filters()" style="padding-right: 80px;"><span
                *ngIf="activeFilters">Close</span> Advance search</a> -->
            <!-- <a class="filters-link" style="padding-left: 10px; color: #A5A8C5; text-decoration:none;display: none;"><img
                [src]="'assets/images/icons/filter.svg'" alt="Sort"> Sort <img [src]="'assets/images/icons/botton.svg'"
                alt="Sort"></a> -->
          </div>

        </ul>
        <hr>
        <div class="row filters" *ngIf="activeFilters" [formGroup]="userSearchForm">
          <div class="col-6 col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label>First Name</label>
              <input type="text" class="form-control input" placeholder="First Name" formControlName="firstName"
                (change)="search()">
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" class="form-control input" placeholder="Last Name" formControlName="lastName"
                (change)="search()">
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control input" placeholder="example@mail.com" formControlName="email"
                (change)="search()">
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label for="role">Role</label>
              <select class="form-control input select-old" formControlName="roleId" name="roleId" (change)="search()">
                <option value="0" selected>Show All</option>
                <option *ngFor="let userRol of listDataUser" [value]="userRol.id">
                  {{ userRol.name }}
                </option>
              </select>
            </div>
          </div>

        </div>
        <div class="row labels">
          <div class="col-5 col-sm-3 col-md-3 col-lg-3">
            <label for="name">name</label>
          </div>
          <div class="col-3 col-sm-3 col-md-4 col-lg-3 email">
            <label for="email">email</label>
          </div>
          <div class="col-2 col-sm-3 col-md-3 col-lg-3">
            <label for="role">role</label>
          </div>
          <div class="col-4 col-sm-3 col-md-2 col-lg-3 text-center options">
            <label for="options">options</label>
          </div>
        </div>
        <div class="row data" *ngFor="let user of dataUserList.data; let i = index">
          <div class="col-5 col-sm-3 col-md-3 col-lg-3">
            <h5>{{ user.name }}</h5>
          </div>
          <div class="col-3 col-sm-3 col-md-4 col-lg-3 email1">
            <p class="info">{{ user.email }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <p class="info">{{ user.role }}</p>
          </div>
          <div class="col-4 col-sm-3 col-md-2 col-lg-3 text-center actions">
            <button type="button" class="btn btn-primary actived" *ngIf="roleLevel <= user.roleLevel"
            (click)="openModalUser(edit, user,i)">
            <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role">
          </button>

            <button type="button" class="btn btn-light actived" *ngIf="roleLevel < user.roleLevel" (click)="openDeleteModal(delete, user)">
              <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete User">
            </button>
            <!--Disable Buttons-->

            <button type="button" class="btn btn-light disabled" disabled *ngIf="roleLevel <= user.roleLevel">
              <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light disabled" disabled *ngIf="roleLevel < user.roleLevel">
              <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete User">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center top-button">

      <div class="col-6 col-md-auto pading-right" *ngIf="activeLess">

        <p class="text-center">
          <button type="button" class="btn btn-light btn-view" (click)="viewLess()">View less</button>
        </p>
      </div>
      <div class="col-6 col-md-auto pading-left" *ngIf="!isDisabled">
        <p class="text-center">
          <button [style]="{'margin-right': !activeLess ? '0px': ''}" type="button" class="btn btn-primary btn-more" (click)="viewMore()" [disabled]="isDisabled">View
            more</button>
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #delete>
  <!-- <div class="modal-body text-center modal-fit">
    <p class="back" (click)="closeModal(delete)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to User Details">
      {{ linkModal }}
    </p>
    <div style="color:#D20404!important;text-align: left;">
      <ul>
          <li *ngFor="let error of msgError">
              {{ error }}
          </li>
      </ul>
    </div>
    <h1 class="modal-title">
      Are you sure you want to
      <br>
      <span class="accent-text">delete</span> this user?
    </h1>
    <p>
      <button type="button" (click)="closeModal(delete)" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeletetModal(delete)">yes I want to delete it</button>
    </p>
  </div> -->

  <div class="modal-body text-center modal-fit">
    <div class="errorModal">
      <ul>
          <li *ngFor="let error of msgError">
              {{ error }}
          </li>
      </ul>
      <span *ngIf="mensajeError !== ''"> {{ mensajeError }} </span>
    </div>
    <h1>
      Are you sure you want to

      <span class="accent-text">delete</span> this user?

    </h1>
    <p>
      <button type="button" (click)="closeModal(delete)" class="btn btn-primary submit full-width top-modal">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeletetModal(template)">yes I want to delete it</button>
    </p>
  </div>


</ng-template>

<ng-template #edit>
  <div class="modal-body text-center modal-fit" [formGroup]="inviteForm">
    <p class="back" (click)="closeModal(template)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to User Details">
      Back to internal users list
    </p>
    <div *ngIf="msgError.length !==0" class="errorModal">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>

    <div class="form-group" style="text-align: left;">
        <label for="role">Role</label>
        <select class="form-control input select" formControlName="role">
          <option value="" selected>Select Role</option>
          <option *ngFor="let userRol of listDataUserEdit" [value]="userRol.id">
            {{ userRol.name }}
          </option>
        </select>
        <div
          *ngIf="inviteForm.controls['role'].invalid && (inviteForm.controls['role'].dirty || inviteForm.controls['role'].touched)"
          class="invalid-feedback">
          <div ngIf="inviteForm.controls['role'].errors.required"> Role is required</div>
        </div>
      </div>
    <p class="text-center">
      <button type="button"
        [disabled]="inviteForm.invalid" (click)="closeFirstModalUser(template)" class="btn btn-primary submit modalSave btn-save">
        save changes
      </button>
    </p>
    <p class="bottom-info  text-center">An email will be sent to the user to notify this change.</p>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-body text-center modal-fit">
    <h1>
      User role {{message}}

      <span class="accent-text">successfully!</span>
    </h1>

  </div>
</ng-template>
