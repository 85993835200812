import { Component, OnInit, TemplateRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppServices } from 'src/app/services/api.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { RolsEnum } from '@Models/enum/rols.enum';
import { facilityRol } from '@Models/role.model';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

class DataOrganization {
  address: Address = new Address();
  facilitiesNumber: number = 0;
  organization: Organization = new Organization();
  usersNumber: number = 0;
}

class Address {
  address!: string;
  city!: string;
  id!: number;
  stateId!: number;
  stateName!: string;
}

class Organization {
  id!: number;
  name!: string;
  phoneNumber!: string;
  status!: boolean;
}

class DataSaveOrganization {
  address: Address = new Address();
  organization: Organization = new Organization();
}

@Component({
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.sass'],
})
export class OrganizationProfileComponent implements OnInit {
  OrganizationProfileForm: FormGroup;
  modalRef: BsModalRef | null;
  activeLess: boolean = false;
  activeLessUser: boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };
  pageSize: number = 2;
  metaDataFacility: any;
  isDisabled: boolean = false;
  isDisabledUser: boolean = false;
  hidenCarousel: boolean = false;
  organizationData = new DataOrganization();
  dataFacility: any = null;
  unitsFacility: any = null;
  listDataFacility: any[] = [];
  listDataUsers: any[] = [];
  listDataUserEdit: any[] = [];
  listDataUser: any[] = [];
  metaDataUsers: any;
  listFacilityCard = [];
  listFacility = [];
  supervisorUser: any = null;
  listState: any = [];
  organizationId: number;
  msgError: any[] = [];
  listFacilities: any = [];
  userRolForm: FormGroup;
  userFacilityForm: FormGroup;
  mensajeError: string;
  userRole: RolsEnum;
  internalRol: boolean = false;
  showBtnUserFacility: boolean = true;
  facilityListRol: boolean = false;
  roleLevel = this.authService.getRoleLevel();
  saveOrganization: DataSaveOrganization = new DataSaveOrganization();
  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private appServices: AppServices,
    private router: Router,
    private fb: FormBuilder,
    private sideNavbarService: SideNavbarService,
    private actionMenu: ActionMenuService
  ) {
    const userRole = this.authService.getAuthStatus();
    if (
      userRole.role === RolsEnum.FACILITY_ADMINISTRATOR ||
      userRole.role === RolsEnum.FACILITY_MANAGER
    ) {
      this.showBtnUserFacility = false;
    }
    const organization = route.params['_value'];

    this.organizationId = Number(organization.id);
    localStorage.setItem('organizationIdProfile', '' + this.organizationId);

    this.userRolForm = this.fb.group({
      roleId: ['0', [this.validatorSelect()]],
    });

    this.userFacilityForm = this.fb.group({
      facilityId: [0, [this.validatorSelect()]],
    });

    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;

    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let indexOrganizationList = menu.findIndex(
        (item) => item.link === '/organization-list'
      );
      const indexUserInvite = menu.findIndex(
        (item) => item.link === `/organization-profile/${this.organizationId}`
      );
      const orgProfile = menu.findIndex(
        (item) => item.link === `/set-up-organization`
      );
      let indexMneChange = -1;
      if (indexOrganizationList !== -1) {
        indexMneChange = indexOrganizationList;
      } else if (indexUserInvite !== -1) {
        indexMneChange = indexUserInvite;
      } else if (orgProfile !== -1) {
        indexMneChange = orgProfile;
      }
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = this.router.url;
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
    this.actionMenu.setSelectMenu('OL');
  }

  ngOnInit(): void {
    this.userRole = this.authService.authStatus.getValue().role;
    this.getListFacilities();
    this.getRolesForUpdateSelect();
    this.buildOrganizationProfileForm();
    this.getOrganizationById(this.organizationId);
    this.getData(this.organizationId);
    this.getStates();
  }

  ngOnDestroy(): void {
    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let index = menu.findIndex((item) =>
        item.link.includes('/organization-profile')
      );
      if (index !== -1) {
        menu[index].link = '/organization-list';
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
  }

  getListFacilities() {

    let organizationStorageId = this.organizationId; //this.authService.getOrganizationId();
    if (+organizationStorageId > 0) {
      this.appServices
        .getListFacility(+organizationStorageId)
        .subscribe((data) => {
          this.listFacilities = data['data'];
        });
    }
  }

  getStates() {
    this.appServices.getStates().subscribe((data) => {
      this.listState = data['data'];
    });
  }

  getOrganizationById(id: number) {
    this.appServices.GetOrganizationProfile(id).subscribe((organization) => {
      this.organizationData.address = organization['data'].address;
      this.organizationData.facilitiesNumber =
        organization['data'].facilitiesNumber;
      this.organizationData.organization = organization['data'].organization;
      this.organizationData.usersNumber = organization['data'].usersNumber;

      this.OrganizationProfileForm.patchValue({
        name: this.organizationData.organization.name,
        address: this.organizationData.address.address,
        state: this.organizationData.address.stateId,
        city: this.organizationData.address.city,
        phoneNumber: this.organizationData.organization.phoneNumber,
      });
    });
  }

  getRolesForUpdateSelect() {
    this.appServices.getRolesForUpdateSelect().subscribe((data) => {
      this.listDataUser = data['data'];
    });
  }

  async getData(organizationId: number) {
    this.listFacilityCard = [];
    this.listDataFacility = [];
    this.dataFacility = await this.appServices
      .getAllOrganizationFacility(organizationId, 5)
      .toPromise();
    this.listDataFacility = this.dataFacility.data;
    this.metaDataFacility = this.dataFacility.meta;
    this.unitsFacility = await this.appServices
      .getUnitFacilityOrganizationAll(organizationId)
      .toPromise();
    this.supervisorUser = await this.appServices
      .getSupervisorOrganization(organizationId, 5)
      .toPromise();
    this.listDataUsers = this.supervisorUser.data;
    organizationId;

    localStorage.setItem('ProfileOrganizationId', organizationId.toString());
    localStorage.setItem('ProfileFacilityId', '0');


    const dataUser = this.supervisorUser.data;
    const rolIdUsuarioLogueado = +this.roleLevel;
    this.listDataUsers = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        user.facilityId === 0 ||
        user.role === 'Facility Administrator' ||
        user.role === 'Facility Manager'
          ? false
          : true;

      return user;
    });
    this.metaDataUsers = this.supervisorUser.meta;
    const countData = this.listDataFacility.length;

    const countFacility = this.unitsFacility.data;

    /*  if (countFacility.length === 0) {
      this.listFacilityCard = [];
    } else {
      for (let i = 0; i < countData; i++) {
        if (this.unitsFacility.data[i]) {
          this.listFacilityCard.push(this.unitsFacility.data[i]);

        }
      }
    } */

    if (countFacility.length === 0) {
      this.listFacilityCard = [];
    } else {
      for (let i = 0; i < countFacility.length; i++) {
        if (this.unitsFacility.data[i]) {
          this.listFacilityCard.push(this.unitsFacility.data[i]);
        }
      }
    }

    this.hidenCarousel = this.listFacilityCard.length > 1 ? false : true;
    this.isDisabled =
      this.metaDataFacility.currentPage === this.metaDataFacility.totalPages
        ? true
        : false;
    this.isDisabledUser =
      this.metaDataUsers.currentPage === this.metaDataUsers.totalPages
        ? true
        : false;
  }

  async viewMoreFacilities() {
    let pageSize = 5;
    this.activeLess = true;

    let organizationId = this.organizationId; //this.authService.getOrganizationId();
    if (!this.isDisabled) {
      let pageNumber = this.metaDataFacility.currentPage + 1;
      let dataViewMore = await this.appServices
        .getAllOrganizationFacility(+organizationId, pageSize, pageNumber)
        .toPromise();
      this.metaDataFacility = dataViewMore['meta'];
      this.listDataFacility = [
        ...this.listDataFacility,
        ...dataViewMore['data'],
      ];
    }
    this.isDisabled =
      this.metaDataFacility.currentPage === this.metaDataFacility.totalPages
        ? true
        : false;
  }
  async viewLessFacilities() {
    let pageSize = 5;

    let organizationId = this.organizationId; //this.authService.getOrganizationId();

    let pageNumber = this.metaDataFacility.currentPage - 1;

    if (pageNumber === 1) {
      this.activeLess = false;
      pageSize = 5;
    }
    let dataViewMore = await this.appServices
      .getAllOrganizationFacility(+organizationId, pageSize, pageNumber)
      .toPromise();

    if (pageNumber !== 1) {
      this.metaDataFacility = dataViewMore['meta'];
      this.listDataFacility = [
        ...this.listDataFacility,
        ...dataViewMore['data'],
      ];
      this.listDataFacility.splice(this.listDataFacility.length - 10, 10);
    } else {
      this.listDataFacility = [];
      this.metaDataFacility = dataViewMore['meta'];
      this.listDataFacility = [
        ...this.listDataFacility,
        ...dataViewMore['data'],
      ];
    }

    this.isDisabled =
      this.metaDataFacility.currentPage === this.metaDataFacility.totalPages
        ? true
        : false;
  }

  async viewMoreUsers() {
    this.activeLessUser = true;
    let pageSize = 5;
    let organizationId = this.organizationId; //this.authService.getOrganizationId();
    if (!this.isDisabledUser) {
      let pageNumber = this.metaDataUsers.currentPage + 1;
      let dataViewMore = await this.appServices
        .getSupervisorOrganization(+organizationId, pageSize, pageNumber)
        .toPromise();

      const dataUser = dataViewMore['data'];
      const rolIdUsuarioLogueado = +this.roleLevel;
      dataViewMore['data'] = dataUser.map(function (user) {
        const rolLebelUserSelected = user.roleLevel;
        const rolLebelUserLogueado = rolIdUsuarioLogueado;
        user['showBtnDeleteUpdateUser'] =
          rolLebelUserLogueado < rolLebelUserSelected ? true : false;
        user['showBtnUpdateFacility'] =
          user.facilityId === 0 ||
          user.role === 'Facility Administrator' ||
          user.role === 'Facility Manager'
            ? false
            : true;

        return user;
      });
      this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']];
    }
    this.isDisabledUser =
      this.metaDataUsers.currentPage === this.metaDataUsers.totalPages
        ? true
        : false;
  }

  async viewLessUsers() {
    let pageSize = 5;
    let organizationId = this.organizationId; //this.authService.getOrganizationId();

    let pageNumber = this.metaDataUsers.currentPage - 1;
    if (pageNumber === 1) {
      this.activeLessUser = false;
      pageSize = 5;
    }
    let dataViewMore = await this.appServices
      .getSupervisorOrganization(+organizationId, pageSize, pageNumber)
      .toPromise();

    const dataUser = dataViewMore['data'];
    const rolIdUsuarioLogueado = +this.roleLevel;
    dataViewMore['data'] = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        user.facilityId === 0 ||
        user.role === 'Facility Administrator' ||
        user.role === 'Facility Manager'
          ? false
          : true;

      return user;
    });
    /*  this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']]; */

    if (pageNumber !== 1) {
      this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']];
      this.listDataUsers.splice(this.listDataUsers.length - 10, 10);
    } else {
      this.listDataUsers = [];
      this.metaDataUsers = dataViewMore['meta'];
      this.listDataUsers = [...this.listDataUsers, ...dataViewMore['data']];
    }

    this.isDisabledUser =
      this.metaDataUsers.currentPage === this.metaDataUsers.totalPages
        ? true
        : false;
  }

  GetExternalRolesForSelectEdit(roleId: string) {
    this.appServices.GetExternalRolesForSelectEdit(roleId).subscribe((data) => {
      this.listDataUserEdit = data['data'];
      this.userRolForm.patchValue({
        roleId: roleId,
      });
    });
  }

  getListFacility() {
    this.appServices.getListFacility(this.organizationId).subscribe((data) => {
      this.listFacility = data['data'];
    });
  }

  buildOrganizationProfileForm(): void {
    this.OrganizationProfileForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      address: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      state: ['0', [this.validatorSelect()]],
      city: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
          ),
        ],
      ],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  openEditModal(template: TemplateRef<any>) {
    this.OrganizationProfileForm.patchValue({
      name: this.organizationData.organization.name,
      address: this.organizationData.address.address,
      state: this.organizationData.address.stateId,
      city: this.organizationData.address.city,
      phoneNumber: this.organizationData.organization.phoneNumber,
    });
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-md modal-dialog-centered customListModal',
    });
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const { address, city, name, phoneNumber, state } =
      this.OrganizationProfileForm.value;
    this.saveOrganization.address.address = address;
    this.saveOrganization.address.city = city;
    this.saveOrganization.address.stateId = +state;
    this.saveOrganization.organization.id = this.organizationId;
    this.saveOrganization.organization.name = name;
    this.saveOrganization.organization.phoneNumber = phoneNumber;
    this.appServices.getEditOrganization(this.saveOrganization).subscribe(
      (data) => {
        if (data) {
          this.modalRef.hide();
          this.modalRef = null;
          this.getOrganizationById(this.organizationId);
        }
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                this.msgError.push(mensajeError);
              }
            }
          }
        } else {
          let errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
        /*this.OrganizationProfileForm.patchValue({
          name: this.organizationData.organization.name,
          address: this.organizationData.address.address,
          state: this.organizationData.address.stateId,
          city: this.organizationData.address.city,
          phoneNumber: this.organizationData.organization.phoneNumber,
        })*/
      }
    );
  }

  async closeModalActivate(template) {
    const idOrganization = {
      id: this.organizationId,
    };
    this.mensajeError = '';
    this.msgError = [];
    this.appServices.activateOrganization(idOrganization).subscribe(
      (data) => {
        if (!this.modalRef) {
          return;
        }
        this.modalRef.hide();
        this.modalRef = null;
        this.mensajeError = '';
        this.msgError = [];
        //this.toastrService.success('Succes', 'Was successfully activated');
        this.getOrganizationById(idOrganization.id);
        this.getData(idOrganization.id);
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.msgError = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                this.msgError.push(valueError);
              }
            });
          }
          if (this.msgError.length === 0 && errors !== '') {
            this.mensajeError = errors;
          }
        } else {
          this.mensajeError = errors;
        }
      }
    );
  }

  async closeModalDesactive(template) {
    const idOrganization = {
      id: this.organizationId,
    };
    this.mensajeError = '';
    this.msgError = [];
    this.appServices.deActivateOrganization(idOrganization).subscribe(
      (data) => {
        if (!this.modalRef) {
          return;
        }
        this.modalRef.hide();
        this.modalRef = null;
        this.mensajeError = '';
        this.msgError = [];
        //this.toastrService.success('Succes', 'Was successfully activated');
        this.getOrganizationById(idOrganization.id);
        this.getData(idOrganization.id);
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.msgError = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                this.msgError.push(valueError);
              }
            });
          }
          if (this.msgError.length === 0 && errors !== '') {
            this.mensajeError = errors;
          }
        } else {
          this.mensajeError = errors;
        }
      }
    );
  }

  openModalUsers(template: TemplateRef<any>, user) {
    this.msgError = [];
    this.GetExternalRolesForSelectEdit(user.roleId);
    this.appServices
      .GetExternalRolesForSelectEdit(user.roleId)
      .subscribe((data) => {
        this.listDataUserEdit = data['data'];
        this.userRolForm.patchValue({
          roleId: user.roleId,
        });
        this.modalRef = this.modalService.show(template, {
          id: user.id,
          class: 'modal-md modal-dialog-centered',
        });
      });
  }

  closeFirstModalUsers() {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const dataRole = {
      id: this.modalRef.id,
      roleId: this.userRolForm.get('roleId').value,
    };
    this.appServices.updateRole(dataRole).subscribe(
      (data) => {
        this.modalRef.hide();
        this.modalRef = null;
        let organizationId = this.organizationId; //this.authService.getOrganizationId();
        this.updateListUser();
        this.userRolForm.patchValue({
          roleId: '0',
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (errorValue) {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
        /*this.userRolForm.reset();
        this.userRolForm.patchValue({
          roleId: user.roleId,
        });*/
      }
    );
    /*if (this.userRolForm.get('roleId').value !== '0') {

    } else {
      this.modalRef.hide();
      this.modalRef = null;
      this.userRolForm.reset();
      this.userRolForm.patchValue({
        roleId: '0',
      })
    }*/
  }

  openModalFacility(template: TemplateRef<any>, user) {
    this.msgError = [];
    this.userFacilityForm.patchValue({
      facilityId: user.facilityId,
    });
    this.modalRef = this.modalService.show(template, {
      id: user.id,
      class: 'modal-md modal-dialog-centered',
    });
  }

  closeModalFacility() {
    if (!this.modalRef) {
      return;
    }

    const dataRole = {
      userId: this.modalRef.id,
      facilityId: +this.userFacilityForm.get('facilityId').value,
    };
    this.msgError = [];
    this.appServices.relocate(dataRole).subscribe(
      (data) => {
        let organizationId = this.organizationId; //this.authService.getOrganizationId();
        this.getData(+organizationId);
        this.modalRef.hide();
        this.modalRef = null;
        this.userFacilityForm.reset();
        this.userFacilityForm.patchValue({
          facilityId: 0,
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
    /*if (+this.userFacilityForm.get('facilityId').value > 0) {
    } else {
      this.userFacilityForm.reset();
      this.modalRef.hide();
      this.modalRef = null;
      this.userFacilityForm.patchValue({
        facilityId: 0,
      })

    }*/
  }

  openDeleteModal(template: TemplateRef<any>, user) {
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: user.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  closeDeletetModal() {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const dataRole = {
      id: this.modalRef.id,
    };
    this.appServices.deleteUser(dataRole).subscribe(
      (data) => {
        this.updateListUser();
        this.modalRef.hide();
        this.modalRef = null;
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
  }

  async updateListUser() {
    this.supervisorUser = await this.appServices
      .getSupervisorOrganization(this.organizationId, 5)
      .toPromise();
    this.listDataUsers = this.supervisorUser.data;
    const dataUser = this.supervisorUser.data;
    const rolIdUsuarioLogueado = +this.roleLevel;
    this.listDataUsers = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        user.facilityId === 0 ||
        user.role === 'Facility Administrator' ||
        user.role === 'Facility Manager'
          ? false
          : true;

      return user;
    });
    this.metaDataUsers = this.supervisorUser.meta;
  }

  openModalDeactivate(template: TemplateRef<any>, facility: any) {
    this.msgError = [];
    this.mensajeError = '';
    this.modalRef = this.modalService.show(template, {
      id: facility.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  openActivateModal(template: TemplateRef<any>, facility: any) {
    this.msgError = [];
    this.mensajeError = '';
    this.modalRef = this.modalService.show(template, {
      id: facility.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  async closeDeactivatel() {
    if (!this.modalRef) {
      return;
    }

    const idFcility = {
      id: this.modalRef.id,
    };
    this.appServices.deactivateFacility(idFcility).subscribe(
      (dataResponse) => {
        this.updateFacilittList();

        this.modalRef.hide();
        this.modalRef = null;
        this.mensajeError = '';
        this.msgError = [];
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.msgError = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                this.msgError.push(valueError);
              }
            });
          }
          if (this.msgError.length === 0 && errors !== '') {
            this.mensajeError = errors;
          }
        } else {
          this.mensajeError = errors;
        }
      }
    );
  }

  async closeActivateModal(activate) {
    if (!this.modalRef) {
      return;
    }
    const idFcility = {
      id: this.modalRef.id,
    };
    this.appServices.activateFacility(idFcility).subscribe(
      (dataResponse) => {
        this.updateFacilittList();
        this.modalRef.hide();
        this.modalRef = null;
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.msgError = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                this.msgError.push(valueError);
              }
            });
          }
          if (this.msgError.length === 0 && errors !== '') {
            this.mensajeError = errors;
          }
        } else {
          this.mensajeError = errors;
        }
      }
    );
  }

  async updateFacilittList() {
    this.dataFacility = await this.appServices
      .getAllOrganizationFacility(this.organizationId)
      .toPromise();
    this.listDataFacility = this.dataFacility.data;
    this.metaDataFacility = this.dataFacility.meta;
  }

  navigateLink(facility: any) {
    this.router.navigate(['/facility-profile/' + facility.id]);
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.msgError = [];
  }

  validatorSelect(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { required: true };
    };
  }
}
