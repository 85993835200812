<div class="wrapper section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-lg-8">
            <p class="back" routerLink="/users-list" (click)="changeMenu()" >
              <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all Users" >
              Back to all external users
            </p>
            <h1>
              External Invites Management
            </h1>
            <p>Use the Advance search filters to optimize your search.</p>
          </div>
          <div class="col-md-6 col-lg-4 text-right ">
            <button type="button"
            class="btn btn-primary submit btn-invite" routerLink="/users-invite">Invite external user(s)</button>
          </div>
        </div>



        <ul class="nav filter-menu">
          <li class="nav-item">
            <a class="nav-link actived">
              All invites
            </a>
          </li>
          <!-- <li class="nav-item">
              <a class="nav-link">
                Accepted
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                Pending
              </a>
            </li> -->
          <div class="float">
            <!-- <a class="filters-link"  (click)="filters()" style="padding-right: 100px;">
                <span *ngIf="activeFilters">Close</span>
                  Advance search
              </a> -->
            <a class="filters-link" (click)="filters()">
              <span *ngIf="activeFilters">Close advance search</span>
              <span *ngIf="!activeFilters">Advance search</span>
            </a>
            <a class="filters-link class-link"
              >
              <img [src]="'assets/images/icons/filter.svg'" alt="Sort">
              Sort
              <img [src]="'assets/images/icons/botton.svg'" alt="Sort"></a>
          </div>
        </ul>
        <hr>
        <div class="row filters" *ngIf="activeFilters" [formGroup]="searchAdvancedFrom">
          <div
          class="col-12 col-sm-3 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control input" placeholder="example@mail.com" formControlName="email"
                (change)="search()">
            </div>
          </div>
          <div
            class="col-12 col-sm-3 col-md-3 col-lg-3">
            <div class="form-group">
              <div class="form-group">
                <label for="role">Role</label>
                <select class="form-control input select-old" formControlName="role" name="role" (change)="search()">
                  <option value="0" selected>Show All</option>
                  <option *ngFor="let userRol of listDataUser" [value]="userRol.id">
                    {{ userRol.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 col-md-3 col-lg-3" *ngIf="internalRol">
            <div class="form-group">
              <label for="facility">Organization</label>
              <select class="form-control input select" formControlName="organization" name="organization"
                (change)="search();inputChange($event)">
                <option value="0" selected>Show All</option>
                <option *ngFor="let organization of listOrganization" [value]="organization.id">
                  {{ organization.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-12 col-sm-3 col-md-3 col-lg-3"
            *ngIf="!facilityListRol">
            <div class="form-group">
              <label for="facility">Facility</label>
              <select class="form-control input select" formControlName="facility" name="facility" (change)="search()">
                <option value="0" selected>Show All</option>
                <option *ngFor="let facility of listFacilities" [value]="facility.id">
                  {{ facility.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row labels">
          <div class="col-9 col-md-10 col-lg-10">
            <div class="row labels">
              <div [ngClass]="{'col-8 col-md-3 col-lg-3': internalRol, 'col-4': !internalRol }" *ngIf="oraganizationListRol || internalRol"
              class="email1">
                <label for="email">email</label>
              </div>
              <div [ngClass]="{'col-3 col-md-3 col-lg-3': internalRol, 'col-4 col-md-4 col-lg-4': !internalRol }" *ngIf="oraganizationListRol || internalRol"
              class="role1">
                <label for="role">role</label>
              </div>

              <div class="col-6 col-md-6 col-lg-6" *ngIf="!(oraganizationListRol || internalRol)">
                <label for="email">email</label>
              </div>
              <div class="col-6 col-md-6 col-lg-6" *ngIf="!(oraganizationListRol || internalRol)">
                <label for="role">role</label>
              </div>

              <div class="facility" [ngClass]="{'col-3 col-md-3 col-lg-3': internalRol, 'col-4 col-md-4 col-lg-4': !internalRol }" *ngIf="oraganizationListRol || internalRol">

                <label for="status">Facility</label>
              </div>
              <div class="col-3 organization" *ngIf="internalRol">
                <label for="status">Organization</label>
              </div>
            </div>
          </div>
          <div class="col-3 col-md-2 col-lg-2 text-center options">
            <label for="options">options</label>
          </div>

        </div>
        <div class="row data" *ngFor="let user of dataInvite.data; let i = index">
          <div class="col-9 col-md-10 col-lg-10">
            <div class="row ">
              <div [ngClass]="{'col-8 col-md-3 col-lg-3': internalRol, 'col-4 col-md-4 col-lg-4': !internalRol }" *ngIf="oraganizationListRol || internalRol">
                <p class="info email">{{ user.email }}</p>
              </div>
              <div class="col-4" [ngClass]="{'col-md-3 col-lg-3': internalRol, 'col-md-4 col-lg-4': !internalRol }" *ngIf="oraganizationListRol || internalRol">
                <p class="info role">{{ user.role }}</p>
              </div>

              <div class="col-6 col-md-6 col-lg-6" *ngIf="!(oraganizationListRol || internalRol)">
                <p class="info">{{ user.email }}</p>
              </div>
              <div class="col-3 col-md-6 col-lg-6" *ngIf="!(oraganizationListRol || internalRol)">
                <p class="info">{{ user.role }}</p>
              </div>

              <div [ngClass]="{'col-3 col-md-3 col-lg-4': internalRol, 'col-4 col-md-4 col-lg-4': !internalRol }" *ngIf="oraganizationListRol || internalRol"
              class="facility1">
                <p class="info">{{ user.facility }}</p>
              </div>
              <div class="col-3 col-md-3 col-lg-3 organization1" *ngIf="internalRol">
                <p class="info">{{ user.organization }}</p>
              </div>

            </div>
          </div>
          <div class="col-3 col-md-2 col-lg-2 text-center actions">
            <button type="button" class="btn btn-primary actived" *ngIf="roleLevel < user.roleLevel"  (click)="openModalUser(template, user,i)">
              <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light actived" *ngIf="roleLevel < user.roleLevel"  (click)="openDeleteModal(delete, user,i)">
              <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete User">
            </button>
            <!--Disable Buttons-->
            <button type="button" class="btn btn-light disabled" disabled *ngIf="roleLevel < user.roleLevel" >
              <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light disabled" disabled *ngIf="roleLevel < user.roleLevel" >
              <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete User">
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 1em;">

      <div class="col-6 col-md-auto pading-right" *ngIf="activeLess">

        <p class="text-center">
          <button type="button" class="btn btn-light btn-view" (click)="viewLess()">View less</button>
        </p>
      </div>
      <div class="col-6 col-md-auto pading-left" *ngIf="!isDisabled">
        <p class="text-center">
          <button type="button" class="btn btn-primary btn-more" (click)="viewMore()" [style]="{'margin-right': !activeLess ? '0px': ''}" [disabled]="isDisabled">View
            more</button>
        </p>
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body">
    <p class="back" (click)="closeFirstModal(template)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to All Invites">
      Back to all invites
    </p>
    <div class="error" *ngIf="msgError.length !==0">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <form [formGroup]="inviteManagementForm">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control input" formControlName="email" placeholder="example@mail.com">
        <div
          *ngIf="inviteManagementForm.controls['email'].invalid && (inviteManagementForm.controls['email'].dirty || inviteManagementForm.controls['email'].touched)"
          class="invalid-feedback">
          <div *ngIf="inviteManagementForm.controls['email'].errors.required">* Email is required</div>
          <div *ngIf="inviteManagementForm.controls['email'].errors.email">* Email pattern is not valid</div>
        </div>
      </div>
      <div class="form-group">
        <label for="role">Role</label>
        <select class="form-control input select" formControlName="role">
          <option value="" selected>Select Role</option>
          <option *ngFor="let userRol of listDataUserEdit" [value]="userRol.id">
            {{ userRol.name }}
          </option>
        </select>
        <div
          *ngIf="inviteManagementForm.controls['role'].invalid && (inviteManagementForm.controls['role'].dirty || inviteManagementForm.controls['role'].touched)"
          class="invalid-feedback">
          <div *ngIf="inviteManagementForm.controls['role'].errors.required">* Role is required</div>
        </div>
      </div>
      <p class="text-center">
        <button type="button" [disabled]="inviteManagementForm.invalid"
           (click)="closeFirstModalUser(succesfully)"
          class="btn btn-primary submit btn-save">save changes</button>
      </p>
 <!--      <p class="bottom-info  text-center">Disclaimer here?</p> -->
    </form>

  </div>
</ng-template>
<ng-template #delete>
  <!-- <div class="modal-body text-center modal-fit">
    <p class="back" (click)="closeFirstModal(delete)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to All Invites">
      Back to all invites
    </p>
    <div style="color:#D20404!important;text-align: left;">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <h1 class="modal-title">
      Are you sure you want to
      <br>
      <span class="accent-text">delete</span> this invite?
    </h1>
    <p>
      <button type="button" (click)="closeFirstModal(delete)" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeleteModal(delete)">yes I want to delete it</button>
    </p>
  </div> -->

  <div class="modal-body text-center modal-fit">
    <h1>
      Are you sure you want to

      <span class="accent-text">delete</span> this invite?
    </h1>
    <p>
      <button type="button" (click)="closeFirstModal(delete)" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width btn-top" (click)="closeDeleteModal(succesfully)">yes I want to delete it</button>
    </p>
  </div>

</ng-template>
<ng-template #succesfully>
  <div class="modal-body text-center modal-fit">
      <h1>
          Invite {{message}}
          <br>
          <span class="accent-text">successfully!</span>
      </h1>
  </div>
</ng-template>
