import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AppServices } from 'src/app/services/api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { facilityRol, internalsRol, organizationRol } from '@Models/role.model';
import { AuthService } from 'src/app/services/auth.service';
import { RolsEnum } from '@Models/enum/rols.enum';
import { Router } from '@angular/router';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActionMenuService } from 'src/app/services/action-menu.service';

@Component({
  selector: 'app-units-dashboard',
  templateUrl: './units-dashboard.component.html',
  styleUrls: ['./units-dashboard.component.sass'],
})
export class UnitsDashboardComponent implements OnInit {
  searchAdvancedFrom: FormGroup;
  activeFilters: boolean = false;
  isDisabled: boolean = false;
  dataUnitHub = [];
  public pageSize: number = 12;
  metaDataUnit: any;
  statusUnitHub: string;
  optionFacility = [];
  urlActive: string;
  allUnitsClassActive: boolean = false;
  onlineClassActive: boolean = false;
  OfflineClassActive: boolean = false;
  listOrganization: any[] = [];
  public userRole: RolsEnum;
  internalRol: boolean = false;
  oraganizationListRol: boolean = false;
  facilityRol: boolean = false;
  organizationLocalStorageId = this.authService.getOrganizationId();
  facilityLocalStorageId = this.authService.getFacilityId();
  activeLess: boolean = false;

  constructor(
    private appServices: AppServices,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private sideNavbarService: SideNavbarService,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('UD');
    this.userRole = this.authService.authStatus.getValue().role;
    /*const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
    this.internalRol = isInternalRol.length > 0 ? true : false;*/
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );

    let menu = this.sideNavbarService.rolsMenu$;
    let indexOrganizationList = menu.findIndex(
      (item) => item.link === '/units-dashboard'
    );
    const indexUserInvite = menu.findIndex((item) =>
      item.link.includes('/flushes-history')
    );
    const indexUnitDetails = menu.findIndex((item) =>
      item.link.includes('/unit-details')
    );
    const orgProfile = menu.findIndex(
      (item) => item.link === `/assign-new-unit`
    );
    let indexMneChange = -1;
    if (indexOrganizationList !== -1) {
      indexMneChange = indexOrganizationList;
    } else if (indexUserInvite !== -1) {
      indexMneChange = indexUserInvite;
    } else if (orgProfile !== -1) {
      indexMneChange = orgProfile;
    } else if (indexUnitDetails !== -1) {
      indexMneChange = indexUnitDetails;
    }
    if (indexMneChange !== -1) {
      menu[indexMneChange].link = this.router.url;
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  ngOnInit(): void {
    const organizationIdProfile = localStorage.getItem('organizationIdProfile');

    let ProfileOrganizationId = localStorage.getItem('ProfileOrganizationId');
    let ProfileFacilityId = +localStorage.getItem('ProfileFacilityId');

    if (localStorage.getItem('urlSearch') === null) {
      this.activeFilters = false;
    } else {
      this.activeFilters = true;
    }
    this.activeFilters =
      localStorage.getItem('urlSearch') === null ? false : true;
    let listSearch =
      JSON.parse(localStorage.getItem('paramSearch')) === null
        ? { name: '', FacilityId: 0, SuinNumber: null, Health: null }
        : JSON.parse(localStorage.getItem('paramSearch'));

    if (listSearch.organizationId !== 0)
      this.getListFacility(+listSearch.organizationId);


    this.searchAdvancedFrom = this.fb.group({
      name: [listSearch.name === '' ? '' : listSearch.name],
      siunNumber: [listSearch.SuinNumber ?? null],
      health: [listSearch.Health ?? '0'],
      facility: [
        +this.facilityLocalStorageId !== 0
          ? this.facilityLocalStorageId
          : listSearch.FacilityId === 0
          ? 0
          : listSearch.FacilityId,
      ],
      //organizationId:  [organizationIdProfile !== 'null' ? +organizationIdProfile : 0],
      organizationId: [
        !isNaN(+organizationIdProfile)
          ? +organizationIdProfile
          : !isNaN(listSearch.organizationId)
          ? listSearch.organizationId
          : 0,
      ],
    });


    if (ProfileOrganizationId !== null) {
      this.activeFilters = true;

      this.getListFacility(+ProfileOrganizationId);

      this.searchAdvancedFrom = this.fb.group({
        name: [''],
        siunNumber: [],
        health: ['0'],
        facility: [ProfileFacilityId],
        //organizationId:  [organizationIdProfile !== 'null' ? +organizationIdProfile : 0],
        organizationId: [ProfileOrganizationId],
      });
    }


    this.search();

    let classOnlineOffline = localStorage.getItem('classActiveOnlineOffline');
    this.OfflineClassActive = classOnlineOffline === 'O' ? true : false;
    this.onlineClassActive = classOnlineOffline === 'A' ? true : false;
    this.allUnitsClassActive = classOnlineOffline === null ? true : false;

    if (this.oraganizationListRol) {
      this.getListFacility(+this.organizationLocalStorageId);
    }
    if (this.internalRol) {
      this.getListOrganization();
    }
  }

  ngOnDestroy(): void {
    let menu = this.sideNavbarService.rolsMenu$;
    let index = menu.findIndex((item) =>
      item.link.includes('/units-dashboard')
    );
    if (index !== -1) {
      menu[index].link = '/units-dashboard';
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  getListOrganization() {
    if (this.internalRol) {
      this.appServices.getForSelectOrganization().subscribe((data) => {
        this.listOrganization = data['data'];
      });
    }
  }

  filters() {
    this.searchAdvancedFrom.patchValue({
      name: '',
      siunNumber: null,
      health: '0',
      facility: 0,
    });
    this.activeFilters = !this.activeFilters;
    if (!this.activeFilters) {
      if (this.statusUnitHub) {
        this.search(0, this.statusUnitHub);
      } else {
        this.search();
      }
    }
  }

  haltUnit(healt: string): string {
    if (healt === 'Steady') {
      return 'status good';
    } else if (healt === 'Critical') {
      return 'status bad';
    } else if (healt === 'Warning') {
      return 'status check';
    }
  }
  allUnits() {
    this.statusUnitHub = undefined;
    this.isDisabled = false;
    this.allUnitsClassActive = true;
    this.onlineClassActive = false;
    this.OfflineClassActive = false;

    localStorage.removeItem('classActiveOnlineOffline');
    let organizationLocalStorage = this.internalRol
      ? '0'
      : this.organizationLocalStorageId;
    let facilityIdLocalStorage = this.internalRol
      ? '0'
      : this.facilityLocalStorageId;
    const facility =
      facilityIdLocalStorage === null ? 0 : +facilityIdLocalStorage;
    const organizationId =
      organizationLocalStorage === null ? 0 : +organizationLocalStorage;
    if (
      this.searchAdvancedFrom.get('name').value !== '' ||
      this.searchAdvancedFrom.get('health').value !== '0' ||
      this.searchAdvancedFrom.get('facility').value !== 0 ||
      this.searchAdvancedFrom.get('siunNumber').value !== null ||
      this.searchAdvancedFrom.get('organizationId').value !== 0
    ) {
      this.search();
    } else {
      let listpage =
        JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;
      if (listpage) {
        this.appServices
          .getUnitHubAll(organizationId, this.pageSize, 1, facility)
          .subscribe((data) => {
            this.dataUnitHub = [];
            this.dataUnitHub = data['data'];
            this.metaDataUnit = data['meta'];
            this.metaDataUnit['filters'] = 'UnitHubAll';
          });
      } else {
        this.dataUnitHub = JSON.parse(localStorage.getItem('listRecord'));
      }
    }
  }

  onlineUnits(option: string) {
    this.statusUnitHub = option;
    this.allUnitsClassActive = false;
    this.onlineClassActive = option === 'online' ? true : false;
    this.OfflineClassActive = option === 'ofline' ? true : false;
    let Status = option === 'online' ? true : false;
    localStorage.setItem('classActiveOnlineOffline', Status ? 'A' : 'O');
    this.isDisabled = false;
    this.activeLess = false;
    let facilityCode = 0;
    let organizacion = 0;
    if (this.internalRol) {
      organizacion = Number(
        this.searchAdvancedFrom.get('organizationId').value
      );
    } else {
      let organizationLocalStorage = this.internalRol
        ? '0'
        : this.organizationLocalStorageId;
      let facilityIdLocalStorage = this.internalRol
        ? '0'
        : this.facilityLocalStorageId;
      facilityCode =
        facilityIdLocalStorage === null ? 0 : +facilityIdLocalStorage;
      organizacion =
        organizationLocalStorage === null ? 0 : +organizationLocalStorage;
    }
    /**  this.searchAdvancedFrom.patchValue({
      name: '',
      siunNumber: null,
      health: '0',
      facility: 0,
    })*/
    if (
      this.searchAdvancedFrom.get('name').value !== '' ||
      this.searchAdvancedFrom.get('health').value !== '0' ||
      this.searchAdvancedFrom.get('facility').value !== 0 ||
      this.searchAdvancedFrom.get('siunNumber').value !== null ||
      this.searchAdvancedFrom.get('organizationId').value !== 0
    ) {
      this.search(0, option);
    } else {
      this.appServices
        .getUnitHubOnLineOfLine(
          organizacion,
          option,
          this.pageSize,
          1,
          facilityCode
        )
        .subscribe((data) => {
          this.dataUnitHub = [];
          this.dataUnitHub = data['data'];
          this.metaDataUnit = data['meta'];
          this.metaDataUnit['filters'] = 'statusUnitHub';
          this.isDisabled = this.metaDataUnit.totalPages <= 1 ? true : false;
        });
    }
  }

  async viewMore() {
    let pageSize = 12;
    if (!this.isDisabled) {
      this.activeLess = true;
      let urlService = this.urlEndpoint();

      let pageNumber = this.metaDataUnit.currentPage + 1;

      let classOnlineOffline = localStorage.getItem('classActiveOnlineOffline');
      if (classOnlineOffline !== null) {
        let Status = classOnlineOffline === 'A' ? 'online' : 'ofline';
        urlService = this.urlEndpoint() + '&Status=' + Status;
      }
      //@ts-ignore
      const filters = this.metaDataUnit?.filters || '';

      if (urlService) {
        this.appServices
          .getUnitParams(urlService, pageNumber)
          .subscribe((data) => {
            //this.dataUnitHub = [];
            this.metaDataUnit = data['meta'];
            this.dataUnitHub = [...this.dataUnitHub, ...data['data']];

            this.isDisabled =
              this.metaDataUnit.currentPage === this.metaDataUnit.totalPages
                ? true
                : false;
          });
      }
    }

    localStorage.setItem('listRecord', JSON.stringify(this.dataUnitHub));
  }

  async viewLess() {
    let pageSize = 12;

    let urlService = this.urlEndpoint();

    let pageNumber = this.metaDataUnit.currentPage - 1;

    let classOnlineOffline = localStorage.getItem('classActiveOnlineOffline');
    if (classOnlineOffline !== null) {
      let Status = classOnlineOffline === 'A' ? 'online' : 'ofline';
      urlService = this.urlEndpoint() + '&Status=' + Status;
    }
    //@ts-ignore
    const filters = this.metaDataUnit?.filters || '';

    if (urlService) {
      this.appServices
        .getUnitParams(urlService, pageNumber)
        .subscribe((data) => {
          //this.dataUnitHub = [];
          // this.metaDataUnit = data['meta'];
          // this.dataUnitHub = [...data['data'], ...this.dataUnitHub];

          if (pageNumber !== 1) {
            this.metaDataUnit = data['meta'];
            this.dataUnitHub = [...this.dataUnitHub, ...data['data']];
            this.dataUnitHub.splice(this.dataUnitHub.length - 24, 24);
          } else {
            this.activeLess = false;
            this.dataUnitHub = [];
            this.metaDataUnit = data['meta'];
            this.dataUnitHub = [...this.dataUnitHub, ...data['data']];
          }

          this.isDisabled =
            this.metaDataUnit.currentPage === this.metaDataUnit.totalPages
              ? true
              : false;
        });
    }

    localStorage.setItem('listRecord', JSON.stringify(this.dataUnitHub));
  }

  search(pagen: number = 0, option?: string) {
    let urlService = this.urlEndpoint(option);

    let classOnlineOffline = localStorage.getItem('classActiveOnlineOffline');
    if (classOnlineOffline !== null) {
      let Status = classOnlineOffline === 'A' ? true : false;
      urlService = this.urlEndpoint() + '&Status=' + Status;
    }
    //@ts-ignore
    const filters = this.metaDataUnit?.filters || '';

    if (urlService) {
      if (pagen === 0) {
        this.appServices.getUnitParams(urlService, 1).subscribe((data) => {
          this.dataUnitHub = [];
          this.metaDataUnit = data['meta'];
          this.dataUnitHub = data['data'];

          if (filters !== '') {
            this.metaDataUnit['filters'] = filters;
          }
          this.isDisabled = this.metaDataUnit.totalPages <= 1 ? true : false;
        });
      } else {
        this.appServices.getUnitParams(urlService, pagen).subscribe((data) => {
          //this.dataUnitHub = [];
          this.metaDataUnit = data['meta'];
          //this.dataUnitHub = data['data'];
          if (filters !== '') {
            this.metaDataUnit['filters'] = filters;
            this.dataUnitHub = [...this.dataUnitHub, ...data['data']];
          }
          this.isDisabled = this.metaDataUnit.totalPages <= 1 ? true : false;
          this.activeLess = this.metaDataUnit.currentPage === 1 ? false : true;
        });
      }
    }
    //this.isDisabled = (this.metaDataUnit.currentPage === this.metaDataUnit.totalPages) ? true : false;
    //this.isDisabled = (this.metaDataUnit.totalPages >= 1) ? true : false;
  }

  urlEndpoint(option?: string): string {
    let url = '/Units/GetAll?PageSize=12';
    if (option) {
      url = `/Units/GetAll?Status=${option}&PageSize=12`;
    } else {
      url = '/Units/GetAll?PageSize=12';
    }
    let paramsHealth = this.searchAdvancedFrom.get('health').value;
    let paramsName = this.searchAdvancedFrom.get('name').value;
    let paramsFacility = Number(this.searchAdvancedFrom.get('facility').value);
    let paramssSiunNumber = this.searchAdvancedFrom.get('siunNumber').value;
    let paramsOrganozation = Number(
      this.searchAdvancedFrom.get('organizationId').value
    );
    let organizationId = +this.organizationLocalStorageId ?? 0;

    if (this.internalRol) {
      url = url + '&OrganizationId=' + paramsOrganozation;
    } else {
      url = url + '&OrganizationId=' + organizationId;
    }

    if (paramsName !== '') {
      url = url + '&Name=' + paramsName;
    }
    if (paramsFacility !== 0) {
      url = url + '&FacilityId=' + Number(paramsFacility);
    }
    if (paramssSiunNumber !== null) {
      url = url + '&SuinNumber=' + paramssSiunNumber;
    }
    if (paramsHealth !== '0') {
      url = url + '&Health=' + paramsHealth;
    }

    if (
      paramsFacility !== 0 ||
      paramssSiunNumber !== '' ||
      paramsName !== '' ||
      paramsHealth !== ''
    ) {
      localStorage.setItem('urlSearch', url);
      localStorage.setItem(
        'paramSearch',
        JSON.stringify({
          name: paramsName,
          FacilityId: Number(paramsFacility),
          SuinNumber: paramssSiunNumber,
          Health: paramsHealth,
          organizationId: this.internalRol
            ? paramsOrganozation
            : organizationId,
        })
      );
    }

    return url;
  }

  inputChange(value) {
    let id = value.target.value;
    this.searchAdvancedFrom.patchValue({ facility: 0 });
    this.getListFacility(+id);
  }

  getListFacility(id: number) {
    this.optionFacility = [];
    this.appServices.getListFacility(id).subscribe((data: any) => {
      this.optionFacility = data['data'];
    });
  }
}
