<div class="container-fluid">
    <div class="row">
        <div class="co-xs-12 col-sm-4 col-md-4 left-col full-height padre">

          <div class="desktop">
            <img class="logo" [src]="'assets/images/logo-janway.svg'" alt="Janway Logo" width="200px">
            <h1>Welcome to <span class="accent-text">Janway.</span> </h1>
          </div>

    <!--           <p>Aptent iaculis lobortis nisl pretium et sed himenaeos neque litora, accu msan eros nibh etiam hac nisi velit.</p> -->
        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 right-col full-height full-height-movil">
            <div class="row form">
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-6 offset-md-2 offset-lg-4">
                    <form [formGroup]="formAddress">
                        <h3>Forgot Password?</h3>
                        <div style="color:#D20404;text-align: left; padding-bottom: 20px;">
                            <ul>
                              <li *ngFor="let error of msgErrorList">
                                {{ error }}
                              </li>
                            </ul>
                            <span *ngIf="msgError !== ''">
                                {{ msgError }}
                            </span>
                        </div>
                        <div style="text-align: justify;">
                            <p>Enter  the email  address where you received the invite email and  we’ll send you instructions to reset your password.</p>
                            <p>For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.</p>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="form-group email-margin" >
                                    <label for="emailAddresss">Email Address</label>
                                    <input type="email"
                                        formControlName="emailAddresss"
                                        name="emailAddresss"
                                        class="form-control"
                                        id="emailAddresss"
                                        placeholder="example@mail.com">
                                    <div *ngIf="formAddress.controls['emailAddresss'].invalid &&
                                            (formAddress.controls['emailAddresss'].dirty ||
                                            formAddress.controls['emailAddresss'].touched)" class="invalid-feedback">
                                        <div *ngIf="formAddress.controls['emailAddresss'].errors.required">* Email Address is required</div>
                                        <div *ngIf="formAddress.controls['emailAddresss'].errors.email">* Email Address pattern is not valid</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-center" placement="top" ngbTooltip="Send instructions">
                            <button type="submit"
                                [disabled]="!formAddress.valid"
                                class="btn btn-primary btn-send"
                                (click)="forgetPassword()">
                                send instructions
                            </button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
