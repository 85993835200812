<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p class="back" routerLink="/organization-list">
                    <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all invites">
                    Back to all organizations
                </p>
                <h1>Add Organization</h1>
                <p>Add the required information to add a new organization.</p>
                <hr>
                <div *ngIf="errorsArray.length !==0" class="error">
                    <ul>
                        <li *ngFor="let error of errorsArray">
                            {{ error }}
                        </li>
                    </ul>
                </div>

                <form [formGroup]=" UsersForm" style="margin-top: 0px;">
                    <div formArrayName="users">
                        <div class="row" *ngFor="let user of users.controls; index as i" [formGroupName]="i">

                               <div class="col-12 col-md-12 col-lg-10">
                                <div class="row">

                                    <div class="col-6 col-sm-3 col-md-4 col-lg-3">
                                        <div class="form-group">
                                            <label for="nameAdmin">Name</label>
                                            <input type="text" class="form-control input" required id="nameAdmin"
                                                formControlName="nameAdmin" placeholder="Name">
                                            <div *ngIf="user.controls['nameAdmin'].invalid &&
                                    (user.controls['nameAdmin'].dirty ||
                                    user.controls['nameAdmin'].touched)" class="invalid-feedback">
                                                <div *ngIf="user.controls['nameAdmin'].errors.required">* Name is
                                                    required</div>
                                                <div *ngIf="user.controls['nameAdmin'].errors.maxlength">
                                                    * Name must have at most 50 characters
                                                </div>
                                                <div *ngIf="user.controls['nameAdmin'].errors.minlength">
                                                    * Name must be at least 5 characters long
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 col-md-4 col-lg-3">
                                        <div class="form-group">
                                            <label for="emailAdmin">Email</label>
                                            <input type="email" class="form-control input" required
                                                formControlName="emailAdmin" placeholder="example@mail.com">
                                            <div *ngIf="user.controls['emailAdmin'].invalid
                                    && (user.controls['emailAdmin'].dirty ||
                                    user.controls['emailAdmin'].touched)" class="invalid-feedback">
                                                <div *ngIf="user.controls['emailAdmin'].errors.required">* Email is
                                                    required
                                                </div>
                                                <div *ngIf="user.controls['emailAdmin'].errors.email">* Email pattern is
                                                    not
                                                    valid
                                                </div>
                                                <div *ngIf="user.controls['emailAdmin'].errors.maxlength">
                                                    * Email must have at most 100 characters
                                                </div>
                                                <div *ngIf="user.controls['emailAdmin'].errors.minlength">
                                                    * Email must be at least 5 characters long
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                               </div>

                            </div>

                        </div>

                        <div class="text-center">
                            <button type="submit" [disabled]="UsersForm.invalid"
                               (click)="openModal(template)"
                              class="btn btn-primary submit btn-add">
                              Add Organization
                            </button>
                          </div>
                        <!--     <p class="text-center form-buttons">
            <button type="button"
                style="border-radius: 26px; color:#303FD7; background: #ffffff !important; font-weight: 700;"
                (click)="backStep(formStep - 1)" class="btn btn-light">previous step</button>
            <button type="button"
                [disabled]="users.controls[0].get('nameAdmin').errors?.required || users.controls[0].get('emailAdmin').errors?.required || users.controls[0].get('nameManager').errors?.required || users.controls[0].get('emailManager').errors?.required"
                style="border-radius: 26px; color: #00CFFF; font-weight: 700;" (click)="nextStep(formStep + 1)"
                class="btn btn-primary">next</button>
        </p> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-body text-center modal-fit ">
        <h1 class="h1-modal">
            Organization added

            <span class="accent-text">successfully!</span>
        </h1>

    </div>
</ng-template>
