import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AppServices } from 'src/app/services/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.sass'],
})
export class AddOrganizationComponent implements OnInit {
  UsersForm: FormGroup;
  position: number = 1;
  errorsArray = [];
  public modalRef: BsModalRef | null;

  constructor(
    private fb: FormBuilder,
    private appServices: AppServices,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buildUsersForm();
    this.addUser(0);
  }

  buildUsersForm(): void {
    this.UsersForm = this.fb.group({
      users: this.fb.array([]),
    });
  }

  addUser(i) {
    i == 0 && this.position == 1 ? (this.position = i) : this.position++;
    this.users.push(this.newUser());
  }

  get users(): FormArray {
    return this.UsersForm.get('users') as FormArray;
  }

  newUser(): FormGroup {
    return this.fb.group({
      nameAdmin: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      emailAdmin: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.errorsArray = [];
    const { users } = this.UsersForm.value;
    let usersArray;
    for (let user of users) {
      const { emailAdmin, nameAdmin } = user;
      const newUser = {
        orgAdminEmail: emailAdmin,
        organizationName: nameAdmin,
      };
      usersArray = newUser;
    }
    this.appServices.postAddOrganizations(usersArray).subscribe(
      (data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, {
            id: 1,
            class: 'modal-md modal-dialog-centered succesfullyCustom',
          });
           let timerId = setInterval(() => this.modalRef.hide(), 3000);
           setTimeout(() => {
            clearInterval(timerId);
            this.modalRef.hide();
          }, 3000);
          this.resetForm(this.UsersForm);
       /*    this.router.navigate(['/organization-list']); */
          /*  this.resetForm(this.UsersForm); */
        }
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          let errorsList = [];
          const arrayErrors = arrayExiste ? errors.split('-') : [];
          this.errorsArray = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('\n', '');
              valueError.replace('-', '');
              if (valueError !== '') {
                errorsList.push(valueError);
              }
            });
          }
          if (this.errorsArray.length === 0 && errors !== '') {
            this.errorsArray.push(errors);
          } else {
            this.errorsArray = errorsList;
          }
        } else {
          this.errorsArray.push(errors);
        }
      }
    );
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
    let lengArray = this.users.length;
    this.position = 1;
    this.UsersForm = this.fb.group({
      users: this.fb.array([]),
    });

    this. addUser(0);
  }

}
