import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-popup',
  templateUrl: './popupForms.component.html',
  styleUrls: ['./popup.component.sass'],
  providers: [BsModalService]
})

export class PopupFormsComponent{
  @Input() title: string;
  @Input() message: string;
  @Input() btn1: string;
  @Input() btn2: string;
  @Input() back: string;

  constructor(public bsModalRef: BsModalRef) {}

  public clickOk() {

    this.bsModalRef.hide();
  }

}
