import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recover-password-email',
  templateUrl: './recover-password-email.component.html',
  styleUrls: ['./recover-password-email.component.sass']
})
export class RecoverPasswordEmailComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {}

  goLogin() {
    this.router.navigate(['/login']);
  }

}
