import { Component, OnInit, TemplateRef } from '@angular/core';
import { AppServices } from 'src/app/services/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupService } from 'src/app/services/popup.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import * as moment from 'moment';
import { RolsEnum } from '@Models/enum/rols.enum';
import { facilityRol, internalsRol, organizationRol } from '@Models/role.model';
import { AuthService } from 'src/app/services/auth.service';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionMenuService } from 'src/app/services/action-menu.service';

@Component({
  selector: 'app-unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.sass'],
})
export class UnitDetailsComponent implements OnInit {
  public applyClass1: Boolean = false;
  public applyClass2: Boolean = false;
  modalRef: BsModalRef | null;
  FacilityEdit: FormGroup;
  unitId: number = 0;
  valueFilter1: number = 0;
  valueFilter2: number = 0;
  valueFilter3: number = 0;
  valueFilter4: number = 0;
  dataUnit: any = null;
  unitName: string = '';
  dataFlushesHistory: any;
  metaFlushesHistory: any;
  showBadStatus: boolean = false;
  showOkStatus: boolean = false;
  facilityId: number = 0;
  nameFacility;

  statusUnitHub: string;
  optionFacility = [];
  urlActive: string;
  isDisabled: boolean = false;
  filterClass1 = 'bad-status';
  steadyClass: boolean = false;
  badClass: boolean = false;
  warninClass: boolean = false;
  allClass: boolean = false;
  mensajeEr: '';

  filterModel: any[] = [];
  msgError: any[] = [];
  msgErrorReboot: any[] = [];
  msgDetach: any[] = [];
  public userRole: RolsEnum;
  internalRol: boolean = false;
  oraganizationListRol: boolean = false;
  facilityListRol: boolean = false;
  organizationLocalStorageId = this.authService.getOrganizationId();
  htmlDetail: any;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private popupService: PopupService,
    private modalService: BsModalService,
    private appServices: AppServices,
    private authService: AuthService,
    private sideNavbarService: SideNavbarService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('UD');
    this.userRole = this.authService.authStatus.getValue().role;
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;
    let menu = this.sideNavbarService.rolsMenu$;
    let indexOrganizationList = menu.findIndex(
      (item) => item.link === '/units-dashboard'
    );
    const indexUserInvite = menu.findIndex((item) =>
      item.link.includes('/flushes-history')
    );
    const indexUnitDetails = menu.findIndex((item) =>
      item.link.includes('/unit-details')
    );
    const orgProfile = menu.findIndex(
      (item) => item.link === `/assign-new-unit`
    );
    let indexMneChange = -1;
    if (indexOrganizationList !== -1) {
      indexMneChange = indexOrganizationList;
    } else if (indexUserInvite !== -1) {
      indexMneChange = indexUserInvite;
    } else if (orgProfile !== -1) {
      indexMneChange = orgProfile;
    } else if (indexUnitDetails !== -1) {
      indexMneChange = indexUnitDetails;
    }
    if (indexMneChange !== -1) {
      menu[indexMneChange].link = this._router.url;
      this.sideNavbarService.rolsMenu$ = menu;
    }

    const unit = route.params['_value'];
    this.unitId = Number(unit.id);
    this.getFlushesHistoryAndUnit(this.unitId);
  }

  ngOnInit(): void {
    this.buildFaciltyEdit();
    if (!this.internalRol) {
      if (!(this.userRole === 'FM' || this.userRole === 'FA')) {
        this.getListFacility(+this.organizationLocalStorageId);
      }
    }
  }

  ngOnDestroy(): void {
    let menu = this.sideNavbarService.rolsMenu$;
    let index = menu.findIndex((item) => item.link.includes('/unit-details'));
    if (index !== -1) {
      menu[index].link = '/units-dashboard';
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  buildFaciltyEdit(): void {
    this.FacilityEdit = this.fb.group({
      id: [0],
      unity: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      facility: [0, [this.validatorSelect()]],
    });
  }

  open(opn: Number) {
    if (opn === 1) {
      this.applyClass1 = this.applyClass1 ? false : true;
    }
    if (opn === 2) {
      this.applyClass2 = this.applyClass2 ? false : true;
    }
  }

  openEditModal(template: TemplateRef<any>) {
    this.msgError = [];
    this.mensajeEr = '';

    if (this.internalRol) {
      this.getListFacility(this.dataUnit?.organizationId);
    }
    this.FacilityEdit.patchValue({
      id: this.dataUnit.id,
      unity: this.dataUnit.name,
      facility: +(this.dataUnit?.facilityId | 0),
    });
    if (this.facilityListRol) {
      this.FacilityEdit.get('facility').disable();
    } else {
      this.FacilityEdit.get('facility').enable();
    }
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-dialog-centered',
    });
  }
  showDetachUnit(template: TemplateRef<any>) {
    this.msgDetach = [];
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-dialog-centered',
    });
    //this.popupService.showConfirm("", "Are you sure you want to detach this unit?", "no, I want to go back", "yes I want to detach it", "Back to app");
  }
  showReset(template: TemplateRef<any>) {
    this.msgErrorReboot = [];
    this.mensajeEr = '';
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-dialog-centered',
    });
    //this.popupService.showConfirm("", "Are you sure you want to reset this unit?", "no, I want to go back", "yes I want to reset it", "Back to app");
  }

  closeModalReebot() {
    const dataSave = {
      id: this.unitId,
    };

    this.appServices.reboothUnit(dataSave).subscribe(
      (data) => {
        if (!this.modalRef) {
          return;
        }
        this.modalRef.hide();
        this.modalRef = null;
        this.msgErrorReboot = [];
        this.mensajeEr = '';
        this._router.navigate(['units-dashboard']);
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgErrorReboot = [];
        this.mensajeEr = '';
        const arrayErrors = responseError.split('\n');
        arrayErrors.forEach((element) => {
          if (element !== '') {
            const errorMensaje = element.split('-');
            if (errorMensaje.length > 1) {
              if (errorMensaje[1] !== '') {
                this.msgErrorReboot.push(errorMensaje[1]);
              }
            }
          }
        });
        if (this.msgError.length === 0) {
          const valueError = responseError.replace(/['"]+/g, '');
          this.mensajeEr = valueError;
        }
      }
    );
  }

  closeFirstModal() {
    const dataSave = {
      id: 0,
      name: '',
      facilityId: 0,
    };
    dataSave.name = this.FacilityEdit.get('unity').value;
    dataSave.id = this.FacilityEdit.get('id').value;
    dataSave.facilityId = this.facilityId;
    this.msgError = [];
    this.mensajeEr = '';
    this.appServices.editUnit(dataSave).subscribe(
      (dataResponse) => {
        window.location.reload();
 /*        if (dataResponse) {
          this.unitName = dataSave.name;
          this.FacilityEdit.patchValue({
            id: 0,
            unity: '',
            facility: +this.dataUnit?.facilityId | 0,
          });
          if (!this.modalRef) {
            return;
          }
          this.modalRef.hide();
          this.modalRef = null;
          this.getFlushesHistoryAndUnit(this.unitId);
          this.mensajeEr = '';
        } */
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        this.mensajeEr = '';
        const arrayErrors = responseError.split('\n');
        arrayErrors.forEach((element) => {

          if (element !== '') {
            const errorMensaje = element.split('-');
            if (errorMensaje.length > 1) {
              if (errorMensaje[1] !== '') {
                this.msgError.push(errorMensaje[1]);
              }
            }
          }
        });
        if (this.msgError.length === 0) {
          const valueError = responseError.replace(/['"]+/g, '');
          this.mensajeEr = valueError;
        }
      }
    );
  }

  closeDatached() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  okDetach() {
    const dataSaveDetach = {
      id: this.dataUnit.id,
      facilityId: this.facilityId
    };
    this.appServices.detachUnit(dataSaveDetach).subscribe(
      (dataResponse) => {
        if (dataResponse) {
          this.closeDatached();
          this.msgDetach = [];
          this._router.navigate(['units-dashboard']);
        }
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        this.mensajeEr = '';
        const arrayErrors = responseError.split('\n');
        arrayErrors.forEach((element) => {
          if (element !== '') {
            const errorMensaje = element.split('-');
            if (errorMensaje.length > 1) {
              if (errorMensaje[1] !== '') {
                this.msgDetach.push(errorMensaje[1]);
              }
            }
          }
        });
        if (this.msgError.length === 0) {
          const valueError = responseError.replace(/['"]+/g, '');
          this.mensajeEr = valueError;
        }
      }
    );
  }

  getFlushesHistoryAndUnit(id: number): void {
    this.allClass = true;
    this.getFlushesHistoryWithUnit(id, 7).subscribe((dataResponse) => {
      this.dataUnit = dataResponse[0]['data'];

      this.unitName = this.dataUnit.name;
      this.FacilityEdit.get('id').setValue(this.dataUnit.id);
      this.FacilityEdit.get('unity').setValue(this.dataUnit.name);
      this.facilityId = this.dataUnit.facilityId;
      this.FacilityEdit.get('facility').setValue(this.facilityId);
      this.nameFacility = this.dataUnit.facilityName;
      this.filterModel = [
        { value: this.dataUnit.lastFlush?.filter1, filter: 1 },
        { value: this.dataUnit.lastFlush?.filter2, filter: 2 },
        /*{value: this.dataUnit.lastFlush.filter3, filter: 3 },
          {value: this.dataUnit.lastFlush.filter4, filter: 4 },*/
      ];
      this.dataFlushesHistory = this.filtersFlushesHistory(
        dataResponse[1]['data']
      );

      this.metaFlushesHistory = dataResponse[1]['meta'];
      this.valueFilter1 = this.dataUnit.lastFlush?.filter1;
      this.valueFilter2 = this.dataUnit.lastFlush?.filter2;
      this.htmlDetail = this.dataUnit?.healthSummary;

      this.htmlDetail = this.transformYourHtml(this.dataUnit?.healthSummary);

    });
  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  filtersFlushesHistory(data: any): string[] {
    return data.map((item) => {
      item['filterModel'] = [
        { value: item.filter1, filter: 1 },
        { value: item.filter2, filter: 2 },
        /*{value: item.filter3, filter: 3 },
        {value: item.filter4, filter: 4 },*/
      ];
      return item;
    });
  }

  getFlushesHistoryWithUnit(
    id: number,
    pageSize: number = 10
  ): Observable<any> {
    this.unitId = Number(this.unitId);

    let dateFlushHistory = moment(new Date()).format('YYYY-MM-DD');
    //let urlFlushesHistory = '/Units/GetFlushesHistory?UnitId=' + this.unitId + '&Date='+dateFlushHistory  +'&WithLastFlush=false';
    let urlFlushesHistory =
      '/Units/GetFlushesHistory?UnitId=' + this.unitId + '&WithLastFlush=false';
    return forkJoin([
      this.appServices.getUnitById(this.unitId),
      this.appServices.getFlushesHistory(urlFlushesHistory, pageSize, 1),
    ]);
  }

  getAll() {
    this.steadyClass = false;
    this.badClass = false;
    this.warninClass = false;
    this.allClass = true;
    let dateFlushHistory = moment(new Date()).format('YYYY-MM-DD');
    //let urlFlushesHistory = '/Units/GetFlushesHistory?UnitId=' + this.unitId + '&Date='+dateFlushHistory  +'&WithLastFlush=false';
    let urlFlushesHistory =
      '/Units/GetFlushesHistory?UnitId=' + this.unitId + '&WithLastFlush=false';
    this.appServices
      .getFlushesHistory(urlFlushesHistory, 10, 1)
      .subscribe((data) => {
        this.dataFlushesHistory = this.filtersFlushesHistory(data['data']);
        this.metaFlushesHistory = data['meta'];
      });
  }

  getParamsFlusHealt(option: string) {
    this.steadyClass = option === 'Steady' ? true : false;
    this.badClass = option === 'Critical' ? true : false;
    this.warninClass = option === 'Warning' ? true : false;
    this.allClass = false;
    let dateFlushHistory = moment(new Date()).format('YYYY-MM-DD');
    //let urlFlushesHistory = '/Units/GetFlushesHistory?UnitId=' + this.unitId + '&Date='+dateFlushHistory  +'&WithLastFlush=false';
    let urlFlushesHistory =
      '/Units/GetFlushesHistory?UnitId=' +
      this.unitId +
      '&Health=' +
      option +
      '&WithLastFlush=false';
    this.appServices
      .getFlushesHistory(urlFlushesHistory, 10, 1)
      .subscribe((data) => {
        this.dataFlushesHistory = this.filtersFlushesHistory(data['data']);

        this.metaFlushesHistory = data['meta'];
      });
  }

  getListFacility(id: number) {
    this.appServices.getListFacility(id).subscribe((data: any) => {
      this.optionFacility = data['data'];
    });
  }

  valueFacility(ev) {
    if (ev) {
      this.facilityId = Number(this.FacilityEdit.get('facility').value);
    }
  }

  statusClass(status: string): string {
    return status.toLowerCase();
  }

  haltUnit(healt: string): string {
    if (healt === 'Steady') {
      return 'status good';
    } else if (healt === 'Critical') {
      return 'status bad';
    } else if (healt === 'Warning') {
      return 'status check';
    } else {
      return '';
    }
  }

  iconUnit(healt: string): string {
    let classIcon = '';
    if (healt === 'Steady') {
      classIcon = 'icon-steady icon';
      return classIcon.toLowerCase();
    } else if (healt === 'Critical') {
      classIcon = 'icon-critical icon';
      return classIcon.toLowerCase();
    } else if (healt === 'Warning') {
      classIcon = 'icon-warning icon';
      return classIcon.toLowerCase();
    } else {
      return '';
    }
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  validatorSelect(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { required: true };
    };
  }
}
