<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <h1>
              {{ tituloModulo }}
            </h1>
            <p>Use the Advance search filters to optimize your search.</p>
          </div>
          <div class="col-md-6 col-lg-6 text-right botom-mobile">
            <div class="mobile">


              <button

              class="btn btn-primary submit btn-invite"
              routerLink="/users-invite"
            >
              Invite external user(s)
            </button>
            <button
                type="button"
                class="btn btn-light submit btn-all"
                (click)="changeMenu('/invite-management')"
                routerLink="/invite-management"
              >
                All external invites
              </button>

            </div>
          </div>
        </div>
        <h1></h1>

        <ul class="nav filter-menu">
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="{ active: allUserClassActive }"
              (click)="allUser()"
            >
              All users
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="{ active: onlineClassActive }"
              (click)="onlineUsers('Active')"
            >
              <span class="online"></span>
              Active
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="{ active: OfflineClassActive }"
              (click)="onlineUsers('Inactive')"
            >
              <span class="offline"></span>
              Inactive
            </a>
          </li>
          <div style="float: right;">
            <a class="filters-link" (click)="filters()">
              <span *ngIf="activeFilters">Close advance search</span>
              <span *ngIf="!activeFilters">Advance search</span>
            </a>
            <!-- <a class="filters-link" (click)="filters()" style="padding-right: 80px;"><span
                *ngIf="activeFilters">Close</span> Advance search</a> -->
            <!-- <a class="filters-link" style="padding-left: 10px; color: #A5A8C5; text-decoration:none;display: none;"><img
                [src]="'assets/images/icons/filter.svg'" alt="Sort"> Sort <img [src]="'assets/images/icons/botton.svg'"
                alt="Sort"></a> -->
          </div>
        </ul>
        <hr />
        <div
          class="row filters"
          *ngIf="activeFilters"
          [formGroup]="userSearchForm"
        >
          <div
            [ngClass]="{
              'col-6 col-sm-6 col-md-4 col-lg-2': !facilityListRol,
              'col-12 col-sm-6 col-md-3 col-lg-3': facilityListRol
            }"
          >
            <div class="form-group">
              <label>First Name</label>
              <input
                type="text"
                class="form-control input"
                placeholder="First Name"
                formControlName="firstName"
                (change)="search()"
              />
            </div>
          </div>
          <div
            [ngClass]="{
              'col-6 col-sm-6 col-md-4 col-lg-2': !facilityListRol,
              'col-12 col-sm-6 col-md-3 col-lg-3': facilityListRol
            }"
          >
            <div class="form-group">
              <label>Last Name</label>
              <input
                type="text"
                class="form-control input"
                placeholder="Last Name"
                formControlName="lastName"
                (change)="search()"
              />
            </div>
          </div>
          <div
            [ngClass]="{
              'col-6 col-sm-6 col-md-4 col-lg-2': !facilityListRol,
              'col-12 col-sm-6 col-md-3 col-lg-3': facilityListRol
            }"
          >
            <div class="form-group">
              <label>Email</label>
              <input
                type="text"
                class="form-control input"
                placeholder="example@mail.com"
                formControlName="email"
                (change)="search()"
              />
            </div>
          </div>
          <div
            [ngClass]="{
              'col-6 col-sm-6 col-md-4 col-lg-2': internalRol,
              'col-12 col-sm-6 col-md-3 col-lg-3':
                facilityListRol || oraganizationListRol
            }"
          >
            <div class="form-group">
              <label for="role">Role</label>
              <select
                class="form-control input select-old"
                formControlName="roleId"
                name="roleId"
                (change)="search()"
              >
                <option value="0" selected>Show All</option>
                <option
                  *ngFor="let userRol of listDataUser"
                  [value]="userRol.id"
                >
                  {{ userRol.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-2" *ngIf="internalRol">
            <div class="form-group">
              <label for="Organization">Organization</label>
              <select
                class="form-control input select-old"
                id="OrganizationId"
                formControlName="organizationId"
                (change)="inputChange($event); search()"
              >
                <option [value]="0" selected>Show All</option>
                <option *ngFor="let org of listOrganization" [value]="org.id">
                  {{ org.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            [ngClass]="{
              'col-6 col-sm-6 col-md-4 col-lg-2': internalRol,
              'col-12 col-sm-6 col-md-3 col-lg-3': !internalRol
            }"
            *ngIf="oraganizationListRol || internalRol"
          >
            <div class="form-group">
              <label for="facility">Facility</label>
              <select
                class="form-control input select-old"
                formControlName="facilityId"
                name="facilityId"
                (change)="search()"
              >
                <option value="0" selected>Show All</option>
                <option
                  *ngFor="let facility of listFacilities"
                  [value]="facility.id"
                >
                  {{ facility.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row labels">
          <div class="col-5 col-sm-3 col-md-3 col-lg-3">
            <label for="name">name</label>
          </div>
          <div class="col-3 col-md-3 email">
            <label for="email">email</label>
          </div>
          <div class="col-3 col-sm-2 col-md-2 col-lg-2 role">
            <label for="role">role</label>
          </div>
          <div class="col-2 col-sm-2 col-md-1 col-lg-2 text-center status">
            <label for="status">status</label>
          </div>
          <div class="col-4 col-sm-2 col-md-3 col-lg-2 text-center options">
            <label for="options">options</label>
          </div>
        </div>
        <div
          class="row data"
          *ngFor="let user of dataUserList.data; let i = index"
        >
          <div class="col-5 col-sm-3 col-md-3 col-lg-3 ">
            <h5>{{ user.name }}</h5>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3 email1">
            <p class="info">{{ user.email }}</p>
          </div>
          <div class="col-3 col-sm-2 col-md-2 col-lg-2">
            <p class="info">{{ user.role }}</p>
          </div>
          <div class="col-4 col-sm-2 col-md-1 lg-2 text-center status1 margin-status">
            <p class="status-box">
              <span class="status on" *ngIf="user.status === 'Active'">
                {{ user.status }}
              </span>

              <span class="inactive" *ngIf="user.status !== 'Active'">
                {{ user.status }}
              </span>
            </p>
          </div>
          <div class="col-4 col-sm-2 col-md-3 col-lg-2 text-center actions margin-options">
            <button
              type="button"
              class="btn btn-primary actived"
              *ngIf="
                user.showBtnDeleteUpdateUser && roleLevelComp < user.roleLevel
              "
            >
              <img
                [src]="'assets/images/icons/change-icon-1.svg'"
                alt="Change Role"
                (click)="openModal(template, user, i)"
              />
            </button>
            <button
              type="button"
              class="btn btn-light actived"
              *ngIf="
                user.showBtnUpdateFacility &&
                roleLevelComp < user.roleLevel &&
                validationfac(user.facilityId, user.roleLevel)
              "
            >
              <img
                [src]="'assets/images/icons/change-icon-2.svg'"
                alt="Change Facility"
                class="imagen"

                (click)="openModalFacility(facility, user, i)"
              />
            </button>
            <button
              type="button"
              class="btn btn-light actived"
              (click)="openDeleteModal(delete, user, i)"
              *ngIf="
                user.showBtnDeleteUpdateUser && roleLevelComp < user.roleLevel
              "
            >
              <img
                [src]="'assets/images/icons/delete-icon-1.svg'"
                alt="Delete User"
              />
            </button>
            <!--Disable Buttons-->
            <button
              type="button"
              class="btn btn-light disabled"
              disabled
              *ngIf="user.showBtnDeleteUpdateUser"
            >
              <img
                [src]="'assets/images/icons/change-icon-disable-1.svg'"
                alt="Change Role"
              />
            </button>
            <button
              type="button"
              class="btn btn-light disabled"
              disabled
              *ngIf="user.showBtnUpdateFacility"
            >
              <img
                [src]="'assets/images/icons/change-icon-disable-2.svg'"
                alt="Change Facility"
                class="imagen-icon"
              />
            </button>
            <button
              type="button"
              class="btn btn-light disabled"
              disabled
              *ngIf="user.showBtnDeleteUpdateUser"
            >
              <img
                [src]="'assets/images/icons/delete-icon-disable-1.svg'"
                alt="Delete User"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
 <div class="row justify-content-center top-button">

      <div class="col-6 col-md-auto pading-right" *ngIf="activeLess">

        <p class="text-center">
          <button type="button" class="btn btn-light btn-view" (click)="viewLess()">View less</button>
        </p>
      </div>
      <div class="col-6 col-md-auto pading-left" *ngIf="!isDisabled">
        <p class="text-center">
          <button [style]="{'margin-right': !activeLess ? '0px': ''}" type="button" class="btn btn-primary btn-more" (click)="viewMore()" [disabled]="isDisabled">View
            more</button>
        </p>
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center modal-fit" [formGroup]="userRolForm">
    <p class="back back3" (click)="closeModal(template)">
      <img
        [src]="'assets/images/icons/arrow-back.svg'"
        alt="Back to User Details"
      />
      {{ linkModal }}
    </p>
    <div *ngIf="msgError.length !==0" class="error" >
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="form-group" style="text-align: left">
      <label for="roleId">Role</label>
      <select
        class="form-control input select-old"
        formControlName="roleId"
        name="roleId"
        required
        (change)="selectedState($event)"
      >
        <option value="0" selected>Select Role</option>
        <option *ngFor="let userRol of listDataUserEdit" [value]="userRol.id">
          {{ userRol.name }}
        </option>
      </select>
      <div
        *ngIf="
          userRolForm.get('roleId').invalid &&
          (userRolForm.get('roleId').dirty || userRolForm.get('roleId').touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="userRolForm.get('roleId').errors.required">
          * Role is required
        </div>
      </div>
    </div>
    <p class="text-center">
      <button
        type="button"

        [disabled]="userRolForm.invalid"
        (click)="closeFirstModal(succesfully)"
        class="btn btn-primary submit btn-save"
      >
        save changes
      </button>
    </p>
    <p class="bottom-info text-center">
      An email will be sent to the user to notify this change.
    </p>
  </div>
</ng-template>
<ng-template #facility>
  <div class="modal-body text-center modal-fit" [formGroup]="userFacilityForm">
    <p class="back back3" (click)="closeModalUpdateFacility(facility)">
      <img
        [src]="'assets/images/icons/arrow-back.svg'"
        alt="Back to User Details"
      />
      {{ linkModal }}
    </p>

    <div *ngIf="msgError.length !==0" class="error">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="form-group" style="text-align: left">
      <label for="role">Facility</label>
      <select
        class="form-control input select-old"
        formControlName="facilityId"
        name="facility"
        required
      >
        <option value="0" selected>Select Facility</option>
        <option *ngFor="let facility of facilitiesList" [value]="+facility.id">
          {{ facility.name }}
        </option>
      </select>
      <div
        *ngIf="
          userFacilityForm.get('facilityId').invalid &&
          (userFacilityForm.get('facilityId').dirty ||
            userFacilityForm.get('facilityId').touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="userFacilityForm.get('facilityId').errors.required">
          * Facility is required
        </div>
      </div>
    </div>
    <div class="text-center">
      <button
        type="button"

        [disabled]="userFacilityForm.invalid"
        (click)="closeModalFacility(succesfully)"
        class="btn btn-primary submit btn-save"
      >
        save changes
      </button>
    </div>
    <p class="bottom-info text-center">
      An email will be sent to the user to notify this change.
    </p>
  </div>
</ng-template>
<ng-template #delete>
  <div class="modal-body text-center modal-fit">
    <h1>
      Are you sure you want to

      <span class="accent-text">delete</span> this user?
    </h1>
    <p>
      <button
        type="button"
        (click)="closeModal(delete)"
        class="btn btn-primary submit full-width margin-btn margins-btn-top"
      >
        no, I want to go back
      </button>
    </p>
    <p>
      <button
        class="btn btn-light full-width margins-btn-top"
        (click)="closeDeletetModal(succesfully)"
      >
        yes I want to delete it
      </button>
    </p>
  </div>
</ng-template>
<ng-template #succesfully>
  <div class="modal-body text-center modal-fit">
    <h1>
      User {{ message }}
      <span class="accent-text"> successfully!</span>
    </h1>
  </div>
</ng-template>
