import { Component, OnInit } from '@angular/core';
import { RolsEnum } from '@Models/enum/rols.enum';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit {
  userRole: string = '';
  fecha: string;
  organizationAndFacility: string = '';
  nameOrFacilityOrganization: string = '';
  rolOrFacilityOrganization: string = '';
  subCadena: string = '';

  rols = [
    {
      user: 'FM',
      role: 'Facility Manager',
    },
    {
      user: 'FA',
      role: 'Facility Administrator',
    },
    {
      user: 'OM',
      role: 'Organization ManageR',
    },
    {
      user: 'OA',
      role: 'Organization Administrator',
    },
    {
      user: 'CS',
      role: 'Customer Support',
    },
    {
      user: 'SM',
      role: 'Support Manager',
    },
    {
      user: 'SA',
      role: 'System Administrator',
    },
    {
      user: 'VT',
      role: 'Sales',
    },
  ];
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.fecha = moment().format('LL');
    this.userRole = this.authService.authStatus.getValue().role;
    const rolFilter = this.rols.filter((item) => item.user === this.userRole);
    this.userRole = rolFilter.length === 0 ? '' : rolFilter[0].role;
    const organization = this.authService.getOrganizationName();
    const facility = this.authService.getFacilityName();
    const sep = facility !== '' && organization !== '' ? ' - ' : '';
    this.organizationAndFacility = `${organization}${sep}${facility}`;
    const nameUser = this.authService.getNameUser();
    sessionStorage.setItem('userFullName', nameUser);
    this.nameOrFacilityOrganization = sessionStorage.getItem('userFullName');
    this.rolOrFacilityOrganization =
      this.organizationAndFacility === ''
        ? this.userRole
        : this.organizationAndFacility;
  }

  getData() {
    var cadena = sessionStorage.getItem('userFullName'),
    separador = ' ', // un espacio en blanco
    arregloDeSubCadenas = cadena.split(separador);
  let cadenaInciales = '';
  for (let x = 0; x < 2; x++) {
    cadenaInciales = cadenaInciales + arregloDeSubCadenas[x].substring(0, 1);
  }
  this.subCadena = cadenaInciales.substring(0,2);

  return sessionStorage.getItem('userFullName');
  }
}
