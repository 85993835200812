import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitModel } from '@Models/unit/unit.model';
import { UsersModel } from '@Models/users/users.model';
import { CompanyModel } from '@Models/company/company.model';
import { AddressModel } from '@Models/address/address.model';
import { Exit } from 'src/app/services/can-deactivate.guard';
import { PopupService } from 'src/app/services/popup.service';
import { FacilityModel } from '@Models/facility/facility.model';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { ActionMenuService } from 'src/app/services/action-menu.service';
import { map } from 'rxjs/operators';

class DataSave {
  organization: CompanyModel = new CompanyModel();
  address: AddressModel = new AddressModel();
  facilities: FacilityModel[] = [];
  users: UsersModel[] = [];
  units: UnitModel[] = [];
}

const ORGANIZATION_MANAGER = '8fd20b7f-7a03-4e97-a3df-4de83e73d983';
const ORGANIZATION_ADMINISTRATOR = '8c58032d-abe5-4cce-aba0-57b3456ee6d1';

@Component({
  selector: 'app-set-up-organization',
  templateUrl: './set-up-organization.component.html',
  styleUrls: ['./set-up-organization.component.sass'],
})
export class SetUpOrganizationComponent implements OnInit, Exit {
  FacilityForm: FormGroup;
  OrganizationForm: FormGroup;
  UsersStepTwoForm: FormGroup;
  FacilityStepTwoForm: FormGroup;
  UnitsStepThreeForm: FormGroup;
  formRiderecTo: FormGroup;
  position: number = 1;
  facilityPosition: number = 1;
  unitsPosition: number = 1;
  formStep: number = 1;
  process: Boolean = false;
  modalRef: BsModalRef;
  listState: any = [];
  listFacility = [];
  errorsOrganization: any[] = [];
  errorsFacility: any[] = [];
  errorsUsers: any[] = [];
  errorsUnits: any[] = [];
  errorsArray: any[] = [];
  errorsSteps: number[] = [];
  arrayFacility: any[] = [];
  arrayUsers: any[] = [];
  arrayUnits: any[] = [];
  arrayOrg: any[] = [];
  arrayFacilities: any[] = [];
  riderecTo: boolean = false;
  dataSaveOrganization: DataSave = new DataSave();
  next: boolean = false;
  disabledBtn = false;
  internalRol = false;
  msjSinPasos = '';
  organizationStorageId = this.authService.getOrganizationId();
  rolesId: any = null;
  userRole;
  idOrganization;
  nameOrganization;
  activeVal: boolean[] = [false];
  @ViewChild('exit') exit: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private appServices: AppServices,
    private popupService: PopupService,
    private toastrService: ToastrService,
    private authService: AuthService,
    readonly router: Router,
    private modalService: BsModalService,
    private sideNavbarService: SideNavbarService,
    private actionMenu: ActionMenuService,
    private rutaActiva: ActivatedRoute
  ) {
    this.formRiderecTo = this.fb.group({
      riderecTo: [true],
    });
    const rolInternal = this.authService.getInternalRole();

      this.internalRol = rolInternal === 'False' ? false : true;


    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let indexOrganizationList = menu.findIndex(
        (item) => item.link === '/organization-list'
      );
      const indexUserInvite = menu.findIndex((item) =>
        item.link.includes('/organization-profile')
      );
      const orgProfile = menu.findIndex(
        (item) => item.link === `/set-up-organization`
      );
      let indexMneChange = -1;
      if (indexOrganizationList !== -1) {
        indexMneChange = indexOrganizationList;
      } else if (indexUserInvite !== -1) {
        indexMneChange = indexUserInvite;
      } else if (orgProfile !== -1) {
        indexMneChange = orgProfile;
      }
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = this.router.url;
        //menu[indexMneChange].ngbTooltip = 'Add Organization';
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
    //this.actionMenu.setSelectMenu('OL');
    this.userRole = this.authService.authStatus.getValue().role;

    if (this.userRole === 'OA') {
      this.actionMenu.setSelectMenu('SO');
    } else {
      this.actionMenu.setSelectMenu('OL');
    }
  }

  letout(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.formRiderecTo.get('riderecTo').value) {
      return true;
    }
    this.openEditModal(this.exit);
  }

  openEditModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-md modal-dialog-centered customListModal',
    });
  }

  public doSomething(ref: BsModalRef<boolean>) {

    !!ref && ref.hide();
  }

  ngOnInit(): void {
    this.process = true;
    this.getStates();
    this.getListFacility();
    this.idOrganization = this.rutaActiva.snapshot.params.id;

    this.buildOrganizationForm();
    this.buildUsersForm();
    this.buildUnitsForm();
    this.buildFacilityForm();
    this.addUser(0);
    this.addFacility(0);
    this.addUnit(0);
    this.appServices
      .getOrganizationName(+this.idOrganization)
      .subscribe((data) => {
        this.nameOrganization = data['data'];
        this.OrganizationForm.get('organization').setValue(
          this.nameOrganization
        );
      });
    this.appServices
      .getAction('/Roles/GetExternalRolesForSetupOrganization')
      .subscribe((data) => {
        this.rolesId = data;
      });
    this.UnitsStepThreeForm.valueChanges.subscribe((data) => {
      const { units } = this.UnitsStepThreeForm.value;
      for (let unit of units) {
        const { name, facility } = unit;
        this.disabledBtn = name === '' && facility === '' ? false : true;
        this.addErrorsUnits();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      let index = menu.findIndex((item) =>
        item.link.includes('/set-up-organization')
      );
      if (index !== -1) {
        menu[index].link = '/organization-list';
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
  }

  addErrorsUnits() {
    const datos = this.UnitsStepThreeForm.value;
    const i = datos.length - 1;
    /*if(this.disabledBtn) {
      this.UnitsStepThreeForm.get('name').setValidators([Validators.required, Validators.minLength(5),
        Validators.maxLength(50)]);
      this.UnitsStepThreeForm.get('siun').setValidators([Validators.required]);
      this.UnitsStepThreeForm.get('facility').setValidators([Validators.required]);
    } else {
      this.UnitsStepThreeForm.get('name').clearValidators();
      this.UnitsStepThreeForm.get('siun').clearValidators();
      this.UnitsStepThreeForm.get('facility').clearValidators();
    }
    this.UnitsStepThreeForm.get('name').updateValueAndValidity();
    this.UnitsStepThreeForm.get('siun').updateValueAndValidity();
    this.UnitsStepThreeForm.get('facility').updateValueAndValidity();*/
  }

  getStates() {
    this.appServices.getStates().subscribe((data) => {
      this.listState = data['data'];
    });
  }

  getListFacility() {
    this.appServices.getListFacility(2).subscribe((data) => {
      this.listFacility = data['data'];
    });
  }

  buildOrganizationForm(): void {
    this.OrganizationForm = this.fb.group({
      organization: [
        this.nameOrganization,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
          ),
        ],
      ],
      address: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      state: ['', [Validators.required]],
      city: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
    });
    this.OrganizationForm.valueChanges.subscribe((data) => {
      this.formRiderecTo.patchValue({
        riderecTo: false,
      });
    });
  }

  buildFacilityForm(): void {
    this.FacilityStepTwoForm = this.fb.group({
      facilities: this.fb.array([]),
    });
  }
  buildUsersForm(): void {
    this.UsersStepTwoForm = this.fb.group({
      users: this.fb.array([]),
    });
  }
  buildUnitsForm(): void {
    this.UnitsStepThreeForm = this.fb.group({
      units: this.fb.array([]),
    });
  }

  get users(): FormArray {
    return this.UsersStepTwoForm.get('users') as FormArray;
  }

  get facilities(): FormArray {
    return this.FacilityStepTwoForm.get('facilities') as FormArray;
  }

  newUser(): FormGroup {
    return this.fb.group({
      nameAdmin: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      emailAdmin: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      nameManager: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      emailManager: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
    });
  }
  newFacility(): FormGroup {
    return this.fb.group({
      facilityName: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
    });
  }

  addUser(i) {
    i == 0 && this.position == 1 ? (this.position = i) : this.position++;
    this.users.push(this.newUser());
  }
  addFacility(i) {
    i == 0 && this.facilityPosition == 1
      ? (this.facilityPosition = i)
      : this.facilityPosition++;
    this.facilities.push(this.newFacility());
  }

  removeUser(i: number) {
    this.position--;
    this.users.removeAt(i);
  }
  removeFacility(i: number) {
    this.facilityPosition--;
    this.facilities.removeAt(i);
  }

  //Units Form
  get units(): FormArray {
    return this.UnitsStepThreeForm.get('units') as FormArray;
  }

  newUnit(): FormGroup {
    return this.fb.group({
      name: [
        '',
        this.unitsPosition === 0
          ? []
          : [
              ,
              Validators.required,
              Validators.minLength(5),
              Validators.maxLength(50),
            ],
      ],
      macAddress: new FormControl(
         '',
        this.unitsPosition === 0
          ? []
          : [Validators.required, Validators.required],
        [this.validatonMacAddress()]
      ),
      //siun: ['', [Validators.required]],
      facility: ['', this.unitsPosition === 0 ? [] : [Validators.required]],
    });
  }

  addUnitAfterDelete(i: number) {
    this.unitsPosition = 1;
    this.addUnit(0);
  }
  addUnit(i) {
    i == 0 && this.unitsPosition == 1
      ? (this.unitsPosition = i)
      : this.unitsPosition++;
    this.units.push(this.newUnit());
  }

  removeUnit(i: number) {
    this.unitsPosition--;
    this.units.removeAt(i);

  }
  inputChange(evt: KeyboardEvent, key: string) {
    //@ts-ignore
    let value = evt.target.value;
  }
  //Form Steps
  backStep(step) {
    this.formStep = step;
    if (step === 1) {
      this.errorsArray = this.errorsOrganization;
    } else if (step === 2) {
      this.errorsArray = this.errorsFacility;
    } else if (step === 3) {
      this.errorsArray = this.errorsUsers;
    } else if (step === 4) {
      this.errorsArray = this.errorsUnits;
    }
  }

  nextStep(step) {
    this.formStep = step;
    if (this.formStep === 2) {
      this.errorsArray = this.errorsFacility;
      const { address, city, organization, phone, state } =
        this.OrganizationForm.value;
      this.dataSaveOrganization.address.id = 0;
      this.dataSaveOrganization.address.address = address;
      this.dataSaveOrganization.address.city = city;
      this.dataSaveOrganization.address.stateId = +state;
      this.dataSaveOrganization.address.stateName = '';

      this.dataSaveOrganization.organization.id = +this.idOrganization;
      this.dataSaveOrganization.organization.name = organization;
      this.dataSaveOrganization.organization.phoneNumber = phone;
      this.dataSaveOrganization.organization.status = true;
    } else if (this.formStep === 3) {
      this.errorsArray = this.errorsUsers;
      let arrayDataFacility: FacilityModel[] = [];
      const { facilities } = this.FacilityStepTwoForm.value;


      for (const [i, facility] of facilities.entries()) {
        const { facilityName } = facility;
        const facilityModel = new FacilityModel();
        facilityModel.id = i + 1;
        facilityModel.name = facilityName;
        facilityModel.phoneNumber = '';
        facilityModel.organizationId = +this.idOrganization; //+this.organizationStorageId;
        facilityModel.status = true;
        arrayDataFacility.push(facilityModel);
      }
      this.dataSaveOrganization.facilities = arrayDataFacility;
    } else if (this.formStep === 4) {
      this.errorsArray = this.errorsUnits;
      let arrayDataUser: UsersModel[] = [];
      const { users } = this.UsersStepTwoForm.value;
      let i = 0;
      for (let user of users) {
        const { emailAdmin, emailManager, nameAdmin, nameManager } = user;
        /*    const userModel = new UsersModel(); */
        const userModelManager = new UsersModel();
        /* userModel.name = nameAdmin;
        userModel.email = emailAdmin; */
        /*   userModel.facility = '';
        userModel.roleId = this.rolesId.data[0].id;
        userModel.facilityId = 1;
        userModel.organizationId = 1;  */ //+this.organizationStorageId;

        userModelManager.name = nameManager;
        userModelManager.email = emailManager;
        userModelManager.facility = '';
        userModelManager.roleId = this.rolesId.data[1].id;

        userModelManager.facilityId = 1;
        userModelManager.organizationId = +this.idOrganization; //+this.organizationStorageId;
        arrayDataUser.push(userModelManager);
        /*  arrayDataUser.push(userModel, userModelManager); */
      }
      this.dataSaveOrganization.users = arrayDataUser;
    }
  }

  save(template: TemplateRef<any>) {
    let arrayDataUnits: UnitModel[] = [];
    this.errorsOrganization = [];
    this.errorsFacility = [];
    this.errorsUsers = [];
    this.errorsUnits = [];
    this.errorsSteps = [];
    this.errorsArray = [];
    this.arrayOrg = [];
    this.arrayFacilities = [];
    this.arrayUsers = [];
    this.arrayUnits = [];

    const { units } = this.UnitsStepThreeForm.value;
    for (let unit of units) {
      const { name, facility, macAddress } = unit;
      if (
        name !== '' &&
        facility !== '' &&
        name !== null &&
        facility !== null
      ) {
        const unitModel = new UnitModel();
        unitModel.name = name;
        unitModel.organizationId = +this.idOrganization; //+this.organizationStorageId;
        //unitModel.suinNumber = siun;
        unitModel.facility = null;
        unitModel.facilityId = 1;
        unitModel.macAddress = macAddress;
        arrayDataUnits = [unitModel, ...arrayDataUnits];
      }
    }
    this.dataSaveOrganization.units = arrayDataUnits;
    this.OrganizationForm.patchValue({ riderecTo: false });

    this.appServices.SetupOrganization(this.dataSaveOrganization).subscribe(
      (data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, {
            id: 1,
            class: 'modal-lg modal-dialog-centered modal-fit succesfullyCustom',
          });

          let timerId = setInterval(() => this.modalRef.hide(), 3000);
          setTimeout(() => {
            clearInterval(timerId);
            this.closeSaveModal();
          }, 3000);
        }
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            if (key.includes('Users')) {
              this.errorsUsers.push(value);
            } else if (key.includes('Facilities')) {
              this.errorsFacility.push(value);
            } else if (key.includes('Units')) {
              this.errorsUnits.push(value);
            } else {
              this.errorsOrganization.push(value);
            }
          });
          this.marcarErrores();
        } else {
          this.errorsOrganization = [];
          this.errorsFacility = [];
          this.errorsUsers = [];
          this.errorsUnits = [];
          this.msjSinPasos = '';
          const arrayErrors = errors.split('\n');
          arrayErrors.forEach((element) => {
            if (element !== '') {
              if (element.includes('Facility-')) {
                const errorFacility = element.split('Facility-');
                if (errorFacility[1]) {
                  this.errorsFacility.push(errorFacility[1]);
                }
              } else if (element.includes('Users-')) {
                const errorUsers = element.split('Users-');
                if (errorUsers[1] !== '') {
                  this.errorsUsers.push(errorUsers[1]);
                }
              } else if (errors.includes('Units-')) {
                const errorUnits = element.split('Units-');
                if (errorUnits[1] !== '') {
                  this.errorsUnits.push(errorUnits[1]);
                }
              } else if (errors.includes('Organization-')) {
                const errorOrganization = element.split('Organization-');
                if (errorOrganization[1] !== '') {
                  this.errorsOrganization.push(errorOrganization[1]);
                }
              } else {
                this.errorsOrganization.push(errors);
              }
            }
          });
          if (
            this.errorsOrganization.length === 0 &&
            this.errorsFacility.length === 0 &&
            this.errorsUsers.length === 0 &&
            this.errorsUnits.length === 0
          ) {
            const valueError = errors.replace(/['"]+/g, '');
            this.msjSinPasos = valueError;
            //this.errorsArray.push(valueError);
            this.formStep = 1;
          } else {
            this.marcarErrores();
          }
        }
      }
    );
  }

  marcarErrores() {
    setTimeout(() => {
      if (this.errorsFacility.length > 0) {
        this.errorsSteps.push(2);
      }
      if (this.errorsUsers.length > 0) {
        this.errorsSteps.push(3);
      }
      if (this.errorsUnits.length > 0) {
        this.errorsSteps.push(4);
      }
      if (this.errorsOrganization.length > 0) {
        this.errorsSteps.push(1);
      }
      this.formStep = Math.min(...this.errorsSteps);

      if (this.formStep === 1) {
        this.errorsArray = this.errorsOrganization;
      } else if (this.formStep === 2) {
        this.errorsArray = this.errorsFacility;
      } else if (this.formStep === 3) {
        this.errorsArray = this.errorsUsers;
      } else {
        this.errorsArray = this.errorsUnits;
      }
    });
  }

  closeSaveModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.resetForm(this.OrganizationForm);
    this.OrganizationForm.patchValue({
      organization: '',
      phone: '',
      address: '',
      state: '',
      city: '',
    });
    this.resetForm(this.FacilityStepTwoForm);
    this.facilities.clear();
    this.facilityPosition = 1;
    this.addFacility(0);
    this.resetForm(this.UsersStepTwoForm);
    this.resetForm(this.UnitsStepThreeForm);
    this.units.clear();
    this.unitsPosition = 1;
    this.addUnit(0);
    this.formRiderecTo.patchValue({
      riderecTo: true,
    });
    this.formStep = 1;
    this.errorsFacility = [];
    this.errorsUsers = [];
    this.errorsUnits = [];
    this.errorsSteps = [];
    this.arrayFacility = [];
    this.arrayUsers = [];
    this.arrayUnits = [];
    this.arrayOrg = [];
    this.arrayFacilities = [];
    this.errorsArray = [];
    this.msjSinPasos = '';
    if (this.internalRol === true) {
      this.router.navigate(['/organization-list']);
    } else {
      if (this.userRole === 'OA') {
        this.authService.setOrganizationSetup('True');
        this.router.navigate(['/organization-profile/'+this.authService.getOrganizationId()]);
      }
    }
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  closeModalYes() {
    this.formRiderecTo.patchValue({
      riderecTo: true,
    });

    this.router.navigate([`/${localStorage.getItem('urlData')}`]);

    this.closeModal();
  }

  closeModalDeleteUser() {
    this.next = true;
    this.OrganizationForm.patchValue({ organization: '' });
    this.letout();
  }
  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  validatonMacAddress(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.appServices
        .GetIsUnitRegistered(control.value)
        .pipe(
          map((result) =>
            Boolean(result['data']) === true
              ? null
              : { macAddressRegistered: true }
          )
        );
    };
  }

  keyupUnitMacAddress(evt: KeyboardEvent, i: number, unit?: FormGroup) {
    //@ts-ignore
    let value = evt.target.value;
    /*
    unit.get('macAddress').setAsyncValidators([this.validatonMacAddress()]);
    unit.get('macAddress').updateValueAndValidity(); */

    if (value !== '') {
      unit.get('facility').setValidators([Validators.required]);
      unit.get('facility').updateValueAndValidity();

      unit.get('name').setValidators([Validators.required]);
      unit.get('name').updateValueAndValidity();
      this.activeVal[i] = true;
    } else {
      if (i === 0) {
        unit.get('facility').clearValidators();
        unit.get('facility').updateValueAndValidity();
        unit.get('name').clearValidators();
        unit.get('name').updateValueAndValidity();

        unit.get('macAddress').clearValidators();
        unit.get('macAddress').updateValueAndValidity();

        this.activeVal[i] = false;
      }
    }
  }

  /*   changeFacility(evt, unit?: FormGroup) {
    let value = evt.target.value;

    if (value !== '') {
      unit.get('name').setValidators([Validators.required]);
      unit.get('name').updateValueAndValidity();
    } else {
      if (
        unit.get('name').value === '' &&
        unit.get('macAddress').value === ''
      ) {
        unit.get('name').clearValidators();
        unit.get('name').updateValueAndValidity();
        unit.get('macAddress').clearValidators();
        unit.get('macAddress').updateValueAndValidity();
      }

      unit.get('facility').clearValidators();
      unit.get('facility').updateValueAndValidity();
    }
  } */
}
