import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PopupService } from 'src/app/services/popup.service';
import { Exit } from 'src/app/services/can-deactivate.guard';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
class Facility {
  id!: number;
  name!: string;
  phoneNumber!: number;
  organizationId!: number;
}
class Address {
  id!: number;
  address!: string;
  city!: string;
  stateId!: number;
  stateName!: string;
}
class Users {
  name!: string;
  email!: string;
  facility!: string;
  roleId!: string;
  facilityId!: number;
  organizationId!: number;
}
class Units {
  name!: string;
  suinNumber!: number;
  facility!: string;
  facilityId!: number;
  organizationId!: number;
  macAddress!: string;
}

class SaveData {
  facility!: Facility;
  address!: Address;
  users!: Users[];
  units!: Units[];
}

@Component({
  selector: 'app-add-facility',
  templateUrl: './add-facility.component.html',
  styleUrls: ['./add-facility.component.sass'],
})
export class AddFacilityComponent implements OnInit /*, Exit*/ {
  FacilityStepOneForm: FormGroup;
  UsersStepTwoForm: FormGroup;
  UnitsStepThreeForm: FormGroup;
  formRiderecTo: FormGroup;
  listState: any = [];
  position: number = 1;
  unitsPosition: number = 1;
  formStep: number = 1;
  listRoles: any[] = [];
  errorsFacility: any[] = [];
  errorsUsers: any[] = [];
  errorsUnits: any[] = [];
  errorsArray: any[] = [];
  arrayFacility: any[] = [];
  arrayUsers: any[] = [];
  arrayUnits: any[] = [];
  errorsSteps: number[] = [];
  modalRef: BsModalRef;
  listOrganization: any[] = [];

  facilityData: Facility = new Facility();
  addressData: Address = new Address();
  usersData: Users[] = [];
  unitsData: Units[] = [];
  dataSaveFacility: SaveData = new SaveData();
  next: boolean = false;
  msjSinPasos = '';
  internalRol: boolean = false;
  @ViewChild('exit') exit: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private popupService: PopupService,
    private appServices: AppServices,
    private authService: AuthService,
    readonly router: Router,
    private sideNavbarService: SideNavbarService,
    private modalService: BsModalService,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('FL');
    this.formRiderecTo = this.fb.group({
      riderecToFacility: [true],
    });

    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    let menu = this.sideNavbarService.rolsMenu$;
    let indexOrganizationList = menu.findIndex(
      (item) => item.link === '/facilities-list'
    );
    const indexUserInvite = menu.findIndex((item) =>
      item.link.includes('/facility-profile')
    );
    const orgProfile = menu.findIndex((item) => item.link === `/add-facility`);
    let indexMneChange = -1;
    if (indexOrganizationList !== -1) {
      indexMneChange = indexOrganizationList;
    } else if (indexUserInvite !== -1) {
      indexMneChange = indexUserInvite;
    } else if (orgProfile !== -1) {
      indexMneChange = orgProfile;
    }
    if (indexMneChange !== -1) {
      menu[indexMneChange].link = this.router.url;
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }
  /*letout(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.formRiderecTo.get('riderecToFacility').value) {
      return true;
    }
    this.openEditModal(this.exit);
    return true;
  }*/

  openEditModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-md modal-dialog-centered succesfullyCustom',
    });
  }

  public doSomething(ref: BsModalRef<boolean>) {

    !!ref && ref.hide();
  }

  ngOnInit(): void {
    //this.actionMenu.setSelectMenu('FL');

    this.buildFacilityStepOneForm();
    this.buildUsersStepTwoForm();
    this.buildUnitsStepThreeForm();
    this.addUser(0);
    this.addUnit(0);
    this.getStates();
    this.getRolesForSelect();
    if (this.internalRol) {
      this.getListOrganization();
      this.FacilityStepOneForm.controls['organizationId'].setErrors({
        required: true,
      });
    } else {
      this.FacilityStepOneForm.controls['organizationId'].setErrors(null);
    }
  }

  ngOnDestroy(): void {
    let menu = this.sideNavbarService.rolsMenu$;
    let index = menu.findIndex((item) => item.link.includes('/add-facility'));
    if (index !== -1) {
      menu[index].link = '/facilities-list';
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  getListOrganization() {
    this.appServices.getForSelectOrganization().subscribe((data) => {
      this.listOrganization = data['data'];
    });
  }

  buildFacilityStepOneForm(): void {
    const organizationIdProfile = localStorage.getItem('organizationIdProfile');

    this.FacilityStepOneForm = this.fb.group({
      organizationId: [
        organizationIdProfile !== 'null' ? organizationIdProfile : 0,
        [this.validatonOrganization()],
      ],
      facility: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
          ),
          /*Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/)*/
        ],
      ],
      address: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      state: ['', [Validators.required]],
      city: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
    });

    this.FacilityStepOneForm.valueChanges.subscribe((data) => {
      this.formRiderecTo.patchValue({
        riderecToFacility: false,
      });
    });
  }

  buildUsersStepTwoForm(): void {
    this.UsersStepTwoForm = this.fb.group({
      users: this.fb.array([]),
    });
  }
  buildUnitsStepThreeForm(): void {
    this.UnitsStepThreeForm = this.fb.group({
      units: this.fb.array([]),
    });

    /*this.UnitsStepThreeForm.valueChanges.subscribe(data => {

    });*/
  }

  get users(): FormArray {
    return this.UsersStepTwoForm.get('users') as FormArray;
  }

  newUser(): FormGroup {
    return this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      role: ['', [Validators.required]],
    });
  }

  addUser(i) {
    i == 0 && this.position == 1 ? (this.position = i) : this.position++;
    this.users.push(this.newUser());
  }

  save(template: TemplateRef<any>) {
    let arrayDataUnits: Units[] = [];
    this.errorsFacility = [];
    this.errorsUsers = [];
    this.errorsUnits = [];
    this.errorsSteps = [];
    this.arrayFacility = [];
    this.arrayUsers = [];
    this.arrayUnits = [];
    this.msjSinPasos = '';

    //let organizationId = this.authService.getOrganizationId();
    const { units } = this.UnitsStepThreeForm.value;
    const { organizationId } = this.FacilityStepOneForm.value;
    const organizacionId =
      organizationId === 0
        ? this.authService.getOrganizationId()
        : organizationId;
    for (let unit of units) {
      const { name, macAddress } = unit;
      const unitModel = new Units();
      unitModel.name = name;
      unitModel.organizationId = +organizacionId;
      unitModel.macAddress = macAddress;
      //unitModel.suinNumber = siun;
      arrayDataUnits = [unitModel, ...arrayDataUnits];
    }
    this.dataSaveFacility.units = arrayDataUnits;
    this.appServices.addFacility(this.dataSaveFacility).subscribe(
      (data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, {
            id: 1,
            class: 'modal-lg modal-dialog-centered modal-fit',
          });

          let timerId = setInterval(() => this.modalRef.hide(), 3000);
          setTimeout(() => {
            clearInterval(timerId);
            this.closeSaveModal();
          }, 3000);
        }
      },
      (error) => {
        const errors = JSON.parse(error);
        this.msjSinPasos = '';
        if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            if (key.includes('Users')) {
              this.errorsUsers.push(value);
            } else if (key.includes('Units')) {
              this.errorsUnits.push(value);
            } else {
              this.errorsFacility.push(value);
            }
          });
          this.marcarErrores();
        } else {
          this.errorsFacility = [];
          this.errorsUsers = [];
          this.errorsUnits = [];
          const arrayErrors = errors.split('\n');
          arrayErrors.forEach((element) => {
            if (element !== '') {
              if (element.includes('Facility-')) {
                const errorFacility = element.split('Facility-');
                if (errorFacility[1]) {
                  this.errorsFacility.push(errorFacility[1]);
                }
              } else if (element.includes('Users-')) {
                const errorUsers = element.split('Users-');
                if (errorUsers[1] !== '') {
                  this.errorsUsers.push(errorUsers[1]);
                }
              } else if (errors.includes('Units-')) {
                const errorUnits = element.split('Units-');
                if (errorUnits[1] !== '') {
                  this.errorsUnits.push(errorUnits[1]);
                }
              } else {
                this.errorsUnits.push(errors);
              }
            }
          });
          if (
            this.errorsUsers.length === 0 &&
            this.errorsFacility.length === 0 &&
            this.errorsUnits.length === 0
          ) {
            const valueError = errors.replace(/['"]+/g, '');
            this.msjSinPasos = valueError;
            //this.errorsArray.push(valueError);
            this.formStep = 1;
          } else {
            this.marcarErrores(errors);
          }
        }
      }
    );
  }

  marcarErrores(error?: string) {
    setTimeout(() => {
      if (this.errorsFacility.length > 0) {
        this.errorsSteps.push(1);
      }
      if (this.errorsUsers.length > 0) {
        this.errorsSteps.push(2);
      }
      if (this.errorsUnits.length > 0) {
        this.errorsSteps.push(3);
      }
      this.formStep = Math.min(...this.errorsSteps);

      if (this.formStep === 1) {
        this.errorsArray = this.errorsFacility;
      } else if (this.formStep === 2) {
        this.errorsArray = this.errorsUsers;
      } else {
        this.errorsArray = this.errorsUnits;
      }
    });
  }

  closeSaveModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.resetForm(this.FacilityStepOneForm);
    this.resetForm(this.UsersStepTwoForm);
    this.users.clear();
    this.units.clear();
    this.position = 1;
    this.unitsPosition = 1;
    this.addUser(0);
    this.addUnit(0);
    this.resetForm(this.UnitsStepThreeForm);
    this.FacilityStepOneForm.patchValue({
      organizationId: 0,
      facility: '',
      phone: '',
      address: '',
      state: '',
      city: '',
      riderecToFacility: true,
    });
    this.formRiderecTo.patchValue({
      riderecToFacility: true,
    });
    this.formStep = 1;
    this.errorsFacility = [];
    this.errorsUsers = [];
    this.errorsUnits = [];
    this.errorsSteps = [];
    this.arrayFacility = [];
    this.arrayUsers = [];
    this.arrayUnits = [];
    this.errorsArray = [];
    this.msjSinPasos = '';
  }

  removeUser(i: number) {
    this.position--;
    this.users.removeAt(i);
  }
  //Units Form

  get units(): FormArray {
    return this.UnitsStepThreeForm.get('units') as FormArray;
  }

  newUnit(): FormGroup {
    return this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      macAddress: new FormControl(
        '',
        [Validators.required],
        [this.validatonMacAddress()]
      ),
      //siun: ['', [Validators.required]],
    });
  }

  addUnit(i) {
    i == 0 && this.unitsPosition == 1
      ? (this.unitsPosition = i)
      : this.unitsPosition++;
    this.units.push(this.newUnit());
  }

  removeUnit(i: number) {
    this.unitsPosition--;
    this.units.removeAt(i);
  }
  //Form Steps
  backStep(step) {
    this.formStep = step;
    if (this.formStep === 1) {
      this.errorsArray = this.errorsFacility;
    } else if (this.formStep === 2) {
      this.errorsArray = this.errorsUsers;
    } else {
      this.errorsArray = this.errorsUnits;
    }
  }

  nextStep(step) {
    //let organizationId = this.authService.getOrganizationId();
    if (this.formStep === 1) {
      this.errorsArray = this.errorsUsers;
      const { facility, phone, address, state, city, organizationId } =
        this.FacilityStepOneForm.value;

      const organizacionId =
        organizationId === 0
          ? this.authService.getOrganizationId()
          : organizationId;
      this.facilityData.name = facility;
      this.facilityData.organizationId = +organizacionId;
      this.facilityData.phoneNumber = phone;

      this.addressData.address = address;
      this.addressData.city = city;
      this.addressData.stateId = +state;
      this.dataSaveFacility.facility = this.facilityData;
      this.dataSaveFacility.address = this.addressData;
      //this.errorsFacility = [];
    } else if (this.formStep === 2) {
      this.errorsArray = this.errorsUnits;
      const { organizationId } = this.FacilityStepOneForm.value;
      const organizacionId =
        organizationId === 0
          ? this.authService.getOrganizationId()
          : organizationId;
      let arrayDataUser: Users[] = [];
      const { users } = this.UsersStepTwoForm.value;
      for (let user of users) {
        const { role, email } = user;
        const userModel = new Users();
        userModel.email = email;
        userModel.organizationId = +organizacionId;
        userModel.roleId = role;
        userModel.facilityId = 1;
        arrayDataUser = [userModel, ...arrayDataUser];
      }
      this.dataSaveFacility.users = arrayDataUser;
      //this.errorsUsers = [];
    }
    this.formStep = step;
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  getStates() {
    this.appServices.getStates().subscribe((data) => {
      this.listState = data['data'];
    });
  }

  getRolesForSelect() {
    this.appServices
      .GetExternalRolesForSelectAddFacility()
      .subscribe((data) => {
        this.listRoles = data['data'];
      });
  }

  selectedState(event) {

  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  closeModalYes() {
    this.closeModal();
    this.formRiderecTo.patchValue({
      riderecToFacility: true,
    });
    this.router.navigate([`/${this.appServices.urlChange}`]);
  }

  validatonOrganization(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (!this.internalRol) {
        return null;
      } else {
        if (value !== '0') {
          return null;
        }
        return { required: true };
      }
    };
  }

  validatonMacAddress(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.appServices
        .GetIsUnitRegistered(control.value)
        .pipe(
          map((result) =>
            Boolean(result['data']) === true
              ? null
              : { macAddressRegistered: true }
          )
        );
    };
  }
}
