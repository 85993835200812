import { Component, OnInit } from '@angular/core';
import { AppServices } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export interface ResponseDataHistory {
  data: any,
  filterHistory: any,
  meta: any
}
@Component({
  selector: 'app-flushes-history',
  templateUrl: './flushes-history.component.html',
  styleUrls: ['./flushes-history.component.sass']
})
export class FlushesHistoryComponent implements OnInit {
  searchForm: FormGroup;
  activeFilters: boolean = false;
  unitId: number = 0;
  meta: any;
  previousDate: Date;
  bsValue: Date;
  isDisabled: boolean = false
  bsConfig: Partial<BsDatepickerConfig>;
  activeLess: boolean = false;
  dataHistory: ResponseDataHistory = {
    data: [],
    filterHistory: [],
    meta: [],
  };
  constructor(
    private appServices: AppServices,
    private route: ActivatedRoute,
    private router: Router,
    private sideNavbarService: SideNavbarService,
    private fb: FormBuilder,private actionMenu: ActionMenuService) {
    const unit = route.params['_value']
    this.unitId = Number(unit.id);

    this.searchForm = this.fb.group({
      date: [''],
      temperature: [],
      presure: ["0"],
      health: ["0"]
    })

    let menu = this.sideNavbarService.rolsMenu$;
    let indexOrganizationList = menu.findIndex((item) => item.link === '/units-dashboard');
    const indexUserInvite = menu.findIndex((item) => (item.link).includes('/flushes-history'));
    const indexUnitDetails = menu.findIndex((item) => (item.link).includes('/unit-details'));
    const orgProfile = menu.findIndex((item) => item.link === `/assign-new-unit`);
    let indexMneChange = -1;
    if (indexOrganizationList !== -1) {
      indexMneChange = indexOrganizationList;
    } else if (indexUserInvite !== -1) {
      indexMneChange = indexUserInvite;
    } else if (orgProfile !== -1) {
      indexMneChange = orgProfile;
    } else if (indexUnitDetails !== -1) {
      indexMneChange = indexUnitDetails;
    }
    if (indexMneChange !== -1) {
      menu[indexMneChange].link = this.router.url;
      this.sideNavbarService.rolsMenu$ = menu;
    }
    this.actionMenu.setSelectMenu('UD');
  }

  ngOnInit(): void {
    this.getListFlushesHistory(this.unitId);
    this.searchForm.get('date').valueChanges.subscribe(selectedDate => {
      this.search();
    })
  }

  ngOnDestroy(): void {
    let menu = this.sideNavbarService.rolsMenu$;
    let index = menu.findIndex((item) => (item.link).includes('/flushes-history'));
    if (index !== -1) {
      menu[index].link = '/units-dashboard';
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  filters() {
    this.activeFilters = !this.activeFilters;
  }

  getListFlushesHistory(unitId: number) {
    let paramsUrl = '/Units/GetFlushesHistory?UnitId=' + unitId + '&WithLastFlush=true';
    this.getDataHistory(paramsUrl)
  }

  getDataHistory(paramsUrl: string, pageSize: number = 5, pageNumber: number = 1) {
    this.appServices.getListFlushesHistory(paramsUrl, pageSize, pageNumber).subscribe(
      (data) => {
        this.dataHistory.meta = data['meta']
        this.dataHistory.data = data['data']
        const dataList = data['data']
        this.dataHistory.filterHistory = dataList.map((item) => {
          item['filterList'] = [
            { value: item.filter1, filter: 1 },
            { value: item.filter2, filter: 2 },
            /*{value: item.filter3, filter: 3 },
            {value: item.filter4, filter: 4 },*/
          ]
          return item['filterList']
        })
        //this.isDisabled = (this.dataHistory.meta.currentPage === this.dataHistory.meta.totalPages) ? true : false;
        this.isDisabled = (this.dataHistory.meta.totalPages <= 1) ? true : false;
      }
    )


  }

  onDateSelec(ev) { }

  urlService(): string {
    let paramsDate = moment(this.searchForm.get('date').value).format('YYYY-MM-DD');
    let paramsTemperature = this.searchForm.get('temperature').value
    let paramsPresure = this.searchForm.get('presure').value
    let paramsHealt = this.searchForm.get('health').value
    let paramsUrl = '/Units/GetFlushesHistory?UnitId=' + this.unitId + '&WithLastFlush=true';
    if (paramsDate !== 'Invalid date') {
      paramsUrl = paramsUrl + '&Date=' + paramsDate
    }
    if (paramsTemperature !== null) {
      paramsUrl = paramsUrl + '&SelenoidTemperature=' + paramsTemperature
    }
    if (paramsPresure !== '0') {
      paramsUrl = paramsUrl + '&PressureOnFilters=' + paramsPresure
    }
    if (paramsHealt !== '0') {
      paramsUrl = paramsUrl + '&Health=' + paramsHealt
    }
    return paramsUrl;
  }


  search(pageNumber: number = 1) {
    const paramsUrl =  this.urlService();
    this.getDataHistory(paramsUrl, 5, pageNumber)

  }

  async viewMore() {
    if (!this.isDisabled) {
      let pageNumber = this.dataHistory.meta.currentPage + 1;
      this.activeLess = true;
      if (this.dataHistory.meta.totalCount > 1) {
        this.isDisabled = true;
      }

      const paramsUrl =  this.urlService();
      this.appServices.getListFlushesHistory(paramsUrl, 5, pageNumber).subscribe(
        (data) => {


          this.dataHistory.meta = data['meta'];
          this.dataHistory.data = [...this.dataHistory.data, ...data['data']];

          const dataList = data['data'];
          const datF = dataList.map((item) => {
            item['filterList'] = [
              { value: item.filter1, filter: 1 },
              { value: item.filter2, filter: 2 },
              /*{value: item.filter3, filter: 3 },
              {value: item.filter4, filter: 4 },*/
            ]
            return item['filterList']
          })
          this.dataHistory.filterHistory = [...this.dataHistory.filterHistory, ...datF];
          /* this.dataHistory.meta = data['meta']
          this.dataHistory.data = data['data']
          const dataList = data['data'] */

          //this.isDisabled = (this.dataHistory.meta.currentPage === this.dataHistory.meta.totalPages) ? true : false;
          this.isDisabled = (this.dataHistory.meta.currentPage === this.dataHistory.meta.totalPages) ? true : false;
        }
      )

    }
  }


  async viewLess() {

    let pageNumber = this.dataHistory.meta.currentPage - 1;

    const paramsUrl =  this.urlService();
    this.appServices.getListFlushesHistory(paramsUrl, 5, pageNumber).subscribe(
      (data) => {
        if (pageNumber === 1){
          this.activeLess = false;
        }

        this.dataHistory.meta = data['meta'];

        if (pageNumber === 1){
          this.dataHistory.data = data['data'];
        }else{
          this.dataHistory.data = [...this.dataHistory.data, ...data['data']];
        }
        const dataList = data['data'];
        const datF = dataList.map((item) => {
          item['filterList'] = [
            { value: item.filter1, filter: 1 },
            { value: item.filter2, filter: 2 },
            /*{value: item.filter3, filter: 3 },
            {value: item.filter4, filter: 4 },*/
          ]
          return item['filterList']
        })
        if (pageNumber === 1){
          this.dataHistory.filterHistory =datF;
        }else{
          this.dataHistory.filterHistory = [...this.dataHistory.filterHistory, ...datF];
        }


      }
    )

    if (pageNumber !== 1){
      this.dataHistory.data.splice(this.dataHistory.data.length - 10, 10);
    }

    this.isDisabled = (this.dataHistory.meta.currentPage === this.dataHistory.meta.totalPages) ? true : false;
  }

}
