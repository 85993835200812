import { RolsEnum } from "./enum/rols.enum";

export class MenuModel {
    link: string;
    isActive: string;
    ngbTooltip: string;
    iconClass: string;
    idParams?: string | number | null = null;
}

export class RolMenuModel {
    rolMenu: { [role: string]: Array<MenuModel> };
}

const facilityUserId = () =>{
    const facility = localStorage.getItem('facilityId');
    let facilityId = (facility !== null && facility !== undefined) ? facility : '0';
    return +facilityId;
}

const facilityOrganizationId = () =>{
    const organization = localStorage.getItem('organizationId');
    let id = (organization !== null && organization !== undefined) ? organization : '0';
    return +id;
}

export const ROLSMENU: Array<RolMenuModel> = [
    {
        rolMenu: {
            FM: [
                {
                    link: '/assign-new-unit',
                    isActive: 'is-active',
                    ngbTooltip: 'Assign Units',
                    iconClass: 'icon icon-link',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            FA: [
                {
                    link: '/facility-profile',
                    isActive: 'is-active',
                    ngbTooltip: 'Facility Profile',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: facilityUserId()
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },
                {
                    link: '/invite-management',
                    isActive: 'is-active',
                    ngbTooltip: 'Invites Management',
                    iconClass: 'icon mail-icon',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            OM: [                
                {
                    link: '/facilities-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Facilities List',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },
                {
                    link: '/invite-management',
                    isActive: 'is-active',
                    ngbTooltip: 'Invites Management',
                    iconClass: 'icon mail-icon',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            OA: [
                {
                    link: '/set-up-organization',
                    isActive: 'is-active',
                    ngbTooltip: 'Setup Organization',
                    iconClass: 'icon icon-play-circle',
                    idParams: null
                },
                {
                    link: '/organization-profile',
                    isActive: 'is-active',
                    ngbTooltip: 'Organization Profile',
                    iconClass: 'icon icon-organization',
                    idParams: facilityOrganizationId(),
                },
                {
                    link: '/facilities-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Facilities List',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },
                {
                    link: '/invite-management',
                    isActive: 'is-active',
                    ngbTooltip: 'Invites Management',
                    iconClass: 'icon mail-icon',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            CS: [
                {
                    link: '/organization-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Organizations List',
                    iconClass: 'icon icon-organization',
                    idParams: null
                },
                {
                    link: '/facilities-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Facilities List',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'External Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },               
                {
                    link: '/unassigned-units',
                    isActive: 'is-active',
                    ngbTooltip: 'Unassigned Units',
                    iconClass: 'icon unassigned-units-icon',
                    idParams: null
                },
                {
                    link: '/internal-users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Internal Users List',
                    iconClass: 'icon icon-internal-users',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            SM: [
                {
                    link: '/organization-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Organizations List',
                    iconClass: 'icon icon-organization',
                    idParams: null
                },
                {
                    link: '/facilities-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Facilities List',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'External Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },
                {
                    link: '/unassigned-units',
                    isActive: 'is-active',
                    ngbTooltip: 'Unassigned Units',
                    iconClass: 'icon unassigned-units-icon',
                    idParams: null
                },
                {
                    link: '/internal-users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Internal Users List',
                    iconClass: 'icon icon-internal-users',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            SA: [
                {
                    link: '/organization-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Organizations List',
                    iconClass: 'icon icon-organization',
                    idParams: null
                },
                {
                    link: '/facilities-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Facilities List',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'External Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },
                {
                    link: '/unassigned-units',
                    isActive: 'is-active',
                    ngbTooltip: 'Unassigned Units',
                    iconClass: 'icon unassigned-units-icon',
                    idParams: null
                },
                {
                    link: '/internal-users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Internal Users List',
                    iconClass: 'icon icon-internal-users',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ],
            VT: [
                {
                    link: '/organization-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Organizations List',
                    iconClass: 'icon icon-organization',
                    idParams: null
                },
                {
                    link: '/facilities-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Facilities List',
                    iconClass: 'icon icon-facilities-organizations',
                    idParams: null
                },
                {
                    link: '/units-dashboard',
                    isActive: 'is-active',
                    ngbTooltip: 'Units Hub',
                    iconClass: 'icon icon-dhub2',
                    idParams: null
                },
                {
                    link: '/users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'External Users List',
                    iconClass: 'icon icon-users',
                    idParams: null
                },               
                {
                    link: '/unassigned-units',
                    isActive: 'is-active',
                    ngbTooltip: 'Unassigned Units',
                    iconClass: 'icon unassigned-units-icon',
                    idParams: null
                },
                {
                    link: '/internal-users-list',
                    isActive: 'is-active',
                    ngbTooltip: 'Internal Users List',
                    iconClass: 'icon icon-internal-users',
                    idParams: null
                },
                {
                    link: '/account-settings',
                    isActive: 'is-active',
                    ngbTooltip: 'Account Settings',
                    iconClass: 'icon icon-account',
                    idParams: null
                },
                {
                    link: '/saving-statistics',
                    isActive: 'is-active',
                    ngbTooltip: 'Saving Statistics',
                    iconClass: 'icon bar-chart-icon',
                    idParams: null
                }
            ]
        }
    }
]