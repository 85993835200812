import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppServices } from 'src/app/services/api.service';
import { EncrDecrService } from 'src/app/services/encrypt.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.sass']
})
export class RecoverPasswordComponent implements OnInit {
  token: string = '';
  email: string = '';
  recoverForm: FormGroup;
  public msgError: string = '';
  public msgErrorList: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private appService: AppServices,
    private EncrDecr: EncrDecrService) {

    if (localStorage.getItem('jwt')) {
      this.router.navigate(['/dashboard']);
    }

    this.route
      .paramMap
      .subscribe(
        (param: Params) => {
          const { ...other } = param;

          if (other.params?.token) {
            this.token = other.params.token;
            this.email = other.params.email;

            this.token = this.token.replace(/-/gi, "/");
            this.email = this.email.replace(/-/gi, "/");


          } else {

            //this.router.navigate['/login'];
          }
        }
      )
  }

  ngOnInit(): void {
    this.recoverForm = this.fb.group({
      password: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
      passwordc: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
    }, {
      validator: this.MatchPassword
    });
  }

  MatchPassword(abstractControl: AbstractControl) {
    let password = abstractControl.get('password').value;
    let passwordc = abstractControl.get('passwordc').value;
    if (password != passwordc) {
      abstractControl.get('passwordc').setErrors({
        MatchPassword: true
      })
    } else {
      return null
    }
  }

  saveChange() {
    const { password, passwordc } = this.recoverForm.value;
    const data = {
      token: this.token,
      email: this.email,
      password: this.EncrDecr.set(
        environment.key_login,
        password),
      confirmPassword: this.EncrDecr.set(
        environment.key_login,
        passwordc)
    }
    this.appService.RecoverPassword(data).subscribe(
      (data) => {
        if (data) {
          this.router.navigate(['/recover-password-email']);
        }
      }, error => {
        this.msgError = '';
        this.msgErrorList = [];
        let errors = JSON.parse(error);
        if (typeof errors === 'string') {
          this.msgError = errors;
        } else if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            //@ts-ignore
            const count = value.length;
            for (let i = 0; i < count; i++) {
              this.msgErrorList.push(value[i])
            }
          })
        }
      }
    )
  }
}
