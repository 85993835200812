    <div class="container">
        <div class="col-xs-12 col-sm-12 col-md-12 text-center ">
            <img class="logo" [src]="'assets/images/logo-janway.svg'" alt="Janway Logo" width="150px" style="margin-bottom: 0px;" routerLink="/">
            <h3>Your password<span class="accent-text"> has been <br> change</span> successfully!</h3>
            <div class="contain-btn">
                <button class="btn btn-primary btn-block btn-janway"
                 (click)="goLogin()">
                    GO TO JANWAY
                </button>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-4 offset-md-4 col-lg-6 offset-lg-3 maxwidth">
                    <img class="logo" [src]="'assets/images/enthusiastic-pana.svg'" alt="Janway Email" width="65%">
                </div>
            </div>
        </div>
    </div>
