import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-register-unit',
  templateUrl: './register-unit.component.html',
  styleUrls: ['./register-unit.component.sass']
})
export class RegisterUnitComponent implements OnInit {
  unitsForm: FormGroup;
  position: number = 1;
  modalRef: BsModalRef | null;

  constructor(private fb:FormBuilder, private modalService: BsModalService) {
    this.unitsForm = this.fb.group({
      name: '',
      units: this.fb.array([]) ,
    });
  }

  ngOnInit(): void {
    this.addQuantity(0);
  }

  units() : FormArray {
    return this.unitsForm.get("units") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      mac: '',
      siun: '',
      organization: '',
    })
  }

  addQuantity(i) {
    i == 0 && this.position == 1 ? this.position = i : this.position++;
    this.units().push(this.newQuantity());
  }

  removeQuantity(i:number) {
    this.position--;
    this.units().removeAt(i);
  }

  onSubmit() {

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg modal-dialog-centered modal-fit' });
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.hide();
    this.modalRef = null;
  }
}
