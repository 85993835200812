import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { RolsEnum } from '@Models/enum/rols.enum';
import { internalsRol } from '@Models/role.model';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export interface ResponseDataFacilities {
  data: any;
  meta: any;
}

@Component({
  selector: 'app-facilities-list',
  templateUrl: './facilities-list.component.html',
  styleUrls: ['./facilities-list.component.sass'],
})
export class FacilitiesListComponent implements OnInit {
  activeFilters: boolean = false;
  allUnitsClassActive: boolean = true;
  onlineClassActive: boolean = false;
  OfflineClassActive: boolean = false;
  isDisabled: boolean = false;
  internalRol: boolean = false;
  activeLess: boolean = false;
  facilityForm: FormGroup;
  searchAdvancedFrom: FormGroup;
  userRole: RolsEnum = RolsEnum.All;
  rowActual;
  modalRef: BsModalRef | null;
  dataFacility: ResponseDataFacilities = {
    data: [],
    meta: [],
  };
  listOrganization: any = [];
  listState: any = [];
  mensajeError = '';
  msgError = [];
  messageModal: string = '';
  organizationStorageId = this.authService.getOrganizationId();
  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private appServices: AppServices,
    private authService: AuthService,
    private toastrService: ToastrService,
    private sideNavbarService: SideNavbarService,
    private router: Router,
    private actionMenu: ActionMenuService
  ) {
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    this.userRole = this.authService.authStatus.getValue().role;
    let menu = this.sideNavbarService.rolsMenu$;
    let indexOrganizationList = menu.findIndex(
      (item) => item.link === '/facilities-list'
    );
    const indexUserInvite = menu.findIndex((item) =>
      item.link.includes('/facility-profile')
    );
    const orgProfile = menu.findIndex((item) => item.link === `/add-facility`);
    let indexMneChange = -1;
    if (indexOrganizationList !== -1) {
      indexMneChange = indexOrganizationList;
    } else if (indexUserInvite !== -1) {
      indexMneChange = indexUserInvite;
    } else if (orgProfile !== -1) {
      indexMneChange = orgProfile;
    }
    if (indexMneChange !== -1) {
      menu[indexMneChange].link = this.router.url;
      this.sideNavbarService.rolsMenu$ = menu;
    }
    this.actionMenu.setSelectMenu('FL');
  }

  ngOnInit(): void {
    const rolInternal = this.authService.getInternalRole();
    const organizationIdProfile = localStorage.getItem('organizationIdProfile');

    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    let organizationId =
      organizationIdProfile !== 'null'
        ? organizationIdProfile
        : this.internalRol
        ? '0'
        : +this.organizationStorageId;

    this.activeFilters =
      localStorage.getItem('urlSearch') === null ? false : true;
    let listSearch =
      JSON.parse(localStorage.getItem('paramSearch')) === null
        ? { name: '', state: 0, city: '', organization: 0 }
        : JSON.parse(localStorage.getItem('paramSearch'));

    this.searchAdvancedFrom = this.fb.group({
      name: [listSearch.name],
      state: [listSearch.state],
      city: [listSearch.city],
      organization: [
        listSearch.organization === ''
          ? +organizationId
          : +listSearch.organization,
      ],
    });
    this.buildFormFacility();
    this.activeFilters =
      localStorage.getItem('urlSearch') === null ? false : true;

    /* if (organizationIdProfile !== 'null') {
      this.activeFilters = true;
      this.getAllFacility();
    } else { */

    let listpage =
      JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;

    if (listpage) {
      this.search();
    } else {
      this.dataFacility = JSON.parse(localStorage.getItem('listRecord'));
    }

    //}

    if (this.internalRol) {
      this.getListOrganization();
    }
    this.getStates();

    if (typeof(this.dataFacility.meta.currentPage)  === "number") {
      if (this.dataFacility.meta.currentPage === 1) {
        this.isDisabled = false;
        this.activeLess = false;
      } else if (
        this.dataFacility.meta.totalPages === this.dataFacility.meta.currentPage
      ) {
        this.isDisabled = true;
        this.activeLess = true;
      } else {
        this.isDisabled = false;
        this.activeLess = true;
      }
    } else {
      this.isDisabled = false;
      this.activeLess = false;
    }

  }

  ngOnDestroy(): void {
    let menu = this.sideNavbarService.rolsMenu$;
    let index = menu.findIndex((item) =>
      item.link.includes('/facilities-list')
    );
    if (index !== -1) {
      menu[index].link = '/facilities-list';
      this.sideNavbarService.rolsMenu$ = menu;
    }
  }

  buildFormFacility() {
    this.facilityForm = this.fb.group({
      facilityName: ['', [Validators.required]],
      units: ['', [Validators.required]],
      users: ['', [Validators.required]],
      city: ['', [Validators.required]],
    });
  }

  onSubmit() {}

  openActivateModal(activate: TemplateRef<any>, facility: any, index) {
    this.rowActual = index;
    this.mensajeError = '';
    this.msgError = [];
    this.modalRef = this.modalService.show(activate, {
      id: facility.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  openModal(template: TemplateRef<any>, facility: any, index) {
    this.rowActual = index;
    this.mensajeError = '';
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: facility.id,
      class: 'modal-md modal-dialog-centered modal-fit  customListModal',
    });
  }

  openEditModal(template: TemplateRef<any>) {
    this.mensajeError = '';
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-lg modal-dialog-centered modal-fit',
    });
  }

  async closeFirstModal(succesfully) {
    if (!this.modalRef) {
      return;
    }

    const idFcility = {
      id: this.modalRef.id,
    };

    this.appServices.deactivateFacility(idFcility).subscribe(
      (dataResponse) => {
        this.getAllFacility();
        this.modalRef.hide();
        this.modalRef = null;
        this.mensajeError = '';
        this.msgError = [];
        this.messageModal = 'deactivated';
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        //setTimeout(this.modalRef.hide,5000)
        this.dataFacility.data[this.rowActual].status = 'Inactive';
        this.dataFacility = this.dataFacility;
        localStorage.setItem('listRecord', JSON.stringify(this.dataFacility));
        let timerId = setInterval(() => this.closeSaveModal(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
      },
      (error) => {
        let errors = JSON.parse(error);

        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.msgError = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                this.msgError.push(valueError);
              }
            });
          }
          if (this.msgError.length === 0 && errors !== '') {
            this.mensajeError = errors;
          }
        } else {
          this.mensajeError = errors;
        }
      }
    );
  }

  async closeActivateModal(succesfully) {
    if (!this.modalRef) {
      return;
    }
    const idFcility = {
      id: this.modalRef.id,
    };

    this.appServices.activateFacility(idFcility).subscribe(
      (dataResponse) => {
        this.getAllFacility();
        this.modalRef.hide();
        this.dataFacility.data[this.rowActual].status = 'Active';
        this.dataFacility = this.dataFacility;
        localStorage.setItem('listRecord', JSON.stringify(this.dataFacility));
        //this.modalRef = null;
        this.messageModal = 'activated';
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        //setTimeout(this.modalRef.hide,5000)
         let timerId = setInterval(() => this.closeSaveModal(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.msgError = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                this.msgError.push(valueError);
              }
            });
          }
          if (this.msgError.length === 0 && errors !== '') {
            this.mensajeError = errors;
          }
        } else {
          this.mensajeError = errors;
        }
      }
    );
  }

  async close() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  filters() {
    this.activeFilters = !this.activeFilters;
  }

  getAllFacility() {
    let organizationId = +this.organizationStorageId;

    const urlEndPoint = this.urlService();

    let listpage =
      JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;

    if (listpage) {

      this.appServices.getAllFacility(urlEndPoint).subscribe(
        (data) => {
          this.dataFacility.data = data['data'];
          this.dataFacility.meta = data['meta'];
          this.isDisabled =
            this.dataFacility.meta.currentPage ===
            this.dataFacility.meta.totalPages
              ? true
              : false;
        },
        (error) => {

          this.isDisabled = true;
        }
      );
    } else {
      this.dataFacility = JSON.parse(localStorage.getItem('listRecord'));
    }
  }

  urlService(): string {
    let url = '/Facilities/GetAll?PageSize=5';
    let paramName = this.searchAdvancedFrom.get('name').value;
    let paramState = Number(this.searchAdvancedFrom.get('state').value);
    let paramsCity = this.searchAdvancedFrom.get('city').value;
    let paramsOrganization = Number(
      this.searchAdvancedFrom.get('organization').value
    );

    paramsOrganization = this.internalRol
      ? paramsOrganization
      : +this.organizationStorageId;
    if (paramsOrganization !== 0) {
      url = url + '&OrganizationId=' + paramsOrganization;
    }

    if (!this.allUnitsClassActive) {
      const status = this.onlineClassActive ? true : false;
      url = url + '&Status=' + status;
    }

    if (paramState !== 0) {
      url = url + '&StateId=' + paramState;
    }

    if (paramsCity !== '') {
      url = url + '&City=' + paramsCity;
    }

    if (paramName !== '') {
      url = url + '&Name=' + paramName;
    }


    if (
      paramState !== 0 ||
      paramsCity !== '' ||
      paramName !== '' ||
      paramsOrganization !== 0 ||
      !this.allUnitsClassActive
    ) {
      localStorage.setItem('urlSearch', url);
      localStorage.setItem(
        'paramSearch',
        JSON.stringify({
          name: paramName,
          state: paramState,
          city: paramsCity,
          organization: paramsOrganization,
        })
      );

    }

    return url;
  }

  allFacilities() {
    this.allUnitsClassActive = true;
    this.onlineClassActive = false;
    this.OfflineClassActive = false;
    this.isDisabled = false;
    let url = '/Facilities/GetAll?PageSize=5';
    this.appServices.getAllFacility(url).subscribe((data) => {
      this.dataFacility.data = data['data'];
      this.dataFacility.meta = data['meta'];
      this.isDisabled =
        this.dataFacility.meta.currentPage === this.dataFacility.meta.totalPages
          ? true
          : false;
    });
  }

  onlineFacilities(option: string) {
    this.allUnitsClassActive = false;
    this.isDisabled = false;
    this.onlineClassActive = option === 'Active' ? true : false;
    this.OfflineClassActive = option === 'Inactive' ? true : false;
    let Status = option === 'Active' ? true : false;
    let url = '/Facilities/GetAll?PageSize=5&Status=' + Status;
    this.appServices.getAllFacility(url).subscribe((data) => {
      this.dataFacility.data = data['data'];
      this.dataFacility.meta = data['meta'];
      this.isDisabled =
        this.dataFacility.meta.currentPage === this.dataFacility.meta.totalPages
          ? true
          : false;
    });
  }

  search() {
    this.isDisabled = false;
    let urlService = this.urlService();
    if (urlService) {
      this.appServices.getAllFacility(urlService).subscribe(
        (data) => {
          this.dataFacility.data = data['data'];
          this.dataFacility.meta = data['meta'];
          this.isDisabled =
            this.dataFacility.meta.totalPages <= 1 ? true : false;
          /*
        if (this.dataFacility.meta.totalPages === 1) {
          this.isDisabled = true;
          this.activeLess = false;
        } */
        },
        (error) => {

          this.isDisabled = true;
        }
      );
    }
  }

  async viewMore() {
    /* if (this.activeFilters) {
      this.search();
    } else { */
    let pageSize = 5;
    if (!this.isDisabled) {
      let pageNumber = this.dataFacility.meta.currentPage + 1;
      let urlService = this.urlService();
      this.activeLess = true;
      if (this.dataFacility.meta.totalCount === 1) {
        this.isDisabled = true;
        this.activeLess = false;
      }
      if (urlService) {
        let dataViewMore = await this.appServices
          .getAllFacility(urlService, pageNumber)
          .toPromise();
        this.dataFacility.meta = dataViewMore['meta'];
        this.dataFacility.data = [
          ...this.dataFacility.data,
          ...dataViewMore['data'],
        ];
      }
    }
    this.isDisabled =
      this.dataFacility.meta.currentPage === this.dataFacility.meta.totalPages
        ? true
        : false;
    localStorage.setItem('listRecord', JSON.stringify(this.dataFacility));
  }

  async viewLess() {
    let pageNumber = this.dataFacility.meta.currentPage - 1;

    let urlService = this.urlService();
    if (this.dataFacility.meta.totalCount === 1) {
      this.isDisabled = true;
    }

    if (pageNumber === 1) {
      this.activeLess = false;
    }

    if (urlService) {
      let dataViewMore = await this.appServices
        .getAllFacility(urlService, pageNumber)
        .toPromise();
      this.dataFacility.meta = dataViewMore['meta'];
      this.dataFacility.data = [
        ...this.dataFacility.data,
        ...dataViewMore['data'],
      ];

      this.dataFacility.data.splice(this.dataFacility.data.length - 10, 10);
    }

    this.isDisabled =
      this.dataFacility.meta.currentPage === this.dataFacility.meta.totalPages
        ? true
        : false;

    localStorage.setItem('listRecord', JSON.stringify(this.dataFacility));
  }

  navigateLink(facility: any) {
    localStorage.setItem('listRecord', JSON.stringify(this.dataFacility));
    this.router.navigate(['/facility-profile/' + facility.id]);
  }

  getListOrganization() {
    this.appServices.getForSelectOrganization().subscribe((data) => {
      this.listOrganization = data['data'];
    });
  }

  getStates() {
    this.appServices.getStates().subscribe((data) => {
      this.listState = data['data'];
    });
  }
  classInputFilter(): string {
    if (
      this.userRole === RolsEnum.ORGANIZATION_ADMINISTRATOR ||
      this.userRole === RolsEnum.ORGANIZATION_MANAGER
    ) {
      return 'col-6 col-sm-3 col-md-3';
    } else {
      return !this.internalRol
        ? 'col-6 col-sm-3 col-md-3'
        : 'col-6 col-sm-3 col-md-3';
    }
  }

  closeSaveModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }
}
