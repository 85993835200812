<div class="side-navbar">
    <ng-container>
        <ul class="nav top-menu flex-column house-icon">
            <li class="nav-item" [routerLink]="['/dashboard']" [routerLinkActive]="['is-active']" placement="right"
                ngbTooltip="Dashboard">
                <span class="icon icon-dashboard"></span>
            </li>
        </ul>

        <ul class="nav middle-menu flex-column" [ngClass]="{'top-ul-menu': role.role === 'SA' || role.role === 'SM'
        || role.role === 'CS' || role.role === 'OA'}">
            <!-- Rol FM -->
            <!--Assign Units-->
            <li *ngIf="role.role === 'FM'" class="nav-item" routerLink="/assign-new-unit"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Assign Units"
                (click)="cleanLocalStorePage('/assign-new-unit')"> <span ngClass="icon icon-link"></span> </li>
            <!--Units Hub-->
            <li *ngIf="role.role === 'FM'" class="nav-item nav-item top-li-menu" routerLink="/units-dashboard"
                placement="right" [routerLinkActive]="['is-active']" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <!--Account Settings-->
            <li *ngIf="role.role === 'FM'" class="nav-item" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>
            <!--Saving Statistics-->
            <li *ngIf="role.role === 'FM'" class="nav-item" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon"></span> </li>
            <!-- End Rol FM -->

            <!-- Rol FA -->
            <!--Facility Profile-->
            <li *ngIf="role.role === 'FA'" class="nav-item top-li-menu"
                [routerLink]="['/facility-profile', facilityIdValue]" [routerLinkActive]="['is-active']"
                placement="right" ngbTooltip="Facility Profile" (click)="cleanLocalStorePage('/facility-profile')">
                <span ngClass="icon icon-facilities-organizations"></span>
            </li>

            <!--Units Hub-->
            <li *ngIf="role.role === 'FA'" class="nav-item" routerLink="/units-dashboard"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>

            <!--Users List-->
            <li *ngIf="role.role === 'FA' && activeGroup !=='UL'" class="nav-item" routerLink="/users-list"
                placement="right" ngbTooltip="Users List" (click)="cleanLocalStorePage('/users-list')"> <span
                    ngClass="icon icon-users"></span> </li>
            <li *ngIf="role.role === 'FA'  && activeGroup ==='UL'" class="nav-item nav-item top-li-menu is-active"
                routerLink="/users-list" placement="right" ngbTooltip="Users List"
                (click)="cleanLocalStorePage('/users-list')"> <span ngClass="icon icon-users"></span> </li>

            <!--Invites Management-->
            <li *ngIf="role.role === 'FA' && activeGroup !=='IM'" class="nav-item top-li-menu" placement="right"
                routerLink="/invite-management" ngbTooltip="Invites Management"
                (click)="cleanLocalStorePage('/invite-management')"> <span ngClass="icon mail-icon"></span> </li>
            <li *ngIf="role.role === 'FA'  && activeGroup ==='IM'" class="nav-item top-li-menu is-active"
                routerLink="/invite-management" placement="right" ngbTooltip="Invites Management"
                (click)="cleanLocalStorePage('/invite-management')">
                <span ngClass="icon mail-icon"></span>
            </li>

            <!--Account Settings-->
            <li *ngIf="role.role === 'FA'" class="nav-item" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>

            <!--Saving Statistics-->
            <li *ngIf="role.role === 'FA'" class="nav-item" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon" ></span> </li>

            <!-- End Rol FA -->

            <!-- Rol OM -->
            <!--Facilities List-->
            <li *ngIf="role.role === 'OM' && activeGroup !=='FL'" class="nav-item top-li-menu"
                routerLink="/facilities-list" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span> </li>
            <li *ngIf="role.role === 'OM' && activeGroup ==='FL'" class="nav-item top-li-menu is-active"
                routerLink="/facilities-list" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span> </li>

            <!--Units Hub-->
            <li *ngIf="role.role === 'OM' && activeGroup !=='UD'" class="nav-item top-li-menu"
                routerLink="/units-dashboard" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <li *ngIf="role.role === 'OM' && activeGroup ==='UD'" class="nav-item top-li-menu is-active"
                routerLink="/units-dashboard" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>

            <!--Internal Users List-->
            <li *ngIf="role.role === 'OM' && activeGroup !=='UL'" class="nav-item top-li-menu" routerLink="/users-list"
                placement="right" ngbTooltip="Users List" (click)="cleanLocalStorePage('/users-list')">
                <span ngClass="icon icon-users"></span>
            </li>
            <li *ngIf="role.role === 'OM' && activeGroup ==='UL'" class="nav-item top-li-menu is-active"
                routerLink="/users-list" placement="right" ngbTooltip="Users List"
                (click)="cleanLocalStorePage('/users-list')">
                <span ngClass="icon icon-users"></span>
            </li>

            <!--Invites Management-->
            <li *ngIf="role.role === 'OM'  && activeGroup !=='IM'" class="nav-item top-li-menu"
                routerLink="/invite-management" placement="right" ngbTooltip="Invites Management"
                (click)="cleanLocalStorePage('/invite-management')">
                <span ngClass="icon mail-icon"></span>
            </li>
            <li *ngIf="role.role === 'OM'  && activeGroup ==='IM'" class="nav-item top-li-menu is-active"
                routerLink="/invite-management" placement="right" ngbTooltip="Invites Management"
                (click)="cleanLocalStorePage('/invite-management')">
                <span ngClass="icon mail-icon"></span>
            </li>

            <!--Account Settings-->
            <li *ngIf="role.role === 'OM'" class="nav-item" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>
            <!--Saving Statistics-->
            <li *ngIf="role.role === 'OM'" class="nav-item" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon"></span> </li>
            <!-- End Rol OM -->

            <!-- Rol OA -->
            <!--Setup Organization-->
            <li *ngIf="role.role === 'OA' && activeGroup !=='SO' && (organizationSetup === 'False')" class="nav-item top-li-menu"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Setup Organization"
                (click)="cleanLocalStorePage('/set-up-organization/'+idOrganization)" >
                <span ngClass="icon icon-play-circle"></span>
            </li>
            <li *ngIf="role.role === 'OA' && activeGroup ==='SO' && (organizationSetup === 'False')" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Setup Organization"
                (click)="cleanLocalStorePage('/set-up-organization/'+idOrganization)">
                <span ngClass="icon icon-play-circle"></span>
            </li>

            <!--Organization Profile-->
            <li *ngIf="role.role === 'OA' && activeGroup !=='OL' && (organizationSetup !== 'False')" class="nav-item top-li-menu"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Organization Profile"
                (click)="cleanLocalStorePage('/organization-profile/'+facilityOrganizationValue)"> <span
                    ngClass="icon icon-organization"></span>
            </li>
            <li *ngIf="role.role === 'OA' && activeGroup ==='OL' && (organizationSetup !== 'False')" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Organization Profile"
                (click)="cleanLocalStorePage('/organization-profile/'+facilityOrganizationValue)"> <span
                    ngClass="icon icon-organization"></span>
            </li>

            <!--Facilities List-->
            <li *ngIf="role.role === 'OA' && activeGroup !=='FL'" class="nav-item top-li-menu"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span> </li>
            <li *ngIf="role.role === 'OA' && activeGroup ==='FL'" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span> </li>

            <!--Units Hub-->
            <li *ngIf="role.role === 'OA' && activeGroup !=='UD'" class="nav-item top-li-menu"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <li *ngIf="role.role === 'OA' && activeGroup ==='UD'" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>

            <!--Internal Users List-->
            <li *ngIf="role.role === 'OA' && activeGroup !== 'UL'" class="nav-item top-li-menu"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Users List"
                (click)="cleanLocalStorePage('/users-list')"> <span ngClass="icon icon-users"></span> </li>
            <li *ngIf="role.role === 'OA' && activeGroup === 'UL'" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Users List"
                (click)="cleanLocalStorePage('/users-list')"> <span ngClass="icon icon-users"></span> </li>

            <!--Invites Management-->
            <li *ngIf="role.role === 'OA' && activeGroup !== 'IM'" class="nav-item top-li-menu"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Invites Management"
                (click)="cleanLocalStorePage('/invite-management')">
                <span ngClass="icon mail-icon"></span>
            </li>
            <li *ngIf="role.role === 'OA'  && activeGroup === 'IM'" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Invites Management"
                (click)="cleanLocalStorePage('/invite-management')">
                <span ngClass="icon mail-icon"></span>
            </li>
            <!--Account Settings-->
            <li *ngIf="role.role === 'OA'" class="nav-item top-li-menu" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>
            <!--Saving Statistics-->
            <li *ngIf="role.role === 'OA'" class="nav-item top-li-menu" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon" ></span> </li>
            <!-- End Rol OA -->


            <!-- Rol CS -->
            <!--Organizations List-->
            <li *ngIf="role.role === 'CS'  && activeGroup !== 'OL'" class="nav-item top-li-menu" placement="right"
                ngbTooltip="Organizations List" (click)="cleanLocalStorePage('/organization-list')"> <span
                    ngClass="icon icon-organization"></span>
            </li>
            <li *ngIf="role.role === 'CS' && activeGroup === 'OL'" class="nav-item top-li-menu is-active"
                placement="right" ngbTooltip="Organizations List" (click)="cleanLocalStorePage('/organization-list')">
                <span ngClass="icon icon-organization"></span>
            </li>

            <!--Facilities List-->
            <li *ngIf="role.role === 'CS' && activeGroup !== 'FL'" class="nav-item top-li-menu"
                routerLink="/facilities-list" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span>
            </li>
            <li *ngIf="role.role === 'CS' && activeGroup === 'FL'" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span>
            </li>
            <!--Units Hub-->
            <li *ngIf="role.role === 'CS' && activeGroup !== 'UD'" class="nav-item top-li-menu"
                routerLink="/units-dashboard" [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <li *ngIf="role.role === 'CS' && activeGroup === 'UD'" class="nav-item top-li-menu is-active"
                routerLink="/units-dashboard" [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>

            <!--External Users List-->
            <li *ngIf="role.role === 'CS' && activeGroup !== 'UL'" class="nav-itemtop-li-menu" placement="right"
                ngbTooltip="Users List" (click)="cleanLocalStorePage('/users-list')"> <span
                    ngClass="icon icon-users"></span> </li>
            <li *ngIf="role.role === 'CS' && activeGroup === 'UL'" class="nav-itemtop-li-menu is-active"
                placement="right" ngbTooltip="Users List" (click)="cleanLocalStorePage('/users-list')"> <span
                    ngClass="icon icon-users"></span> </li>

            <!--Unassigned Units-->
            <li *ngIf="role.role === 'CS' && activeGroup !== 'UU'" class="nav-item top-li-menu" placement="right"
                ngbTooltip="Unassigned Units" (click)="cleanLocalStorePage('/unassigned-units')"> <span
                    ngClass="icon unassigned-units-icon"></span>
            </li>
            <li *ngIf="role.role === 'CS' && activeGroup === 'UU'" class="nav-item top-li-menu is-active"
                placement="right" ngbTooltip="Unassigned Units" (click)="cleanLocalStorePage('/unassigned-units')">
                <span ngClass="icon unassigned-units-icon"></span>
            </li>

            <!--Internal Users List-->
            <li *ngIf="role.role === 'CS' && activeGroup !== 'IU'" class="nav-item top-li-menu" placement="right"
                ngbTooltip="Internal Users List" (click)="cleanLocalStorePage('/internal-users-list')"> <span
                    ngClass="icon icon-internal-users"></span>
            </li>
            <li *ngIf="role.role === 'CS' && activeGroup === 'IU'" class="nav-item top-li-menu is-active"
                placement="right" ngbTooltip="Internal Users List"
                (click)="cleanLocalStorePage('/internal-users-list')"> <span ngClass="icon icon-internal-users"></span>
            </li>

            <!--Account Settings-->
            <li *ngIf="role.role === 'CS'" class="nav-item top-li-menu" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>

            <!--Saving Statistics-->
            <li *ngIf="role.role === 'CS'" class="nav-item top-li-menu" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon"  ></span> </li>
            <!--Fin Rol CS-->
            <!-- Rol Sale -->
            <!--Organizations List-->
            <li *ngIf="role.role === 'VT'  && activeGroup !== 'OL'" class="nav-item top-li-menu" placement="right"
                ngbTooltip="Organizations List" (click)="cleanLocalStorePage('/organization-list')"> <span
                    ngClass="icon icon-organization"></span>
            </li>
            <li *ngIf="role.role === 'VT' && activeGroup === 'OL'" class="nav-item top-li-menu is-active"
                placement="right" ngbTooltip="Organizations List" (click)="cleanLocalStorePage('/organization-list')">
                <span ngClass="icon icon-organization"></span>
            </li>

            <!--Facilities List-->
            <li *ngIf="role.role === 'VT' && activeGroup !== 'FL'" class="nav-item top-li-menu"
                routerLink="/facilities-list" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span>
            </li>
            <li *ngIf="role.role === 'VT' && activeGroup === 'FL'" class="nav-item top-li-menu is-active"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span>
            </li>
            <!--Units Hub-->
            <li *ngIf="role.role === 'VT' && activeGroup !== 'UD'" class="nav-item top-li-menu"
                routerLink="/units-dashboard" [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <li *ngIf="role.role === 'VT' && activeGroup === 'UD'" class="nav-item top-li-menu is-active"
                routerLink="/units-dashboard" [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>

            <!--External Users List-->
            <li *ngIf="role.role === 'VT' && activeGroup !== 'UL'" class="nav-itemtop-li-menu" placement="right"
                ngbTooltip="Users List" (click)="cleanLocalStorePage('/users-list')"> <span
                    ngClass="icon icon-users"></span> </li>
            <li *ngIf="role.role === 'VT' && activeGroup === 'UL'" class="nav-itemtop-li-menu is-active"
                placement="right" ngbTooltip="Users List" (click)="cleanLocalStorePage('/users-list')"> <span
                    ngClass="icon icon-users"></span> </li>

            <!--Unassigned Units-->
            <li *ngIf="role.role === 'VT' && activeGroup !== 'UU'" class="nav-item top-li-menu" placement="right"
                ngbTooltip="Unassigned Units" (click)="cleanLocalStorePage('/unassigned-units')"> <span
                    ngClass="icon unassigned-units-icon"></span>
            </li>
            <li *ngIf="role.role === 'VT' && activeGroup === 'UU'" class="nav-item top-li-menu is-active"
                placement="right" ngbTooltip="Unassigned Units" (click)="cleanLocalStorePage('/unassigned-units')">
                <span ngClass="icon unassigned-units-icon"></span>
            </li>

            <!--Internal Users List-->
            <li *ngIf="role.role === 'VT' && activeGroup !== 'IU'" class="nav-item top-li-menu" placement="right"
                ngbTooltip="Internal Users List" (click)="cleanLocalStorePage('/internal-users-list')"> <span
                    ngClass="icon icon-internal-users"></span>
            </li>
            <li *ngIf="role.role === 'VT' && activeGroup === 'IU'" class="nav-item top-li-menu is-active"
                placement="right" ngbTooltip="Internal Users List"
                (click)="cleanLocalStorePage('/internal-users-list')"> <span ngClass="icon icon-internal-users"></span>
            </li>

            <!--Account Settings-->
            <li *ngIf="role.role === 'VT'" class="nav-item top-li-menu" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>

            <!--Saving Statistics-->
            <li *ngIf="role.role === 'VT'" class="nav-item top-li-menu" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon"  ></span> </li>
            <!--Fin Rol Sale-->

            <!-- Rol SM -->
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/organization-list"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Organizations List"
                (click)="cleanLocalStorePage('/organization-list')"> <span ngClass="icon icon-organization"></span>
            </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/facilities-list"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span ngClass="icon icon-organization"></span>
            </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/units-dashboard"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/users-list"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Users List"
                (click)="cleanLocalStorePage('/users-list')"> <span ngClass="icon icon-users"></span> </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/unassigned-units"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Unassigned Units"
                (click)="cleanLocalStorePage('/unassigned-units')"> <span ngClass="icon unassigned-units-icon"></span>
            </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/users-list"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Internal Users List"
                (click)="cleanLocalStorePage('/internal-users-list')"> <span ngClass="icon icon-internal-users"></span>
            </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/account-settings"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Account Settings"
                (click)="cleanLocalStorePage('/account-settings')"> <span ngClass="icon icon-account"></span> </li>
            <li *ngIf="role.role === 'SM'" class="nav-item top-li-menu" routerLink="/saving-statistics"
                [routerLinkActive]="['is-active']" placement="right" ngbTooltip="Saving Statistics"
                (click)="cleanLocalStorePage('/saving-statistics')"  style="display:none ;"> <span ngClass="icon bar-chart-icon"  ></span> </li>
            <!--End Rol SM-->

            <!--Rol SA-->
            <!--Organizations List-->
            <li *ngIf="role.role === 'SA' && activeGroup !=='OL'" class="nav-item top-li-menu"
                routerLink="/organization-list" [routerLinkActive]="(activeGroup ==='OL')?'is-active':''"
                placement="right" ngbTooltip="Organizations List" (click)="cleanLocalStorePage('/organization-list')">
                <span ngClass="icon icon-organization"></span>
            </li>
            <li *ngIf="role.role === 'SA' && activeGroup ==='OL'" class="nav-item top-li-menu is-active"
                routerLink="/organization-list" placement="right" ngbTooltip="Organizations List"
                (click)="cleanLocalStorePage('/organization-list')"> <span ngClass="icon icon-organization"></span>
            </li>
            <!--Facilities List-->
            <li *ngIf="role.role === 'SA' && activeGroup !=='FL'" class="nav-item top-li-menu"
                routerLink="/facilities-list" [routerLinkActive]="(activeGroup ==='FL')?'is-active':''"
                placement="right" ngbTooltip="Facilities List" (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span>
            </li>
            <li *ngIf="role.role === 'SA' && activeGroup ==='FL'" class="nav-item top-li-menu is-active"
                routerLink="/facilities-list" placement="right" ngbTooltip="Facilities List"
                (click)="cleanLocalStorePage('/facilities-list')"> <span
                    ngClass="icon icon-facilities-organizations"></span>
            </li>
            <!--Units Hub-->
            <li *ngIf="role.role === 'SA' && activeGroup !=='UD'" class="nav-item top-li-menu"
                routerLink="/units-dashboard" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>
            <li *ngIf="role.role === 'SA' && activeGroup ==='UD'" class="nav-item top-li-menu is-active"
                routerLink="/units-dashboard" placement="right" ngbTooltip="Units Hub"
                (click)="cleanLocalStorePage('/units-dashboard')"> <span ngClass="icon icon-dhub2"></span> </li>

            <!--External Users List-->
            <li *ngIf="role.role === 'SA' && activeGroup !=='UL'" class="nav-item top-li-menu" routerLink="/users-list"
                ngbTooltip="External Users List" (click)="cleanLocalStorePage('/users-list')"> <span
                    ngClass="icon icon-users"></span> </li>
            <li *ngIf="role.role === 'SA' && activeGroup ==='UL'" class="nav-item top-li-menu is-active"
                routerLink="/users-list" ngbTooltip="External Users List" (click)="cleanLocalStorePage('/users-list')">
                <span ngClass="icon icon-users"></span>
            </li>

            <!--Unassigned Units-->
            <li *ngIf="role.role === 'SA'  && activeGroup !=='UU'" class="nav-item top-li-menu"
                routerLink="/unassigned-units" placement="right" ngbTooltip="Unassigned Units"
                (click)="cleanLocalStorePage('/unassigned-units')">
                <span ngClass="icon unassigned-units-icon"></span>
            </li>
            <li *ngIf="role.role === 'SA'  && activeGroup ==='UU'" class="nav-item top-li-menu is-active"
                routerLink="/unassigned-units" placement="right" ngbTooltip="Unassigned Units"
                (click)="cleanLocalStorePage('/unassigned-units')">
                <span ngClass="icon unassigned-units-icon"></span>
            </li>
            <!--Internal Users List-->
            <li *ngIf="role.role === 'SA' && activeGroup !=='IU'" class="nav-item top-li-menu"
                routerLink="/internal-users-list" placement="right" ngbTooltip="Internal Users List"
                (click)="cleanLocalStorePage('/internal-users-list')"> <span ngClass="icon icon-internal-users"></span>
            </li>
            <li *ngIf="role.role === 'SA' && activeGroup ==='IU'" class="nav-item top-li-menu is-active"
                routerLink="/internal-users-list" placement="right" ngbTooltip="Internal Users List"
                (click)="cleanLocalStorePage('/internal-users-list')"> <span ngClass="icon icon-internal-users"></span>
            </li>
            <!--Account Settings-->
            <li *ngIf="role.role === 'SA'" class="nav-item" routerLink="/account-settings"
                [routerLinkActive]="'is-active'" placement="right" ngbTooltip="Account Settings"
                [ngClass]="{'top-li-menu': role.role === 'SA'}" (click)="cleanLocalStorePage('/account-settings')">
                <span ngClass="icon icon-account"></span>
            </li>

            <!--Saving Statistics-->
            <li *ngIf="role.role === 'SA'" class="nav-item" routerLink="/saving-statistics"
                [routerLinkActive]="(activeGroup ==='SS')?'is-active':''" placement="right"
                ngbTooltip="Saving Statistics"
                [ngClass]="{'top-li-menu': role.role === 'SA' ,'top-li-menu': role.role === 'SA'}"
                (click)="cleanLocalStorePage('/saving-statistics')"   style="display:none ;"> <span ngClass="icon bar-chart-icon"></span> </li>

            <!--End Rol SA-->

        </ul>
        <ul class="nav bottom-menu flex-column">
         <li class="nav-item li-support" placement="right" ngbTooltip="Support">
                <span class="icon icon-support"   style="visibility: hidden ;"></span>
            </li>
            <li class="nav-item" (click)="openModal(template)" placement="right" ngbTooltip="Log Out">
                <span class="icon icon-logout"></span>
            </li>
        </ul>
    </ng-container>

</div>
<ng-template #template>
    <div class="modal-body" style="padding-left: 0px;padding-right: 0px;">
        <div class="row">
            <div class="col-md-6 position"><img class="logo"
                    [src]="'assets/images/logo_janway_popup.svg'" alt="Janway Logo" width="200px" /></div>
            <div class="col-md-6 text-right">
                <a (click)="closeFirstModal(template)" class="backLog"
                    >Back to app </a>
                <img class="text-right symbol-right" [src]="'assets/images/icons/right.svg'" alt="Janway Logo" />
            </div>
        </div>
    </div>
    <div class="modal-body text-center">
        <h1 >
            Are you sure you

            want to <span class="accent-text"> log out?</span>
        </h1>
        <p>
            <button   type="button"
                (click)="closeFirstModal(template)" class="btn btn-primary full-width btn-closeModal">no, I want to go back</button>
        </p>
        <p>
            <button
                type="button" class="btn btn-light full-width btn-logOut" (click)="logOut()">yes I want to log out</button>
        </p>
    </div>
</ng-template>
