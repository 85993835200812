<div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 text-center">
    <img class="logo" [src]="'assets/images/logo-janway.svg'" alt="Janway Logo" width="150px" routerLink="/">
    <h3>Check your <span class="accent-text">email</span></h3>
    <p>We have sent you an email with intructions<br>to recover your password.</p>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 offset-md-4 col-lg-6 offset-lg-3">
        <img  [src]="'assets/images/email-confirmation.svg'" alt="Janway Email" width="75%">
      </div>
    </div>
    <p class="terms">Didn’t get an email? <a placement="top" ngbTooltip="Get a new one here"
        routerLink="/forgot-password">Get a new one here</a></p>

  </div>
</div>
