import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { AppServices } from './services/api.service';
import { Router, Event, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'janway';
  path: string;
  _displayMenu = false;
  previousUrl: string;
  currentRoute: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private appServices: AppServices) {
   /*  if (localStorage.getItem('remember') === null) {
      localStorage.removeItem('remember');
      this.authService.logout();
      this.router.navigate(['/']);
    } */
    /* this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.appServices.urlChange = event.url;
        console.log('app')
      }

      if (event instanceof NavigationEnd) {
        const url = event.url;
        const confirmAccount = url.includes('confirm-account');
        console.log(confirmAccount);
        const recoverAccount = url.includes('recover-password');
        console.log(recoverAccount);
        console.log(url);
        if (confirmAccount) {
          const arrayUrl = url.split('/confirm-account;token=');
          const arrayUrlToken = arrayUrl.length > 0 ? arrayUrl[1] : '';
          const tokenAndId = arrayUrlToken === '' ? [] : arrayUrlToken.split(';id=');
          const token = tokenAndId.length > 0 ? tokenAndId[0] : '';
          const id = tokenAndId.length > 0 ? tokenAndId[1] : 0;
          if (token !== '' && id > 0) {
            this.router.navigate(['/confirm-account', { token: token, id: id }], { relativeTo: this.route });
          }
        }
      }
    }); */
  }
  ngOnInit(): void {
    this.authService.authStatus.subscribe(
      authStatus => {
        const jwt = this.authService.getToken();
        setTimeout(() => (this._displayMenu = !(jwt == null || jwt === ''), 0));

      }
    );
  }
  get displayMenu() {
    return this._displayMenu;
  }
}
