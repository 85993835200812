<div class="container-fluid">
    <div *ngIf="terminos">
        <div class="row">
            <div class="co-xs-12 col-sm-12 col-md-12 left-col" >
                <h1 style="margin-top: 0% !important;">Terms of service <span class="accent-text">sit amet consectetur</span> adipiscing.</h1>
                <p>Aptent iaculis lobortis nisl pretium et sed himenaeos neque litora, accu msan eros nibh etiam hac nisi
                    velit.</p>
                    <p class="text-center">
                        <button type="button" placement="top" ngbTooltip="Confirm account" class="btn btn-primary" (click)="change(1)">
                            Back to confirm your account</button>
                    </p>
            </div>

        </div>
    </div>
    <div *ngIf="privacidad">
        <div class="row">
            <div class="co-xs-12 col-sm-12 col-md-12 left-col" >
                <h1 style="margin-top: 0% !important;">Privacity policy <span class="accent-text">sit amet consectetur</span> adipiscing.</h1>
                <p>Aptent iaculis lobortis nisl pretium et sed himenaeos neque litora, accu msan eros nibh etiam hac nisi
                    velit.</p>
                    <p class="text-center">
                        <button type="button" placement="top" ngbTooltip="Confirm account" class="btn btn-primary" (click)="change(2)">
                            Back to confirm your account
                        </button>
                    </p>
            </div>

        </div>
    </div>
    <div class="row" *ngIf="terminos == false && privacidad == false">
        <div class="co-xs-12 col-sm-4 col-md-4 left-col full-height padre">
            <img class="logo" [src]="'assets/images/logo-janway.svg'" alt="Janway Logo" width="200px">
            <h1>Welcome to <span class="accent-text">Janway.</span> </h1>
           <!--  <p>Aptent iaculis lobortis nisl pretium et sed himenaeos neque litora, accu msan eros nibh etiam hac nisi velit.</p> -->
        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 right-col full-height">
            <div class="row form">
                <div class="col-xs-12 col-sm-8 col-md-8 offset-md-2 bottom-form">
                    <form [formGroup]="ConfirmAccountForm" (ngSubmit)="onSubmit(ConfirmAccountForm)">
                        <h3 class="h3-title">You’re almost set!<br><span class="accent-text">Confirm your account</span></h3>
                        <div *ngIf="errorsArray.length !==0" class="error" >
                            <ul>
                              <li *ngFor="let error of msgErrorList">
                                {{ error }}
                              </li>
                            </ul>
                            <span *ngIf="msgError !== ''">
                                {{ msgError }}
                            </span>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-6 col-sm-6 col-md-6">
                                <div class="form-group">
                                    <label for="firstName">First Name</label>
                                    <input
                                        type="text"
                                        class="form-control input"
                                        formControlName="firstName"
                                        placeholder="First Name"
                                        id="firstName">
                                    <div *ngIf="ConfirmAccountForm.controls['firstName'].invalid &&
                                        (ConfirmAccountForm.controls['firstName'].dirty ||
                                        ConfirmAccountForm.controls['firstName'].touched)" class="invalid-feedback">

                                        <div *ngIf="ConfirmAccountForm.controls['firstName'].errors.required">
                                            * First Name is required
                                        </div>

                                        <div *ngIf="ConfirmAccountForm.controls['firstName'].errors.maxlength">
                                            * First Name must have  at most 100 characters
                                        </div>

                                        <div *ngIf="ConfirmAccountForm.controls['firstName'].errors.minlength">
                                            * First Name must be at least 5 characters long
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-6 col-sm-6 col-md-6">
                                <div class="form-group">
                                    <label for="lastName">Last Name</label>
                                    <input type="text" class="form-control input" formControlName="lastName" id="lastName" placeholder="Last Name">
                                    <div *ngIf="ConfirmAccountForm.controls['lastName'].invalid && (ConfirmAccountForm.controls['lastName'].dirty || ConfirmAccountForm.controls['lastName'].touched)" class="invalid-feedback">
                                        <div *ngIf="ConfirmAccountForm.controls['lastName'].errors.required">
                                            * Last Name is required
                                        </div>

                                        <div *ngIf="ConfirmAccountForm.controls['lastName'].errors.maxlength">
                                            * Last Name must have  at most 100 characters
                                        </div>

                                        <div *ngIf="ConfirmAccountForm.controls['lastName'].errors.minlength">
                                            * Last Name must be at least 5 characters long
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-12 col-sm-12 col-md-12">
                                <div class="form-group">
                                    <label for="email">Email Address</label>
                                    <input type="email" class="form-control input" formControlName="email" id="email" placeholder="example@mail.com">
                                    <div *ngIf="ConfirmAccountForm.controls['email'].invalid && (ConfirmAccountForm.controls['email'].dirty || ConfirmAccountForm.controls['email'].touched)" class="invalid-feedback">
                                        <div *ngIf="ConfirmAccountForm.controls['email'].errors.required">* Email Address is required</div>
                                        <div *ngIf="ConfirmAccountForm.controls['email'].errors.email">* Email Address pattern is not valid</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-6 col-sm-6 col-md-6">
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <div class="input-group mb-3">
                                        <input
                                            #password
                                            type="password"
                                            class="form-control input"
                                            formControlName="password"
                                            id="password"
                                            placeholder="*******">
                                        <div class="input-group-append" (click)="password.type=password.type=='password'?'text':'password'">
                                            <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                                        </div>
                                        <div *ngIf="ConfirmAccountForm.controls['password'].invalid && (ConfirmAccountForm.controls['password'].dirty || ConfirmAccountForm.controls['password'].touched)" class="invalid-feedback">
                                            <div *ngIf="ConfirmAccountForm.controls['password'].errors.required">* Password is required</div>
                                            <div *ngIf="ConfirmAccountForm.controls['password'].errors.minlength">* Password must have at least 7 characters</div>
                                            <div *ngIf="ConfirmAccountForm.controls['password'].errors.maxlength">* Password must have  at most 100 characters</div>
                                            <div *ngIf="ConfirmAccountForm.controls['password'].errors.pattern">
                                                * Password must contain at least: one capital letter, one lowercase letter, one numeric digit and one special character.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-6 col-sm-6 col-md-6">
                                <div class="form-group">
                                    <label [ngClass]="{'error': submitted && ConfirmAccountForm.controls.passwordc.errors}" for="passwordc">Confirm Password</label>
                                    <div class="input-group mb-3">
                                        <input #passwordc
                                            type="password"
                                            class="form-control input"
                                            formControlName="passwordc"
                                            id="passwordc"
                                            placeholder="*******">
                                        <div class="input-group-append" (click)="passwordc.type=passwordc.type=='password'?'text':'password'">
                                            <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                                        </div>
                                        <div *ngIf="ConfirmAccountForm.controls['passwordc'].invalid && (ConfirmAccountForm.controls['passwordc'].dirty || ConfirmAccountForm.controls['passwordc'].touched)" class="invalid-feedback">
                                            <div *ngIf="ConfirmAccountForm.controls['passwordc'].errors.required">
                                                * Confirm Password is required
                                            </div>
                                            <div *ngIf="ConfirmAccountForm.controls['passwordc'].errors.MatchPassword">
                                                * Password and Confirm Password must match
                                            </div>
                                            <div *ngIf="ConfirmAccountForm.controls['passwordc'].errors.pattern">
                                                * Password must contain at least: one capital letter, one lowercase letter, one numeric digit and one special character.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isInternalRole && isOrganization">
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="form-group">
                                    <label for="organization">Organization</label>
                                    <input type="text" class="form-control input" formControlName="organization" id="organization" placeholder="Organization assigned">
                                    <div *ngIf="ConfirmAccountForm.controls['organization'].invalid && (ConfirmAccountForm.controls['organization'].dirty || ConfirmAccountForm.controls['organization'].touched)" class="invalid-feedback">
                                        <div *ngIf="ConfirmAccountForm.controls['organization'].errors.required">* Organization is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isInternalRole && isFacility">
                            <div class="col-xs-12 col-sm-12 col-md-12">
                                <div class="form-group">
                                    <label for="facility">Facility</label>
                                    <input
                                        type="text"
                                        class="form-control input"
                                        formControlName="facility"
                                        id="facility"
                                        placeholder="Facility">
                                    <!-- <select class="form-control input" formControlName="facility" id="facility">
                                      <option>Select Facility</option>
                                      <option value="1">X</option>
                                    </select> -->
                                    <div *ngIf="ConfirmAccountForm.controls['facility'].invalid && (ConfirmAccountForm.controls['facility'].dirty || ConfirmAccountForm.controls['facility'].touched)" class="invalid-feedback">
                                        <div *ngIf="ConfirmAccountForm.controls['facility'].errors.required">* Facility is required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-check terms" placement="top" ngbTooltip="Creating an account means you're okay with our">
                            <input type="checkbox" formControlName="confirm" (change)="checkConfirm()" [(ngModel)]="confirm" class="form-check-input" id="remember">
                            <label class="checkbox" for="remember">Creating an account means you’re okay with our <a (click)="change(1)">Terms of Service</a>, and our <a (click)="change(2)">Privacy Policy.</a></label>
                            <div *ngIf="ConfirmAccountForm.controls['confirm'].invalid && (ConfirmAccountForm.controls['confirm'].dirty || ConfirmAccountForm.controls['confirm'].touched)" class="invalid-feedback">
                                <div *ngIf="ConfirmAccountForm.controls['confirm'].errors.pattern">* Accept Terms and Privacity is required</div>
                            </div>
                        </div>
                        <p class="text-center">
                            <!-- <button type="submit" placement="top" ngbTooltip="Confirm account"  class="btn btn-primary" routerLink="/login">confirm account</button> -->
                            <button
                                type="submit"
                                placement="top"
                                ngbTooltip="Confirm account"
                                [disabled]="ConfirmAccountForm.invalid"
                                class="btn btn-primary">confirm account</button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
