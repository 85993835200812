import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MenuModel, ROLSMENU } from '@Models/menu.model';
import { SideNavbarService } from '../services/side-navbar.service';
import { Subject, Subscription } from 'rxjs';
import { RolsEnum } from '@Models/enum/rols.enum';
import { internalsRol } from '@Models/role.model';
import { takeUntil } from 'rxjs/operators';
import { ActionMenuService } from '../services/action-menu.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.sass'],
})
export class SideNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  modalRef: BsModalRef | null;
  role;
  menuForRole: MenuModel[] = [];
  userRole: RolsEnum;
  internalRol: boolean = false;
  activeGroup: string = 'H';
  private unsubscribe$ = new Subject();
  facilityIdValue;
  facilityOrganizationValue;
  organizationSetup;
  idOrganization;

  constructor(
    private authService: AuthService,
    private router: Router,
    private sideNavbarService: SideNavbarService,
    private modalService: BsModalService,
    private actionMenuService: ActionMenuService,
    private cd: ChangeDetectorRef
  ) {
    this.idOrganization = this.authService.getOrganizationId()
    this.userRole = this.authService.authStatus.getValue().role;
    const rolInternal = localStorage.getItem('inernalRole');
    //const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
  /*   this.organizationSetup = authService.getOrganizationSetup(); */
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    this.role = JSON.parse(localStorage.getItem('authStatus'));
    let listRol = ROLSMENU[0].rolMenu;
    const filterRolMenu = listRol[this.role.role];
    this.menuForRole = [];
    this.sideNavbarService.rolsMenu$ = filterRolMenu ? filterRolMenu : [];

    const url = this.router.url;
    const confirmAccount = url.includes('confirm-account');
    const recoverAccount = url.includes('recover-password');
    //alert('trueee')
    if (confirmAccount) {
      const arrayUrl = url.split('/confirm-account;token=');
      const arrayUrlToken = arrayUrl.length > 0 ? arrayUrl[1] : '';
      const tokenAndId =
        arrayUrlToken === '' ? [] : arrayUrlToken.split(';id=');
      const token = tokenAndId.length > 0 ? tokenAndId[0] : '';
      const id = 500; //tokenAndId.length > 0 ? tokenAndId[1] : 0;
      if (token !== '' && id > 0) {
        let queryParams: any;
        this.router.navigate([
          '/confirm-account',
          {
            ...queryParams,
            token,
            id,
          },
        ]);
      }

    }
    /* this.actionMenuService.menuActive$.subscribe((data) => {
      if (!(data[0].grupo === 'group')) this.activeGroup = data[0][0].grupo;

    }); */
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    //this.activeGroup = 'H';
    this.facilityIdValue = this.facilityUserId();
    this.facilityOrganizationValue = this.facilityOrganizationId();
    this.authService.myStatus$.subscribe((status) => {
      this.organizationSetup = status;
    });
  }
  ngAfterViewInit(): void {
    this.actionMenuService.menuActive$.subscribe((data) => {
      if (!(data[0].grupo === 'group')) this.activeGroup = data[0][0].grupo;
    });
    this.cd.detectChanges();
  }
  logOut() {
    this.authService.logoutUser();
    this.closeFirstModal();
    localStorage.removeItem('remember');
    this.router.navigate(['/']);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-lg modal-dialog-centered customListModal',
    });
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.hide();
    this.modalRef = null;
  }

  cleanLocalStorePage(link) {
    localStorage.setItem('listRecord', null);
    localStorage.setItem('organizationIdProfile', null);
    localStorage.removeItem('ProfileOrganizationId');
    localStorage.removeItem('ProfileFacilityId');
    localStorage.removeItem('urlSearch');
    localStorage.removeItem('paramSearch');
    localStorage.removeItem('classActiveOnlineOffline');

    const url = this.router.url;

    localStorage.setItem('urlData', link);

    this.router.navigate([link]);

    this.cd.detectChanges();
  }

  facilityUserId() {
    const facility = localStorage.getItem('facilityId');
    let facilityId =
      facility !== null && facility !== undefined ? facility : '0';
    return +facility.replace(/['"]+/g, '');
  }

  facilityOrganizationId() {
    const organization = localStorage.getItem('organizationId');
    let id =
      organization !== null && organization !== undefined ? organization : '0';
    return +id.replace(/['"]+/g, '');
  }
}
