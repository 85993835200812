<div class="container-fluid">
    <div class="row">
        <div class="co-xs-12 col-sm-4 col-md-4 left-col full-height padre">
          <div class="desktop">
            <img class="logo" [src]="'assets/images/logo-janway.svg'" alt="Janway Logo" width="200px">
            <h1>Welcome to <span class="accent-text">Janway.</span> </h1>
          </div>


      <!--       <p>Aptent iaculis lobortis nisl pretium et sed himenaeos neque litora, accu msan eros nibh etiam hac nisi velit.</p> -->
        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 right-col full-height full-height-movil">
            <div class="row form">
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                    <form [formGroup]="recoverForm">
                        <h3>Almost done, <span class="accent-text">change your password</span> here</h3>
                        <div class="error">
                            <ul>
                              <li *ngFor="let error of msgErrorList">
                                {{ error }}
                              </li>
                            </ul>
                            <span *ngIf="msgError !== ''">
                                {{ msgError }}
                            </span>
                        </div>
                        <div class="form-group" style="margin-top: 10px">
                            <label for="password">Password</label>
                            <!-- <input type="password" class="form-control input" id="password"> -->
                            <div class="input-group mb-3">
                                <input #password type="password" class="form-control input" formControlName="password" placeholder="*******" id="password">
                                <div class="input-group-append" (click)="password.type=password.type=='password'?'text':'password'">
                                    <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                                </div>
                                <div *ngIf="recoverForm.controls['password'].invalid &&
                                        (recoverForm.controls['password'].dirty ||
                                        recoverForm.controls['password'].touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="recoverForm.controls['password'].errors.required">* Password is required</div>
                                    <div *ngIf="recoverForm.controls['password'].errors.minlength">* Password must have at least 7 characters</div>
                                    <div *ngIf="recoverForm.controls['password'].errors.maxlength">* Password must have  at most 100 characters</div>
                                    <div *ngIf="recoverForm.controls['password'].errors.pattern">
                                        * Password must contain at least: one capital letter, one lowercase letter, one numeric digit and one special character.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group top-password">
                            <label for="confirmPassword">Confirm Password</label>
                            <div class="input-group mb-3">
                                <input #passwordc type="password" class="form-control input" formControlName="passwordc" placeholder="*******" id="passwordc">
                                <div class="input-group-append" (click)="passwordc.type=passwordc.type=='password'?'text':'password'">
                                    <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                                </div>
                                <div *ngIf="recoverForm.controls['passwordc'].invalid && (recoverForm.controls['passwordc'].dirty ||
                                    recoverForm.controls['passwordc'].touched)" class="invalid-feedback">
                                    <div *ngIf="recoverForm.controls['passwordc'].errors.required">
                                        * Confirm Password is required
                                    </div>
                                    <div *ngIf="recoverForm.controls['passwordc'].errors.MatchPassword">
                                        * Password and Confirm Password must match
                                    </div>
                                    <div *ngIf="recoverForm.controls['passwordc'].errors.pattern">
                                        * Password must contain at least: one capital letter,
                                        one lowercase letter, one numeric digit and one special character.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-center top-btn">
                            <button type="button"

                                [disabled]="recoverForm.invalid"
                                (click)="saveChange()"
                                class="btn btn-primary btn-change">change password</button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
