<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <p class="back" routerLink="/internal-invite-management">
          <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to all invites">
          Back to all internal invites
        </p>
        <h1>Invite New Internal Users</h1>
        <p>Add the required information to invite a new user.</p>
        <hr>
        <div  *ngIf="errorsArray.length !==0" class="error">
          <ul>
            <li *ngFor="let error of errorsArray">
              {{ error }}
            </li>
          </ul>
        </div>
        <form [formGroup]="usersForm" (ngSubmit)="sendForm()" style="margin-top: 0px;">
          <div formArrayName="users">
            <div class="row" *ngFor="let user of users.controls; let i=index" [formGroupName]='i'>
              <div class="col-12 col-md-12 col-lg-10">
                <div class="row">
                  <div [ngClass]="[internalRol || oraganizationListRol ? 'col-12 col-sm-3 col-md-5 col-lg-3':'col-12 col-sm-3 col-md-3 col-lg-3']">

                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="email" class="form-control input" id="email" formControlName="email"
                        placeholder="example@mail.com">
                      <div *ngIf="user.controls['email'].invalid &&
                                            (user.controls['email'].dirty || user.controls['email'].touched)"
                        class="invalid-feedback">
                        <div *ngIf="user.controls['email'].errors.required">* Email is required</div>
                        <div *ngIf="user.controls['email'].errors.email">* Email pattern is not valid</div>
                      </div>
                    </div>
                  </div>
                  <div
                     [ngClass]="[internalRol || oraganizationListRol ? 'col-8 col-sm-3 col-md-4 col-lg-3':'col-12 col-sm-3 col-md-3 col-lg-3']" >
                    <div class="form-group">
                      <label for="role">Role</label>
                      <select class="form-control input select-old" formControlName="role" id="role"
                        (change)="selectRole($event, user)">
                        <option value="" selected>Select Role</option>
                        <option *ngFor="let userRol of listDataUser" [value]="userRol.id">
                          {{ userRol.name }}
                        </option>
                      </select>
                      <div *ngIf="user.controls['role'].invalid &&
                                        (user.controls['role'].dirty || user.controls['role'].touched)"
                        class="invalid-feedback">
                        <div *ngIf="user.controls['role'].errors.required">* Role is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4 col-md-auto text-centers buttons"  *ngIf="user.controls['showSelectFacility'].value === false">
                    <button (click)="removeQuantity(i)" *ngIf="position > 0" class="btn btn-light sm-btn">
                      <span>-</span>
                    </button>
                    <button type="button" [disabled]="usersForm.invalid" (click)="addQuantity(i)" *ngIf="position == i"
                      class="btn btn-light sm-btn">
                      <span>+</span>
                    </button>
                  </div>
                </div>


              </div>
              <div class="col-md-auto text-centers" *ngIf="user.controls['showSelectFacility'].value === true">
                <button (click)="removeQuantity(i)" *ngIf="position > 0" class="btn btn-light sm-btn">
                  <span>-</span>
                </button>
                <button type="button" [disabled]="usersForm.invalid" (click)="addQuantity(i)" *ngIf="position == i"
                  class="btn btn-light sm-btn">
                  <span>+</span>
                </button>
              </div>
            </div>
            <p class="text-center">
              <button type="submit" [disabled]="usersForm.invalid"
                 (click)="openModal(template)"
                class="btn btn-primary submit btn-send">
                send invite(s)
              </button>
            </p>
          </div>

        </form>
        <!-- {{this.usersForm.value | json}} -->
      </div>
    </div>
  </div>
</div>
<ng-template #template>
  <div class="modal-body text-center modal-fit">
    <h1 class="h1-modal">
      Invite(s) sent

      <span class="accent-text">successfully!</span>
    </h1>

  </div>
</ng-template>
