import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { facilityRol, internalsRol, organizationRol } from '@Models/role.model';
import { RolsEnum } from '@Models/enum/rols.enum';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { Router } from '@angular/router';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export class InviteUser {
  email: string;
  roleId: string;
  facilityId: number;
  organizationId: number;
}

@Component({
  selector: 'app-users-invite',
  templateUrl: './users-invite.component.html',
  styleUrls: ['./users-invite.component.sass'],
})
export class UsersInviteComponent implements OnInit, OnDestroy {
  usersForm: FormGroup;
  public position: number = 1;
  public modalRef: BsModalRef | null;
  dataSave: InviteUser[] = [];
  listFacilities = {
    listFacility: [],
  };
  listDataUser: any = [];
  errorsArray: string[] = [];
  public userRole: RolsEnum;
  listOrganization: any[] = [];
  internalRol: boolean = false;
  oraganizationListRol: boolean = false;
  facilityListRol: boolean = false;
  organizationLocalStorageId = this.authService.getOrganizationId();
  roleLevel = +this.authService.getRoleLevel();
  indexRow;
  loadFirstBand;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private appServices: AppServices,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private router: Router,
    private sideNavbarService: SideNavbarService,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('UL');
    this.userRole = this.authService.authStatus.getValue().role;
    /*const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
    this.internalRol = isInternalRol.length > 0 ? true : false;*/
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;

    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      const indexUserList = menu.findIndex(
        (item) => item.link === '/users-list'
      );
      const indexUserInviteManagement = menu.findIndex(
        (item) => item.link === '/invite-management'
      );
      let indexMneChange =
        indexUserList > -1 ? indexUserList : indexUserInviteManagement;
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = '/users-invite';
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }

    if (!this.internalRol) {
      this.getListFacilities(+this.organizationLocalStorageId, 0);
    }
    this.getListOrganization();
    this.usersForm = this.fb.group({
      users: this.fb.array([]),
    });
    this.addQuantity(0, true);
  }

  ngOnDestroy(): void {
    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      const menuUser = ['/users-list', '/invite-management'];
      const search = menuUser.includes(this.router.url);
      if (!search) {
        const indexUserList = menu.findIndex(
          (item) => item.link === '/users-invite'
        );
        if (indexUserList !== -1) {
          menu[indexUserList].link = '/users-list';
          this.sideNavbarService.rolsMenu$ = menu;
        }
      }
    }
  }

  ngOnInit() {
    //this.userRole = this.authService.authStatus.getValue().role;

    this.getRolesForUpdateSelect();
  }

  getListOrganization() {
    if (this.internalRol) {

      this.appServices.getForSelectOrganization().subscribe((data) => {
        this.listOrganization = data['data'];
      });
    }
  }

  getListFacilities(id: number, i: number) {
    this.listFacilities.listFacility[i] = [];
    if (id) {
      this.appServices.getListFacility(id).subscribe(
        (data: any) => {
          this.listFacilities.listFacility[i] = data['data'];
        },
        (error) => {
          let errors = JSON.parse(error);
          if (typeof errors !== 'object') {

            //this.toastrService.error('error', errors)
          }
        }
      );
    }
  }

  getRolesForUpdateSelect() {
    this.appServices.getRolesForUpdateSelect().subscribe((data) => {
      this.listDataUser = data['data'];
      const roleExist = this.listDataUser;
      if (this.roleLevel === 6 || this.roleLevel === 7) {
        this.listDataUser = roleExist.filter(
          (data) =>
            data.name === 'Facility Administrator' ||
            data.name === 'Facility Manager'
        );
      }
    });
  }

  get users(): FormArray {
    return this.usersForm.get('users') as FormArray;
  }

  newQuantity(i: number, loadFirst: boolean = false): FormGroup {
    let organizationId = this.internalRol
      ? '0'
      : this.organizationLocalStorageId;
    organizationId = organizationId === null ? '0' : organizationId;

    const facilityLocalStorage = localStorage.getItem('facilityId');
    let facilityId = this.facilityListRol ? facilityLocalStorage : '0';

    const organizationIdProfile = localStorage.getItem('organizationIdProfile');

    if (!this.internalRol && !this.facilityListRol) {
      this.getListFacilities(+organizationId, i + 1);
    } else if (organizationIdProfile !== 'null') {
      this.getListFacilities(+organizationIdProfile, loadFirst ? 0 : i + 1);
    }
    let ProfileOrganizationId = localStorage.getItem('ProfileOrganizationId');
    let ProfileFacilityId = localStorage.getItem('ProfileFacilityId');

    if (ProfileOrganizationId === null) {
      this.getListFacilities(+ProfileOrganizationId, loadFirst ? 0 : i + 1);
    }
    this.indexRow = i;
    this.loadFirstBand = loadFirst;
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
      organizationId:
        ProfileOrganizationId === null
          ? [
              organizationIdProfile !== 'null'
                ? +organizationIdProfile
                : +organizationId,
            ]
          : [ProfileOrganizationId],
      facility: ProfileFacilityId === null ? [facilityId] : +ProfileFacilityId,
      nameRole: [''],
      showSelectFacility: [false],
    });
  }

  addQuantity(i, loadFirst: boolean = false) {
    i == 0 && this.position == 1 ? (this.position = i) : this.position++;
    this.users.push(this.newQuantity(i, loadFirst));
  }

  removeQuantity(i: number) {
    this.position--;
    this.users.removeAt(i);
  }

  selectRole(data: any, user: FormGroup) {
    if (data) {
      const roleId = data.target.value;
      user.patchValue({ facility: '0' });
      if (roleId !== '') {
        const roleExist = this.listDataUser.find((data) => data.id === roleId);
        if (this.facilityListRol) {
          const facilityLocalStorage = localStorage.getItem('facilityId');
          user.patchValue({
            nameRole: roleExist.name,
            showSelectFacility: false,
            facility: +facilityLocalStorage,
          });
        } else {
          if (
            roleExist.name === 'Facility Administrator' ||
            roleExist.name === 'Facility Manager' ||
            (this.internalRol &&
              (roleExist.name === 'Facility Administrator' ||
                roleExist.name === 'Facility Manager'))
          ) {
            user.patchValue({
              nameRole: roleExist.name,
              showSelectFacility: true,
            });
            user.get('facility').setValidators(this.validatonFacility());
          } else {
            user.patchValue({
              nameRole: '',
              facility: null,
              showSelectFacility: false,
            });
            user.get('facility').clearValidators();
          }
        }

        let ProfileOrganizationId = localStorage.getItem(
          'ProfileOrganizationId'
        );
        let ProfileFacilityId = localStorage.getItem('ProfileFacilityId');

        if (ProfileOrganizationId !== null) {
          this.getListFacilities(
            +ProfileOrganizationId,
            this.loadFirstBand ? 0 : this.indexRow + 1
          );
          user.get('facility').setValue(+ProfileFacilityId);
        }
      } else {
        user.patchValue({
          nameRole: '',
          facility: null,
        });
        user.get('facility').clearValidators();
      }
      if (this.internalRol){
        user.get('organizationId').setValidators(this.validatonOrganization());
        user.get('organizationId').updateValueAndValidity();
      }
      user.get('facility').updateValueAndValidity();

    }
  }

  sendForm() {}

  openModal(template: TemplateRef<any>) {
    this.dataSave = [];
    this.errorsArray = [];
    const { users } = this.usersForm.value;
    const organizatioId = this.authService.getOrganizationId();
    for (let user of users) {
      const { email, role, facility, organizationId } = user;
      const newUser = new InviteUser();
      newUser.email = email;
      newUser.roleId = role;

      if (this.facilityListRol) {
        let facilityLocalStorage = this.authService.getFacilityId();

        newUser.facilityId = +facilityLocalStorage;

      } else {
        newUser.facilityId = facility !== null ? +facility : null;
      }
      //newUser.facilityId = facility !== null ? +facility : null;

      if (newUser.facilityId === 0) {
        newUser.facilityId = null;
      }
      newUser.organizationId = this.internalRol
        ? +organizationId
        : +organizatioId;
      this.dataSave = [newUser, ...this.dataSave];
    }
    this.appServices.addInviteUsers(this.dataSave).subscribe(
      (data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, {
            id: 1,
            class: 'modal-md modal-dialog-centered succesfullyCustom',
          });
          let timerId = setInterval(() => this.modalRef.hide(), 3000);
          setTimeout(() => {
            clearInterval(timerId);
            this.modalRef.hide();
          }, 3000);

          this.resetForm(this.usersForm);
        }
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          let errorsList = [];
          const arrayErrors = arrayExiste ? errors.split('-') : [];
          this.errorsArray = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('\n', '');
              valueError.replace('-', '');
              if (valueError !== '') {
                errorsList.push(valueError);
              }
            });
          }
          if (this.errorsArray.length === 0 && errors !== '') {
            this.errorsArray.push(errors);
          } else {
            this.errorsArray = errorsList;
          }
        } else {
          this.errorsArray.push(errors);
        }
      }
    );
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.hide();
    this.modalRef = null;
    this.resetForm(this.usersForm);
    this.usersForm.setControl('users', this.fb.array([]));
    this.position = 1;
    this.addQuantity(0);
    this.errorsArray = [];
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
    let lengArray = this.users.length;
    this.position = 1;
    this.usersForm = this.fb.group({
      users: this.fb.array([]),
    });

    this.addQuantity(0, true);
  }

  inputChange(evt: KeyboardEvent, key: string, i?: number) {
    //@ts-ignore
    let value = evt.target.value;
    if (this.internalRol) {
      let taskListArrays = this.users;
      taskListArrays.controls[i].patchValue({ facility: '0' });
      this, this.getListFacilities(+value, i);
    }
  }

  validatonFacility(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { requiredFacility: true };
    };
  }
  validatonOrganization(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { requiredrganization: true };
    };
  }
}
