import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { ForgotPasswordEmailComponent } from './components/forgot-password/forgot-password-email/forgot-password-email.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { AppServices } from './services/api.service';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './navbar/navbar.component';
import { AssignNewUnitComponent } from './components/assign-new-unit/assign-new-unit.component';
import { UnitsDashboardComponent } from './components/units-dashboard/units-dashboard.component';
import { GaugeModule } from 'angular-gauge';
import { UnitDetailsComponent } from './components/unit-details/unit-details.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { FlushesHistoryComponent } from './components/flushes-history/flushes-history.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersInviteComponent } from './components/users-invite/users-invite.component';
import { InviteManagementComponent } from './components/invite-management/invite-management.component';
import { FacilityProfileComponent } from './components/facility-profile/facility-profile.component';
import { OrganizationProfileComponent } from './components/organization-profile/organization-profile.component';
import { FacilitiesListComponent } from './components/facilities-list/facilities-list.component';
import { AddFacilityComponent } from './components/add-facility/add-facility.component';
import { SetUpOrganizationComponent } from './components/set-up-organization/set-up-organization.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RegisterUnitComponent } from './components/register-unit/register-unit.component';
import { PopupService } from './services/popup.service';
import { PopupComponent } from './components/popup/popup.component';
import { PopupFormsComponent } from './components/popup/popupForms.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterMomentPipe } from './pipes/filter-moment.pipe';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { SavingStatisticsComponent } from './components/saving-statistics/saving-statistics.component';
import { UnassignedUnitsComponent } from './components/unassigned-units/unassigned-units.component';
import { InternalUsersListComponent } from './components/internal-users-list/internal-users-list.component';
import { RecoverPasswordEmailComponent } from './components/recover-password/recover-password-email/recover-password-email.component';
import { NavigationService } from './services/navigation.service';
import { ReAssignUnitComponent } from './components/re-assign-unit/re-assign-unit.component';
import { LoaderService } from './services/LoaderService';
import { LoaderInterceptor } from './services/LoaderInterceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SavingsStatisticsComponent } from './components/savings-statistics/savings-statistics.component';
import { InternalInviteManagementComponent } from './components/internal-invite-management/internal-invite-management.component';
import { InviteInternalUsersComponent } from './components/invite-internal-users/invite-internal-users.component';
import { AuthInterceptorService } from './services/AuthInterceptorService';
import { AddOrganizationComponent } from './components/add-organization/add-organization.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ConfirmAccountComponent,
    ForgotPasswordEmailComponent,
    SideNavbarComponent,
    AccountSettingsComponent,
    NavbarComponent,
    AssignNewUnitComponent,
    UnitsDashboardComponent,
    UnitDetailsComponent,
    RecoverPasswordComponent,
    FlushesHistoryComponent,
    UsersListComponent,
    UsersInviteComponent,
    InviteManagementComponent,
    FacilityProfileComponent,
    OrganizationProfileComponent,
    FacilitiesListComponent,
    AddFacilityComponent,
    SetUpOrganizationComponent,
    DashboardComponent,
    RegisterUnitComponent,
    PopupComponent,
    PopupFormsComponent,
    FilterMomentPipe,
    OrganizationListComponent,
    SavingStatisticsComponent,
    UnassignedUnitsComponent,
    InternalUsersListComponent,
    RecoverPasswordEmailComponent,
    ReAssignUnitComponent,
    LoaderComponent,
    SavingsStatisticsComponent,
    InternalInviteManagementComponent,
    InviteInternalUsersComponent,
    AddOrganizationComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    GaugeModule.forRoot(),
    CarouselModule,
    ChartsModule,
    NgbModule,
    NgxSpinnerModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    AppServices,
    PopupService,
    NavigationService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  exports:[FilterMomentPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
