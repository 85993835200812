<div class="navbar text-center">
  <div class="date">{{ fecha }}</div>
  <div class="r-menu">
      <div class="user-info margin-user">
          {{ getData() }}
          <span class="top-span">{{ rolOrFacilityOrganization }}</span>
      </div>
      <div id="circulo">{{subCadena}}</div>
      <!-- <img [src]="'assets/images/icons/avatar.svg'" alt="Usuario" width="40px" height="auto"> -->
  </div>
</div>
