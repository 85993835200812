import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionMenuService {
  private grupoMenu: any[] = [{ grupo: 'group'}];
  private menu = new BehaviorSubject<any[]>([]);

  menuActive$ = this.menu.asObservable();

  constructor() {
    this.menu.next(this.grupoMenu);
  }

  /* Menu
  OL = Organizations List
  FL = Facilities List
  UD = Units Hub
  UL = External Users List
  UU = Unassigned Units
  IU = Internal Users List
  AS = Account Settings
  SS = Saving Statistics
  IM = Invites Management
  SO = Set up your organization
  */
  setSelectMenu(group) {
    this.grupoMenu = [];
    this.grupoMenu.push([{ grupo: group}]);
    this.menu.next(this.grupoMenu);
  }
  getSelectMenu() {
    return this.grupoMenu;
  }
}
