<div>
  <div class="modal-body" style="padding-left: 0px;padding-right: 0px;">
    <div class="row">
            <div class="col-md-6 text-left">
                <a (click)="bsModalRef.hide()" style="color: #303FD7; text-decoration: none; cursor: pointer;">{{back}}</a>
                <!-- <img class="text-right" [src]="'assets/images/icons/left.svg'" alt="Janway Logo"/> -->
            </div>
        </div>
</div>
    <div class="modal-body text-center">
      <h1 style="margin-top: -0.5em;">
        {{title}}
        <br>
        <form >
          <div class="form" >
              <div class="row">
                      <div class="col-12 col-md-12 col-lg-12">
                          <div class="form-group">
                              <span style="font-size: 18px; margin-right: 290px;">Unity Name</span>
                              <input type="text" class="form-control input" placeholder="Unity name">
                          </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-12">
                          <div class="form-group">
                              <span style="font-size: 18px; margin-right: 290px;">New Facility</span>
                              <input type="text" class="form-control input" placeholder="Facility name">
                          </div>
                      </div>
                     
              </div>
          </div>
                  
        </form>
      </h1>
      <p>
        <button style="border-radius: 26px; color: #00CFFF; font-weight: 700;" type="button" (click)="bsModalRef.hide()" translate="cancel" class="btn btn-primary full-width">{{btn1}}</button>
      </p>
      <p>
        <button style="border-radius: 26px; color:#303FD7; background: #ffffff !important; font-weight: 700;" type="button" (click)="clickOk()" translate="ok" class="btn btn-light full-width">{{btn2}}</button>
      </p>
    </div>
</div>