<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-3 col-md-3 col-lg-3" *ngIf="dataFacility !==null">
        <!-- <h1>Facility<br>{{ dataFacility.data.facility.name }}</h1> -->
        <h1>{{ dataFacility.data.facility.name }}</h1>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
        <div class="row">
          <div class="col-4 col-sm-4 col-md-5 col-lg-4" *ngIf="dataFacility !==null">
            <label for="address">address</label>
            <p class="info">{{ dataFacility.data.address?.address || 'No address' }}</p>
            <label for="phone">phone number</label>
            <p class="info">{{ dataFacility.data.facility.phoneNumber}}</p>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4" *ngIf="dataFacility !==null">
            <label for="city">City</label>
            <p class="info">{{ dataFacility.data.address?.city || 'No city' }}</p>
            <label for="state">state</label>
            <p class="info">{{dataFacility.data.address?.stateName || 'No state'}}</p>
          </div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-4" *ngIf="dataFacility !==null">
            <label for="units">Units</label>
            <p class="info">{{ dataFacility.data.unitsNumber }}</p>
            <label for="members">Members</label>
            <p class="info">{{ dataFacility.data.usersNumber }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3 col-md-3 col-lg-3 btn-col" *ngIf="dataFacility !==null">
        <button class="btn btn-primary btn-edit" (click)="openEditModal(edit)" >
          edit facility
        </button>
        <button class="btn btn-light btn-activate" (click)="openModal(activate)"
          *ngIf="!dataFacility['data'].facility.status">
          Activate facility
        </button>
        <button class="btn btn-light btn-deactivate" (click)="openModal(deactivate)"
          *ngIf="dataFacility['data'].facility.status">
          Deactivate facility
        </button>
        <!-- <button class="btn btn-light reboot-unit" (click)="openModal(template)">delete facility</button> -->
      </div>
    </div>
    <hr>
    <div class="user-card">
      <label for="units">units</label>
      <a class="right-action add top-units" routerLink="/assign-new-unit">Assign new unit</a>
      <a class="right-action dhub top-units" (click)="DetallUnit()" routerLink="/units-dashboard">See all units</a>
    </div>
    <owl-carousel-o [options]="customOptions" *ngIf="unitsFacility !== null">
      <ng-template carouselSlide *ngFor="let item of listFacilityCard">
        <!-- <div class="row" [ngClass]="{ 'hide-section': !hidenCarousel }">
          <div class="col-3">
            <div class="card">
              <h4>{{ item.name }}</h4>
              <div class="status good" *ngIf="item.health === 'Steady'"></div>
              <div class="status check" *ngIf="item.health === 'Warning'"></div>
              <div class="status bad" *ngIf="item.health === 'Critical'"></div>
              <p class="network">
                <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">
                {{ item.ipAddress }}
              </p>
              <p class="status">
                <span class="offline" *ngIf="item.status === 'Offline'"></span>
                <span class="online" *ngIf="item.status === 'Online'"></span>
                {{ item.status}}
                <span class="details" [routerLink]="['/unit-details/' + item.id]">
                  Details
                  <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
                </span>
              </p>
            </div>
          </div>
        </div> -->
        <div class="card">
          <h4>{{ item.name }} </h4>
          <div class="status good" *ngIf="item.health === 'Steady'"></div>
          <div class="status check" *ngIf="item.health === 'Warning'"></div>
          <div class="status bad" *ngIf="item.health === 'Critical'"></div>
          <p class="network">
            <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">
            {{ item.ipAddress }}
          </p>
          <p class="status">
            <span class="offline" *ngIf="item.status === 'Offline'"></span>
            <span class="online" *ngIf="item.status === 'Online'"></span>
            {{ item.status}}
            <span class="details class-details" [routerLink]="['/unit-details/' + item.id]" (click)="DetallUnit()"
              style="z-index: 999!important;">
              Details
              <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
            </span>
          </p>
        </div>
      </ng-template>
      <ng-template carouselSlide *ngIf="listFacilityCard.length < 2">
        <div class="col-3 hide-section">
          <div class="card">
            <h4></h4>
            <div class="status good"></div>
            <div class="status check"></div>
            <div class="status bad"></div>
            <p class="network">
              <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">

            </p>
            <p class="status">
              <span class="offline"></span>
              <span class="online"></span>

              <span class="details">
                Details
                <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
              </span>
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide *ngIf="listFacilityCard.length < 3">
        <div class="col-3 hide-section">
          <div class="card">
            <h4></h4>
            <div class="status good"></div>
            <div class="status check"></div>
            <div class="status bad"></div>
            <p class="network">
              <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">

            </p>
            <p class="status">
              <span class="offline"></span>
              <span class="online"></span>

              <span class="details">
                Details
                <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
              </span>
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide *ngIf="listFacilityCard.length < 4">
        <div class="col-3 hide-section">
          <div class="card">
            <h4></h4>
            <div class="status good"></div>
            <div class="status check"></div>
            <div class="status bad"></div>
            <p class="network">
              <img [src]="'assets/images/icons/network-wifi.svg'" alt="Network">

            </p>
            <p class="status">
              <span class="offline"></span>
              <span class="online"></span>

              <span class="details">
                Details
                <img [src]="'assets/images/icons/arrow-details.svg'" alt="Details">
              </span>
            </p>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
    <div class="user-card">
      <label for="users">users</label>
      <div class="right-action add top-users" routerLink="/users-invite">Invite new user</div>
      <div class="right-action users top-users" (click)="DetallUnit()" routerLink="/users-list">See all users</div>
    </div>
    <div class="accent-card" *ngIf="supervisorUser !== null">
      <div class="row data" *ngFor="let item of listDataUsers">
        <div class="col-6 col-md-3 col-lg-3 font-h">
          <h5 class="font-sizeH">{{ item.name }}</h5>
        </div>
        <div class="col-2 col-md-2 col-lg-2 role">
          <p class="info">{{ item.role }}</p>
        </div>
        <div class="col-3 col-md-3 col-lg-3 email">
          <p class="info">{{ item.email }}</p>
        </div>
        <div class="col-3 col-md-2 col-lg-2 text-center status">
          <p class="status-box">
            <span class="info" *ngIf="item.status !== 'Active'">Inactive</span>
            <span class="status on" *ngIf="item.status === 'Active'">
              Active
            </span>
          </p>
        </div>
        <div class="col-3 col-md-2 col-lg-2 text-center actions">
          <button type="button" (click)="openModalOrganizationManager(organizationManager, item)"
            class="btn btn-primary actived" *ngIf="item.showBtnDeleteUpdateUser">
            <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role">
          </button>
          <button type="button" *ngIf="item.showBtnUpdateFacility" class="btn btn-light actived"
            (click)="openModalNewFacility(newFacility, item)">
            <img [src]="'assets/images/icons/change-icon-2.svg'" alt="Change Facility" style="width: 20px">
          </button>
          <button type="button" class="btn btn-light actived" (click)="openModalDeleteUser(deleteUser, item)"
            *ngIf="item.showBtnDeleteUpdateUser">
            <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete User">
          </button>

          <button type="button" class="btn btn-light disabled" disabled *ngIf="item.showBtnDeleteUpdateUser">
            <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
          </button>
          <button type="button" class="btn btn-light disabled" disabled *ngIf="item.showBtnUpdateFacility">
            <img [src]="'assets/images/icons/change-icon-disable-2.svg'" alt="Change Facility" style="width: 20px">
          </button>
          <button type="button" class="btn btn-light disabled" disabled *ngIf="item.showBtnDeleteUpdateUser">
            <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete User">
          </button>
        </div>
      </div>
    </div>

    <div class="btn-view-more" *ngIf="usersNumber > 5">
      <div class="row justify-content-md-center top-less">

        <div class="col-md-auto">

          <p class="text-center" *ngIf="activeLess">
            <button type="button"

              class="btn btn-light btn-view" (click)="viewLess()">View less</button>

          </p>

        </div>
        <div class="col-md-auto" *ngIf="!isDisabledUser">
          <p class="text-center btn-margin"*ngIf="supervisorUser?.data.length > 0">
            <button type="button"  class="btn btn-primary btn-more"
              (click)="viewMoreUsers()">View more</button>
          </p>
        </div>

      </div>
    </div>
    <div class="text-center" *ngIf="userRole !== 'FA'">
      <button type="button" routerLink="/facilities-list" class="btn btn-light submit btn-back">
        Back to all facilities</button>
    </div>

  </div>
</div>
<!-- Modals -->
<ng-template #edit>
  <div class="modal-body">
    <form [formGroup]="facilityForm" (ngSubmit)="onSubmit()">
      <p class="back" (click)="closeModal(edit)">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
        Back to facility profile
      </p>
      <div class="error" *ngIf="msgError.length !==0"
      >
        <ul>
          <li *ngFor="let error of msgError">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="error bottom-btn"
     >
        <span> {{ mensajeEr }} </span>
      </div>
      <div class="form-group" *ngIf="internalRol">
        <label for="Organization">Organization</label>
        <select class="form-control input select" id="idOrganizacion" formControlName="idOrganizacion">
          <option [value]="0" selected>Select Organization</option>
          <option *ngFor="let org of listOrganization" [value]="org.id">
            {{ org.name }}
          </option>
        </select>
        <div *ngIf="facilityForm.controls['idOrganizacion'].invalid &&
                (facilityForm.controls['idOrganizacion'].dirty ||
                facilityForm.controls['idOrganizacion'].touched)" class="invalid-feedback">
          <div *ngIf="facilityForm.controls['idOrganizacion'].errors.required">* Organization is required
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="facilityName">Name</label>
        <input type="text" class="form-control input" formControlName="facilityName" id="facilityName"
          placeholder="Name">
        <div
          *ngIf="facilityForm.controls['facilityName'].invalid && (facilityForm.controls['facilityName'].dirty || facilityForm.controls['facilityName'].touched)"
          class="invalid-feedback">
          <div *ngIf="facilityForm.controls['facilityName'].errors.required">* Name is required</div>

          <div *ngIf="facilityForm.controls['facilityName'].errors.maxlength">
            * Name must have at most 50 characters
          </div>
          <div *ngIf="facilityForm.controls['facilityName'].errors.minlength">
            * Name must be at least 5 characters long
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="address">Address</label>
        <input type="text" class="form-control input" formControlName="address" id="address"
          placeholder="4348 Rubaiyat Road Grand Rapids, MI 49503">
        <div *ngIf="facilityForm.controls['address'].invalid && (facilityForm.controls['address'].dirty
                || facilityForm.controls['address'].touched)" class="invalid-feedback">
          <div *ngIf="facilityForm.controls['address'].errors.required">* Address is required</div>
          <div *ngIf="facilityForm.controls['address'].errors.maxlength">
            * Address must have at most 100 characters
          </div>
          <div *ngIf="facilityForm.controls['address'].errors.minlength">
            * Address must be at least 5 characters long
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="state">State</label>
        <select class="form-control input select-old" formControlName="state" id="state" required>
          <option value="0" selected>Select State</option>
          <option *ngFor="let state of listState" [value]="state.id">
            {{ state.name }}
          </option>
        </select>
        <div
          *ngIf="facilityForm.controls['state'].invalid && (facilityForm.controls['state'].dirty || facilityForm.controls['state'].touched)"
          class="invalid-feedback">
          <div *ngIf="facilityForm.controls['state'].errors.stateSelected">* State is required</div>
        </div>
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input type="text" class="form-control input" formControlName="city" id="city" placeholder="City">
        <div *ngIf="facilityForm.controls['city'].invalid && (facilityForm.controls['city'].dirty ||
              facilityForm.controls['city'].touched)" class="invalid-feedback">
          <div *ngIf="facilityForm.controls['city'].errors.required">* City is required</div>
          <div *ngIf="facilityForm.controls['city'].errors.maxlength">
            * City must have at most 50 characters
          </div>
          <div *ngIf="facilityForm.controls['city'].errors.minlength">
            * City must be at least 5 characters long
          </div>

        </div>
      </div>
      <div class="form-group">
        <label for="phoneNumber">Phone Number</label>
        <input type="tel" class="form-control input" formControlName="phoneNumber" id="phoneNumber"
          placeholder="123 123 1234">
        <div
          *ngIf="facilityForm.controls['phoneNumber'].invalid && (facilityForm.controls['phoneNumber'].dirty || facilityForm.controls['phoneNumber'].touched)"
          class="invalid-feedback">
          <div *ngIf="facilityForm.controls['phoneNumber'].errors.required">* Phone Number is required</div>
          <div *ngIf="facilityForm.controls['phoneNumber'].errors.pattern">* Phone Number pattern is not valid</div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" [disabled]="facilityForm.invalid"
           (click)="closeFirstModal(edit)"
          class="btn btn-primary submit btn-modal btn-save">save changes</button>
      </div>
     <!--  <p class="bottom-info text-center">Disclaimer here?</p> -->
    </form>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-body text-center modal-fit">
    <p class="back" (click)="closeModal(template)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
      Back to facility profile
    </p>
    <h1 class="modal-title">
      Are you sure you want to

      <span class="accent-text">delete</span> this facility?
    </h1>
    <p>
      <button type="button" (click)="closeModal(template)" class="btn btn-primary submit full-width">no, I want to
        go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeFirstModal(template)">yes I want to delete it</button>
    </p>
  </div>
</ng-template>

<ng-template #deactivate>
  <div class="modal-body text-center modal-fit">
    <p class="back" (click)="closeModal(deactivate)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
      Back to facility profile
    </p>
    <div class="error"  *ngIf="msgError.length !==0">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="error btn-nobottom" >
      <span> {{ mensajeEr }} </span>
    </div>
    <h1 class="modal-title margin-modal" >
      Are you sure you want to

      <span class="accent-text">deactivate</span> this facility?
    </h1>
    <p>
      <button type="button" (click)="closeModal(deactivate)" class="btn btn-primary submit full-width">no, I
        want to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeModalDesactive(deactivate)">yes I want to deactivate
        it</button>
    </p>
  </div>
</ng-template>

<ng-template #activate>
  <div class="modal-body text-center modal-fit">
    <p class="back" (click)="closeModal(activate)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
      Back to facility profile
    </p>
    <div class="error"  *ngIf="msgError.length !==0">
      <ul>
        <li *ngFor="let error of msgError">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="error btn-nobottom">
      <span> {{ mensajeEr }} </span>
    </div>
    <h1 class="modal-title btn-notop">
      Are you sure you want to

      <span class="accent-text">activate</span> this facility?
    </h1>
    <p>
      <button type="button" (click)="closeModal(activate)" class="btn btn-primary submit full-width">no, I want
        to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeModalActivate(activate)">yes I want to activate it</button>
    </p>
  </div>
</ng-template>

<ng-template #organizationManager>
  <div class="modal-body">
    <form [formGroup]="facilityOrganizationManager" (ngSubmit)="onSubmit()">
      <p class="back" (click)="closeModal(organizationManager)">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
        Back to facility profile
      </p>

      <div class="error"  *ngIf="msgError.length !==0">
        <ul>
          <li *ngFor="let error of msgError">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="error bottom-btn">
        <span> {{ mensajeEr }} </span>
      </div>
      <div class="form-group">
        <label for="newRole">Role</label>
        <select class="form-control input select-old" id="newRole" formControlName="newRole" required>
          <option value="0" selected>Select Role</option>
          <option *ngFor="let role of listDataUser" [value]="role.id">
            {{ role.name }}
          </option>
        </select>
        <div *ngIf="facilityOrganizationManager.controls['newRole'].invalid && (facilityOrganizationManager.controls['newRole'].dirty ||
            facilityOrganizationManager.controls['newRole'].touched)" class="invalid-feedback">
          <div *ngIf="facilityOrganizationManager.controls['newRole'].errors.stateSelected">* Role is required</div>
        </div>
      </div>
      <p class="text-center">
        <button type="button" [disabled]="facilityOrganizationManager.invalid"
       class="btn-save"
          (click)="closeOrganizationManager(organizationManager)" class="btn btn-primary submit btn-save">save changes</button>
      </p>
      <p class="bottom-info text-center">An email will be sent to the user to notify this change</p>
    </form>
  </div>
</ng-template>

<ng-template #newFacility>
  <div class="modal-body">
    <form [formGroup]="newFacilityForm" (ngSubmit)="onSubmit()">
      <p class="back back32" (click)="closeModal(newFacility)">
        <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
        Back to facility profile
      </p>

      <div class="error"  >
        <ul>
          <li *ngFor="let error of msgErrorDeleteUsers">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="error bottom-btn">
        <span> {{ mensajeEr }} </span>
      </div>

      <div class="form-group">
        <label for="name">Facility</label>
        <select class="form-control input select-old" formControlName="name" id="name" required>
          <option value="0" selected>Select Facility</option>
          <option *ngFor="let facility of listFacility" [value]="facility.id">
            {{ facility.name }}
          </option>
        </select>
        <div
          *ngIf="newFacilityForm.controls['name'].invalid && (newFacilityForm.controls['name'].dirty || newFacilityForm.controls['name'].touched)"
          class="invalid-feedback">
          <div *ngIf="newFacilityForm.controls['name'].errors.stateSelected">* Facility is required</div>
        </div>
      </div>
      <p class="text-center">
        <button type="button" [disabled]="newFacilityForm.invalid"
         (click)="closeNewFacility(newFacility)"
          class="btn btn-primary submit btn-save">save changes</button>
      </p>
      <p class="bottom-info text-center">An email will be sent to the user to notify this change</p>
    </form>
  </div>
</ng-template>

<ng-template #deleteUser>
  <div class="modal-body text-center modal-fit">
    <p class="back back32" (click)="closeModalGeneral(deleteUser)">
      <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to Facility Profile">
      Back to facility profile
    </p>
    <div class="error"  >
      <ul>
        <li *ngFor="let error of msgErrorDeleteUsers">
          {{ error }}
        </li>
      </ul>
    </div>
    <h1 class="modal-title">
      Are you sure you want to

      <span class="accent-text">delete</span> this user?
    </h1>
    <p>
      <button type="button" (click)="closeModalGeneral(deleteUser)" class="btn btn-primary submit full-width">no, I want
        to go back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeModalDeleteUser(deleteUser)">yes I want to delete
        it</button>
    </p>
  </div>
</ng-template>
