import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppServices } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {
  public formAddress: FormGroup;
  public emailAddresss: String;
  public msgError: string = '';
  public msgErrorList: string[] = [];

  constructor(private appServices: AppServices, private router: Router,) {
    if (localStorage.getItem('jwt')) {
      this.router.navigate(['/dashboard']);
    }

    let  email = localStorage.getItem('emailForgot');
    this.formAddress = new FormGroup({
      emailAddresss: new FormControl(email, [Validators.required, Validators.email]),
    });

 
    //this.formAddress.valueChanges.subscribe(val => console.log(val))
  }

  ngOnInit() { }
  forgetPassword() {
    const { emailAddresss } = this.formAddress.value;
    const data = {
      email: emailAddresss
    }
    this.appServices.forgotPassword(data).subscribe(
      (dataResponse) => {
        if (dataResponse) {
          this.router.navigate(['/forgot-password-email']);
        }
      }, error => {
        let errors = JSON.parse(error);
        if (typeof errors === 'string') {
          this.msgError = errors;
        } else if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            //@ts-ignore
            const count = value.length;
            for (let i = 0; i < count; i++) {
              this.msgErrorList.push(value[i])
            }
          })
        }
      }
    )
  }

}
