import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { AppServices } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { RolsEnum } from '@Models/enum/rols.enum';
import { facilityRol, internalsRol, organizationRol } from '@Models/role.model';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export interface ResponseDataUsers {
  data: any;
  meta: any;
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.sass'],
})
export class UsersListComponent implements OnInit {
  activeFilters: boolean = false;
  modalRef: BsModalRef | null;
  userRole: RolsEnum;
  userSearchForm: FormGroup;
  userRolForm: FormGroup;
  userFacilityForm: FormGroup;
  NorRepe;
  dataUserList: ResponseDataUsers = {
    data: [],
    meta: [],
  };
  tituloModulo: string = '';
  isDisabled: boolean = false;
  allUserClassActive: boolean = true;
  onlineClassActive: boolean = false;
  OfflineClassActive: boolean = false;
  showBtnUserFacility: boolean = true;
  listOrganization: any[] = [];
  listFacilities: any = [];
  facilitiesList: any[] = [];
  listDataUser: any = [];
  listDataUserEdit: any[] = [];
  msgError: any[] = [];
  internalRol: boolean = false;
  oraganizationListRol: boolean = false;
  facilityListRol: boolean = false;
  organizationLocalStorageId = this.authService.getOrganizationId();
  facilityLocalStorageId = this.authService.getFacilityId();
  roleLevel = this.authService.getRoleLevel();
  linkModal: string = '';
  roleLevelComp;
  activeLess: boolean = false;
  message: string = '';
  rowActual;
  constructor(
    private modalService: BsModalService,
    private authService: AuthService,
    private fb: FormBuilder,
    private appServices: AppServices,
    private toastrService: ToastrService,
    private sideNavbarService: SideNavbarService,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('UL');
    this.userRole = this.authService.authStatus.getValue().role;
    /*const isInternalRol = internalsRol.filter((item) => item.role === this.userRole);
    this.internalRol = isInternalRol.length > 0 ? true : false;*/
    const userRole = this.authService.getAuthStatus();
    if (
      userRole.role === RolsEnum.FACILITY_ADMINISTRATOR ||
      userRole.role === RolsEnum.FACILITY_MANAGER
    ) {
      this.showBtnUserFacility = false;
    }
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;

    if (this.internalRol) {
      /*let menu = this.sideNavbarService.rolsMenu$;
      const indexMneChange = menu.findIndex((item) => item.link === '/invite-management');
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = '/users-list';
        menu[indexMneChange].ngbTooltip = 'External Users List';
        this.sideNavbarService.rolsMenu$ = menu;
      }*/
    }

    const organizationIdProfile = localStorage.getItem('organizationIdProfile');
    let organizationId =
      organizationIdProfile !== 'null' ? organizationIdProfile : 0;

    this.userSearchForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      facilityId: [
        this.facilityListRol === true ? +this.facilityLocalStorageId : 0,
      ],
      roleId: ['0'],
      email: [''],
      organizationId: [+organizationId],
    });

    this.userRolForm = this.fb.group({
      roleId: ['0', [this.validatorSelect()]],
    });

    this.userFacilityForm = this.fb.group({
      facilityId: [0, [this.validatorSelect()]],
    });
    this.getListOrganization();
  }

  ngOnInit(): void {
    const organizationIdProfile = localStorage.getItem('organizationIdProfile');
    let organizationId =
      organizationIdProfile !== 'null' ? organizationIdProfile : 0;

    let ProfileOrganizationId = localStorage.getItem('ProfileOrganizationId');
    let ProfileFacilityId = localStorage.getItem('ProfileFacilityId');

    if (ProfileOrganizationId !== null) {
      this.activeFilters = true;

      this.getListFacilities(+ProfileOrganizationId);

      this.userSearchForm = this.fb.group({
        firstName: [''],
        lastName: [''],
        facilityId: [ProfileFacilityId],
        roleId: ['0'],
        email: [''],
        organizationId: [+ProfileOrganizationId],
      });
      let locaRecor = localStorage.getItem('listRecord');
      if (locaRecor === 'null') {
        this.search();
      } else {
        this.dataUserList = JSON.parse(localStorage.getItem('listRecord'));


        if (this.dataUserList.meta.totalPages > 1) {
          this.activeLess = true;
        }
        this.isDisabled =
          this.dataUserList.meta.currentPage ===
          this.dataUserList.meta.totalPages
            ? true
            : false;
      }
    } else {
      this.getAllUsers();


      if (this.dataUserList.meta.totalPages > 1) {
        this.activeLess = true;
      }
      this.isDisabled =
        this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages
          ? true
          : false;
      if (!this.internalRol) {
        this.getListFacilities(+this.organizationLocalStorageId);
      } else if (organizationId !== 0) {
        this.getListFacilities(+organizationId);
      }
    }

    this.getRolesForUpdateSelect();
    this.userRole = this.authService.authStatus.getValue().role;
    this.tituloModulo =
      this.userRole === 'CS' || this.userRole === 'SM' || this.userRole === 'SA'
        ? 'List of External Users '
        : 'List of Users';
    this.linkModal =
      this.userRole === 'CS' || this.userRole === 'SM' || this.userRole === 'SA'
        ? 'Back to external users list'
        : 'Back to users list';

    this.roleLevelComp = +this.roleLevel;


    if (this.dataUserList.meta.totalPages === 1) {
      this.isDisabled = true;
    }
  }

  getListOrganization() {
    if (this.internalRol) {

      this.appServices.getForSelectOrganization().subscribe((data) => {
        this.listOrganization = data['data'];
      });
    }
  }

  getListFacilities(id: number) {
    this.appServices.getListFacility(id).subscribe((data) => {
      this.listFacilities = data['data'];
    });
  }

  getListFacilitiesEdit(id: number) {
    this.appServices.getListFacility(id).subscribe((data) => {
      this.facilitiesList = data['data'];
    });
  }

  getListFacilitiesEditUser(user: any, template: TemplateRef<any>) {
    this.appServices.getListFacility(user.organizationId).subscribe((data) => {
      this.facilitiesList = data['data'];
      //this.userFacilityForm.get('facilityId').setValue(+user.facilityId);
      this.modalRef = this.modalService.show(template, {
        id: user.id,
        class: 'modal-md modal-dialog-centered customListModal',
      });
      this.userFacilityForm.get('facilityId').setValue(user.facilityId);
    });
  }

  getRolesForUpdateSelect() {
    this.appServices.getRolesForUpdateSelect().subscribe((data) => {
      this.listDataUser = data['data'];
    });
  }

  GetExternalRolesForSelectEdit(roleId: string) {
    this.appServices.GetExternalRolesForSelectEdit(roleId).subscribe((data) => {
      this.listDataUserEdit = data['data'];
    });
  }

  getAllUsers(refres: boolean = false) {
    const urlEndPoint = this.urlService();

    const organizationIdProfile = localStorage.getItem('organizationIdProfile');

    if (organizationIdProfile === 'null') {
      let listpage =
        JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;

      if (listpage) {
        this.appServices.getUsers(urlEndPoint).subscribe(
          (data) => {
            this.dataUserList.data = data['data'];
            const dataUser = this.dataUserList.data;
            const rolIdUsuarioLogueado = +this.roleLevel;
            const rolUserIsFacility = this.facilityListRol;
            this.dataUserList.data = dataUser.map(function (user) {
              const rolLebelUserSelected = user.roleLevel;
              const rolLebelUserLogueado = rolIdUsuarioLogueado;
              const itemRolUser = user.role;
              const itemRolUserIsFacility = itemRolUser.includes('Facility');
              user['showBtnDeleteUpdateUser'] =
                rolLebelUserLogueado < rolLebelUserSelected ? true : false;
              user['showBtnUpdateFacility'] =
                !rolUserIsFacility && itemRolUserIsFacility ? true : false;


              //
              return user;
            });
            this.dataUserList.meta = data['meta'];
            this.isDisabled =
              this.dataUserList.meta.totalPages <= 1 ? true : false;
          },
          (error) => {

            this.isDisabled = true;
          }
        );

        if (this.dataUserList.data.length === 0) {
          this.isDisabled = true;
        }
      } else {
        this.dataUserList = JSON.parse(localStorage.getItem('listRecord'));
        this.isDisabled =
          this.dataUserList.meta.currentPage ===
          this.dataUserList.meta.totalPages
            ? true
            : false;
        if (this.dataUserList.data.length > 5) {
          this.activeLess = true;
        }
      }
      this.isDisabled = this.dataUserList.meta.totalPages <= 1 ? true : false;
    } else {
      this.activeFilters = true;
      this.search();
    }


  }

  filters() {
    this.activeFilters = !this.activeFilters;
  }

  allUser() {
    this.allUserClassActive = true;
    this.onlineClassActive = false;
    this.OfflineClassActive = false;
    this.isDisabled = false;
    let localOrganizationId =
      this.organizationLocalStorageId !== null
        ? +this.organizationLocalStorageId
        : 0;
    let url = this.internalRol
      ? '/Users/GetAll?PageSize=5'
      : '/Users/GetAll?PageSize=5&OrganizationId=' + localOrganizationId;
    this.appServices.getUsers(url).subscribe((data) => {
      this.dataUserList.data = data['data'];
      const dataUser = this.dataUserList.data;
      const rolIdUsuarioLogueado = +this.roleLevel;
      const rolUserIsFacility = this.facilityListRol;
      this.dataUserList.data = dataUser.map(function (user) {
        const rolLebelUserSelected = user.roleLevel;
        const rolLebelUserLogueado = rolIdUsuarioLogueado;
        const itemRolUser = user.role;
        const itemRolUserIsFacility = itemRolUser.includes('Facility');
        user['showBtnDeleteUpdateUser'] =
          rolLebelUserLogueado < rolLebelUserSelected ? true : false;
        user['showBtnUpdateFacility'] =
          !rolUserIsFacility && itemRolUserIsFacility ? true : false;


        return user;
      });
      // this.dataUserList.meta = data['meta']

      this.dataUserList.data = data['data'];
      this.dataUserList.meta = data['meta'];

      this.isDisabled = this.dataUserList.meta.totalPages <= 1 ? true : false;

      //this.isDisabled = (this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages) ? true : false;
    });


  }

  onlineUsers(option: string) {
    this.allUserClassActive = false;
    this.isDisabled = false;
    this.isDisabled = false;
    this.onlineClassActive = option === 'Active' ? true : false;
    this.OfflineClassActive = option === 'Inactive' ? true : false;
    let Status = option === 'Active' ? true : false;
    let localOrganizationId =
      this.organizationLocalStorageId !== null
        ? +this.organizationLocalStorageId
        : 0;
    let url = this.internalRol
      ? '/Users/GetAll?PageSize=5&IsActive=' + Status
      : '/Users/GetAll?PageSize=5&OrganizationId=' +
        localOrganizationId +
        '&IsActive=' +
        Status;
    this.appServices.getUsers(url).subscribe((data) => {
      this.dataUserList.data = data['data'];
      const dataUser = this.dataUserList.data;
      const rolIdUsuarioLogueado = +this.roleLevel;
      const rolUserIsFacility = this.facilityListRol;
      this.dataUserList.data = dataUser.map(function (user) {
        const rolLebelUserSelected = user.roleLevel;
        const rolLebelUserLogueado = rolIdUsuarioLogueado;
        const itemRolUser = user.role;
        const itemRolUserIsFacility = itemRolUser.includes('Facility');
        user['showBtnDeleteUpdateUser'] =
          rolLebelUserLogueado < rolLebelUserSelected ? true : false;
        user['showBtnUpdateFacility'] =
          !rolUserIsFacility && itemRolUserIsFacility ? true : false;

        //this.isDisabled = (this.dataUserList.meta.totalPages <= 1) ? true : false;
        return user;
      });

      this.dataUserList.data = data['data'];
      this.dataUserList.meta = data['meta'];
      this.isDisabled =
        this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages
          ? true
          : false;
      this.isDisabled = this.dataUserList.meta.totalPages <= 1 ? true : false;
    });
  }

  async viewMore() {
    if (!this.isDisabled) {
      this.activeLess = true;
      let pageNumber = this.dataUserList.meta.currentPage + 1;
      if (this.dataUserList.meta.totalPages === 1) {
        this.isDisabled = true;
      }
      let urlService = this.urlService();
      if (urlService) {
        let dataViewMore = await this.appServices
          .getAllFacility(urlService, pageNumber)
          .toPromise();
        //this.dataUserList.meta = dataViewMore['meta'];
        const dataUser = dataViewMore['data'];
        const rolIdUsuarioLogueado = +this.roleLevel;
        const rolUserIsFacility = this.facilityListRol;
        dataViewMore['data'] = dataUser.map(function (user) {
          const rolLebelUserSelected = user.roleLevel;
          const rolLebelUserLogueado = rolIdUsuarioLogueado;
          const itemRolUser = user.role;
          const itemRolUserIsFacility = itemRolUser.includes('Facility');
          user['showBtnDeleteUpdateUser'] =
            rolLebelUserLogueado < rolLebelUserSelected ? true : false;
          user['showBtnUpdateFacility'] =
            !rolUserIsFacility && itemRolUserIsFacility ? true : false;

          return user;
        });

        this.dataUserList.meta = dataViewMore['meta'];
        this.dataUserList.data = [
          ...this.dataUserList.data,
          ...dataViewMore['data'],
        ];
      }
    }

    this.isDisabled =
      this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages
        ? true
        : false;

    localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));
  }
  async viewLess() {
    let pageNumber = this.dataUserList.meta.currentPage - 1;
    if (this.dataUserList.meta.totalPages === 1) {
      this.isDisabled = true;
    }

    if (pageNumber === 1 || pageNumber === 0) {
      this.activeLess = false;
    }
    let urlService = this.urlService();
    if (urlService) {
      let dataViewMore = await this.appServices
        .getAllFacility(urlService, pageNumber)
        .toPromise();
      //this.dataUserList.meta = dataViewMore['meta'];
      const dataUser = dataViewMore['data'];
      const rolIdUsuarioLogueado = +this.roleLevel;
      const rolUserIsFacility = this.facilityListRol;
      dataViewMore['data'] = dataUser.map(function (user) {
        const rolLebelUserSelected = user.roleLevel;
        const rolLebelUserLogueado = rolIdUsuarioLogueado;
        const itemRolUser = user.role;
        const itemRolUserIsFacility = itemRolUser.includes('Facility');
        user['showBtnDeleteUpdateUser'] =
          rolLebelUserLogueado < rolLebelUserSelected ? true : false;
        user['showBtnUpdateFacility'] =
          !rolUserIsFacility && itemRolUserIsFacility ? true : false;

        return user;
      });

      if (pageNumber === 1 || pageNumber === 0) {
        this.dataUserList.data = [];

        this.dataUserList.meta = dataViewMore['meta'];
        this.dataUserList.data = [
          ...this.dataUserList.data,
          ...dataViewMore['data'],
        ];
      } else {
        this.dataUserList.meta = dataViewMore['meta'];
        this.dataUserList.data = [
          ...this.dataUserList.data,
          ...dataViewMore['data'],
        ];
        this.dataUserList.data.splice(this.dataUserList.data.length - 10, 10);
      }
    }

    this.isDisabled =
      this.dataUserList.meta.currentPage === this.dataUserList.meta.totalPages
        ? true
        : false;

    localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));
  }
  search() {
    //this.isDisabled = false;
    let urlService = this.urlService();
    if (urlService) {
      this.appServices.getUsers(urlService).subscribe(
        (data) => {
          this.dataUserList.data = data['data'];
          const dataUser = this.dataUserList.data;
          const rolIdUsuarioLogueado = +this.roleLevel;
          const rolUserIsFacility = this.facilityListRol;
          this.dataUserList.data = dataUser.map(function (user) {
            const rolLebelUserSelected = user.roleLevel;
            const rolLebelUserLogueado = rolIdUsuarioLogueado;
            const itemRolUser = user.role;
            const itemRolUserIsFacility = itemRolUser.includes('Facility');
            user['showBtnDeleteUpdateUser'] =
              rolLebelUserLogueado < rolLebelUserSelected ? true : false;
            user['showBtnUpdateFacility'] =
              !rolUserIsFacility && itemRolUserIsFacility ? true : false;

            return user;
          });
          this.dataUserList.meta = data['meta'];
          this.isDisabled =
            this.dataUserList.meta.totalPages <= 1 ? true : false;
        },
        (error) => {

          this.isDisabled = true;
        }
      );
    }
  }

  urlService() {
    let url = '/Users/GetAll?PageSize=5';
    let paramFirstName = this.userSearchForm.get('firstName').value;
    let paramLastName = this.userSearchForm.get('lastName').value;
    let paramFacilityId = Number(this.userSearchForm.get('facilityId').value);
    let paramRoleId = this.userSearchForm.get('roleId').value;
    let paramEmail = this.userSearchForm.get('email').value;
    let paramOrganization = +this.userSearchForm.get('organizationId').value;

    let localOrganizationId =
      this.organizationLocalStorageId !== null
        ? +this.organizationLocalStorageId
        : 0;

    if (!(this.roleLevel === '6' || this.roleLevel === '7')) {
      if (this.internalRol) {
        if (paramOrganization > 0) {
          url = url + '&OrganizationId=' + paramOrganization;
        }
      } else {
        url = url + '&OrganizationId=' + localOrganizationId;
      }
    }

    if (!this.allUserClassActive) {
      const status = this.onlineClassActive ? true : false;
      url = url + '&IsActive=' + status;
    }

    if (paramFirstName !== '') {
      url = url + '&FirstName=' + paramFirstName;
    }

    if (paramLastName !== '') {
      url = url + '&LastName=' + paramLastName;
    }

    if (paramFacilityId !== 0) {
      url = url + '&FacilityId=' + paramFacilityId;
    }

    if (paramRoleId !== '0') {
      url = url + '&RoleId=' + paramRoleId;
    }

    if (paramEmail !== '') {
      url = url + '&Email=' + paramEmail;
    }
    return url;
  }

  selectedState(event) {}

  async openModal(template: TemplateRef<any>, user, index) {
    this.rowActual = index;
    this.msgError = [];
    //this.GetExternalRolesForSelectEdit(user.roleId);
    this.appServices
      .GetExternalRolesForSelectEdit(user.roleId)
      .subscribe((data) => {
        this.listDataUserEdit = data['data'];
        this.userRolForm.patchValue({
          roleId: user.roleId,
        });
        this.modalRef = this.modalService.show(template, {
          id: user.id,
          class: 'modal-md modal-dialog-centered customListModal',
        });
      });
  }

  openModalFacility(template: TemplateRef<any>, user, index) {
    this.msgError = [];
    this.rowActual = index;



    this.getListFacilitiesEditUser(user, template);
  }

  openDeleteModal(template: TemplateRef<any>, user, index) {
    this.rowActual = index;
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: user.id,
      class: 'modal-md modal-dialog-centered modal-fit customListModal',
    });
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.msgError = [];
  }

  closeModalUpdateFacility() {
    this.resetForm(this.userFacilityForm);
    this.closeModal();
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  async closeFirstModal(succesfully) {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const dataRole = {
      id: this.modalRef.id,
      roleId: this.userRolForm.get('roleId').value,
    };
    this.appServices.updateRole(dataRole).subscribe(
      (data) => {
        this.modalRef.hide();
        this.modalRef = null;
        this.message = 'role updated';
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });


        let dataCom = this.userRolForm.get('roleId');
        this.dataUserList.data[this.rowActual].roleId = dataCom.value;
        this.dataUserList.data[this.rowActual].role =
          this.listDataUserEdit.find((d) => d.id === dataCom.value).name;

        this.dataUserList = this.dataUserList;
        localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));

        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
        //localStorage.removeItem('listRecord');
        //this.getAllUsers();

        this.userRolForm.reset();
        this.userRolForm.patchValue({
          roleId: '0',
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (errorValue) {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
        /*this.userRolForm.reset();
        this.userRolForm.patchValue({
          roleId: user.roleId,
        });*/
      }
    );
    /*if (this.userRolForm.get('roleId').value !== '0') {

    } else {
      this.modalRef.hide();
      this.modalRef = null;
      this.userRolForm.reset();
      this.userRolForm.patchValue({
        roleId: '0',
      })
    }*/
  }

  async closeModalFacility(succesfully) {
    if (!this.modalRef) {
      return;
    }

    const dataRole = {
      userId: this.modalRef.id,
      facilityId: +this.userFacilityForm.get('facilityId').value,
    };
    this.msgError = [];
    this.appServices.relocate(dataRole).subscribe(
      (data) => {
        this.modalRef.hide();
        this.modalRef = null;
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        //setTimeout(this.modalRef.hide,5000)
        let dataCom = this.userFacilityForm.get('facilityId').value;
        this.dataUserList.data[this.rowActual].facilityId = dataCom;
        localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));

        this.message = 'facility updated';
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);

        this.userFacilityForm.reset();
        this.userFacilityForm.patchValue({
          facilityId: +this.userFacilityForm.get('facilityId').value,
        });
        //localStorage.removeItem('listRecord');
        //this.getAllUsers();
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
    /*if (+this.userFacilityForm.get('facilityId').value > 0) {
    } else {
      this.userFacilityForm.reset();
      this.modalRef.hide();
      this.modalRef = null;
      this.userFacilityForm.patchValue({
        facilityId: 0,
      })

    }*/
  }

  closeDeletetModal(succesfully) {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const dataRole = {
      id: this.modalRef.id,
    };
    this.appServices.deleteUser(dataRole).subscribe(
      (data) => {
        //this.toastrService.success('Succes', 'Was successfully');
        this.modalRef.hide();
        this.modalRef = null;
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        //this.dataUserList.data.splice(this.rowActual, 1);

        this.message = 'deleted';

        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
        let pageNumber = this.dataUserList.meta.currentPage;
        this.dataUserList.meta = [];
        this.dataUserList.data = [];

        for (let i = 0; i < pageNumber; i++) {
          this.dataPageReturn(i + 1);
        }

        //localStorage.removeItem('listRecord');
        //this.getAllUsers();
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
  }

  async dataPageReturn(pageNumber) {
    let urlService = this.urlService();
    let dataViewMore = await this.appServices
      .getAllFacility(urlService, pageNumber)
      .toPromise();
    //this.dataUserList.meta = dataViewMore['meta'];
    const dataUser = dataViewMore['data'];
    const rolIdUsuarioLogueado = +this.roleLevel;
    const rolUserIsFacility = this.facilityListRol;
    dataViewMore['data'] = dataUser.map(function (user) {
      const rolLebelUserSelected = user.roleLevel;
      const rolLebelUserLogueado = rolIdUsuarioLogueado;
      const itemRolUser = user.role;
      const itemRolUserIsFacility = itemRolUser.includes('Facility');
      user['showBtnDeleteUpdateUser'] =
        rolLebelUserLogueado < rolLebelUserSelected ? true : false;
      user['showBtnUpdateFacility'] =
        !rolUserIsFacility && itemRolUserIsFacility ? true : false;

      return user;
    });

    this.dataUserList.meta = dataViewMore['meta'];
    this.dataUserList.data = [
      ...this.dataUserList.data,
      ...dataViewMore['data'],
    ];
    localStorage.setItem('listRecord', JSON.stringify(this.dataUserList));
    if (this.dataUserList.data.length === 0 && pageNumber === 2) {
      this.activeLess = false;
    }
  }

  changeMenu(url) {
    localStorage.setItem('listRecord', null);
    if (this.internalRol) {
      /*let menu = this.sideNavbarService.rolsMenu$;
      const indexMneChange = menu.findIndex((item) => item.link === '/users-list');
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = url;
        menu[indexMneChange].ngbTooltip = 'Invites Management';
        this.sideNavbarService.rolsMenu$ = menu;
      }*/
    }
  }

  validatorSelect(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { required: true };
    };
  }

  inputChange(evt) {
    let value = evt.target.value;
    if (this.internalRol) {
      this.userSearchForm.patchValue({ facilityId: 0 });
      this.getListFacilities(+value);
    }
  }

  validationfac(idfacility, roleLevel) {
    const facilcityid = +this.facilityLocalStorageId;
    const userRole = this.authService.getAuthStatus();
    let userFacility = true;
    if (roleLevel >= 6) {
      if (facilcityid > idfacility) {
        if (
          userRole.role === RolsEnum.FACILITY_ADMINISTRATOR ||
          userRole.role === RolsEnum.FACILITY_MANAGER
        ) {
          userFacility = false;
        }
      }
    }

    return userFacility;
  }
}
