import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  Validators,
  FormGroup,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { RolsEnum } from '@Models/enum/rols.enum';
import { UnitModel } from '@Models/unit/unit.model';
import { AppServices } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { facilityRol, organizationRol } from '@Models/role.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ReAssignUnitModel } from '@Models/unit/reassign.model';
import { ActionMenuService } from 'src/app/services/action-menu.service';

@Component({
  selector: 'app-re-assign-unit',
  templateUrl: './re-assign-unit.component.html',
  styleUrls: ['./re-assign-unit.component.sass'],
})
export class ReAssignUnitComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private appServices: AppServices,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: ActivatedRoute,
    private routeNav: Router,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('UU');
  }

  errorsArray: string[] = [];
  unitsForm: FormGroup;
  listOrganization: [];
  organizationLocalStorageId = this.authService.getOrganizationId();
  oraganizationListRol: boolean = false;
  public userRole: RolsEnum;
  internalRol: boolean = false;
  facilityListRol: boolean = false;
  modalRef: BsModalRef | null;
  public dataUnit = new UnitModel();
  position: number = 1;
  idUnits;
  optionFacility = {
    listFacility: [],
  };

  ngOnInit(): void {
    this.userRole = this.authService.authStatus.getValue().role;
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );
    this.facilityListRol = isFacilityRol.length > 0 ? true : false;

    this.unitsForm = this.fb.group({
      units: this.fb.array([]),
    });

    this.idUnits = this.router.params['_value'];

    this.addQuantity(0);
    this.getListOrganization();
  }

  addQuantity(i) {
    i == 0 && this.position == 1 ? (this.position = i) : this.position++;
    this.units.push(this.newQuantity(i));
  }

  newQuantity(i: number): FormGroup {
    let organizationId =
      this.organizationLocalStorageId === null
        ? '0'
        : this.organizationLocalStorageId;
    const facilityLocalStorage = localStorage.getItem('facilityId');
    let facilityId = this.facilityListRol ? +facilityLocalStorage : 0;

    if (this.oraganizationListRol || this.internalRol) {
      this.getListFacility(+organizationId, i + 1);
    }
    return this.fb.group({
      id: Number(this.idUnits.id),
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],
      //suinNumber: ['', [Validators.required, Validators.minLength(99)]],
      facilityId: [facilityId, [this.validatonFacility()]],
      organizationId: ['0'],
    });
  }

  validatonFacility(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value =
        typeof control.value === 'number'
          ? control.value.toString()
          : control.value;
      if (value !== '0') {
        return null;
      }
      return { requiredFacility: true };
    };
  }
  onSubmit() {}

  get units(): FormArray {
    return this.unitsForm.get('units') as FormArray;
  }

  getListOrganization() {
    this.appServices.getForSelectOrganization().subscribe((data) => {
      this.listOrganization = data['data'];
    });
  }

  openModal(template: TemplateRef<any>) {
    let dataUnitSave = this.unitsForm.value.units;

    const dataSave: ReAssignUnitModel = new ReAssignUnitModel();

    dataUnitSave.map((item) => {
      dataSave.facilityId = Number(item.facilityId);
      dataSave.organizationId = Number(item.organizationId);
      dataSave.id = item.id;
      dataSave.name = item.name;
      dataSave.suinNumber = item.suinNumber;
    });


    this.appServices.postReAssignUnit(dataSave).subscribe(
      (data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, {
            id: 1,
            class: 'modal-lg modal-dialog-centered modal-fit succesfullyCustom',
          });
        }
      },
      (error) => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          let errorsList = [];
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.errorsArray = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach((value) => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                errorsList.push(valueError);
              }
            });
          }
          if (this.errorsArray.length === 0 && errors !== '') {
            this.errorsArray.push(errors);
          } else {
            this.errorsArray = errorsList;
          }
        } else {
          this.errorsArray.push(errors);
        }
      }
    );

    /* let organizationId = this.organizationLocalStorageId !== null ? +this.organizationLocalStorageId : 0;
    this.errorsArray = [];
    const dataSave: UnitModel[] = dataUnitSave.map((item) => {
      item.facilityId = Number(item.facilityId);
      item.organizationId = Number(item.organizationId);;
      return item;
    })
    this.appServices.postReAssignUnit(dataSave)
      .subscribe((data) => {
        if (data['data']) {
          const dataResponse = data['data'];
          this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg modal-dialog-centered modal-fit' });
        }
      }, error => {
        let errors = JSON.parse(error);
        if (typeof errors !== 'object') {
          const arrayExiste = errors.includes('-');
          let errorsList = [];
          const arrayErrors = arrayExiste ? errors.split('\n') : [];
          this.errorsArray = arrayErrors;
          if (arrayErrors.length > 0) {
            arrayErrors.forEach(value => {
              let valueError = value.replace('-', '');
              if (valueError !== '') {
                errorsList.push(valueError);
              }
            });
          }
          if (this.errorsArray.length === 0 && errors !== '') {
            this.errorsArray.push(errors);
          } else {
            this.errorsArray = errorsList;
          }
        } else {
          this.errorsArray.push(errors);
        }
      }) */
  }

  inputChange(evt: KeyboardEvent, key: string, i?: number) {
    //@ts-ignore
    let value = evt.target.value;
    if (this.unitsForm[key] !== value) {
      this.dataUnit[key] = value;
    }
    if (key === 'OrganizationId') {
      if (this.internalRol) {
        let taskListArrays = this.units;
        taskListArrays.controls[i].patchValue({ facilityId: 0 });
        this.getListFacility(+value, i);
      }
    }

  }

  getListFacility(organizationId?: number, i?: number) {
    this.optionFacility.listFacility[i] = [];
    if (organizationId) {

      this.appServices.getListFacility(organizationId).subscribe(
        (data: any) => {
          this.optionFacility.listFacility[i] = data['data'];

        },
        (error) => {
          let errors = JSON.parse(error);
          if (typeof errors !== 'object') {
            //this.toastrService.error('error', errors)
          }
        }
      );
    }
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.hide();
    this.modalRef = null;
    this.resetForm(this.unitsForm);
    this.unitsForm.setControl('units', this.fb.array([]));
    this.position = 1;
    this.addQuantity(0);
    this.errorsArray = [];
    this.routeNav.navigate(['/unassigned-units']);
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }
}
