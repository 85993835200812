import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ResponseDataUsers } from '../users-list/users-list.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppServices } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ActionMenuService } from 'src/app/services/action-menu.service';

@Component({
  selector: 'app-unassigned-units',
  templateUrl: './unassigned-units.component.html',
  styleUrls: ['./unassigned-units.component.sass'],
})
export class UnassignedUnitsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private appServices: AppServices,
    private modalService: BsModalService,
    private authService: AuthService,
    private router: Router,
    private actionMenu: ActionMenuService
  ) {
    this.actionMenu.setSelectMenu('UU');
  }

  internalRol: boolean = false;
  allUserClassActive: boolean = true;
  onlineClassActive: boolean = false;
  OfflineClassActive: boolean = false;
  activeFilters: boolean = false;
  optionFacility = [];
  listOrganization = [];
  dataUnassignedUnitsList: ResponseDataUsers = {
    data: [],
    meta: [],
  };
  isDisabled: boolean = false;
  userFacilityForm: FormGroup;
  modalRef: BsModalRef | null;
  msgError: any[] = [];
  userSearchForm: FormGroup;
  listDataUser: any = [];
  organizationLocalStorageId = this.authService.getOrganizationId();
  facilityLocalStorageId = this.authService.getFacilityId();
  activeLess: boolean = false;
  ngOnInit(): void {
    this.allUserClassActive = true;
    this.onlineClassActive = false;
    this.OfflineClassActive = false;

    this.userSearchForm = this.fb.group({
      MacAddress: [''],
      UinNumber: [null],
    });

    this.allUnassignedl();
    this.getListOrganization();
  }

  changeMenu(url) {
    localStorage.setItem('listRecord', null);
  }

  onlineUsers(option: string) {}

  allUnassignedl() {
    const urlEndPoint = this.urlService();
    let listpage =
      JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;

    if (listpage) {
      this.appServices.getUnassignedl(urlEndPoint).subscribe(
        (data) => {
          this.dataUnassignedUnitsList.data = data['data'];
          const dataUser = this.dataUnassignedUnitsList.data;

          this.dataUnassignedUnitsList.data = dataUser.map(function (user) {
            return user;
          });
          this.dataUnassignedUnitsList.meta = data['meta'];

          this.isDisabled =
            this.dataUnassignedUnitsList.meta.totalPages <= 1 ? true : false;
        },
        (error) => {

          this.isDisabled = true;
        }
      );
    } else {
      this.dataUnassignedUnitsList = JSON.parse(
        localStorage.getItem('listRecord')
      );
      // this.isDisabled =
      //   this.dataUnassignedUnitsList.meta.totalPages <= 1 ? true : false;
      this.isDisabled =
        this.dataUnassignedUnitsList.meta.currentPage ===
        this.dataUnassignedUnitsList.meta.totalPages
          ? true
          : false;
    }
  }

  filters() {
    this.activeFilters = !this.activeFilters;
  }

  async viewMore() {
    if (!this.isDisabled) {
      if (this.activeFilters) {
        this.search();
      } else {
        let pageNumber = this.dataUnassignedUnitsList.meta.currentPage + 1;
        this.activeLess = true;
        let urlService = this.urlService();
        if (urlService) {
          let dataViewMore = await this.appServices
            .getUnassignedl(urlService, pageNumber)
            .toPromise();
          //this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
          const dataUser = dataViewMore['data'];

          dataViewMore['data'] = dataUser.map(function (user) {
            return user;
          });

          this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
          this.dataUnassignedUnitsList.data = [
            ...this.dataUnassignedUnitsList.data,
            ...dataViewMore['data'],
          ];
        }
      }
    }
    this.isDisabled =
      this.dataUnassignedUnitsList.meta.currentPage ===
      this.dataUnassignedUnitsList.meta.totalPages
        ? true
        : false;

    localStorage.setItem(
      'listRecord',
      JSON.stringify(this.dataUnassignedUnitsList)
    );
  }
  async viewLess() {
    if (this.activeFilters) {
      this.search();
    } else {
      let pageNumber = this.dataUnassignedUnitsList.meta.currentPage - 1;
      if (pageNumber === 1 || pageNumber === 0) {
        this.activeLess = false;
      }

      let urlService = this.urlService();
      if (urlService) {
        let dataViewMore = await this.appServices
          .getUnassignedl(urlService, pageNumber)
          .toPromise();
        //this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
        const dataUser = dataViewMore['data'];

        dataViewMore['data'] = dataUser.map(function (user) {
          return user;
        });

        /*         this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
        this.dataUnassignedUnitsList.data = [
          ...this.dataUnassignedUnitsList.data,
          ...dataViewMore['data'],
        ]; */
        if (pageNumber === 1 || pageNumber === 0) {
          this.dataUnassignedUnitsList.data = [];

          this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
          this.dataUnassignedUnitsList.data = [
            ...this.dataUnassignedUnitsList.data,
            ...dataViewMore['data'],
          ];
        } else {
          this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
          this.dataUnassignedUnitsList.data = [
            ...this.dataUnassignedUnitsList.data,
            ...dataViewMore['data'],
          ];

          this.dataUnassignedUnitsList.data.splice(
            this.dataUnassignedUnitsList.data.length - 10,
            10
          );
        }
      }
    }

    /*     this.dataUnassignedUnitsList.data.splice(
      this.dataUnassignedUnitsList.data.length - 10,
      10
    ); */

    this.isDisabled =
      this.dataUnassignedUnitsList.meta.currentPage ===
      this.dataUnassignedUnitsList.meta.totalPages
        ? true
        : false;

    localStorage.setItem(
      'listRecord',
      JSON.stringify(this.dataUnassignedUnitsList)
    );
  }

  async closeFirstModal() {}

  closeModalUpdateFacility() {
    this.resetForm(this.userFacilityForm);
    this.closeModal();
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
    this.msgError = [];
  }

  urlService() {
    let url = '/Units/GetAllUnassigned?PageSize=5';
    let UinNumber = this.userSearchForm.get('MacAddress').value;
    let SuinNumber = this.userSearchForm.get('UinNumber').value;

    if (UinNumber !== '') {
      url = url + '&MacAddress=' + UinNumber;
    }

    if (!(SuinNumber === null || SuinNumber === '')) {
      url = url + '&SuinNumber=' + Number(SuinNumber);
    }

    return url;
  }

  search() {
    this.isDisabled = false;
    let urlService = this.urlService();
    if (urlService) {
      this.appServices.getAllFacility(urlService).subscribe((data) => {
        this.dataUnassignedUnitsList.data = data['data'];
        const dataUser = this.dataUnassignedUnitsList.data;
        this.dataUnassignedUnitsList.data = dataUser.map(function (user) {
          return user;
        });
        this.dataUnassignedUnitsList.meta = data['meta'];
        this.isDisabled =
          this.dataUnassignedUnitsList.meta.totalPages <= 1 ? true : false;
      });
    }
  }

  openDeleteModal(template: TemplateRef<any>, user) {
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: user.id,
      class: 'modal-md modal-dialog-centered modal-fit',
    });
  }

  closeDeletetModal(succesfully) {
    if (!this.modalRef) {
      return;
    }
    this.msgError = [];
    const dataRole = {
      id: this.modalRef.id,
    };
    this.appServices.deleteUnit(dataRole).subscribe(
      (data) => {
        //this.toastrService.success('Succes', 'Was successfully');
/*         localStorage.removeItem('listRecord');
        this.allUnassignedl(); */
        let pageNumber = this.dataUnassignedUnitsList.meta.currentPage;
        this.dataUnassignedUnitsList.meta = [];
        this.dataUnassignedUnitsList.data = [];

        for (let i = 0; i < pageNumber; i++) {
          this.dataPageReturn(i + 1);
        }
        this.modalRef.hide();
        this.modalRef = null;
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                if (mensajeError !== '') {
                  this.msgError.push(mensajeError);
                }
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
      }
    );
  }

  async dataPageReturn(pageNumber) {
    let urlService = this.urlService();

    let dataViewMore = await this.appServices
      .getUnassignedl(urlService, pageNumber)
      .toPromise();
    //this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
    const dataUser = dataViewMore['data'];

    dataViewMore['data'] = dataUser.map(function (user) {
      return user;
    });

    this.dataUnassignedUnitsList.meta = dataViewMore['meta'];
    this.dataUnassignedUnitsList.data = [
      ...this.dataUnassignedUnitsList.data,
      ...dataViewMore['data'],
    ];

    localStorage.setItem(
      'listRecord',
      JSON.stringify(this.dataUnassignedUnitsList)
    );
    if (this.dataUnassignedUnitsList.data.length === 0 && pageNumber === 2) {
      this.activeLess = false;
    }
  }

  getListOrganization() {
    this.appServices.getForSelectOrganization().subscribe((data) => {
      this.listOrganization = data['data'];
    });
  }

  getListFacility(id: number) {
    this.optionFacility = [];
    this.appServices.getListFacility(id).subscribe((data: any) => {
      this.optionFacility = data['data'];
    });
  }

  inputChange(evt) {
    let value = evt.target.value;

    this.userSearchForm.patchValue({ facilityId: 0 });
    this.getListFacility(+value);
  }

  navigateLink(id) {
    this.router.navigate(['/re-assign-units/' + id]);
  }
}
