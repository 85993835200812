import { RolsEnum  } from './enum/rols.enum';
export class RoleModel {
    user: string;
    role: RolsEnum;
    typeRol: string;
}

export const ROLS: Array<RoleModel> = [
    {
        user: '1@1.com',
        role: RolsEnum.FACILITY_MANAGER,
        typeRol: 'ER',
    },
    {
        user: '2@2.com',
        role: RolsEnum.FACILITY_ADMINISTRATOR,
        typeRol: 'ER',
    },
    {
        user: '3@3.com',
        role: RolsEnum.ORGANIZATION_MANAGER,
        typeRol: 'ER',
    },
    {
        user: '4@4.com',
        role: RolsEnum.ORGANIZATION_ADMINISTRATOR,
        typeRol: 'ER',
    },
    {
        user: '5@5.com',
        role: RolsEnum.CUSTOMER_SUPPORT,
        typeRol: 'IR',
    },
    {
        user: '6@6.com',
        role: RolsEnum.SUPPORT_MANAGER,
        typeRol: 'IR',
    },
    {
        user: '7@7.com',
        role: RolsEnum.SYSTEM_ADMINISTRATOR,
        typeRol: 'IR',
    },
    {
        user: '7@7.com',
        role: RolsEnum.SALE,
        typeRol: 'IR',
    }
]

export const internalsRol: Array<RoleModel> = ROLS.filter((item) => item.typeRol === 'IR');
export const organizationRol: Array<RoleModel> = ROLS.filter((item) => (item.role === RolsEnum.ORGANIZATION_MANAGER || item.role === RolsEnum.ORGANIZATION_ADMINISTRATOR));
export const facilityRol: Array<RoleModel> = ROLS.filter((item) => (item.role === RolsEnum.FACILITY_ADMINISTRATOR 
        || item.role === RolsEnum.FACILITY_MANAGER));