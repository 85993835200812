 <div class="wrapper">
  <div class="container-fluid">

    <div class="row">

      <div class="col-12">
        <h1>
          Unassigned Units
          <!-- <button type="button" style="border-radius: 26px; color: #00CFFF; font-weight: 700;"
            class="btn btn-primary submit" routerLink="/re-assign-units">Re-Assign Units</button> -->

        </h1>
        <p class="marginP">Use the Advance search filters to optimize your search.</p>
        <ul class="nav filter-menu">

          <div class="link" >
            <a class="filters-link" (click)="filters()">
              <span *ngIf="activeFilters">Close advance search</span>
              <span *ngIf="!activeFilters">Advance search</span>
            </a>

          </div>

        </ul>

        <hr>
        <div class="row filters" *ngIf="activeFilters" [formGroup]="userSearchForm">
          <div class="col-6 col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Mac Address</label>
              <input type="text" class="form-control input" placeholder="Mac Address" formControlName="MacAddress"
                (change)="search()">
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Serial Number</label>
              <input type="text" class="form-control input" placeholder="Serial Number" formControlName="UinNumber"
                (change)="search()">
            </div>
          </div>
        </div>



        <div class="row labels">
          <div class="col-5 col-md-5 col-lg-5 mac-margin">
            <label for="name">Mac Address</label>
          </div>
          <div class="col-5 col-md-4 col-lg-5 serial-margin">
            <label for="email">Serial Number</label>
          </div>
          <div class="col-2 col-md-3 col-lg-2 text-center options">
            <label for="options">options</label>
          </div>
        </div>
        <div class="row data" *ngFor="let UnassignedUnits of dataUnassignedUnitsList.data; let i = index">
          <div class="col-5 col-md-5 col-lg-5">
            <h5>{{ UnassignedUnits.macAddress }}</h5>
          </div>
          <div class="col-4 col-md-4 col-lg-5 serial-bottom">
            <p class="info">{{ UnassignedUnits.suinNumber }}</p>
          </div>
          <div class="col-3 col-md-3 col-lg-2 text-center actions margin-actions">
            <button type="button" class="btn btn-primary actived" >
              <img [src]="'assets/images/icons/change-icon-1.svg'" alt="Change Role" (click)="navigateLink(UnassignedUnits.id)">
            </button>
            <button type="button" class="btn btn-light actived" (click)="openDeleteModal(delete, UnassignedUnits)">
              <img [src]="'assets/images/icons/delete-icon-1.svg'" alt="Delete User">
            </button>


            <button type="button" class="btn btn-light disabled" disabled >
              <img [src]="'assets/images/icons/change-icon-disable-1.svg'" alt="Change Role">
            </button>
            <button type="button" class="btn btn-light disabled" disabled >
              <img [src]="'assets/images/icons/delete-icon-disable-1.svg'" alt="Delete User">
            </button>

          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center" style="padding-top: 1em;">

      <div class="col-md-auto">

        <p class="text-center" *ngIf="activeLess">
          <button type="button"

            class="btn btn-light btn-view" (click)="viewLess()">View less</button>

        </p>


      </div>
      <div class="col-md-auto">
        <p class="text-center" *ngIf="!isDisabled">
          <button type="button" class="btn btn-primary btn-more"
            (click)="viewMore()" [disabled]="isDisabled">View more</button>
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #delete>
  <div class="modal-body text-center modal-fit">

    <div style="color:#D20404!important;text-align: left;">
      <ul>
          <li *ngFor="let error of msgError">
              {{ error }}
          </li>
      </ul>
    </div>
    <h1 class="modal-title">
      Are you sure you want to

      <span class="accent-text">delete</span> this user?
    </h1>
    <p>
      <button type="button" (click)="closeModal(delete)" class="btn btn-primary submit full-width">no, I want to go
        back</button>
    </p>
    <p>
      <button class="btn btn-light full-width" (click)="closeDeletetModal(succesfully)">yes I want to delete it</button>
    </p>
  </div>
</ng-template>
<ng-template #succesfully>
  <div class="modal-body text-center modal-fit">
      <h1>
        Unit deleted
          <span class="accent-text"> successfully!</span>
      </h1>
  </div>
</ng-template>
