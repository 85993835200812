import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { AddFacilityComponent } from './components/add-facility/add-facility.component';
import { SetUpOrganizationComponent } from './components/set-up-organization/set-up-organization.component';
import { AssignNewUnitComponent } from './components/assign-new-unit/assign-new-unit.component';
import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FacilitiesListComponent } from './components/facilities-list/facilities-list.component';
import { FacilityProfileComponent } from './components/facility-profile/facility-profile.component';
import { FlushesHistoryComponent } from './components/flushes-history/flushes-history.component';
import { ForgotPasswordEmailComponent } from './components/forgot-password/forgot-password-email/forgot-password-email.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { InviteManagementComponent } from './components/invite-management/invite-management.component';
import { LoginComponent } from './components/login/login.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { OrganizationProfileComponent } from './components/organization-profile/organization-profile.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { RegisterUnitComponent } from './components/register-unit/register-unit.component';
import { UnitDetailsComponent } from './components/unit-details/unit-details.component';
import { UnitsDashboardComponent } from './components/units-dashboard/units-dashboard.component';
import { UsersInviteComponent } from './components/users-invite/users-invite.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { AuthGuard } from './services/auth.guard';
import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { RolsEnum  } from './models/enum/rols.enum';
import { SavingStatisticsComponent } from './components/saving-statistics/saving-statistics.component';
import { UnassignedUnitsComponent } from './components/unassigned-units/unassigned-units.component';
import { InternalUsersListComponent } from './components/internal-users-list/internal-users-list.component';
import { ROLSMENU } from '@Models/menu.model';
import { RecoverPasswordEmailComponent } from './components/recover-password/recover-password-email/recover-password-email.component';
import { ReAssignUnitComponent } from './components/re-assign-unit/re-assign-unit.component';
import { SavingsStatisticsComponent } from './components/savings-statistics/savings-statistics.component';
import { InternalInviteManagementComponent } from './components/internal-invite-management/internal-invite-management.component';
import { InviteInternalUsersComponent } from './components/invite-internal-users/invite-internal-users.component';
import { AddOrganizationComponent } from './components/add-organization/add-organization.component';



const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'forgot-password-email',
    component: ForgotPasswordEmailComponent,
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent
  },
  {
    path: 'recover-password-email',
    component: RecoverPasswordEmailComponent

  },
  {
    path: 'confirm-account',
    pathMatch: 'full',
    component: ConfirmAccountComponent
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
             RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
             RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'assign-new-unit',
    component: AssignNewUnitComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
        RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
        RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'units-dashboard',
    component: UnitsDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
            RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
            RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'unit-details/:id',
    component: UnitDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
        RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
        RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'flushes-history/:id',
    component: FlushesHistoryComponent,
    canActivate: [AuthGuard],
    data: {
      role : [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
        RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
        RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  //Facility Administrator | Users
  {
    path: 'users-list',
    component: UsersListComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_ADMINISTRATOR, RolsEnum.ORGANIZATION_MANAGER,
             RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT,
            RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'users-invite',
    component: UsersInviteComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_ADMINISTRATOR, RolsEnum.ORGANIZATION_MANAGER,
        RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT,
       RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'invite-internal-users',
    component: InviteInternalUsersComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'invite-management',
    component: InviteManagementComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_ADMINISTRATOR, RolsEnum.ORGANIZATION_MANAGER,
        RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT,
        RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'internal-invite-management',
    component: InternalInviteManagementComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_ADMINISTRATOR, RolsEnum.ORGANIZATION_MANAGER,
        RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT,
        RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'facility-profile/:id',
    component: FacilityProfileComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_ADMINISTRATOR, RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  //Organization Manager
  {
    path: 'facilities-list',
    component: FacilitiesListComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
            RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'add-facility',
    component: AddFacilityComponent,
    canActivate: [AuthGuard],
    canDeactivate: [ CanDeactivateGuard],
    data: {
      role: [RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    },
  },
  {
    path: 'set-up-organization/:id',
    component: SetUpOrganizationComponent,
    canActivate: [AuthGuard],
    canDeactivate: [ CanDeactivateGuard],
    data: {
      role: [RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },

  {
    path: 'add-organization',
    component: AddOrganizationComponent,
    canActivate: [AuthGuard],
    canDeactivate: [ CanDeactivateGuard],
    data: {
      role: [RolsEnum.ORGANIZATION_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'organization-list',
    component: OrganizationListComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'organization-profile/:id',
    component: OrganizationProfileComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.ORGANIZATION_ADMINISTRATOR,RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'register-unit',
    component: RegisterUnitComponent,
    canActivate: [AuthGuard],
    data: {
      role: [1,2,3,4]
    }
  },
  {
    path: 'saving-statistics/:id',
    component: SavingStatisticsComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
             RolsEnum.ORGANIZATION_MANAGER, RolsEnum.ORGANIZATION_ADMINISTRATOR,
             RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }

  },
  {
    path: 'unassigned-units',
    component: UnassignedUnitsComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path: 'Savings-Statistics',
    component: SavingsStatisticsComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  }
  ,{
     path: 're-assign-units/:id',
    component: ReAssignUnitComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  }
  ,
  {
    path: 'internal-users-list',
    component: InternalUsersListComponent,
    data: {
      role: [RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR, RolsEnum.CUSTOMER_SUPPORT]
    }

  },
  //Dashboard
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      role: [RolsEnum.FACILITY_MANAGER, RolsEnum.FACILITY_ADMINISTRATOR,
             RolsEnum.ORGANIZATION_MANAGER,RolsEnum.ORGANIZATION_ADMINISTRATOR,
             RolsEnum.CUSTOMER_SUPPORT, RolsEnum.SUPPORT_MANAGER, RolsEnum.SYSTEM_ADMINISTRATOR]
    }
  },
  {
    path:  '**',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
