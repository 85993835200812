<div class="account-settings">
  <div class="wrapper">
    <div class="container-fluid">
      <div class="row marginFirst margin-row">
        <div class="col-12">
          <h1>Account Settings</h1>
          <p>Here you can edit all your personal information. </p>
          <hr>
        </div>
      </div>
      <div *ngIf="msgErrorList.length !==0"  class="error">
        <ul>
          <li *ngFor="let error of msgErrorList">
            {{ error }}
          </li>
        </ul>
        <span *ngIf="msgError !== ''">
          {{ msgError }}
        </span>
      </div>
      <!-- <div class="row basic-info">
                <div class="col-12 text-center">
                    <div>
                        <div class="form-group col-md-8 offset-md-4">
                            <div class="row">

                                <div class="col-md-6">
                                    <input class="form-control input_name" value="John Doe" *ngIf="!editName" type="text" id="name">
                                    <input class="form-control input_name" autofocus (focus)="onfocus()" value="John Doe" *ngIf="editName" type="text" id="name">
                                </div>
                                <div class="col-md-1">
                                    <img [src]="editName ? 'assets/images/icons/close.svg' : 'assets/images/icons/edit.svg'" style="padding-top: 18px;" (click)="showEditName(!editName)" alt="Edit Info" *ngIf="!disableForm">
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="subtitle">Location, State</p>
                </div>
            </div> -->
      <form [formGroup]="informationForm" (ngSubmit)="saveChanges(succesfully)">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 offset-md-2 offset-lg-2">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-xs-12 col-6 col-sm-6 col-md-6">
                    <div class="form-group">
                      <label class="label-account" for="last" style="margin-bottom: 0px;">
                        First Name
                      </label>
                      <input type="text" class="form-control input_name" id="name" formControlName="name"
                        placeholder="First Name">
                      <div *ngIf="informationForm.controls['name'].invalid &&
                                            (informationForm.controls['name'].dirty ||
                                            informationForm.controls['name'].touched)" class="invalid-feedback">
                        <div *ngIf="informationForm.controls['name'].errors.required">
                          * First Name is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                      <label class="label-account" for="lastName" style="margin-bottom: 0px;">
                        Last Name
                      </label>
                      <input type="text" class="form-control input_name" id="lastName" formControlName="lastName"
                        placeholder="Last Name">
                      <div *ngIf="informationForm.controls['lastName'].invalid &&
                                        (informationForm.controls['lastName'].dirty ||
                                        informationForm.controls['lastName'].touched)" class="invalid-feedback">
                        <div *ngIf="informationForm.controls['lastName'].errors.required">
                          * Last Name is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-xs-4 col-sm-4 col-md-4">
                                    <div class="form-group">
                                        <label for="location" style="margin-bottom: 0px;">Location</label>
                                        <select class="form-control input_name" id="location" formControlName="location">
                                            <option value="0" [selected]="true">Location, ST</option>
                                            <option value="1">X</option>
                                        </select>
                                        <div *ngIf="informationForm.controls['location'].invalid && (informationForm.controls['location'].dirty || informationForm.controls['location'].touched)" class="invalid-feedback">
                                            <div *ngIf="informationForm.controls['location'].errors.required">* Location is required</div>
                                        </div>
                                    </div>
                                </div> -->

                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="form-group" *ngIf="!internalRol">
                      <label for="CustomerId">Customer ID</label>
                      <input type="text" class="form-control input" id="CustomerId" formControlName="CustomerId"
                        placeholder="">
                      <div *ngIf="informationForm.controls['CustomerId'].invalid &&
                                      (informationForm.controls['CustomerId'].dirty ||
                                      informationForm.controls['CustomerId'].touched)" class="invalid-feedback">
                        <div *ngIf="informationForm.controls['CustomerId'].errors.required">
                          * Customer ID is required
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="label-account" for="emailAdress">Email Address</label>
                      <input type="email" class="form-control input" id="emailAdress" formControlName="emailAdress"
                        placeholder="example@mail.com">
                      <div *ngIf="informationForm.controls['emailAdress'].invalid &&
                                        (informationForm.controls['emailAdress'].dirty ||
                                        informationForm.controls['emailAdress'].touched)" class="invalid-feedback">
                        <div *ngIf="informationForm.controls['emailAdress'].errors.required">
                          * Email is required
                        </div>
                        <div *ngIf="informationForm.controls['emailAdress'].errors.email">
                          * Email pattern is not valid
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="label-account" for="phone">Phone Number</label>
                      <input type="text" class="form-control input labels" id="phone" formControlName="phone"
                        placeholder="123 123 1234">
                      <div *ngIf="informationForm.controls['phone'].invalid &&
                                        (informationForm.controls['phone'].dirty ||
                                        informationForm.controls['phone'].touched)" class="invalid-feedback">
                        <div *ngIf="informationForm.controls['phone'].errors.required">
                          * Phone is required
                        </div>
                        <div *ngIf="informationForm.controls['phone'].errors.pattern">
                          * Phone pattern is not valid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group" *ngIf="!internalRol && isOrganization">
                  <label class="label-account" for="organization">Organization</label>
                  <input type="text" class="form-control input labels" id="organization" formControlName="organization"
                    placeholder="Organization">
                  <div *ngIf="informationForm.controls['organization'].invalid &&
                            (informationForm.controls['organization'].dirty ||
                            informationForm.controls['organization'].touched)" class="invalid-feedback">
                    <div *ngIf="informationForm.controls['organization'].errors.required">
                      * Organization is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="form-group" *ngIf="!internalRol && isFacility">
                  <label class="label-account" for="facility">Facility</label>
                  <input type="text" class="form-control input" id="facility" formControlName="facility"
                    placeholder="Facility">
                  <div *ngIf="informationForm.controls['facility'].invalid &&
                      (informationForm.controls['facility'].dirty ||
                      informationForm.controls['facility'].touched)" class="invalid-feedback">
                    <div *ngIf="informationForm.controls['facility'].errors.required">
                      * Facility is required
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-xs-12 col-6 col-sm-6 col-md-6 col-lg-6">



                <div class="form-group">
                  <label class="label-account" for="password">Password</label>
                  <input type="password" class="form-control input" id="password" formControlName="password"
                    placeholder="*******">
                  <div *ngIf="informationForm.controls['password'].invalid &&
                                    (informationForm.controls['password'].dirty ||
                                    informationForm.controls['password'].touched)" class="invalid-feedback">
                    <div *ngIf="informationForm.controls['password'].errors.required">
                      * Password is required
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-xs-12 col-6 col-sm-6 col-md-6">


                <button type="button" class="btn btn-light change-password" [disabled]="disableForm"
                (click)="openModal(template)">
                  change password
                </button>
              </div>
            </div>
            <p class="text-center">
              <button (click)="activeForm()"

                class="btn btn-primary submit btn-edit" [disabled]="informationForm.invalid" *ngIf="disableForm">
                Edit information
              </button>
              <button *ngIf="!disableForm" [disabled]="informationForm.invalid"
                style="" type="submit"
                class="btn btn-primary submit btn-save">
                save changes
              </button>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #template>
    <div #facilityUpdate>
      <div class="modal-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="saveNewPassword(succesfully)">
          <p class="back" (click)="closeModal(template)">
            <img [src]="'assets/images/icons/arrow-back.svg'" alt="Back to profile">
            Back to account settings
          </p>
          <div class="error" *ngIf="msgErrorListPassword.length !==0">
            <ul>
              <li *ngFor="let error of msgErrorListPassword">
                {{ error }}
              </li>
            </ul>
            <span *ngIf="msgErrorPassword !== ''">
              {{ msgErrorPassword }}
            </span>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="currentPassword">Current Password</label>
                <div class="input-group mb-3">
                  <input #currentPassword type="password" class="form-control input" formControlName="currentPassword"
                    id="currentPassword" placeholder="*******">
                  <div class="input-group-append"
                    (click)="currentPassword.type=currentPassword.type=='password'?'text':'password'">
                    <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                  </div>
                  <div *ngIf="changePasswordForm.controls['currentPassword'].invalid &&
                                    (changePasswordForm.controls['currentPassword'].dirty ||
                                    changePasswordForm.controls['currentPassword'].touched)" class="invalid-feedback">
                    <div *ngIf="changePasswordForm.controls['currentPassword'].errors.required">
                      * Current password is required
                    </div>
                    <div *ngIf="changePasswordForm.controls['currentPassword'].errors.minlength">
                      * Current password must have at least 7 characters
                    </div>
                    <div *ngIf="changePasswordForm.controls['currentPassword'].errors.maxlength">
                      * Current password must have at most 100 characters
                    </div>
                    <div *ngIf="changePasswordForm.controls['currentPassword'].errors.pattern">
                      * Current password must contain at least: one capital letter,
                      one lowercase letter, one numeric digit and one special character.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="newPassword">New Password</label>
                <div class="input-group mb-3">
                  <input #password type="password" class="form-control input" formControlName="newPassword"
                    id="newPassword" placeholder="*******">
                  <div class="input-group-append" (click)="password.type=password.type=='password'?'text':'password'">
                    <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                  </div>
                  <div *ngIf="changePasswordForm.controls['newPassword'].invalid &&
                                    (changePasswordForm.controls['newPassword'].dirty ||
                                    changePasswordForm.controls['newPassword'].touched)" class="invalid-feedback">
                    <div *ngIf="changePasswordForm.controls['newPassword'].errors.required">
                      * New password is required
                    </div>
                    <div *ngIf="changePasswordForm.controls['newPassword'].errors.minlength">
                      * New password must have at least 7 characters
                    </div>
                    <div *ngIf="changePasswordForm.controls['newPassword'].errors.maxlength">
                      * New password must have at most 100 characters
                    </div>
                    <div *ngIf="changePasswordForm.controls['newPassword'].errors.pattern">
                      * New password must contain at least: one capital letter,
                      one lowercase letter, one numeric digit and one special character.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <div class="input-group mb-3">
                  <input #confirmPassword type="password" class="form-control input" formControlName="confirmPassword"
                    id="confirmPassword" placeholder="*******">
                  <div class="input-group-append"
                    (click)="confirmPassword.type=confirmPassword.type=='password'?'text':'password'">
                    <img [src]="'assets/images/icons/eye-off.svg'" alt="show password">
                  </div>
                  <div *ngIf="changePasswordForm.controls['confirmPassword'].invalid &&
                                    (changePasswordForm.controls['confirmPassword'].dirty ||
                                    changePasswordForm.controls['confirmPassword'].touched)" class="invalid-feedback">
                    <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.required">
                      * Confirm password is required
                    </div>
                    <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.minlength">
                      * Confirm password must have at least 7 characters
                    </div>
                    <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.maxlength">
                      * Confirm password must have at most 100 characters
                    </div>
                    <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.MatchPassword">
                      * Password and Confirm Password must match
                    </div>
                    <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.pattern">
                      * Confirm password must contain at least: one capital letter,
                      one lowercase letter, one numeric digit and one special character.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-4" style="display: none;">
                        <p>

                            <img [src]="'assets/images/icons/step-form-bad.svg'" >
                            At least 7 characters long
                        </p>
                        <p>

                            <img [src]="'assets/images/icons/step-form-good.svg'">
                            Must have  at most 100 characters
                        </p>
                        <p>

                            <img [src]="'assets/images/icons/step-form-good.svg'">
                            one capital letter,
                            one lowercase letter, one numeric digit and one special character.
                        </p>
                        <p>
                            <img [src]="'assets/images/icons/step-form-bad.svg'">
                            Don't use your login name or email
                        </p>
                    </div> -->
          </div>
          <div class="text-center">
            <button type="button" [disabled]="changePasswordForm.invalid"
               (click)="closeFirstModal(succesfully)"
              class="btn btn-primary submit btn-save button-btn">
              save password
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #succesfully>
  <div class="modal-body text-center modal-fit">
      <h1 class="h1-modal">
          Account updated

          <span class="accent-text">successfully!</span>
      </h1>
  </div>
</ng-template>
