<div class="wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Register new unit</h1>
                <p>To register a new unit enter the MAC addres, SUIN Number and the organization that  it belongs.</p>
                <hr>
                <form [formGroup]="unitsForm" (ngSubmit)="onSubmit()">
                    <div class="form" formArrayName="units">
                        <div class="row" *ngFor="let quantity of units().controls; let i=index" [formGroupName]="i">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="mac">MAC Address</label>
                                        <input type="text" class="form-control input" formControlName="mac" placeholder="wirte mac address">
                                    </div>
                                </div>
                                <div class="col-8 col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="siun">SUIN Number</label>
                                        <input type="text" 
                                            class="form-control input" 
                                            formControlName="siun" 
                                            placeholder="0000">
                                    </div>
                                </div>
                                <div class="col-8 col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="organization">Organization</label>
                                        <select class="form-control input select-old" id="organization" formControlName="organization" placeholder="select organization">
                                          <option selected>select organization</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 col-lg-2 text-centers">
                                    <button (click)="removeQuantity(i)" *ngIf="position > 0" class="btn btn-light sm-btn">
                                        <span>-</span>
                                    </button>
                                    <button type="button" (click)="addQuantity(i)" *ngIf="position == i" class="btn btn-light sm-btn">
                                        <span>+</span>
                                    </button>
                                </div>
                        </div>
                    </div>
                    <p class="text-center">
                        <button type="button" style="border-radius: 26px; color: #00CFFF; font-weight: 700;" (click)="openModal(template)" class="btn btn-primary submit">Register unit(s)</button>
                    </p>                    
                  </form>
                  <!-- {{this.unitsForm.value | json}} -->
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-body text-center modal-fit">
        <h1>
            Unit(s) registered
            <br>
            <span class="accent-text">successfully!</span>
        </h1>
        <button type="button" (click)="closeFirstModal(template)" class="btn btn-primary submit">Accept</button>
    </div>
</ng-template>