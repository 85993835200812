import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EncrDecrService } from 'src/app/services/encrypt.service';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppServices } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.sass']
})
export class AccountSettingsComponent implements OnInit {
  informationForm: FormGroup;
  changePasswordForm: FormGroup;
  disableForm: boolean = true;
  modalRef: BsModalRef | null;
  editName: Boolean = false;
  public msgError: string = '';
  public msgErrorList: string[] = [];
  msgErrorPassword: string = ''
  msgErrorListPassword: string[] = [];
  internalRol: boolean = false;
  dataUser: any = null;
  organizationId = 0;
  facilityId = 0;
  isFacility;
  isOrganization;

  constructor(
    private fb: FormBuilder,
    private appServices: AppServices,
    private authService: AuthService,
    private EncrDecr: EncrDecrService,
    private modalService: BsModalService,
    private actionMenu:ActionMenuService) {
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    this.actionMenu.setSelectMenu('AS');
  }

  MatchPassword(abstractControl: AbstractControl) {
    let newPassword = abstractControl.get('newPassword').value;
    let confirmPassword = abstractControl.get('confirmPassword').value;
    if (newPassword != confirmPassword) {
      abstractControl.get('confirmPassword').setErrors({
        MatchPassword: true
      })
    } else {
      return null
    }
  }

  ngOnInit(): void {
    this.buildChangePasswordForm();
    this.informationForm = this.fb.group({
      name: [{ value: '', disabled: true }, [Validators.required]],
      lastName: [{ value: '', disabled: true }, [Validators.required]],
      emailAdress: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      password: [{ value: '', disabled: true }, [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?+&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
      confirmPassword: [{ value: '', disabled: true }, [Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?+&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
        Validators.minLength(7), Validators.maxLength(100)]],
      facility: [{ value: '', disabled: true }, /*[Validators.required]*/],
      phone: [{ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/)]
      ],
      organization: [{ value: '', disabled: true }],
      CustomerId: [{ value: '', disabled: true }, [Validators.required]],
    });
    const userId = this.authService.getUserId();
    if (userId) {

      const url = '/Users/GetUserProfile?id=' + userId;
      this.appServices.getAction(url).subscribe(
        (data) => {
          if (data) {
            this.dataUser = data['data'];
            this.organizationId = this.dataUser?.organizationId || 0;
            this.facilityId = this.dataUser?.facilityId || 0;
            this.informationForm.patchValue({
              name: this.dataUser.firstName,
              lastName: this.dataUser.lastName,
              emailAdress: this.dataUser.email,
              facility: this.dataUser?.facility || '',
              organization: this.dataUser?.organization || '',
              phone: this.dataUser?.phoneNumber || '',
              CustomerId: this.dataUser?.customerId || '',
            })
          }
        }
      )

      this.buildChangePasswordForm();
    }

    this.isFacility = localStorage.getItem("facilityId") === 'null' ? false : true;
    this.isOrganization = localStorage.getItem("organizationId") === 'null' ? false : true;

  }

  buildInformationForm(): void {

  }

  buildChangePasswordForm(): void {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!+%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
      newPassword: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!+%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
      confirmPassword: ['', [Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!+%*?&-/.,;:_¡=])([a-z\d$@$!%*?&-/.,;:_¡=]|[^ ]){7,90}$/),
      Validators.minLength(7), Validators.maxLength(100)]],
    }, {
      validator: this.MatchPassword
    })
    this.changePasswordForm.valueChanges
  }

  showEditName(show: Boolean) {
    this.editName = show;
  }

  activeForm() {
    this.disableForm = false;
    this.informationForm.get('name').enable();
    this.informationForm.get('lastName').enable();
    this.informationForm.get('phone').enable();
    //this.informationForm.get('emailAdress').enable();
  }

  saveChanges(succesfully) {
    this.disableForm = true;
    const { name, lastName, phone, emailAdress,CustomerId } = this.informationForm.value;
    const data = {
      firstName: name,
      lastName: lastName,
      email: this.dataUser.email,
      phoneNumber: phone,
      CustomerId: CustomerId
    }
    const url = '/Account/EditProfile';
    this.appServices.postAction(url, data).subscribe(
      (data) => {

        const { name, lastName } = this.informationForm.value;
        sessionStorage.setItem('userFullName', name + ' ' + lastName);
        this.informationForm.disable();/*.get('name').disable();
        this.informationForm.get('lastName').disable();
        this.informationForm.get('phone').disable();
        this.informationForm.get('emailAdress').disable();*/
        this.modalRef = this.modalService.show(succesfully, { id: 1, class: 'modal-md modal-dialog-centered succesfullyCustom' });
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => { clearInterval(timerId); this.modalRef.hide(); }, 3000);
      }, error => {
        this.msgError = '';
        this.msgErrorList = [];
        let errors = JSON.parse(error);
        if (typeof errors === 'string') {
          this.msgError = errors;
        } else if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            //@ts-ignore
            const count = value.length;
            for (let i = 0; i < count; i++) {
              this.msgErrorList.push(value[i])
            }
          })
        }
      }
    );

    //this.usuarioFull.emit(data.firstName + ' '+ data.lastName );
  }

  saveNewPassword(succesfully) {
    if (this.disableForm) {
      this.activeForm();
    } else {
      this.saveChanges(succesfully);
    }
  }

  onfocus() {
    this.editName = true;
  }

  openModal(template: TemplateRef<any>) {
    this.msgErrorPassword = '';
    this.msgErrorListPassword = [];
    this.changePasswordForm.patchValue({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-md modal-dialog-centered modal-facility-update customListModal' });
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.resetForm(this.changePasswordForm);
    this.modalRef.hide();
    this.modalRef = null;
  }

  closeFirstModal(succesfully) {
    if (!this.modalRef) {
      return;
    }
    const url = '/Account/ChangePassword';
    const { currentPassword, newPassword, confirmPassword } = this.changePasswordForm.value;
    //const { emailAdress } = this.informationForm.value;

    const data = {
      email: this.dataUser.email,
      oldPassword: this.EncrDecr.set(
        environment.key_login,
        currentPassword),
      password: this.EncrDecr.set(
        environment.key_login,
        newPassword),
      confirmPassword: this.EncrDecr.set(
        environment.key_login,
        confirmPassword),
    }
    this.appServices.postAction(url, data).subscribe(
      (data) => {
        this.closeModal();
        this.informationForm.disable();
        this.disableForm = true;
        this.modalRef = this.modalService.show(succesfully, { id: 1, class: 'modal-md modal-dialog-centered' });
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => { clearInterval(timerId); this.modalRef.hide(); }, 3000);
      }, error => {
        this.msgErrorPassword = '';
        this.msgErrorListPassword = [];
        let errors = JSON.parse(error);
        if (typeof errors === 'string') {
          this.msgErrorPassword = errors;
        } else if (typeof errors === 'object') {
          Object.entries(errors).forEach(([key, value]) => {
            //@ts-ignore
            const count = value.length;
            for (let i = 0; i < count; i++) {
              this.msgErrorListPassword.push(value[i])
            }
          })
        }
      }
    )
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }
}
