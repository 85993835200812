import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { PopupComponent } from '../components/popup/popup.component';
import { PopupFormsComponent } from '../components/popup/popupForms.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  messages: string[] = [];
  modalRef: BsModalRef | null;

  constructor(private modalService: BsModalService) {}

  showConfirm(...data) {
    this.modalService.show(PopupComponent, {
      initialState: {
        title: data[0],
        message: data[1],
        btn1: data[2],
        btn2: data[3],
        back: data[4],
        urlBack: data[5],
      },
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
    });
  }

  showForm(...data) {
    this.modalService.show(PopupFormsComponent, {
      initialState: {
        title: data[0],
        message: data[1],
        btn1: data[2],
        btn2: data[3],
        back: data[4],
      },
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
    });
  }
}
