import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { transformError } from './auth.service';
import { CacheService } from './cache.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppServices extends CacheService {
  private urlApi = environment.server + '/api';
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  private _urlChange: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(private httpClient: HttpClient, private router: Router,) {
    super();
  }

  public get urlChange(): string {
    return this._urlChange.getValue();
  }

  public set urlChange(urlChange: string) {
    /*
    const confirmAccount = urlChange.includes('confirm-account');
    const recoverAccount = urlChange.includes('recover-password');
    if (confirmAccount) {
      const arrayUrl = urlChange.split('/confirm-account;token=');
      const arrayUrlToken = arrayUrl.length > 0 ? arrayUrl[1] : '';
      const tokenAndId = arrayUrlToken === '' ? [] : arrayUrlToken.split(';id=');
      const token = tokenAndId.length > 0 ? tokenAndId[0] : '';
      const id = tokenAndId.length > 0 ? tokenAndId[1] : 0;
      if (token !== '' && id > 0) {
        let queryParams: any;
        this.router.navigate(['/confirm-account', {
          ...queryParams,
          token,
          id
        }])
      }


    }*/
    this._urlChange.next(urlChange);
  }

  public onUrlChange(): Observable<string> {
    return this._urlChange.asObservable();
  }

  /**
  * @description metodo que recuperar la contraseña
  * @param
  * @author Ing. Mari Alvarez
  * @version 1.0
  *
  */
  public forgotPassword(data) {
    return this.httpClient.post(`${this.urlApi}/Account/ForgotPassword`, data,
      {
        headers: this.headers
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  RecoverPassword(data) {
    return this.httpClient.post(`${this.urlApi}/Account/RecoverPassword`, data,
      {
        headers: this.headers
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  public getSignOut() {
    return this.getAction('/Account/SignOut');
  }

  public UserEdit(data) {
    const url = '/Users/UpdateInternalRole';
    return this.putAction(url, data);
  }

  public confirmAccount(data) {
    return this.httpClient.post(`${this.urlApi}/Account/ConfirmAccount`, data,
      {
        headers: this.headers
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  /**
   * @description metodo que permite asignar una o varia unidades
   * @param recibe array de objetos de unidades
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public assignUnit(data) {
    const url = '/Units/AssignUnits';
    return this.postAction(url, data);
  }

  /**
   * @description metodo que permite obtener una lista de fscility
   * @param id de la organizacion
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public getListFacility(organizationId: number) {
    return this.getAction(`/Facilities/GetForSelect?organizationId=${organizationId}`);
  }

  /**
   * @description metodo que permite obtener una lista de unidades
   * @param pageSize cantidad de registros a mostrar por paginacion
   * PageNumber numero de pagina
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public getUnitHubAll(organizationId: number, PageSize: number, PageNumber: number = 1, facilityId: number) {
    let url = ''; /*organizationId > 0 ?
      `/Units/GetAll?OrganizationId=${organizationId}&PageSize=` + PageSize + `&PageNumber=` + PageNumber :
      `/Units/GetAll?PageSize=` + PageSize + `&PageNumber=` + PageNumber*/
    if (organizationId > 0 && facilityId === 0) {
      url = `/Units/GetAll?OrganizationId=${organizationId}&PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    } else if (organizationId > 0 && facilityId > 0) {
      url = `/Units/GetAll?OrganizationId=${organizationId}&FacilityId=${facilityId}&PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    } else {
      url = `/Units/GetAll?PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    }
    return this.getAction(url);
  }

  /**
   * @description metodo que permite obtener una lista de unidades por status
   * @param descripción del status, pageSize cantidad de registros a mostrar por paginacion y
   * PageNumber numero de pagina
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public getUnitHubOnLineOfLine(organizationId: number, Status: string, PageSize: number, PageNumber: number = 1, facilityId: number) {
    let url = ''; /*organizationId > 0 ?
      `/Units/GetAll?OrganizationId=${organizationId}&PageSize=` + PageSize + `&PageNumber=` + PageNumber :
      `/Units/GetAll?PageSize=` + PageSize + `&PageNumber=` + PageNumber*/
    if (organizationId > 0 && facilityId === 0) {
      url = `/Units/GetAll?Status=` + Status + `&OrganizationId=${organizationId}&PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    } else if (organizationId > 0 && facilityId > 0) {
      url = `/Units/GetAll?Status=` + Status + `&OrganizationId=${organizationId}&FacilityId=${facilityId}&PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    } else {
      url = `/Units/GetAll?Status=` + Status + `&PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    }
    //const url =`/Units/GetAll?Status=` + Status + `&PageSize=` + PageSize + `&PageNumber=` + PageNumber;
    return this.getAction(url);
  }

  /**
   * @description metodo que permite obtener una lista de unidades por varios parametros
   * que son armados de manera dinamica en el ts
   * @param descripción del status, pageSize cantidad de registros a mostrar por paginacion y
   * PageNumber numero de pagina
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public getUnitParams(url: string, PageNumber: number = 1) {
    const urlEndpoint = `${url}&PageNumber=${PageNumber}`;
    return this.getAction(urlEndpoint);
  }

  /**
   * @description metodo que permite obtener una unidad por su id
   * @param id unit
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */

  public getUnitById(unityId: number) {
    const url = `/Units/GetUnitProfile?id=${unityId}`;
    return this.getAction(url);
  }

  public GetIsUnitRegistered(macAddress: number) {
    const url = `/Units/IsUnitRegistered?macAddress=${macAddress}`;
    return this.getAction(url);
  }

  public getOrganizationName(idOrganization: number) {
    const url = `/Organizations/GetOrganizationName?id=${idOrganization}`;
    return this.getAction(url);
  }

  /**
   * @description metodo que permite obtener una lista de flutchesHistory
   * @param id unit
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */

  public getFlushesHistory(paramsUrl?: any, PageSize: number = 10, PageNumber: number = 1) {
    let token = localStorage.getItem('jwt');
    const tokenReq = (token) ? token.replace(/['"]+/g, '') : '';
    return this.httpClient.get(`${this.urlApi}` + paramsUrl + `&PageSize=` + PageSize + `&PageNumber=` + PageNumber,
      {
        headers: this.headers.set('Authorization', `Bearer ${tokenReq}`)
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  /**
   * @description metodo que permite editar unidades
   * @param recibe objeto con la data a modificar
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public editUnit(data) {
    return this.putAction('/Units/EditUnit', data);
  }


  /**
   * @description metodo que desaciar un facility de la unidad
   * @param recibe objeto con el id de la unidad
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public detachUnit(data) {
    return this.postAction(`/Units/DetachUnit`, data)
  }

  /**
 * @description metodo que permite listas los fluse\hes history
 * @param recibe unidadId, WithLastFlush, PageSize, PageNumber
 * @author Ing. Mari Alvarez
 * @version 1.0
 *
 */
  public getListFlushesHistory(paramsUrl: string, pageSize: number = 5, pageNumber: number = 1) {
    const url = paramsUrl + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
    return this.getAction(url);
  }

  /**
* @description metodo que permite listas los facility
* @param recibe Name, OrganizationId, City, StateId, Status, PageSize, PageNumber
* @author Ing. Mari Alvarez
* @version 1.0
*
*/
  public getAllFacility(paramsUrl: string, PageNumber: number = 1) {
    const url = paramsUrl + '&PageNumber=' + PageNumber;
    return this.getAction(url);
  }

  /**
   * @description metodo que desactivar un facility
   * @param recibe objeto con el id de la unidad
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
  public deactivateFacility(data) {
    const url = `/Facilities/DeactivateFacility`;
    return this.postAction(url, data);
  }

  public activateFacility(data) {
    const url = `/Facilities/ActivateFacility`;
    return this.postAction(url, data);
  }

  public getFacilityId(facilityId: number) {
    const url = `/Facilities/GetFacilityProfile?id=${facilityId}`;
    return this.getAction(url);
  }

  getUnitFacilityAll(facilityId: number, organizationId: number) {
    let url = organizationId > 0 ?
      `/Units/GetAll?OrganizationId=${organizationId}&PageSize=5&PageNumber=1&FacilityId=${facilityId}` :
      `/Units/GetAll?PageSize=5&PageNumber=1&FacilityId=${facilityId}`;
    return this.getAction(url);
  }

  getUnitFacilityOrganizationAll(organizationId: number) {
    const url = `/Units/GetAll?OrganizationId=${organizationId}`;
    return this.getAction(url);
  }

  getSupervisor(facilityId: number, organizationId: number, pageSize: number = 5, pageNumber: number = 1) {
    let url = organizationId > 0 ?
      `/Users/GetAll?OrganizationId=${organizationId}&PageSize=${pageSize}&PageNumber=${pageNumber}&FacilityId=` + facilityId :
      `/Users/GetAll?PageSize=${pageSize}&PageNumber=${pageNumber}&FacilityId=` + facilityId
    return this.getAction(url);
  }



  getForSelectOrganization() {
    return this.getAction('/Organizations/GetForSelect');
  }

  getStates() {
    const url = `/States/GetForSelect`;
    return this.getAction(url);
  }

  public facilityEdit(data) {
    const url = `/Facilities/EditFacility`;
    return this.putAction(url, data);
  }

  public getRolesForUpdateSelect() {
    const url = `/Roles/GetExternalRolesforSelect`;
    return this.getAction(url);
  }

  public updateRole(data) {
    const url = `/Users/UpdateRole`;
    return this.putAction(url, data)
  }

  public relocate(data) {
    const url = `/Supervisors/Relocate`;
    return this.putAction(url, data);
  }

  public addFacility(data) {
    const url = `/Facilities/AddFacility`;
    return this.postAction(url, data);
  }

  public GetRolesForSelect() {
    const url = `/Roles/GetExternalRolesforSelect`;
    return this.getAction(url);
  }

  public GetOrganizationProfile(organizationId: number) {
    const url = `/Organizations/GetOrganizationProfile?id=${organizationId}`;
    return this.getAction(url);
  }

  public getAllOrganizationFacility(organizationId: number, pageSize: number = 2, pageNumber: number = 1) {
    const url = `/Facilities/GetAll?OrganizationId=${organizationId}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    return this.getAction(url);
  }

  getSupervisorOrganization(organizationId: number, pageSize: number = 2, pageNumber: number = 1) {
    const url = `/Users/GetAll?OrganizationId=${organizationId}&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    return this.getAction(url);
  }

  getEditOrganization(data) {
    const url = `/Organizations/EditOrganization`;
    return this.putAction(url, data);
  }

  activateOrganization(data) {
    const url = `/Organizations/ActivateOrganization`;
    return this.postAction(url, data);
  }

  deActivateOrganization(data) {
    const url = `/Organizations/DeactivateOrganization`;
    return this.postAction(url, data);
  }

  SetupOrganization(data) {
    const url = `/Organizations/SetupOrganization`;
    return this.postAction(url, data);
  }

  public getAllOrganization(paramsUrl: string, PageNumber: number = 1) {
    const url = paramsUrl + '&PageNumber=' + PageNumber;
    return this.getAction(url);
  }

  public getUsers(paramsUrl: string, PageNumber: number = 1) {
    const url = paramsUrl + '&PageNumber=' + PageNumber;
    return this.getAction(url);
  }

  public deleteUser(data: any) {
    const url = '/Users/DeleteUser';
    return this.postAction(url, data);
  }

  public deleteUserInternal(data: any) {
    const url = '/Users/DeleteInternalUser';
    return this.postAction(url, data);
  }

  public getInviteUser(paramsUrl: string, PageNumber: number = 1) {
    const url = paramsUrl + '&PageNumber=' + PageNumber;
    return this.getAction(url);
  }

  public invitesEdit(data) {
    const url = '/Invites/EditInvite';
    return this.putAction(url, data);
  }

  public deleteInvites(data: any) {
    const url = '/Invites/DeleteInvite';
    return this.postAction(url, data);
  }

  public addInviteUsers(data) {
    let token = localStorage.getItem('jwt');
    const tokenReq = (token) ? token.replace(/['"]+/g, '') : '';
    return this.httpClient.post(`${this.urlApi}/Invites/InviteUsers`, data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${tokenReq}`)
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  public addInternalInviteUsers(data) {
    let token = localStorage.getItem('jwt');
    const tokenReq = (token) ? token.replace(/['"]+/g, '') : '';
    return this.httpClient.post(`${this.urlApi}/Invites/InviteInternalUsers`, data,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${tokenReq}`)
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  public reboothUnit(data) {
    return this.postAction('/Units/RebootUnit', data);
  }

  public GetExternalRolesForSelectEdit(currentRoleId: string) {
    const url = `/Roles/GetExternalRolesForSelectEdit?currentRoleId=${currentRoleId}`;
    return this.getAction(url);
  }

  public GetExternalRolesForSelectAddFacility() {
    const url = `/Roles/GetExternalRolesForSelectAddFacility`;
    return this.getAction(url);
  }

  public GetInviteForConfirmAccount(idInvite: number) {
    const url = `/Invites/GetInviteForConfirmAccount?id=${idInvite}`;
    return this.getAction(url);
  }

  /** methodo get */
  public getAction(url: string) {
    let token = localStorage.getItem('jwt');
    const tokenReq = (token) ? token.replace(/['"]+/g, '') : '';
    let urlEndpoint = '';
    urlEndpoint = `${this.urlApi}${url}`;

    return this.httpClient.get(urlEndpoint,
      {
        headers: tokenReq === '' ? this.headers : this.headers.set('Authorization', `Bearer ${tokenReq}`)
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  public postAction(url: string, data: any) {
    let token = localStorage.getItem('jwt');
    const tokenReq = (token) ? token.replace(/['"]+/g, '') : '';
    let urlEndpoint = '';
    urlEndpoint = `${this.urlApi}${url}`;
    return this.httpClient.post(urlEndpoint, data,
      {
        headers: tokenReq === '' ? this.headers : this.headers.set('Authorization', `Bearer ${tokenReq}`)
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  public putAction(url: string, data: any) {
    let token = localStorage.getItem('jwt');
    const tokenReq = (token) ? token.replace(/['"]+/g, '') : '';
    let urlEndpoint = '';
    urlEndpoint = `${this.urlApi}${url}`;
    return this.httpClient.put(urlEndpoint, data,
      {
        headers: tokenReq === '' ? this.headers : this.headers.set('Authorization', `Bearer ${tokenReq}`)
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(transformError)
      );
  }

  /**
   * @description metodo que desaciar un facility de la unidad
   * @param recibe objeto con el id de la unidad
   * @author Ing. David Canache
   * @version 1.0
   *
   */
  public getUserInternal(paramsUrl: string, PageNumber: number = 1) {
    const url = paramsUrl + '&PageNumber=' + PageNumber;
    return this.getAction(url);
  }

  public getRolesInternalForUpdateSelect() {
    const url = `/Roles/GetInternalRolesForSelect`;
    return this.getAction(url);
  }

  public GetInternalRolesForSelectEdit(currentRoleId: string) {
    const url = `/Roles/GetInternalRolesForSelectEdit?currentRoleId=${currentRoleId}`;
    return this.getAction(url);
  }

  public getUnassignedl(paramsUrl: string, PageNumber: number = 1) {
    const url = paramsUrl + '&PageNumber=' + PageNumber;
    return this.getAction(url);
  }

  public deleteUnassignedl(data: any) {
    const url = '/Users/DeleteUser';
    return this.postAction(url, data);
  }
  /**
   * @description metodo que permite asignar una o varia unidades
   * @param recibe array de objetos de unidades
   * @author Ing. Mari Alvarez
   * @version 1.0
   *
   */
   public postReAssignUnit(data) {
    const url = '/Units/ReAssignUnit';
    return this.postAction(url, data);
  }

  public deleteUnit(data: any) {
    const url = '/Units/DeleteUnit';
    return this.postAction(url, data);
  }

  public postAddOrganizations(data) {
    const url = '/Organizations/AddOrganization';
    return this.postAction(url, data);
  }

}
