import { Injectable } from '@angular/core';
import { MenuModel, RolMenuModel, ROLSMENU } from '@Models/menu.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavbarService {
  
   private _rolsMenu$: BehaviorSubject<MenuModel[]> =
   new BehaviorSubject<MenuModel[]>([]);

  constructor() { }

  public get rolsMenu$(): MenuModel[] {
    return this._rolsMenu$.getValue();
  }

  public set rolsMenu$(dataRolsMenu: MenuModel[]) {    
    this._rolsMenu$.next(dataRolsMenu);
  }

  public onRolsMenu$(): Observable<MenuModel[]> {
    return this._rolsMenu$.asObservable();
  }
}
