import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppServices } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { facilityRol, internalsRol, organizationRol } from '@Models/role.model';
import { RolsEnum } from '@Models/enum/rols.enum';
import { SideNavbarService } from 'src/app/services/side-navbar.service';
import { ActionMenuService } from 'src/app/services/action-menu.service';

export interface ResponseDataInvite {
  data: any;
  meta: any;
  count: number;
}

@Component({
  selector: 'app-internal-invite-management',
  templateUrl: './internal-invite-management.component.html',
  styleUrls: ['./internal-invite-management.component.sass'],
})
export class InternalInviteManagementComponent implements OnInit, OnDestroy {
  inviteManagementForm: FormGroup;
  activeFilters: boolean = false;
  isDisabled: boolean = false;
  modalRef: BsModalRef | null;
  listFacilities: any = [];
  listDataUser: any = [];
  listDataUserEdit: any = [];
  msgError: any[] = [];
  public userRole: RolsEnum;
  message;
  rowActual;
  internalRol: boolean = false;
  oraganizationListRol: boolean = false;
  roleLevel = +this.authService.getRoleLevel();
  activeLess: boolean = false;
  dataInvite: ResponseDataInvite = {
    data: [],
    meta: [],
    count: 0,
  };

  searchAdvancedFrom: FormGroup;

  constructor(
    private modalService: BsModalService,
    private sideNavbarService: SideNavbarService,
    private fb: FormBuilder,
    private appServices: AppServices,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
    private actionMenu: ActionMenuService
  ) {
    this.userRole = this.authService.authStatus.getValue().role;
    const rolInternal = this.authService.getInternalRole();
    if (rolInternal) {
      this.internalRol = rolInternal === 'False' ? false : true;
    }
    const isOrganizationRol = organizationRol.filter(
      (item) => item.role === this.userRole
    );
    this.oraganizationListRol = isOrganizationRol.length > 0 ? true : false;
    const isFacilityRol = facilityRol.filter(
      (item) => item.role === this.userRole
    );


    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      const indexUserList = menu.findIndex(
        (item) => item.link === '/users-list'
      );
      const indexUserInvite = menu.findIndex(
        (item) => item.link === '/users-invite'
      );
      let indexMneChange = indexUserList > -1 ? indexUserList : indexUserInvite;
      if (indexMneChange !== -1) {
        menu[indexMneChange].link = this.router.url;
        this.sideNavbarService.rolsMenu$ = menu;
      }
    }
    this.searchAdvancedFrom = this.fb.group({
      email: [''],
      role: ['0'],
    });
  }

  ngOnDestroy(): void {
    if (this.internalRol) {
      let menu = this.sideNavbarService.rolsMenu$;
      const menuUser = ['/users-list', '/users-invite'];
      const search = menuUser.includes(this.router.url);
      if (!search) {
        const indexUserList = menu.findIndex(
          (item) => item.link === '/invite-management'
        );
        if (indexUserList !== -1) {
          menu[indexUserList].link = '/users-list';
          this.sideNavbarService.rolsMenu$ = menu;
        }
      }
    }
  }

  ngOnInit(): void {
    this.actionMenu.setSelectMenu('IU');
    this.buildInviteManagementForm();
    this.getAllInvite();
    this.getRolesForUpdateSelect();
    if (this.dataInvite.meta.totalPages === 1) {
      this.isDisabled = true;
      this.activeLess = false;
    }
  }
  filters() {
    this.activeFilters = !this.activeFilters;
  }

  buildInviteManagementForm(): void {
    this.inviteManagementForm = this.fb.group({
      email: ['', [Validators.email]],
      role: ['', [Validators.required]],
    });
    this.inviteManagementForm.get('email').disable();
  }

  getAllInvite() {
    const urlEndPoint = this.urlService();

    let listpage =
      JSON.parse(localStorage.getItem('listRecord')) === null ? true : false;
    if (listpage) {
      this.appServices.getInviteUser(urlEndPoint).subscribe(
        (data) => {
          const countData = data['data'];
          this.dataInvite.data = data['data'];
          this.dataInvite.meta = data['meta'];
          this.dataInvite.count = countData.length;
          this.isDisabled =
            this.dataInvite.meta.currentPage === this.dataInvite.meta.totalPages
              ? true
              : false;
        },
        (error) => {
          this.isDisabled = true;
          //this.toastrService.error(errors, 'error');
        }
      );
    } else {
      this.dataInvite = JSON.parse(localStorage.getItem('listRecord'));
      this.isDisabled =
        this.dataInvite.meta.currentPage === this.dataInvite.meta.totalPages
          ? true
          : false;
      if (this.dataInvite.data.length > 5) {
        this.activeLess = true;
      }
    }
  }

  urlService(): string {
    let url = '/Invites/GetAllInternal?PageSize=5';
    let paramEmail = this.searchAdvancedFrom.get('email').value;
    let paramRole = this.searchAdvancedFrom.get('role').value;

    if (paramEmail !== '') {
      url = url + '&Email=' + paramEmail;
    }

    if (paramRole !== '0') {
      url = url + '&RoleId=' + paramRole;
    }

    return url;
  }

  search() {
    //this.isDisabled = false;
    //this.getAllInvite();

    const urlEndPoint = this.urlService();

    this.appServices.getInviteUser(urlEndPoint).subscribe(
      (data) => {
        const countData = data['data'];
        this.dataInvite.data = data['data'];
        this.dataInvite.meta = data['meta'];
        this.dataInvite.count = countData.length;
        this.isDisabled =
          this.dataInvite.meta.currentPage === this.dataInvite.meta.totalPages
            ? true
            : this.dataInvite.meta.totalPages === 0
            ? true
            : false;
        let pageNumber = this.dataInvite.meta.currentPage;
        if (pageNumber === 1) {
          this.activeLess = false;
        }
      },
      (error) => {
        let errors = JSON.parse(error);
        console.log(errors);
        //this.toastrService.error(errors, 'error');
      }
    );
  }

  async viewMore() {
    let pageSize = 5;
    this.activeLess = true;

    let pageNumber = this.dataInvite.meta.currentPage + 1;
    let urlService = this.urlService();
    if (urlService) {
      let dataViewMore = await this.appServices
        .getInviteUser(urlService, pageNumber)
        .toPromise();
      this.dataInvite.meta = dataViewMore['meta'];
      this.dataInvite.data = [...this.dataInvite.data, ...dataViewMore['data']];
      const countData = this.dataInvite.data;
      this.dataInvite.count = countData.length;
    }
    this.isDisabled =
      this.dataInvite.meta.currentPage === this.dataInvite.meta.totalPages
        ? true
        : false;
    localStorage.setItem('listRecord', JSON.stringify(this.dataInvite));
  }

  async viewLess() {
    let pageSize = 5;

    let pageNumber = this.dataInvite.meta.currentPage - 1;
    let urlService = this.urlService();
    if (pageNumber === 1 || pageNumber === 0) {
      this.activeLess = false;
    }
    if (urlService) {
      let dataViewMore = await this.appServices
        .getInviteUser(urlService, pageNumber)
        .toPromise();

      if (pageNumber === 1 || pageNumber === 0) {
        this.dataInvite.data = [];

        this.dataInvite.meta = dataViewMore['meta'];
        this.dataInvite.data = [
          ...this.dataInvite.data,
          ...dataViewMore['data'],
        ];
        const countData = this.dataInvite.data;
        this.dataInvite.count = countData.length;
      } else {
        this.dataInvite.meta = dataViewMore['meta'];
        this.dataInvite.data = [
          ...this.dataInvite.data,
          ...dataViewMore['data'],
        ];
        this.dataInvite.data.splice(this.dataInvite.data.length - 10, 10);
        const countData = this.dataInvite.data;
        this.dataInvite.count = countData.length;
      }
      this.isDisabled =
        this.dataInvite.meta.currentPage === this.dataInvite.meta.totalPages
          ? true
          : false;
    }
    localStorage.setItem('listRecord', JSON.stringify(this.dataInvite));
  }

  getRolesForUpdateSelect() {
    this.appServices.getRolesInternalForUpdateSelect().subscribe((data) => {
      this.listDataUser = data['data'];
    });
  }

  getInternalRolesForSelectEdit(roleId: string) {
    this.appServices.GetExternalRolesForSelectEdit(roleId).subscribe((data) => {
      this.listDataUserEdit = data['data'];
    });
  }

  openModal(template: TemplateRef<any>) {
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-md modal-dialog-centered',
    });
  }

  openModalUser(template: TemplateRef<any>, user, index) {
    this.rowActual = index;
    this.msgError = [];
    this.appServices
      .GetInternalRolesForSelectEdit(user.roleId)
      .subscribe((data) => {
        this.listDataUserEdit = data['data'];
        this.inviteManagementForm.patchValue({
          email: user.email,
          role: user.roleId,
        });
        this.modalRef = this.modalService.show(template, {
          id: user,
          class: 'modal-md modal-dialog-centered customListModal',
        });
      });
  }

  openDeleteModal(template: TemplateRef<any>, user, index) {
    this.rowActual = index;
    this.msgError = [];
    this.modalRef = this.modalService.show(template, {
      id: user,
      class: 'modal-md modal-dialog-centered modal-fit',
    });
  }
  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.hide();
    this.modalRef = null;
  }

  closeDeleteModal(template) {
    if (!this.modalRef) {
      return;
    }

    const inviteDeletesData = {
      id: this.modalRef.id['id'],
      email: this.modalRef.id['email'],
    };
    this.appServices.deleteInvites(inviteDeletesData).subscribe(
      (data) => {
        //this.search();
        let pageNumber = this.dataInvite.meta.currentPage;
        this.dataInvite.meta = [];
        this.dataInvite.data = [];
        //console.log(pageNumber);
        for (let i = 0; i < pageNumber; i++) {
          this.dataPageReturn(i + 1);
        }
        //this.toastrService.success('Succes', 'Was successfully');
        this.modalRef.hide();
        //this.modalRef = null;
        this.message = 'deleted';
        this.modalRef = this.modalService.show(template, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);
      },
      (error) => {
        let errors = JSON.parse(error);
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const msjError = errorValue.replace('-', '');
                this.msgError.push(msjError);
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
        this.inviteManagementForm.patchValue({
          email: this.modalRef.id['email'],
        });
      }
    );
  }
  async dataPageReturn(pageNumber) {
    let urlService = this.urlService();

    let dataViewMore = await this.appServices
      .getInviteUser(urlService, pageNumber)
      .toPromise();
    this.dataInvite.meta = dataViewMore['meta'];
    this.dataInvite.data = [...this.dataInvite.data, ...dataViewMore['data']];
    const countData = this.dataInvite.data;
    this.dataInvite.count = countData.length;
    localStorage.setItem('listRecord', JSON.stringify(this.dataInvite));

    if (this.dataInvite.data.length === 0 && pageNumber === 2) {
      this.activeLess = false;
    }
  }
  closeFirstModalUser(succesfully) {
    if (!this.modalRef) {
      return;
    }
    const invitesData = {
      id: this.modalRef.id['id'],
      email: this.inviteManagementForm.get('email').value,
      roleId: this.inviteManagementForm.get('role').value,
    };
    this.appServices.invitesEdit(invitesData).subscribe(
      (data) => {
        //this.toastrService.success('Succes', 'Was successfully');

        let dataCom = this.inviteManagementForm.get('role').value;
        this.dataInvite.data[this.rowActual].roleId = dataCom;
        this.dataInvite.data[this.rowActual].role = this.listDataUserEdit.find(
          (d) => d.id === dataCom
        ).name;
        localStorage.setItem('listRecord', JSON.stringify(this.dataInvite));

        this.modalRef.hide();
        this.modalRef = null;
        this.getAllInvite();
        this.message = 'updated';
        this.modalRef = this.modalService.show(succesfully, {
          id: 1,
          class: 'modal-md modal-dialog-centered succesfullyCustom',
        });
        //setTimeout(this.modalRef.hide,5000)
        let timerId = setInterval(() => this.modalRef.hide(), 3000);
        setTimeout(() => {
          clearInterval(timerId);
          this.modalRef.hide();
        }, 3000);

        this.inviteManagementForm.reset();
        this.inviteManagementForm.patchValue({
          email: '',
          role: '',
        });
      },
      (error) => {
        let responseError = JSON.parse(error);
        this.msgError = [];
        if (typeof responseError === 'object') {
          for (const key in responseError) {
            if (Object.prototype.hasOwnProperty.call(responseError, key)) {
              const element = responseError[key];
              for (const error of element) {
                let errorValue = error.replace(/['\\n]+/g, '');
                const mensajeError = errorValue.replace('-', '');
                this.msgError.push(mensajeError);
              }
            }
          }
        } else {
          const errorValue = error.replace(/['"]+/g, '');
          const mensajeError = errorValue.replace('-', '');
          this.msgError.push(mensajeError.replace(/['\\n]+/g, ''));
        }
        this.inviteManagementForm.patchValue({
          email: this.modalRef.id['email'],
        });
      }
    );
  }
  changeMenu() {
    localStorage.setItem('listRecord', null);
  }
}
